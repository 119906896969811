import React, { PureComponent } from 'react';
import { isMobile } from 'react-device-detect';
import System from '../models/System';
import Avatar from './Avatar';
import { get as L } from '../models/Label';

class ChatMessageItem extends PureComponent {

    constructor(props) {
        super(props);
    };

    getImages(images, process) {
        if (!Array.isArray(images)) {
            images = images.split(',');
        }
        const style = {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(192px, 1fr))',
            gridGap: '5px',
        }
        return <div style={style}>
            {images && images.map((v, i) => {
                if (!v) {
                    return null;
                }
                return <img
                    key={v}
                    className="chat-image pointer"
                    src={process === 1 ? v : System.resolveThumnailPath(v, 'chat')}
                    alt={L('รูปที่') + ' ' + (i + 1)}
                    // openImage, images, path, selectedIndex
                    onClick={() => process === 1 ? null : this.props.showImageHandler(true, images, 'chat', i)}
                />;
            })}
        </div>;
    }

    render() {
        const { data, profile } = this.props;
        const isUser = parseInt(data.user_id) === parseInt(System.profile?.id);
        const cls = isUser ? 'user ml-3' : 'friend mr-3';
        const { images, message } = data;
        // console.log(data);
        let style = { maxWidth: '60%' };
        if (isMobile) {
            style = { maxWidth: '100%' };
        }
        return <div className={'chat-message-item ' + cls}>
            <div className={"d-flex align-items-start"} style={style}>
                {!isUser && <Avatar
                    className="mr-3"
                    src={profile}
                    iconFill />}
                <div className={'chat-message flex-1'}>
                    {images
                        ? this.getImages(images, data.process)
                        : <span>{message}</span>}
                </div>
            </div>
            {data.process === 1 && <div className="spinner spinner-border" role="status" style={{ opacity: .4 }}>
                <span className="sr-only">Loading...</span>
            </div>}
        </div>;
    }
}

export default ChatMessageItem;