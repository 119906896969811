import React, { PureComponent } from 'react';
import Icon from '../models/Icon';

class Avatar extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        // console.log(this.props.badges);
        let style = null;
        if (this.props.backgroundColor || this.props.style) {
            style = { ...this.props.style, backgroundColor: this.props.backgroundColor };
        }
        style = this.props.iconFill ? {
            ...style,
            backgroundImage: 'url("' + (this.props.src ?? Icon.get('avatar')) + '")',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        } : style;
        let iconStyle = {};
        if (this.props.iconWidth || this.props.iconHeight) {
            iconStyle = { width: this.props.iconWidth, height: this.props.iconHeight };
        }
        return (
            <div
                className={`avatar ${this.props.className ?? ''}`}
                style={style}
                onClick={this.props.onClick ?? null}
                onMouseDown={this.props.onMouseDown ?? null}>
                {!this.props.iconFill && <img src={this.props.src} alt={this.props.alt} style={iconStyle} />}
                {this.props.badges >= 1 && <span className="badge">{this.props.badges}</span>}
                {this.props.unread === true && <span className="spot-unread"></span>}
            </div>
        );
    }
}

export default Avatar;
