import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import homeIcon from '../assets/images/home-icon-mobile.svg';
import homeIconSelected from '../assets/images/home-icon-white-mobile.svg';
import newsIcon from '../assets/images/news-icon-mobile.svg';
import newsIconSelected from '../assets/images/news-icon-white-mobile.svg';
import marketIcon from '../assets/images/market-icon-mobile.svg';
import marketIconSelected from '../assets/images/market-icon-white-mobile.svg';
import activityIcon from '../assets/images/activity-icon-mobile.svg';
import activityIconSelected from '../assets/images/activity-icon-white-mobile.svg';
import otherIcon from '../assets/images/other-icon-mobile.svg';
import otherIconSelected from '../assets/images/other-icon-white-mobile.svg';

import { get as L } from '../models/Label';
import System from '../models/System';

class ButtomNavigator extends PureComponent {

    constructor(props) {
        super(props);

        this.menus = [
            { id: 'menu_1', label: L('หน้าหลัก'), icon: homeIcon, selectedIcon: homeIconSelected, path: 'home' },
            { id: 'menu_2', label: L('ข่าวสาร'), icon: newsIcon, selectedIcon: newsIconSelected, path: 'news' },
            { id: 'menu_3', label: L('ร้านค้า'), icon: marketIcon, selectedIcon: marketIconSelected, path: 'market' },
            { id: 'menu_4', label: L('กิจกรรม'), icon: activityIcon, selectedIcon: activityIconSelected, path: 'activity' },
            { id: 'menu_5', label: L('อื่น'), icon: otherIcon, selectedIcon: otherIconSelected, path: 'other' }
        ];

        // console.log(this.props);
    };

    getMenuItem(item) {
        // console.log(item.path, this.props.path);
        let selected;
        if (this.props.path === System.publicPath && item.path === System.resolvePath('home')) {
            selected = true;
        } else {
            selected = this.props.path !== System.publicPath && item.path.indexOf(this.props.path) !== -1;
        }
        const className = "menu-bottom " + (selected ? 'active' : '');
        return <Link to={System.resolvePath(item.path)} className={className}>
            <img src={selected ? item.selectedIcon : item.icon} alt={item.label} />
        </Link>;
    }

    render() {
        return (
            <div className="nav-bottom">
                {this.menus.map((value) => this.getMenuItem(value))}
            </div>
        );
    }
}

export default ButtomNavigator;
