import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// const testData = [
//     {
//         name: 'Page A',
//         uv: 4000,
//         pv: 2400,
//         amt: 2400,
//     },
//     {
//         name: 'Page B',
//         uv: 3000,
//         pv: 1398,
//         amt: 2210,
//     },
//     {
//         name: 'Page C',
//         uv: 2000,
//         pv: 9800,
//         amt: 2290,
//     },
//     {
//         name: 'Page D',
//         uv: 2780,
//         pv: 3908,
//         amt: 2000,
//     },
//     {
//         name: 'Page E',
//         uv: 1890,
//         pv: 4800,
//         amt: 2181,
//     },
//     {
//         name: 'Page F',
//         uv: 2390,
//         pv: 3800,
//         amt: 2500,
//     },
//     {
//         name: 'Page G',
//         uv: 3490,
//         pv: 4300,
//         amt: 2100,
//     },
// ];

class OrderChart extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            activeIndex: -1,
            data: this.constructData(),
        };
    };

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.constructData() });
        }
    }

    constructData() {
        return this.props.data;
    }
    render() {
        const { activeIndex, data } = this.state;
        const { width, height } = this.props;
        const _h = height ?? 300;
        return (
            <div className="w-100 font-size-0" style={{ height: _h + 'px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        height={_h}
                        data={data}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        {/* <Legend /> */}
                        {/* <Bar dataKey="amount" fill="#FFCA3E" /> */}
                        <Bar dataKey="amount" fill="#665191" />
                        {/* <Bar dataKey="amount" fill="#a05195" /> */}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

export default OrderChart;
