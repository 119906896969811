import React, { PureComponent } from 'react';
import ErrorText from './ErrorText';

class InputSwitch extends PureComponent {

    constructor(props) {
        super(props);
        if (this.props.name) {
            this.id = 'input_' + this.props.name + '_' + (this.props.index ?? '');
        } else {
            this.id = 'input_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000);
        }
    };

    onChange(e) {
        if (this.props.controller) {
            this.props.controller.set(this.props.name, e.target.checked ? 1 : 0, true);
        } else {
            if (this.props.onChange) {
                this.props.onChange(e);
            }
        }
    }

    render() {
        const { checked, text, name } = this.props;
        const content = <>
            <div className="custom-control custom-switch">
                <input
                    className="custom-control-input"
                    type="checkbox"
                    id={'ch_' + name}
                    checked={checked}
                    onChange={this.onChange.bind(this)}
                />
                <label className="custom-control-label" htmlFor={'ch_' + name}>{text}</label>
            </div>
            <ErrorText isError={this.props.isError}>{this.props.errorMessage}</ErrorText>
        </>;
        if (!this.props.label) {
            return content;
        }
        return (
            <div className="form-group">
                <label htmlFor={this.id}>{this.props.label}</label>
                {content}
            </div>
        );
    }
}

export default InputSwitch;
