import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';

import InputBox from '../components/InputBox';
import InputImageBox from '../components/InputImageBox';
import FormTitle from '../components/FormTitle';
import Controller from '../models/Controller';
import Form from '../components/Form';
import Button from '../components/Button';

import { validateEmptyValue, validateNumber } from '../utils/UValidate';
import { isEmpty } from '../utils/UObj';

import { isMobile } from 'react-device-detect';
import AppBar from '../components/AppBar';
import System from '../models/System';
import TextAreaBox from '../components/TextAreaBox';
import API from '../models/API';
import InputSwitch from '../components/InputSwitch';
import ParagraphForm from '../components/ParagraphForm';

class AdminNewsFormPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            valid: {},
        };

        // console.log(this.props);

        const formData = this.props.location?.formData;
        // console.log(formData);
        this.controller = new Controller(this, {
            images: [],
            status: 1,
            paragraphs: [{
                id: 'paragraph_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000),
                description: '',
                images: [],
            }],
            ...formData
        });

        if (!System.isAdmin()) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('home'));
            return;
        }

        //check id
        const _path = System.matchPath(this.props.location.pathname, 'admin/news/:newId');
        if (_path) {
            this.id = validateNumber(_path.params.newId);
            if (this.id) {
                this.isEdit = true;
            }
        }
    };

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        if (!this.isEdit) {
            return;
        }

        //check loaded
        if (this.controller.get('name')) {
            return;
        }

        this.controller.loadData(API.API_NEWS + '/' + this.id, 'news', null, (data) => {
            if (!data) {
                this.isError = true;
                this.props.history.replace(System.resolvePath('admin/news'));
                return;
            }

            let obj;
            let paragraphs = this.controller.get('paragraphs');
            if (data['paragraphs']) {
                paragraphs = [];
                for (let k in data['paragraphs']) {
                    obj = data['paragraphs'][k];
                    // console.log(obj.image);
                    paragraphs.push({
                        id: obj.id,
                        description: obj.description,
                        images: [obj],
                    });
                }
                // console.log(paragraphs);
            }

            const formData = {
                images: data['images'],
                name: data['name'],
                description: data['description'],
                status: data['status'],
                reference: data['reference'],
                paragraphs
            };

            this.controller.append(formData);
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    closeButtonClickHandler(index) {
        // console.log(index);
        const paragraphs = this.controller.get('paragraphs');
        const paragraph = paragraphs[index];
        if (validateNumber(paragraph.id)) {
            const removeFields = this.controller.get('remove_paragraphs') ?? [];
            removeFields.push(paragraph.id);
            this.controller.set('remove_paragraphs', removeFields);
        }
        paragraphs.splice(index, 1);
        this.controller.set('paragraphs', paragraphs, true);
    }

    async delete() {
        await this.setState({ openDeleteDialog: true });
        window.openModalDialog('deleteModal', () => {
            this.setState({ openDeleteDialog: false });
        });
    }

    confirmDelete() {
        this.controller.deleteData(API.API_NEWS + '/' + this.id,
            (data) => {
                window.hideModalDialog('deleteModal');
                this.setState({ openDeleteDialog: false });
                this.props.history.goBack();
            },
            (error) => {
                window.openMessageModalDialog(error);
            });
    }

    async submit() {

        // console.log('submit');
        this.setState({ valid: {} });

        const c = this.controller;
        const params = { ...c.all() };

        const name = params['name'] ?? '';
        const description = params['description'] ?? '';
        const images = params['images'] ?? [];
        const paragraphs = params['paragraphs'];

        const valid = {
            name: validateEmptyValue(name),
            description: validateEmptyValue(description),
            // image: images.length !== 0
        };
        // console.log(valid);

        //validate open not necessary

        for (let k in valid) {
            if (valid[k] === false) {
                this.setState({ valid });
                console.log('invalid');
                return;
            }
            if (Array.isArray(valid[k])) {
                for (let i in valid[k]) {
                    if (valid[k][i] === false) {
                        this.setState({ valid });
                        console.log('invalid');
                        return;
                    }
                }
            }
        }
        // console.log(valid);

        let i = 0;
        let key;
        // console.log(images);
        let file, blob;
        i = 0;
        for (let k in images) {
            if (!isEmpty(images[k]) && typeof images[k] === 'object') {
                continue;
            }
            blob = await fetch(images[k]).then(r => r.blob());
            file = new File([blob], 'image_' + i + '.jpg', { type: 'image/jpg' });
            params['image_' + i] = file;
            i++;
        }
        if (params['remove_images']) {
            params['remove_images'] = params['remove_images'].toString();
        }
        delete params['images'];

        i = 0;
        let paragraph;
        let pimages;
        for (let k in paragraphs) {
            paragraph = paragraphs[k];
            params['paragraph_id_' + i] = paragraph['id'];
            params['paragraph_description_' + i] = paragraph['description'];
            pimages = paragraph.images;
            for (let l in pimages) {
                if (!isEmpty(pimages[l]) && typeof pimages[l] === 'object') {
                    continue;
                }
                blob = await fetch(pimages[l]).then(r => r.blob());
                file = new File([blob], 'paragraph_image_' + i + '.jpg', { type: 'image/jpg' });
                params['paragraph_image_' + i] = file;
                break;
            }
            i++;
        }
        if (params['remove_paragraphs']) {
            params['remove_paragraphs'] = params['remove_paragraphs'].toString();
        }
        delete params['paragraphs'];

        const formData = new FormData();
        let value;
        for (let key in params) {
            value = params[key];
            if (isEmpty(value)) {
                continue;
            }
            formData.append(key, value);
        }

        // console.log(params);
        // return;

        if (this.isEdit) {
            this.controller.putMultipathData(API.API_NEWS + '/' + this.id, formData,
                (data) => {
                    this.props.history.goBack()
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        } else {
            this.controller.postMultipathData(API.API_NEWS, formData,
                (data) => {
                    this.props.history.goBack()
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        }

    }

    render() {
        if (this.isError) {
            return null;
        }
        // console.log('AdminNewsFormPage render');
        const c = this.controller;
        const data = c.all();
        let { paragraphs, status } = data;
        const { valid, post, openDeleteDialog, destroy } = this.state;
        const loading = (post && post.loading) || (destroy && destroy.loading);
        // console.log(paragraphs);
        return (
            <div className="section-content no-nav">
                <Form
                    loading={post.loading}
                    className="form-register">
                    <AppBar
                        title={L('ข้อมูลข่าว')}
                        subTitle={L('กรอกข้อมูลข่าวและคำอธิบาย')}
                        showLeftArrow={true}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <InputImageBox
                                path="news"
                                name="images"
                                description={L('รูปภาพ: คุณสามารถอัพรูปปกเพื่อ แนะนำข่าวของคุณเองได้ ขนาดของรูปที่เหมาะสมคือ 1280x720 พิกเซลและอัพได้ 1 รูป')}
                                max={1}
                                controller={c}
                                label={L('รูปปกข่าว*')}
                                isError={valid.image === false}
                                errorMessage={L('กรุณาเพิ่มรูปปกข่าว')} />
                        </div>
                        <div className="col-12">
                            <InputBox
                                label={L('หัวข้อข่าว*')}
                                placeholder={L('หัวข้อข่าว')}
                                name="name"
                                controller={c}
                                isError={valid.name === false}
                                errorMessage={L('กรุณากรอกหัวข้อข่าว')} />
                        </div>
                        <div className="col-12">
                            <TextAreaBox
                                label={L('รายละเอียดข่าว*')}
                                placeholder={L('เนื้อหาข่าว')}
                                name="description"
                                controller={c}
                                isError={valid.description === false}
                                errorMessage={L('กรุณากรอกรายละเอียดข่าว')} />
                        </div>
                        <div className="col-6">
                            <InputBox
                                label={L('แหล่งที่มา')}
                                placeholder={L('แหล่งที่มาของข่าว')}
                                name="reference"
                                controller={c} />
                        </div>
                        <div className="col-6">
                            <InputSwitch
                                name="status"
                                checked={parseInt(status) === 1}
                                label={L('สถานะข่าว')}
                                text={L('แสดงในหน้าข่าว')}
                                controller={c} />
                        </div>
                    </div>

                    <FormTitle
                        label={L('คำอธิบายพร้อมรูปภาพ')}
                        subLabel="รูปภาพ: รูปเกี่ยวกับข่าว ขนาดของรูปที่เหมาะสมคือ 1024x576 พิกเซล"
                        onButtonPress={() => {
                            //test
                            paragraphs.push({
                                id: 'paragraph_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000),
                                description: '',
                                images: [],
                            });
                            c.set('paragraphs', paragraphs, true);
                        }} />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <div className="form-row form-section-register">
                                <div className="col-12">
                                    {paragraphs && paragraphs.map((v, i) => <ParagraphForm
                                        key={v.id}
                                        index={i}
                                        controller={c}
                                        path="news"
                                        closeButtonClickHandler={this.closeButtonClickHandler.bind(this)}
                                        showCloseButton={paragraphs.length > 1} />)}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row mt-4">
                        {this.isEdit && <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => this.delete()}>
                                {L('ลบข้อมูล')}
                            </Button>
                        </div>}
                        <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.submit()}>
                                {L('บันทึกข้อมูล')}
                            </Button>
                        </div>
                    </div>

                </Form>

                {openDeleteDialog && <div id="deleteModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('ลบข้อมูล')}</h5>
                            </div>
                            <div className="modal-body">
                                <p>{L('คุณต้องการลบข้อมูลนี้ใช่หรือไม่')}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-3"
                                    onClick={() => {
                                        window.hideModalDialog('deleteModal');
                                        this.setState({ openDeleteDialog: false });
                                    }}>{L('ยกเลิก')}</button>
                                <button
                                    type="button"
                                    className="btn btn-primary mr-3"
                                    onClick={() => {
                                        this.confirmDelete();
                                    }}>{L('ลบข้อมูล')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}

export default withRouter(AdminNewsFormPage);