import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
// import AppBar from '../components/AppBar';
// import { get as L } from '../models/Label';

import RegisterProfileContent from '../components/RegisterProfileContent';
import System from '../models/System';

class RegisterPage extends Component {

    constructor(props) {
        super(props);
    };

    componentDidMount() {
    }

    success(data) {
        // console.log('RegisterPage success => ', data);
        System.setProfile(data.user);
        System.setToken(data.token);
        this.props.history.replace(System.resolvePath('home'));
    }

    render() {
        return (
            <div className="page-register container">
                <div className="box-register pb-5">
                    <RegisterProfileContent
                        success={(data) => this.success(data)}
                        onBackPress={() => this.props.history.goBack()} />
                </div>
            </div>
        );
    }
}

export default withRouter(RegisterPage);
