import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import homeIcon from '../assets/images/home-icon-gray.svg';
import homeIconSelected from '../assets/images/home-icon-white.svg';
import newsIcon from '../assets/images/news-icon-gray.svg';
import newsIconSelected from '../assets/images/news-icon-white.svg';
import shopIcon from '../assets/images/shop-icon-gray.svg';
import shopIconSelected from '../assets/images/shop-icon-white.svg';
import activityIcon from '../assets/images/activity-icon-gray.svg';
import activityIconSelected from '../assets/images/activity-icon-white.svg';

import { get as L } from '../models/Label';
import System from '../models/System';

class HeaderContentSection extends PureComponent {

    constructor(props) {
        super(props);

        this.menus = [
            { id: 'menu_1', label: L('หน้าหลัก'), icon: homeIcon, selectedIcon: homeIconSelected, path: 'home', ally: 'feed' },
            { id: 'menu_2', label: L('ข่าวสาร'), icon: newsIcon, selectedIcon: newsIconSelected, path: 'news' },
            { id: 'menu_3', label: L('ร้านค้า'), icon: shopIcon, selectedIcon: shopIconSelected, path: 'market' },
            { id: 'menu_4', label: L('กิจกรรม'), icon: activityIcon, selectedIcon: activityIconSelected, path: 'activity' }
        ];

        // console.log(this.props);
    };

    checkActive(path) {
        if (!path) {
            return false;
        }
        return this.props.path !== System.publicPath && this.props.path.indexOf(System.resolvePath(path)) !== -1;
    }

    getMenuItem(item) {
        // console.log(item.path, this.props.path, System.publicPath);
        let selected;
        if (this.props.path === System.publicPath && item.path === 'home') {
            selected = true;
        } else {
            const pub = this.props.path.indexOf('/user') === -1 && this.props.path.indexOf('/admin') === -1;
            selected = pub && (this.checkActive(item.path) || this.checkActive(item.ally));
        }
        const className = "menu-header " + (selected ? 'active' : '');
        return <Link key={item.id} to={System.resolvePath(item.path)} className={className}>
            <img src={selected ? item.selectedIcon : item.icon} alt={item.label} />
        </Link>;
    }

    render() {
        return (
            <div className="header-section-content">
                {this.menus.map((value) => this.getMenuItem(value))}
            </div>
        );
    }
}

export default HeaderContentSection;
