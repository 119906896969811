import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';

import Controller from '../models/Controller';

import { isMobile } from 'react-device-detect';
import AppBar from '../components/AppBar';
import System from '../models/System';

// import API from '../models/API';
import Icon from '../models/Icon';
import { nf } from '../utils/UObj';
import DashboardScalar from '../components/DashboardScalar';
import Label from '../components/Label';
// import DashboardReviewItem from '../components/DashboardReviewItem';
import moment from 'moment';
import InputBox from '../components/InputBox';
import OrderChart from '../components/OrderChart';
import { renderImage, renderIndex, renderNumberFormat } from '../utils/UTable';
import Table from '../components/Table';

class UserDashboardPage extends Component {

    constructor(props) {
        super(props);

        const tableHeaders = [
            { title: L('ลำดับที่'), render: renderIndex, className: 'text-center', width: '72px' },
            { title: L(''), render: renderImage, width: '64px', path: 'product', imageSize: { width: '64px', height: '52px' } },
            { title: L('ชื่อสินค้า'), data: 'name', className: 'font-size-0 pl-3' },
            { title: L('หมวดหมู่'), data: 'category', width: '200px' },
            { title: L('จำนวน'), data: 'sale_count', render: this.renderUnit.bind(this), className: 'text-right', width: '180px' },
            { title: L('ยอดขาย'), data: 'sale_amount', render: renderNumberFormat, className: 'text-right pr-3', width: '160px' },
        ];

        this.state = {
            tableHeaders
        };
        this.controller = new Controller(this, {
            start_date: moment().startOf('month').format('YYYY-MM-DD'),
            end_date: moment().endOf('month').format('YYYY-MM-DD'),
        });
        if (!System.isFarmer() && !System.isAdmin()) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('home'));
            return;
        }
    };

    componentDidMount() {
        this.loadData();
    }

    componentWillUnmount() {
        // console.log('componentWillUnmount');
        if (this.controller) {
            this.controller.destroy();
        }
    }

    renderUnit(row, col, i, j) {
        const key = col.data;
        return nf(row[key]) + ' ' + row['unit'];
    }

    loadData() {
        this.controller.loadData('user/products/quantity', 'productQty', null, (data) => {
        }, () => {
        });
        // this.controller.loadData('user/products/reviews', 'review', { page: 1, limit: 3 }, (data) => {
        // }, () => {
        // });
        this.loadSaleData();
    }

    loadSaleData() {
        const c = this.controller;
        c.loadData('user/orders/sales', 'order',
            {
                start_date: c.get('start_date'),
                end_date: c.get('end_date')
            }
            , (data) => {
            }, () => {
            });
    }

    sortChangeHandler(col) {
        this.loadSaleData();
    }

    dateHandler(value, name) {
        // console.log(name, value);
        const c = this.controller;
        let sd, ed;
        if (name === 'start_date') {
            sd = moment(value);
            ed = moment(c.get('end_date'));
            if (sd > ed) {
                ed = sd.clone();
            }
        } else {
            sd = moment(c.get('start_date'));
            ed = moment(value);
            if (ed < sd) {
                sd = ed.clone();
            }
        }
        c.set('start_date', sd.format('YYYY-MM-DD'));
        c.set('end_date', ed.format('YYYY-MM-DD'), true);
        this.loadSaleData();
    }

    render() {
        // console.log('UserDashboard render');
        if (this.isError) {
            return null;
        }
        const c = this.controller;
        const data = c.all();
        const { start_date, end_date } = data;
        const { tableHeaders, productQty, review, order } = this.state;

        // const reviews = review?.response?.list ?? [];
        const qty = productQty?.response ?? {};
        const ac = qty.total_activity_count ?? 0;
        const pc = qty.total_product_count ?? 0;
        // const peoductCountData = qty.product_category_count ?? [];

        const sale = order?.response ?? {};
        const tsc = sale?.total_amount_count ?? 0;
        const tsa = sale?.total_amount ?? 0;
        const saleData = sale?.sale_data ?? [];
        const topSale = sale?.top_sale ?? [];
        // console.log(topSale);
        return (
            <div className="section-content flex-column justify-content-start">
                <AppBar
                    title={L('แดชบอร์ด')}
                    subTitle={L('กระดานแสดงข้อมูลภาพรวมของผู้ใช้งาน')}
                    showLeftArrow={isMobile}
                    onBackPress={() => {
                        this.props.history.goBack();
                    }}
                />

                <div className="form-row mb-4">
                    <div className="col">
                        <DashboardScalar
                            icon={Icon.get('product', 'white')}
                            label={'จำนวนสินค้า'}
                            value={nf(pc)}
                            iconBackgroundColor={'#d9534f'}
                        />
                    </div>
                    <div className="col">
                        <DashboardScalar
                            icon={Icon.get('activity', 'white')}
                            label={'จำนวนกิจกรรม'}
                            value={nf(ac)}
                            iconBackgroundColor={'#337ab7'}
                        />
                    </div>
                    <div className="col">
                        <DashboardScalar
                            icon={Icon.get('cart', 'white')}
                            label={'รายการสั่งซื้อ'}
                            value={nf(tsc)}
                            iconBackgroundColor={'#d9534f'}
                        />
                    </div>
                    <div className="col">
                        <DashboardScalar
                            icon={Icon.get('sale', 'white')}
                            label={'ยอดขาย'}
                            value={'฿' + nf(tsa)}
                            iconBackgroundColor={'#d9534f'}
                        />
                    </div>
                </div>
                <div className="form-row mb-4">
                    <div className="col-12 d-flex align-items-center mb-3">
                        <Label className="mr-3" noMargin>{L('กราฟแสดงยอดสั่งซื้อ')}</Label>
                        {L('ตั้งแต่')}
                        <div className="mx-2">
                            <InputBox
                                type="date"
                                name="start_date"
                                value={start_date}
                                onChange={this.dateHandler.bind(this)} />
                        </div>
                        {L('ถึง')}
                        <div className="ml-2">
                            <InputBox
                                type="date"
                                name="end_date"
                                value={end_date}
                                onChange={this.dateHandler.bind(this)} />
                        </div>
                    </div>
                    <div className="col-12 mb-2">
                        <OrderChart data={saleData}></OrderChart>
                    </div>
                    <div className="col-12">
                        <Label>{L('รายการสินค้าทำยอดสูงสุด')}</Label>
                        <Table
                            id="order"
                            maxRows={10}
                            loading={order?.loading === true}
                            headers={tableHeaders}
                            data={topSale}
                            sortChange={this.sortChangeHandler.bind(this)} />
                    </div>
                </div>
                {/* <div className="form-row">
                    <div className="col-12">
                        <Label>{L('การแสดงความคิดเห็นล่าสุด')}</Label>
                        {reviews.map((item) => {
                            return <DashboardReviewItem
                                key={'d_p_r_' + item.id}
                                data={item}
                            />
                        })}
                    </div>
                </div> */}

            </div>
        );
    }
}

export default withRouter(UserDashboardPage);
