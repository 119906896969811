import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import System from '../models/System';
import ImageBackground from './ImageBackground';

class Recommendtem extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        const { data } = this.props;
        return (
            <Link to={this.props.path}>
                <div className="d-flex mt-3">
                    <ImageBackground
                        width={'120px'}
                        height={'80px'}
                        borderRadius={'4px'}
                        src={System.resolveThumnailPath(data.thumbnail, 'product')} />
                    <div className="ml-3 flex-1 pre-line">
                        <div className="reccomend-title label-clip-2">{data.name}</div>
                        <div className="reccomend-name label-clip-2">{data.description}</div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default Recommendtem;
