import React, { Component } from 'react';
import System from '../models/System';
import API from '../models/API';
import ChatMessageItem from './ChatMessageItem';
import Event from '../models/Event';
import Request from '../models/Request';

class ChatMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            preload: false,
            messages: [],
        };
        const { data } = this.props;
        this.id = data?.id;
        this.total = 0;
        this.limit = 0;
        this.offset = 0;
        this.firstTime = true;

        this.mapMember = {};
        this.mapOffsetLoaded = {};
        this.count = 0;
        this.unsendMessage = {};
    };

    componentDidMount() {
        this.loadData();
        this.scroll.addEventListener('scroll', this.handleScroll.bind(this));
        this.event = Event.add(Event.CHAT_MESSAGE_CHANGE, () => {
            // console.log('event to load data');
            this.updateData();
        });
    }

    componentWillUnmount() {
        clearTimeout(this.interval);
        Event.remove(this.event);
    }

    componentDidUpdate() {
        const { data } = this.props;
        const id = data?.id;
        this.mapMember = {};
        if (this.id !== id) {
            this.id = id;

            this.total = 0;
            this.limit = 0;
            this.offset = 0;
            this.firstTime = true;

            this.mapMember = {};
            this.mapOffsetLoaded = {};
            this.count = 0;
            this.unsendMessage = {};

            for (let i in data.members) {
                let member = data.members[i];
                let user = member.user;
                let shop = user.shop;
                this.mapMember[data.members[i].user_id] = shop
                    ? System.resolveImagePath(shop.logo, 'shop')
                    : System.resolveImagePath(user.profile_image, 'profile');
            }
            this.setState({
                ready: false,
                preload: false,
                messages: [],
            });
            this.loadData();
        }
    }

    async addMessage(id, item) {
        const { messages } = this.state;
        this.unsendMessage[id] = item;
        messages.push(this.unsendMessage[id]);
        // console.log('messages before sync', messages);
        await this.setState({ messages });
        this.scrollToBottom();
    }

    syncMessage(id, item) {
        if (!this.unsendMessage[id]) {
            return;
        }
        for (let k in item) {
            this.unsendMessage[id][k] = item[k];
        }
        this.unsendMessage[id].process = 0;
        this.setState({});
        delete this.unsendMessage[id];
        // console.log('messages after sync', this.state.messages);
    }

    handleScroll(event) {
        // const target = event.target;
        // const maxScroll = target.scrollHeight - target.offsetHeight;
        // if (target.scrollTop >= maxScroll) {
        //     // console.log('bottom');
        // } else {
        //     if (target.scrollTop <= 0) {
        //         // console.log('top'); check to load data
        //         const { messages } = this.state;
        //         // console.log('top to load', messages.length, this.total);
        //         if (messages.length < this.total) {
        //             if (!this.loading) {
        //                 this.offset = messages.length;
        //                 this.loadData();
        //             }
        //         }
        //     }
        // }
        let target = event.target;
        const top = window.scrollTop || window.pageYOffset || target.scrollTop || document?.scrollTop || document?.documentElement?.scrollTop || document.body?.scrollTop;
        if (top <= 0) {
            // console.log('top'); check to load data
            const { messages } = this.state;
            // console.log('top to load', messages.length, this.total);
            if (messages.length < this.total) {
                if (!this.loading) {
                    this.offset = messages.length;
                    this.loadData();
                }
            }
        }
    }

    scrollToBottom(smooth = true) {
        // console.log('try scrollToBottom');
        // console.log('scrollToBottom', this.messagesEnd);
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView(smooth ? { behavior: 'smooth' } : {});
        }
    }

    startInterval() {
        clearTimeout(this.interval);
        // this.interval = setTimeout(() => this.updateData(), 5000);
    }

    async _loadData(data) {
        // console.log(data, this.firstTime);
        this.total = parseInt(data.total);
        this.limit = parseInt(data.limit);
        this.offset = parseInt(data.offset);
        this.loading = false;

        //check scroll before change
        const target = this.scroll;
        let h = 0;
        if (target) {
            if (!this.firstTime) {
                h = target.scrollHeight;
            }
        }

        await this.setState({
            preload: false,
            messages: data.list.concat(this.state.messages)
        });
        //first time make scroll to bottom
        if (this.firstTime) {
            this.firstTime = false;
            this.scrollToBottom(false);
        } else {
            if (target) {
                const diff = target.scrollHeight - h;
                target.scroll({ top: diff });
            }
        }
    }

    loadData() {
        clearTimeout(this.interval);
        // console.log(this.id);
        if (this.id) {
            this.setState({ preload: true });
            const offset = this.offset;
            this.loading = true;
            Request.get(API.API_CHAT + '/' + this.id + '/messages',
                { offset: offset },
                (data) => {
                    this._loadData(data);
                    this.startInterval();
                    // Chat.update();
                },
                (error) => {
                    this.loading = false;
                    this.setState({ preload: false });
                    this.startInterval();
                });
        }
    }

    async _updateData(messages) {
        let isBottom = true;
        const target = this.scroll;
        if (target) {
            const maxScroll = target.scrollHeight - target.offsetHeight;
            if (target.scrollTop < maxScroll) {
                isBottom = false;
            }
        }
        await this.setState({ messages });
        if (isBottom) {
            this.scrollToBottom();
        }
    }

    updateData() {
        if (!this.id) {
            return;
        }
        const { messages } = this.state;
        const lastIndex = messages.length - 1;
        const lastItem = messages[lastIndex];
        if (!lastItem || !lastItem.id || lastItem.process) {
            this.startInterval();
            return;
        }

        Request.get(API.API_CHAT + '/' + this.id + '/messages',
            { offset: 0 },
            (data) => {
                //check last message is in new data offset 0 or not?
                const list = data.list;
                const len = list.length;
                let item;
                let isChanged = false;
                const items = [];
                for (let i = len - 1; i >= 0; i--) {
                    item = list[i];
                    // console.log(item.id, lastItem.id);
                    if (parseInt(item.id) === parseInt(lastItem.id)) {
                        break;
                    }
                    items.push(item);
                    isChanged = true;
                }
                if (isChanged) {
                    this._updateData(messages.concat(items.reverse()));
                }
                this.startInterval();
            },
            (error) => {
                this.startInterval();
            });
        //set scroll to bottom
    }

    render() {
        const { messages, preload, ready } = this.state;
        // console.log(list);
        // console.log(this.mapMember);
        // console.log(message?.response );
        // const cls = ready ? '' : 'invisible';
        // const scrollCls = ready ? '' : 'invisible';
        // console.log(this.props.showImageHandler);
        return <div key={'chat_message'}
            className={"chat-message-box py-2 scroll-inner"}
            ref={(el) => this.scroll = el}>

            {(preload === true || !this.id) && <div className="w-100 text-center p-3">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>}

            {messages.map((item) => <ChatMessageItem
                key={'msg_' + item.id}
                data={item}
                profile={this.mapMember[item.user_id]}
                showImageHandler={this.props.showImageHandler.bind(this)}
            />)}

            <div key={'end_message'} ref={(el) => { this.messagesEnd = el; }}></div>
        </div>;
    }
}

export default ChatMessage;