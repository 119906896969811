import React, { PureComponent } from 'react';
import { getBone, getBoneFix } from '../utils/URender';
import IncreaseInput from './IncreaseInput';
import { get as L } from '../models/Label';
import deleteIcon from '../assets/images/delete-item-icon.svg';
import { nf } from '../utils/UObj';

class ListItemCart extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        // console.log(this.props);
        const { data, icon } = this.props;
        if (data === 'dummy' || (data && data.hasOwnProperty('id') && data.id === 'dummy')) {
            return <div className={'list-cart-item py-2 ' + (this.props.className ?? '')}>
                <div className="row">
                    {icon && <div className="col-12 col-md-auto mb-2 mb-md-0">
                        {getBoneFix(84, 84)}
                    </div>}
                    <div className="col-12 col-md mb-2 mb-md-0">
                        <div className="list-tile-label">{getBone(200, 20, 4)}</div>
                        <div className="list-tile-sublabel">{getBone(250, 17, 4)}</div>
                        <div className="list-tile-label mb-2">{getBone(220, 24, 8)}</div>
                    </div>
                    <div className="col-auto">
                        <div className="d-flex">
                            {getBoneFix(220, 36)}
                        </div>
                        <div className="list-tile-unit mt-2">{getBoneFix(222, 23, 4)}</div>
                    </div>
                </div>
            </div>;
        }

        const price = (data.price ?? 0) * (this.props.quantity ?? 0);

        return (
            <div className={'list-cart-item py-2 ' + (this.props.className ?? '')}>
                <div className="row">
                    {icon && <div className="col-12 col-md-auto mb-2 mb-md-0">
                        <img src={icon} className="list-tile-thumbnail" alt={data.name} />
                    </div>}
                    <div className="col-12 col-md mb-2 mb-md-0">
                        <div className="list-tile-label">{data.name}</div>
                        <div className="list-tile-sublabel">{data.description}</div>
                        <div className="list-tile-label mb-2">{L('ราคา')} ฿{nf(data.price)} / {data.unit?.name}</div>
                    </div>
                    <div className="col-auto">
                        <div className="d-flex">
                            <IncreaseInput
                                name={"quantity_" + this.props.key}
                                value={this.props.quantity}
                                min={1}
                                max={data.quantity}
                                onChange={(value) => this.props.onChange(value)} />
                            <button
                                className="btn btn-danger btn-image ml-3"
                                onClick={() => this.props.onDelete(data.id)}>
                                <img width={16} height={16} src={deleteIcon} alt={L('ลบสินค้าออกจากตะกร้า')} />
                            </button>
                        </div>
                        <div className="list-tile-unit mt-2">{L('รวมราคา')} <span className="list-tile-price">฿{nf(price)}</span></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListItemCart;
