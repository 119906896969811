import React, { PureComponent } from 'react';
import { getBone } from '../utils/URender';
import { get as L } from '../models/Label';

class ListItemCheck extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {

        const { data, checked, onChange } = this.props;
        if (data === 'dummy') {
            return <div className="list-item-check col-12 py-2">
                <div className="w-100">
                    <div className="list-item-check-label">{getBone(240, 20, 4)}</div>
                    <div className="list-item-check-sublabel">{getBone(280, 18, 3)}</div>
                </div>
                {getBone(20, 20)}
            </div>;
        }

        return (
            <div className="list-item-check col-12 py-2">
                <div>
                    <div className="list-item-check-label">{data.name}</div>
                    <div className="list-item-check-sublabel">{data.description}</div>
                </div>
                <div className="custom-control custom-checkbox">
                    <input
                        className="custom-control-input"
                        type="checkbox"
                        id={data.id}
                        value="1"
                        checked={checked}
                        onChange={onChange.bind(this)}
                    />
                    <label className="custom-control-label" htmlFor={data.id}></label>
                </div>
            </div>
        );
    }
}

export default ListItemCheck;
