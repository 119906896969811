import React, { Component, Fragment } from 'react';
import { get as L } from '../models/Label';
import Avatar from './Avatar';

import System from '../models/System';
import API from '../models/API';
import optionIcon from '../assets/images/option-icon.svg';
import likeIcon from '../assets/images/like-icon.svg';
import likeFillIcon from '../assets/images/like-fill-icon.svg';
import moment from 'moment';
import Request from '../models/Request';

class FeedbackBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openDeleteDialog: false,
            data: this.props.data,
            feedbackItem: {
                feed_id: this.props.data.id,
                message: '',
                inputHeight: 40,
                limit: this.props.limit,
            },
            feedbackLoading: false,
            replyItem: {},
            replyLoading: false,

            //map info for action
            feedbackAction: {},
            replyAction: {},
        }

        this.feedbackInput = null;
        this.replyInput = {};
    };

    componentDidUpdate(prevProps) {
        // console.log(prevProps.feedback != this.props.feedback);
        // console.log(prevProps.limit !== this.props.limit);
        if (prevProps.limit !== this.props.limit) {
            this.setState({ data: this.props.data });
        }
    }

    feedbackReplyLikeHandler(item) {
        const { replyAction } = this.state;
        if (!replyAction[item.id]) {
            return;
        }
        const { likeCount, like } = replyAction[item.id];
        let token;
        if (like && parseInt(like['user_id']) === parseInt(System.profile?.id)) {
            token = 0;
            replyAction[item.id].likeCount = parseInt(likeCount) - 1;
            replyAction[item.id].like = null;
            this.setState({ replyAction });
        } else {
            token = 1;
            replyAction[item.id].likeCount = parseInt(likeCount) + 1;
            replyAction[item.id].like = { user_id: System.profile?.id };
            this.setState({ replyAction });
        }
        Request.pos(API.API_FEEDBACK_REPLY + '/' + item.id + '/like', { like: token }, () => { }, () => { });
    }

    feedbackLikeHandler(item) {
        const { feedbackAction } = this.state;
        if (!feedbackAction[item.id]) {
            return;
        }
        const { likeCount, like } = feedbackAction[item.id];
        let token;
        if (like && parseInt(like['user_id']) === parseInt(System.profile?.id)) {
            token = 0;
            feedbackAction[item.id].likeCount = parseInt(likeCount) - 1;
            feedbackAction[item.id].like = null;
            this.setState({ feedbackAction });
        } else {
            token = 1;
            feedbackAction[item.id].likeCount = parseInt(likeCount) + 1;
            feedbackAction[item.id].like = { user_id: System.profile?.id };
            this.setState({ feedbackAction });
        }
        Request.pos(API.API_FEEDBACK + '/' + item.id + '/like', { like: token }, () => { }, () => { });
    }

    feedbackReplyHandler(item) {
        const { replyItem } = this.state;
        if (replyItem[item.id]) {
            return;
        }
        replyItem[item.id] = {
            feedback_id: item.id,
            inputHeight: 40,
            message: '',
            limit: this.props.limit,
        };
        this.setState({ replyItem });
    }

    sendReplyMessage(item) {
        const { replyItem, replyLoading } = this.state;
        const loading = replyLoading;
        if (loading) {
            return;
        }
        const reply = replyItem[item.id];
        this.setState({ replyLoading: true });
        Request.pos(API.API_FEEDBACK_REPLY, { ...reply }, async (data) => {
            reply.message = '';
            reply.inputHeight = 1;
            await this.setState({});
            const sch = this.replyInput[item.id].scrollHeight;
            reply.inputHeight = sch;
            this.setState({ data, replyLoading: false, feedbackAction: {}, replyAction: {} });
        }, () => {
            this.setState({ replyLoading: false });
        });
    }

    sendMessage() {
        const { feedbackItem, feedbackLoading } = this.state;
        const loading = feedbackLoading;
        if (loading) {
            return;
        }
        this.setState({ feedbackLoading: true });
        Request.pos(API.API_FEEDBACK, { ...feedbackItem }, async (data) => {
            feedbackItem.message = '';
            feedbackItem.inputHeight = 1;
            await this.setState({});
            const sch = this.feedbackInput.scrollHeight;
            feedbackItem.inputHeight = sch;
            this.setState({ data, feedbackLoading: false, feedbackAction: {}, replyAction: {} });
            if (this.props.updateHandler) {
                this.props.updateHandler(data);
            }
        }, () => {
            this.setState({ feedbackLoading: false });
        });
    }

    async delete(item, index, type) {
        // console.log(item, index);
        // return;
        this.id = item.id;
        this.index = index;
        this.type = type;
        await this.setState({ openDeleteDialog: true });
        window.openModalDialog('deleteModal', () => {
            this.setState({ openDeleteDialog: false });
        });
    }

    confirmDelete() {
        let api;
        if (this.type === 'feedback') {
            api = API.API_FEEDBACK;
        } else {
            api = API.API_FEEDBACK_REPLY;
        }
        api += '/' + this.id;
        if (this.props.limit) {
            api += '?limit=' + this.props.limit;
        }
        Request.del(api,
            (data) => {
                window.hideModalDialog('deleteModal');
                if (this.type === 'feedback') {
                    if (this.props.updateHandler) {
                        this.props.updateHandler(data);
                    }
                }
                this.setState({ openDeleteDialog: false, data, feedbackAction: {}, replyAction: {} });
            },
            (error) => {
                window.openMessageModalDialog(error);
            });
    }

    block(item, index, type) {
        if (type === 'feedback') {
            const { status, limit } = this.state.feedbackAction[item.id];
            const newStatus = parseInt(status) === 2 ? 1 : 2;
            Request.pos(API.API_FEEDBACK + '/' + item.id + '/block', { status: newStatus, limit: limit }, (data) => {
                const { feedbackAction } = this.state;
                feedbackAction[item.id].status = data ?? 1;
                this.setState({ feedbackAction });
            }, () => { });
        } else {
            const { status, limit } = this.state.replyAction[item.id];
            const newStatus = parseInt(status) === 2 ? 1 : 2;
            Request.pos(API.API_FEEDBACK_REPLY + '/' + item.id + '/block', { status: newStatus, limit: limit }, (data) => {
                const { replyAction } = this.state;
                replyAction[item.id].status = data ?? 1;
                this.setState({ replyAction });
            }, () => { });
        }
    }

    getReplyItem(item, index) {
        const cls = '';
        const style = { paddingLeft: '32px' };
        const message = item.message;
        const visible = this.state['v_r_' + item.id] == 1;
        const logged = System.isLogged();
        const isOwner = parseInt(System.profile?.id) === parseInt(item.user_id);
        const showOption = logged && (isOwner || System.isAdmin());
        const { replyAction } = this.state;
        if (!replyAction[item.id]) {
            replyAction[item.id] = {
                status: item.status ?? 1,
                likeCount: item.like_count ?? 0,
                like: item.like,
                showMenu: false,
            };
        }

        const action = replyAction[item.id];
        const { likeCount, like, showMenu, status } = action;
        const _likeIcon = like && parseInt(like['user_id']) === parseInt(System.profile?.id) ? likeFillIcon : likeIcon;
        const blockColor = parseInt(status) === 2 ? 'bg-danger text-white' : '';

        return <div
            key={'reply_item_' + item.id}
            className={'row no-gutters feedback-item ml-2 ' + cls}
            onMouseOver={() => {
                if (showOption) {
                    this.setState({ ['v_r_' + item.id]: 1 });
                }
            }}
            onMouseOut={() => {
                if (showOption) {
                    this.setState({ ['v_r_' + item.id]: 0 });
                }
            }}>
            <div className="col-auto" style={style}>
                <Avatar
                    className="mr-2"
                    src={System.resolveImagePath(item.user?.profile_image, 'profile')}
                    iconFill
                    style={menuItemStyle} />
            </div>
            <div className="col">
                <div className="d-flex">
                    <div className={"feedback-message reply " + blockColor}>
                        <div className={"feedback-name " + blockColor}>{item.user?.first_name + ' ' + item.user?.last_name}</div>
                        <div>{message}</div>
                    </div>
                    <div className="dropdown">
                        <div
                            className={'btn-icon ml-1 ' + (visible ? 'visible' : 'invisible')}
                            id={"dropdownMenuButton_fbr_" + item.id}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            onClick={() => {
                                if (visible) {
                                    action.showMenu = true;
                                    this.setState({ replyAction });
                                }
                            }}>
                            <img src={optionIcon} alt={L('จัดการความคิดเห็น')} />
                        </div>
                        {showMenu && <div className="dropdown-menu" aria-labelledby={"dropdownMenuButton_fbr_" + item.id}>
                            <>
                                {System.isAdmin() && <div
                                    className="dropdown-item pointer"
                                    onClick={this.block.bind(this, item, index, 'reply')}>
                                    {parseInt(status) === 2 ? L('อันบล็อกโพสต์') : L('บล็อกโพสต์')}
                                </div>}
                                {isOwner && <div
                                    className="dropdown-item pointer"
                                    onClick={this.delete.bind(this, item, index, 'reply')}>
                                    {L('ลบโพสต์')}
                                </div>}
                            </>
                        </div>}
                    </div>
                </div>

                <div className="d-flex pl-2 pb-2">
                    {logged && <ul className="feedback-action">
                        <li>
                            <img className="mr-1" src={_likeIcon} alt={L('ถูกใจ')} style={{ marginBottom: '5px' }} />
                            <span className="fbtn" onClick={this.feedbackReplyLikeHandler.bind(this, item)}>{L('ถูกใจ')} {'(' + likeCount + ')'}</span>
                        </li>
                        <li><span>&nbsp;·&nbsp;</span><span className="feed-box-date">{moment(item.created_at).fromNow()}</span></li>
                    </ul>}
                </div>

            </div>
        </div>;
    }

    getFeedbackItem(item, index) {
        const cls = '';
        const style = {};
        const message = item.message;
        const visible = this.state['v_' + item.id] == 1;
        const logged = System.isLogged();
        const isOwner = parseInt(System.profile?.id) === parseInt(item.user_id);
        const showOption = logged && (isOwner || System.isAdmin());
        const { feedbackAction } = this.state;
        if (!feedbackAction[item.id]) {
            feedbackAction[item.id] = {
                status: item.status ?? 1,
                commentCount: item.comment_count ?? 0,
                likeCount: item.like_count ?? 0,
                like: item.like,
                showMenu: false,
            };
        }

        const action = feedbackAction[item.id];
        const { likeCount, commentCount, like, showMenu, status } = action;
        const _likeIcon = like && parseInt(like['user_id']) === parseInt(System.profile?.id) ? likeFillIcon : likeIcon;
        const blockColor = parseInt(status) === 2 ? 'bg-danger text-white' : '';

        return <Fragment key={'feedback_item_' + item.id}>
            <div
                className={'row no-gutters feedback-item ' + cls}
                onMouseOver={() => {
                    if (showOption) {
                        this.setState({ ['v_' + item.id]: 1 });
                    }
                }}
                onMouseOut={() => {
                    if (showOption) {
                        this.setState({ ['v_' + item.id]: 0 });
                    }
                }}>
                <div className="col-auto" style={style}>
                    <Avatar
                        className="mr-2"
                        src={System.resolveImagePath(item.user.profile_image, 'profile')}
                        iconFill
                        style={menuItemStyle} />
                </div>
                <div className="col">
                    <div className="d-flex">
                        <div className={"feedback-message " + blockColor}>
                            <div className={"feedback-name " + blockColor}>{item.user.first_name + ' ' + item.user.last_name}</div>
                            <div>{message}</div>
                        </div>
                        <div className="dropdown">
                            <div
                                className={'btn-icon ml-1 ' + (visible ? 'visible' : 'invisible')}
                                id={"dropdownMenuButton_fb_" + item.id}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                onClick={() => {
                                    if (visible) {
                                        action.showMenu = true;
                                        this.setState({ feedbackAction });
                                    }
                                }}>
                                <img src={optionIcon} alt={L('จัดการความคิดเห็น')} />
                            </div>
                            {showMenu && <div className="dropdown-menu" aria-labelledby={"dropdownMenuButton_fb_" + item.id}>
                                <>
                                    {System.isAdmin() && <div
                                        className="dropdown-item pointer"
                                        onClick={this.block.bind(this, item, index, 'feedback')}>
                                        {parseInt(status) === 2 ? L('อันบล็อกโพสต์') : L('บล็อกโพสต์')}
                                    </div>}
                                    {isOwner && <div
                                        className="dropdown-item pointer"
                                        onClick={this.delete.bind(this, item, index, 'feedback')}>
                                        {L('ลบโพสต์')}
                                    </div>}
                                </>
                            </div>}
                        </div>
                    </div>
                    <div className="d-flex pl-2 pb-2">
                        {logged
                            ? <ul className="feedback-action">
                                <li>
                                    <img className="mr-1" src={_likeIcon} alt={L('ถูกใจ')} style={{ marginBottom: '5px' }} />
                                    <span className="fbtn" onClick={this.feedbackLikeHandler.bind(this, item)}>{L('ถูกใจ')} {'(' + likeCount + ')'}</span>
                                </li>
                                <li>
                                    <span>&nbsp;·&nbsp;</span>
                                    <span className="fbtn" onClick={this.feedbackReplyHandler.bind(this, item)}>{L('ตอบกลับ')} {'(' + commentCount + ')'}</span>
                                </li>
                                <li><span>&nbsp;·&nbsp;</span><span className="feed-box-date">{moment(item.created_at).fromNow()}</span></li>
                            </ul>
                            : null}
                    </div>
                </div>
            </div>
            {item.replies.map((reply, index) => this.getReplyItem(reply, index))}
            {this.state.replyItem[item.id] && this.getReplyInputBox(item)}
        </Fragment>;
    }

    getReplyInputBox(item) {
        const profile = System.profile;
        const { replyItem, replyLoading } = this.state;
        const loading = replyLoading;
        const indent = { paddingLeft: '32px' };
        const avatarStyle = { marginTop: '4px', width: '32px', height: '32px' };
        const reply = replyItem[item.id];
        return <div key={'reply_input_' + item.id} className={"d-flex my-1 ml-2"} style={indent}>
            <Avatar
                iconFill
                style={avatarStyle}
                src={profile.profile_image} />
            <div className="flex-1 pr-2">
                <div className="input-feedback-box">
                    <textarea
                        ref={(el) => this.replyInput[item.id] = el}
                        className="input-feedback ml-2"
                        type="text"
                        placeholder={L('เขียนความคิดเห็น...')}
                        value={reply.message}
                        style={{ height: reply.inputHeight + 'px' }}
                        onChange={async (e) => {
                            reply.message = e.target.value
                            reply.inputHeight = 1;
                            await this.setState({});
                            const sch = this.replyInput[item.id].scrollHeight;
                            reply.inputHeight = sch;
                            this.setState({});
                        }}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                this.sendReplyMessage(item);
                                return;
                            }
                        }}
                        disabled={loading}
                    />
                    {loading && <div className="spinner spinner-border" role="status" style={{ opacity: .4 }}>
                        <span className="sr-only">Loading...</span>
                    </div>}
                </div>
                <div className="label-info ml-3 mb-1">{L('กดปุ่ม Enter เพื่อโพสต์')}</div>
            </div>
        </div>;
    }

    getFeedbackInputBox() {
        const profile = System.profile;
        const { feedbackItem, feedbackLoading } = this.state;
        const loading = feedbackLoading;
        const cls = this.props.data?.feedback?.length > 0 ? 'mt-2' : '';
        return <div key={'feedback_input_' + this.props.data.id} className={"d-flex " + cls}>
            <Avatar
                iconFill
                src={profile.profile_image} />
            <div className="flex-1 pr-2">
                <div className="input-feedback-box">
                    <textarea
                        ref={(el) => this.feedbackInput = el}
                        className="input-feedback ml-2"
                        type="text"
                        placeholder={L('เขียนความคิดเห็น...')}
                        value={feedbackItem.message}
                        style={{ height: feedbackItem.inputHeight + 'px' }}
                        onChange={async (e) => {
                            feedbackItem.message = e.target.value;
                            feedbackItem.inputHeight = 1;
                            await this.setState({});
                            const sch = this.feedbackInput.scrollHeight;
                            feedbackItem.inputHeight = sch;
                            this.setState({});
                        }}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                this.sendMessage();
                                return;
                            }
                        }}
                        disabled={loading}
                    />
                    {loading && <div className="spinner spinner-border" role="status" style={{ opacity: .4 }}>
                        <span className="sr-only">Loading...</span>
                    </div>}
                </div>
                <div className="label-info ml-3">{L('กดปุ่ม Enter เพื่อโพสต์')}</div>
            </div>
        </div>;
    }

    render() {
        const { data, openDeleteDialog } = this.state;
        const feedback = data?.feedback ?? [];
        const logged = System.isLogged();
        return <>
            {feedback.map((item, index) => this.getFeedbackItem(item, index))}
            {logged ? this.getFeedbackInputBox() : <div className="pb-2" />}

            {openDeleteDialog && <div id="deleteModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{L('ลบความคิดเห็น')}</h5>
                        </div>
                        <div className="modal-body">
                            <p>{L('คุณต้องการลบความคิดเห็นนี้ใช่หรือไม่')}</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary mr-3"
                                onClick={() => {
                                    window.hideModalDialog('deleteModal');
                                    this.setState({ openDeleteDialog: false });
                                }}>{L('ยกเลิก')}</button>
                            <button
                                type="button"
                                className="btn btn-primary mr-3"
                                onClick={() => {
                                    this.confirmDelete();
                                }}>{L('ลบความคิดเห็น')}</button>
                        </div>
                    </div>
                </div>
            </div>}

        </>;
    }
}

const menuItemStyle = { width: '32px', height: '32px' };

export default FeedbackBox;