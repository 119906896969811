import React, { Component } from 'react';
import Cropper from 'react-easy-crop';
import Slider from 'rc-slider';
import Button from './Button';
import { get as L } from '../models/Label';
import { getCroppedImg } from '../utils/UCanvas';

class ImageCropDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            zoom: 1,
            crop: { x: 0, y: 0 },
            src: this.props.src,
            croppedAreaPixels: null,
        };
    };

    async submit() {
        // console.log('ImageCropDialog submit');
        const { src, croppedAreaPixels } = this.state;
        const croppedImage = await getCroppedImg(src, croppedAreaPixels);
        this.props.submit(croppedImage);
    }

    onCropChange(crop) {
        this.setState({ crop });
    }

    onCropComplete(croppedArea, croppedAreaPixels) {
        // console.log(croppedArea, croppedAreaPixels);
        this.setState({ croppedAreaPixels });
    }

    render() {
        const { src, crop, zoom } = this.state;
        return <div id="imageCropModal" className="modal p-5" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-inner" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{L('ตัดรูปภาพ')}</h5>
                    </div>
                    <div className="modal-body">
                        <div className="crop-container" style={{ height: '240px' }}>
                            <Cropper
                                image={src}
                                crop={crop}
                                minZoom={0.5}
                                zoom={zoom}
                                cropShape="round"
                                aspect={1}
                                showGrid={false}
                                // restrictPosition={false}
                                onCropComplete={this.onCropComplete.bind(this)}
                                onCropChange={this.onCropChange.bind(this)}
                            />
                        </div>
                    </div>
                    <div className="p-3">
                        <Slider
                            min={50}
                            max={400}
                            value={zoom * 100}
                            onChange={(value) => {
                                this.setState({ zoom: value * .01 });
                            }}
                        />
                    </div>
                    <div className="modal-footer">
                        <Button
                            onClick={() => this.submit()}
                            className="btn btn-primary"
                        >{L('ตกลง')}</Button>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default ImageCropDialog;
