import React, { PureComponent } from 'react';

class Button extends PureComponent {

    constructor(props) {
        super(props);
    };

    onClick(e) {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    }

    render() {
        return (
            <button
                type="button"
                className={`btn ${this.props.className} w-100`}
                disabled={this.props.loading}
                onClick={(e) => this.onClick(e)}>
                {this.props.loading && <span className="spinner-border spinner-border-sm mr-2 mb-1" role="status" aria-hidden="true"></span>}
                {this.props.children}
            </button>
        );
    }
}

export default Button;
