import React, { Component } from 'react';

class IncreaseInput extends Component {

    constructor(props) {
        super(props);
        if (this.props.name) {
            this.id = 'input_' + this.props.name + '_' + (this.props.index ?? '');
        } else {
            this.id = 'input_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000);
        }
    };

    onChange(e) {
        this.changeValue(e.target.value);
    }

    getValue() {
        // console.log(this.props.name, this.props.value, this.props.controller);
        if (!this.props.controller) {
            return this.props.value ?? '';
        }
        const tmp = this.props.name?.split('.');
        if (tmp && tmp[1]) {
            const parent = this.props.controller.get(tmp[0]);
            return parent[this.props.index][tmp[1]] ?? '';
        }
        return this.props.controller.get(this.props.name) ?? '';
    }

    changeValue(value) {
        if (this.props.controller) {
            //check nestest
            const tmp = this.props.name.split('.');
            if (tmp && tmp[1]) {
                const parent = this.props.controller.get(tmp[0]);
                parent[this.props.index][tmp[1]] = value;
                this.props.controller.set(tmp[0], parent, true);
                // console.log('InputBox after changed =>', this.props.name, this.props.controller.get(tmp[0])[this.props.index][tmp[1]]);
            } else {
                this.props.controller.set(this.props.name, value, true);
                // console.log('InputBox after changed =>', this.props.name, this.props.controller.get(this.props.name));
            }
        } else {
            if (this.props.onChange) {
                this.props.onChange(value, this.props.name);
            }
        }
    }

    buttonClickHandler(value) {
        let newValue = Math.min(this.props.max, Math.max(this.props.min, this.getValue() + value));
        this.changeValue(newValue);
    }

    render() {
        return (
            <div className="d-flex">
                <button className="btn-step" onClick={this.buttonClickHandler.bind(this, -1)}>-</button>
                <input
                    id={this.id}
                    type="number"
                    className="input-step text-center"
                    min={this.props.min}
                    max={this.props.max}
                    value={this.getValue()}
                    onChange={(e) => this.onChange(e)}
                ></input>
                <button className="btn-step" onClick={this.buttonClickHandler.bind(this, 1)}>+</button>
            </div>
        );
    }
}

export default IncreaseInput;
