import Event from "./Event";
import System from "./System";

export default class Cart {

    static items;

    static init() {
        const js = localStorage.getItem('cart');
        if (js) {
            try {
                this.items = JSON.parse(js);
            } catch (error) {

            }
            // console.log('cart', this.items);
            if (Array.isArray(this.items) && this.items.length) {
                this.validateCart();
                return;
            }
        }
        this.items = [];
        localStorage.setItem('cart', '');
    }

    static add(id, quantity) {
        let item;
        let found = false;
        for (let k in this.items) {
            item = this.items[k];
            if (item && parseInt(item.id) === parseInt(id)) {
                item.quantity = quantity;
                found = true;
                break;
            }
        }
        if (!found) {
            this.items.push({ id, quantity });
        }
        this.validateCart();
    }

    static remove(id) {
        let item;
        for (let k in this.items) {
            item = this.items[k];
            if (item && parseInt(item.id) === parseInt(id)) {
                this.items.splice(k, 1);
                this.validateCart();
                break;
            }
        }
    }

    static validateCart() {
        const item = System.userMenus[0];
        item.badges = this.items.length;
        localStorage.setItem('cart', JSON.stringify(this.items));
        Event.dispatch(Event.CART_CHANGE, this.items);
    }

    static all() {
        return this.items;
    }

    static reset(data) {
        this.items = [data];
        this.validateCart();
    }

    static clear() {
        this.items = [];
        this.validateCart();
    }

}