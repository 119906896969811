import React, { PureComponent } from 'react';
import Card from './Card';
import ImageBackground from './ImageBackground';
import { get as L } from '../models/Label';
import { getDateString } from '../utils/UDate';
import moment from 'moment';
import System from '../models/System';
import { Link } from 'react-router-dom';

const dateOption = { 'type': 'short' };

class NewsBox extends PureComponent {

    constructor(props) {
        super(props);
    };

    getImageLayout(image) {
        return <div className="news-box-image">
            <ImageBackground src={image} width={'100%'} height={'100%'} />
        </div>
    }

    render() {
        const { data } = this.props;
        const { thumbnail } = data;
        return (
            <Link to={this.props.path}>
                <Card className="mb-4">
                    {thumbnail && this.getImageLayout(System.resolveImagePath(data.thumbnail.image, 'news'))}
                    <div className="news-box-title">{data.name}</div>
                    <div className="news-box-detail">{data.description}</div>
                    <div className="news-box-date">{L('วันที่') + ' ' + getDateString(moment(data.created_at), dateOption)}</div>
                </Card>
            </Link>
        );
    }
}

export default NewsBox;