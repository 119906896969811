import { matchPath } from 'react-router';
import Event from "./Event";
import { isEmpty } from "../utils/UObj";

import thumbnail404Image from '../assets/images/thumbnail-404.jpg';
import friendAvatar from '../assets/images/mock/avatar/4.jpg';

import { get as L } from '../models/Label';

import Cart from './Cart';
import Icon from './Icon';
import Notification from './Notification';
import Inbox from './Inbox';
import Chat from './Chat';
import { isMobile } from 'react-device-detect';

export default class System {

    static API_URL = 'https://api.northorganicfood.com/';
    static IMAGE_PREFIX = 'https://api.northorganicfood.com/media/';

    // static API_URL = 'http://192.168.1.99:8000/';
    // static IMAGE_PREFIX = 'http://192.168.1.99:8000/index.php/media/';
    // static API_URL = '';
    // static API_URL = '';

    static HOME_PATH = '';

    static LANG_EN = 'en';
    static LANG_TH = 'th';
    static KEY_NAME_EN = 'name_en';
    static KEY_NAME_TH = 'name_th';

    static lang;
    static kayName;
    static token;

    static publicPath = '';

    static profile;
    static friend;

    static MAX_ROWS = 20;

    static userMenus;

    static instance;

    static init() {
        // console.log('System init');
        if (this.instance) {
            // return;
        }

        this.lang = this.getLang();
        this.kayName = this.lang === System.LANG_TH ? System.KEY_NAME_TH : System.KEY_NAME_EN;
        this.publicPath = process.env.PUBLIC_URL + '/';

        Icon.init();

        this.friend = {
            profile_image: friendAvatar,
            first_name: 'จินตหรา',
            last_name: 'ภูทรเหนือ',
            display_name: 'จินตหรา ภูทรเหนือ',
        };

        if (this.isLogged()) {
            System.profile = {
                profile_image: Icon.get('avatar'),
                first_name: '',
                display_name: '',
            };
        }
        // console.log(Icon.get('cart'));
        this.userMenus = [
            { id: 'item_menu_2', label: L('ตะกร้าสินค้า'), icon: 'cart', path: System.resolvePath('user/cart') },
            { id: 'item_menu_11', label: L('ประวัติการซื้อ'), icon: 'history', path: System.resolvePath('user/order'), login: true },
            { id: 'item_menu_34', label: L('โพสของฉัน'), icon: 'activity', path: System.resolvePath('user/feed'), login: true },
        ];

        Cart.init();
        Notification.init();

        this.instance = new System();
    }

    static setProfile(data, isNew = true) {
        if (data.profile_image) {
            data.profile_image = this.IMAGE_PREFIX + 'profile/' + data.profile_image;
        } else {
            data.profile_image = Icon.get('avatar');
        }
        data.display_name = data.first_name + ' ' + data.last_name;
        if (data.location) {
            data.province = data.location.province;
            data.district = data.location.district;
            data.subdistrict = data.location.subdistrict;
            data.zip_code = data.location.zip_code;
        }
        for (let k in data) {
            data[k] = data[k] ?? '';
        }
        System.profile = data;

        if (isNew && this.isLogged && data.id) {
            Notification.commitToken();
        }
    }

    static update() {
        if (this.isLogged && System.profile?.id) {
            Inbox.update();
            Chat.update();
        }
    }

    static isLogged() {
        this.token = this.getToken();
        return !isEmpty(this.token);
    };

    static isFarmer() {
        return this.profile && parseInt(this.profile.role_id) === 3;
    }

    static isAdmin() {
        return this.profile && parseInt(this.profile.role_id) === 2;
    }

    static setToken(token) {
        // console.log('setToken => ', token);
        this.token = token;
        localStorage.setItem('token', token);
        Event.dispatch(Event.LOGIN, token !== '');
    }

    static getToken() {
        // console.log(localStorage.getItem('token'));
        if (this.token) {
            return this.token;
        }
        this.token = localStorage.getItem('token');
        return this.token;
    }

    static setLang(l) {
        System.lang = l;
        System.kayName = System.lang === System.LANG_TH ? System.KEY_NAME_TH : System.KEY_NAME_EN;
        localStorage.setItem('lang', l);
    }

    static getLang() {
        const l = localStorage.getItem('lang');
        if (l !== System.LANG_EN && l !== System.LANG_TH) {
            return System.LANG_TH;
        }
        return l;
    }

    static logout() {
        // console.log('logout');
        System.setProfile({
            profile_image: Icon.get('avatar'),
            first_name: '',
            display_name: '',
        });
        localStorage.removeItem('token');
        // window.location.href = '';
        window.location.reload();
    }

    static resolvePath(path) {
        // console.log(this.publicPath);
        return this.publicPath + '' + path;
    }

    static resolveImagePath(fileName, path = '') {
        // console.log(this.publicPath);
        if (!fileName) {
            return null;
        }
        return this.IMAGE_PREFIX + (path ? path + '/' : '') + fileName;
    }

    static resolveThumnailPath(fileName, path = '') {
        // console.log(fileName);
        if (!fileName) {
            return thumbnail404Image;
        }
        // console.log(this.publicPath);
        return this.IMAGE_PREFIX + (path ? path + '/' : '') + 'thumbnail_' + fileName;
    }

    static matchPaths(path, paths) {
        let _rpath;
        let _path;
        for (let k in paths) {
            _rpath = this.resolvePath(paths[k]);
            _path = matchPath(path, {
                path: _rpath,
                exact: true,
                strict: false
            });
            // console.log(System.resolvePath(paths[k]), _path);
            if (_path) {
                return _path;
            }
        }
        return null;
    }

    static matchPath(path, match) {
        return matchPath(path, {
            path: this.resolvePath(match),
            exact: true,
            strict: false
        });
    }

    static scrollTo(a, b) {
        if (isMobile) {
            const root = document.getElementById('root');
            if (root) {
                root.scrollTo(a, b);
            }
        } else {
            window.scrollTo(a, b);
        }
    }
}