import React, { Component } from 'react';

import System from '../models/System';
import HomeLeftSection from './HomeLeftSection';

class Left extends Component {

    constructor(props) {
        super(props);
    };

    getContent() {
        if (System.matchPaths(this.props.path, [
            '',
            'home',
            'news',
            'news/:newsId([0-9]+)',
            'market/product/:productId([0-9]+)',
            'market/product/:productId([0-9]+)/review/create',
            'market/product/:productId([0-9]+)/review/:reviewId([0-9]+)',
            'activity/:activityId([0-9]+)',
            'activity/:productId([0-9]+)/review/create',
            'activity/:productId([0-9]+)/review/:reviewId([0-9]+)',
            'chat',
            'chat/user/:userId([0-9]+)',
            'shop/:shopId([0-9]+)',
            'user/dashboard',
            'user/feed',
            'user/feed/create',
            'user/feed/create/tag',
            'user/feed/create/location',
            'user/feed/:feedId([0-9]+)',
            'user/feed/:feedId([0-9]+)/tag',
            'user/feed/:feedId([0-9]+)/location',
            'user/profile',
            'user/inbox',
            'user/cart',
            'user/cart/location',
            'user/cart/location/create',
            'user/cart/location/:locationId([0-9]+)',
            'user/customer/order',
            'user/customer/order/:orderId([0-9]+)',
            'user/customer/order',
            'user/cuntomer/order/:orderId([0-9]+)',
            'user/order',
            'user/order/:orderId([0-9]+)',
            'user/farm',
            'user/field',
            'user/field/:fieldId([0-9]+)/plant',
            'user/field/:fieldId([0-9]+)/plant/:plantId([0-9]+)',
            'user/shop',
            'user/activity',
            'user/activity/create',
            'user/activity/:activityId([0-9]+)',
            'user/product/create',
            'user/product/:productId([0-9]+)',
            'user/product/create/logistics',
            'user/product/:productId([0-9]+)/logistics',
            'user/product',
            'admin/user',
            'admin/news',
            'admin/news/create',
            'admin/news/:newsId([0-9]+)',
            'admin/plant',
            'admin/plant/create',
            'admin/plant/:plantId([0-9]+)',
            'admin/plant-group',
            'admin/plant-group/create',
            'admin/plant-group/:groupId([0-9]+)',
            'admin/product-category',
            'admin/product-category/create',
            'admin/product-category/:groupId([0-9]+)',
            'admin/activity-service',
            'admin/activity-service/create',
            'admin/activity-service/:serviceId([0-9]+)',
            'admin/unit',
            'admin/unit/create',
            'admin/unit/:serviceId([0-9]+)',
        ])) {
            return <HomeLeftSection key="left_section_home" />;
        }
        return <></>;
    }

    render() {
        // console.log('Left section');
        return (
            <>{this.getContent()}</>
        );
    }
}

export default Left;