import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import pinIcon from '../assets/images/farm-marker-icon.svg';

const AnyReactComponent = ({ text }) => <div className="marker"><img src={pinIcon} alt="พิกัด" /></div>;


class MapSelector extends Component {

    // static defaultProps = {
    //     center: {
    //         lat: 15.8700,
    //         lng: 100.9925
    //     },
    //     zoom: 12
    // };

    constructor(props) {
        super(props);
        this.state = {
            center: {
                lat: parseFloat(this.props.lat ?? 19.9105),
                lng: parseFloat(this.props.lng ?? 99.8406)
            },
            select: {
                lat: parseFloat(this.props.lat ?? 19.9105),
                lng: parseFloat(this.props.lng ?? 99.8406)
            },
            zoom: 9
        };
    };

    onCLickHandler(select) {
        // console.log(select);
        if (this.props.onChange) {
            this.props.onChange(select);
        }
        this.setState({ select });
    }

    render() {
        const { center, zoom, select } = this.state;
        // console.log(center, select);
        return (
            // Important! Always set the container height explicitly
            <div style={{ height: '100%', width: '100%' }}>
                <GoogleMapReact
                    options={{ mapTypeControl: true }}
                    bootstrapURLKeys={{ key: 'AIzaSyBYl4VWLo7Lj2VYK4Pd-uri1LNH2G6c8J8' }}
                    // yesIWantToUseGoogleMapApiInternals
                    defaultCenter={center}
                    defaultZoom={zoom}
                    onClick={this.onCLickHandler.bind(this)}
                >
                    <AnyReactComponent
                        lat={select.lat}
                        lng={select.lng}
                        text="My Marker"
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

export default MapSelector;