import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Controller from '../models/Controller';
import { get as L } from '../models/Label';
import System from '../models/System';
import logo from '../assets/images/logo_256.png';
import Button from '../components/Button';
import Form from '../components/Form';
import AppBar from '../components/AppBar';
import { validateEmail, validatePhoneNumber } from '../utils/UValidate';
import InputBox from '../components/InputBox';
import API from '../models/API';

class ForgetPasswordPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            valid: {},
        };
        this.controller = new Controller(this, {
            username: '',
        });
    };

    componentWillUnmount() {
        this.controller.destroy();
    }

    submit() {

        this.setState({ valid: {} });

        const c = this.controller;
        const params = c.all();

        const username = params['username'];

        const valid = {
            username: validatePhoneNumber(username) || validateEmail(username),
        };

        for (let k in valid) {
            if (valid[k] === false) {
                this.setState({ valid });
                return;
            }
        }

        this.controller.postData(API.API_FORGET_PASSWORD,
            (data) => {
                window.openMessageModalDialog(data.message, () => this.props.history.replace(System.resolvePath('home')));
            },
            (error) => {
                window.openMessageModalDialog(error);
            });

    }

    render() {
        const { post, valid } = this.state;
        return (
            <div className="page-login container">
                <div className="box-login">
                    <AppBar
                        title={L('ลืมรหัสผ่าน')}
                        subTitle={L('กรอกข้อมูลเพื่อแจ้งการกำหนดรหัสผ่านใหม่')}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <Form loading={post.loading} className="form-row m-auto">

                        <div className="col-6 col-sm-4 mb-5">
                            <img className="w-100" src={logo} alt={L('โลโก้')} />
                        </div>

                        <div className="col-12 col-sm-8 mb-5">
                            <h2 className="color-black-1 mb-4">{L('นอร์ทออร์แกนิคฟู้ด')}</h2>
                            <h5>{L('โครงการการพัฒนาเพื่อเกษตรอินทรีย์')}</h5>
                            <h5>{L('เพื่อการพัฒนาเกษตรกรในภาคเหนือตอนบน 2')}</h5>
                        </div>

                        <div className="col-12 mb-5">
                            <InputBox
                                placeholder={L('อีเมล หรือ เบอร์โทรศัพท์')}
                                isError={valid.username === false}
                                errorMessage={L('กรุณากรอกอีเมลหรือเบอร์โทรศัพท์ให้ถูกต้อง')}
                                name="username"
                                controller={this.controller} />
                        </div>
                        <div className="col-12 mb-3">
                            <Button loading={post.loading} className="btn-primary" onClick={() => this.submit()}>{L('ตกลง')}</Button>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

export default withRouter(ForgetPasswordPage);
