import React, { Component } from 'react';
import ErrorText from './ErrorText';

class InputBox extends Component {

    constructor(props) {
        super(props);
        if (this.props.name) {
            this.id = 'input_' + this.props.name + '_' + (this.props.index ?? '');
        } else {
            this.id = 'input_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000);
        }
    };

    onChange(e) {
        if (this.props.controller) {
            // console.log('InputBox onChange:' + this.props.index + ' =>', this.props.name, e.target.value);
            //check nestest
            const tmp = this.props.name.split('.');
            if (tmp && tmp[1]) {
                const parent = this.props.controller.get(tmp[0]);
                parent[this.props.index][tmp[1]] = e.target.value;
                this.props.controller.set(tmp[0], parent, true);
                // console.log('InputBox after changed =>', this.props.name, this.props.controller.get(tmp[0])[this.props.index][tmp[1]]);
            } else {
                this.props.controller.set(this.props.name, e.target.value, true);
                // console.log('InputBox after changed =>', this.props.name, this.props.controller.get(this.props.name));
            }
        } else {
            // console.log(e.target.value, this.props.name);
            if (this.props.onChange) {
                this.props.onChange(e.target.value, this.props.name);
            }
        }
    }

    getValue() {
        // console.log(this.props.name, this.props.value, this.props.controller);
        if (!this.props.controller) {
            return this.props.value ?? '';
        }
        const tmp = this.props.name?.split('.');
        if (tmp && tmp[1]) {
            const parent = this.props.controller.get(tmp[0]);
            return parent[this.props.index][tmp[1]] ?? '';
        }
        return this.props.controller.get(this.props.name) ?? '';
    }

    render() {
        const content = <>
            <input
                type={this.props.type ?? 'text'}
                className="form-control"
                id={this.id}
                placeholder={this.props.placeholder}
                value={this.getValue()}
                onChange={(e) => this.onChange(e)}
            />
            <ErrorText isError={this.props.isError}>{this.props.errorMessage}</ErrorText>
        </>;

        if (!this.props.label) {
            return content;
        }

        return (
            <div className="form-group">
                <label htmlFor={this.id}>{this.props.label}</label>
                {content}
            </div>
        );
    }
}

export default InputBox;
