import React, { PureComponent } from 'react';
import Avatar from './Avatar';

import arrowRightIcon from '../assets/images/arrow-right-icon.svg';
import { Link } from 'react-router-dom';
import { getBone } from '../utils/URender';

class MenuItem extends PureComponent {

    constructor(props) {
        super(props);
    };

    onClick(e) {
        // console.log('MenuItem onClick');
        if (this.props.onClick) {
            // console.log('MenuItem this.props.onClick');
            this.props.onClick(e);
        }
    }

    render() {
        // console.log(this.props.label);
        // console.log(this.props.badges);
        const labelMargin = this.props.labelMargin ?? 'ml-3';

        if (this.props.name === 'dummy') {
            return <div
                className="menu-item d-flex align-items-center inactive"
                style={this.props.style}>
                <Avatar
                    src={this.props.src}
                    iconFill={this.props.iconFill}
                    iconWidth={this.props.iconWidth}
                    iconHeight={this.props.iconHeight}
                    style={this.props.iconStyle}
                    backgroundColor={this.props.iconBackgroundColor} />
                <div className={`flex-1 ${labelMargin}`}>
                    <div
                        className={`font-weight-2 ${this.props.className ?? ''}`}
                        style={this.props.labelStyle}>
                        {getBone(200, 18, 4)}
                    </div>
                    <div className="sublabel clip">{getBone(300, 15, 3)}</div>
                </div>
            </div>;
        }

        const cls = this.props.onClick || this.props.path ? '' : 'inactive';

        const content = <div
            className={"menu-item d-flex align-items-center " + cls}
            style={this.props.style}
            onClick={(e) => { this.onClick(e) }}>
            <Avatar
                src={this.props.src}
                iconFill={this.props.iconFill}
                iconWidth={this.props.iconWidth}
                iconHeight={this.props.iconHeight}
                style={this.props.iconStyle}
                badges={this.props.badges}
                unread={this.props.iconUnread}
                backgroundColor={this.props.iconBackgroundColor} />
            <div className={`flex-1 ${labelMargin}`}>
                <div
                    className={`color-black-1 font-weight-2 ${this.props.className ?? ''}`}
                    style={this.props.labelStyle}>
                    {this.props.label}
                </div>
                {this.props.sublabel && <div className={"sublabel " + (this.props.fullSublabel ? '' : 'clip')}>{this.props.sublabel}</div>}
            </div>
            {(this.props.showRightArrow || this.props.rightIcon) && <img src={this.props.rightIcon ?? arrowRightIcon} alt={this.props.label} />}
            {this.props.trailing}
        </div>;

        if (this.props.path) {
            return <Link to={this.props.path}>{content}</Link>;
        }

        return content;
    }
}

export default MenuItem;
