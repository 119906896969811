import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';

import FormTitle from '../components/FormTitle';
import Controller from '../models/Controller';
import Form from '../components/Form';

import { validateNumber } from '../utils/UValidate';

import { isMobile } from 'react-device-detect';
import AppBar from '../components/AppBar';
import System from '../models/System';

import rightIcon from '../assets/images/arrow-right-icon.svg';
import harvestIcon from '../assets/images/plant-icon-color.svg';
import EmptyBox from '../components/EmptyBox';
import API from '../models/API';

class UserFieldPage extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.controller = new Controller(this, {
            fields: [],
        });
        if (!System.isFarmer()) {
            this.props.history.replace(System.resolvePath('home'));
            return;
        }
    };

    componentDidMount() {
        this.loadFieldData();
    }

    loadFieldData() {
        this.controller.loadData(API.API_USER_FARM_FIELD, 'fieldData', null, (data) => {
            // console.log(data);
            //override name
            let field;
            for (let k in data) {
                field = data[k];
                field.displayName = field.name + ' : ' + L('ขนาด ');
                field.displayName += parseFloat(field.rai) ? ' ' + field.rai + ' ' + L('ไร่') : '';
                field.displayName += parseFloat(field.ngan) ? ' ' + field.ngan + ' ' + L('งาน') : '';
                field.displayName += parseFloat(field.square_wa) ? ' ' + field.square_wa + ' ' + L('ตารางวา') : '';
            }
            this.controller.set('fields', data);
        }, () => { 

        });
    }

    componentWillUnmount() {
        // console.log('componentWillUnmount');
        this.controller.destroy();
    }

    closeButtonClickHandler(index) {
        // console.log(index);
        const harvests = this.controller.get('harvests');
        const field = harvests[index];
        if (validateNumber(field.id)) {
            const removeFields = this.controller.get('remove_harvests') ?? [];
            removeFields.push(field.id);
            this.controller.set('remove_harvests', removeFields);
        }
        harvests.splice(index, 1);
        this.controller.set('harvests', harvests, true);
    }

    getPlantItem(item, fieldItem) {
        const label = item.plant_category.name + ' : ' + item.plant.name;
        const key = 'plant_' + item.id;
        return <Link
            to={System.resolvePath('user/field/' + fieldItem.id + '/plant/' + item.id + '?name=' + fieldItem.name)}
            key={key}
            className="col-12 px-3">
            <div className="input-next mb-3">
                <div>{label}</div>
                <img src={rightIcon} alt={label} />
            </div>
        </Link>;
    }

    render() {
        // console.log('UserPlantPage render');
        const c = this.controller;
        const data = c.all();
        const { fields } = data;
        const { post, fieldData } = this.state;
        // console.log(fields, fieldData);
        const isEmpty = (!fields || !fields.length) && fieldData && fieldData.loaded === true;
        return (
            <div className="section-content no-nav">
                <Form
                    loading={post.loading}
                    className="form-register">
                    <AppBar
                        title={L('ข้อมูลพืชที่ปลูก')}
                        subTitle={L('เพิ่มข้อมูลข้อมูลพืชที่ปลูกในแต่ละแปลง')}
                        showLeftArrow={isMobile}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />

                    {isEmpty && <EmptyBox
                        className="mt-5"
                        title={L('ไม่พบข้อมูล')}
                        icon={harvestIcon}
                        description={L('ไม่พบข้อมูลฟาร์มและแปลงผลิตของท่าน กรุณากดปุ่มเพื่อกรอกข้อมูล')}
                        buttonLabel={L('กรอกข้อมูลฟาร์มและแปลงผลิต')}
                        onButtonClick={() => {
                            // console.log('onButtonClick');
                            this.props.history.replace(System.resolvePath('user/farm'));
                        }}
                    />}

                    {fields.map((value, index) =>
                        <div key={'field_' + value.id}>
                            <FormTitle
                                label={value.displayName}
                                onButtonPress={() => {
                                    this.props.history.push({
                                        pathname: 'field/' + value.id + '/plant',
                                        search: 'name=' + value.name
                                    });
                                }} />
                            <div className="form-row form-section-register">
                                {value.plants.map((v, i) => this.getPlantItem(v, value))}
                            </div>
                        </div>
                    )}

                </Form>
            </div>
        );
    }
}

export default withRouter(UserFieldPage);
