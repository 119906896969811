import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';

import InputBox from '../components/InputBox';
import InputImageBox from '../components/InputImageBox';
import FormTitle from '../components/FormTitle';
import Controller from '../models/Controller';
import Form from '../components/Form';
import Button from '../components/Button';

import { validateEmptyValue, validateNumber } from '../utils/UValidate';
import { isEmpty } from '../utils/UObj';

import { isMobile } from 'react-device-detect';
import AppBar from '../components/AppBar';
import System from '../models/System';
import TextAreaBox from '../components/TextAreaBox';
import SelectionInput from '../components/SelectionInput';
import API from '../models/API';
import InputSwitch from '../components/InputSwitch';
import Icon from '../models/Icon';
import MapSelector from '../components/MapSelector';
import ParagraphForm from '../components/ParagraphForm';

class UserActivityFormPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            showMapDialog: false,
            valid: {},
        };

        // console.log(this.props);

        const formData = this.props.location?.formData;
        // console.log(formData);
        this.controller = new Controller(this, {
            images: [],
            status: 1,
            product_services: [],
            paragraphs: [{
                id: 'paragraph_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000),
                description: '',
                images: [],
            }],
            open: {
                'mon': { checked: false, start_time: null, end_time: null },
                'tue': { checked: false, start_time: null, end_time: null },
                'wen': { checked: false, start_time: null, end_time: null },
                'thu': { checked: false, start_time: null, end_time: null },
                'fri': { checked: false, start_time: null, end_time: null },
                'sat': { checked: false, start_time: null, end_time: null },
                'sun': { checked: false, start_time: null, end_time: null },
            },
            ...formData
        });

        this.days = [
            { id: 'mon', name: 'วันจันทร์' },
            { id: 'tue', name: 'วันอังคาร' },
            { id: 'wen', name: 'วันพุธ' },
            { id: 'thu', name: 'วันพฤหัสบดี' },
            { id: 'fri', name: 'วันศุกร์' },
            { id: 'sat', name: 'วันเสาร์' },
            { id: 'sun', name: 'วันอาทิตย์' }
        ];

        if (!System.isFarmer()) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('home'));
            return;
        }

        //check id
        const _path = System.matchPath(this.props.location.pathname, 'user/activity/:activityId');
        if (_path) {
            this.id = validateNumber(_path.params.activityId);
            if (this.id) {
                this.isEdit = true;
            }
        }
    };

    componentDidMount() {
        this.loadActivityService();
        this.loadProductData();
    }

    loadActivityService() {
        this.controller.loadData(API.API_ACTIVITY_SERVICE, 'activityService', { filter: 0 }, (data) => {
        });
    }

    loadProductData() {
        if (!this.isEdit) {
            return;
        }

        //check loaded
        if (this.controller.get('name')) {
            return;
        }

        this.controller.loadData(API.API_USER_ACTIVITY + '/' + this.id, 'activity', null, (data) => {
            if (!data) {
                this.isError = true;
                this.props.history.replace(System.resolvePath('user/activity'));
                return;
            }

            const selecteds = [];
            for (let k in data?.product_services) {
                selecteds.push(parseInt(data.product_services[k]?.pivot?.product_service_id));
            }
            data.product_services = selecteds;

            let obj;
            let paragraphs = this.controller.get('paragraphs');
            if (data['paragraphs']) {
                paragraphs = [];
                for (let k in data['paragraphs']) {
                    obj = data['paragraphs'][k];
                    // console.log(obj.image);
                    paragraphs.push({
                        id: obj.id,
                        description: obj.description,
                        images: [obj],
                    });
                }
                // console.log(paragraphs);
            }

            const open = this.controller.get('open');
            if (data['opens']) {
                for (let k in data['opens']) {
                    obj = data['opens'][k];
                    open[obj.day].checked = true;
                    open[obj.day].start_time = obj.start_time;
                    open[obj.day].end_time = obj.end_time;
                }
                // console.log(open);
            }

            const formData = {
                images: data['images'],
                name: data['name'],
                description: data['description'],
                detail: data['detail'],
                price: data['price'],
                quantity: data['quantity'],
                status: data['status'],
                lat: data['lat'],
                lng: data['lng'],
                product_services: data['product_services'],
                open,
                paragraphs
            };

            this.controller.append(formData);
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    closeButtonClickHandler(index) {
        // console.log(index);
        const paragraphs = this.controller.get('paragraphs');
        const paragraph = paragraphs[index];
        if (validateNumber(paragraph.id)) {
            const removeFields = this.controller.get('remove_paragraphs') ?? [];
            removeFields.push(paragraph.id);
            this.controller.set('remove_paragraphs', removeFields);
        }
        paragraphs.splice(index, 1);
        this.controller.set('paragraphs', paragraphs, true);
    }

    async delete() {
        await this.setState({ openDeleteDialog: true });
        window.openModalDialog('deleteModal', () => {
            this.setState({ openDeleteDialog: false });
        });
    }

    confirmDelete() {
        this.controller.deleteData(API.API_USER_PRODUCT + '/' + this.id,
            (data) => {
                window.hideModalDialog('deleteModal');
                this.setState({ openDeleteDialog: false });
                this.props.history.goBack();
            },
            (error) => {
                window.openMessageModalDialog(error);
            });
    }

    async openMapDialog() {
        this.lat = null;
        this.lng = null;
        await this.setState({ showMapDialog: true });
        window.openModalDialog('mapModal', () => {
            this.setState({ showMapDialog: false });
        });
    }

    closeMapDialog() {
        // console.log(this.lat, this.lng);
        if (this.lat && this.lng) {
            this.controller.set('lat', this.lat);
            this.controller.set('lng', this.lng);
        }
        window.hideModalDialog('mapModal');
        this.setState({ showMapDialog: false });
    }

    async submit() {

        // console.log('submit');
        this.setState({ valid: {} });

        const c = this.controller;
        const params = { ...c.all() };

        const name = params['name'] ?? '';
        const description = params['description'] ?? '';
        // const detail = params['detail'] ?? '';
        const images = params['images'] ?? [];
        const product_services = params['product_services'];
        const paragraphs = params['paragraphs'];
        const open = params['open'];
        // const price = params['price'];
        // const quantity = params['quantity'];

        const valid = {
            name: validateEmptyValue(name),
            description: validateEmptyValue(description),
            product_services: product_services.length !== 0,
            image: images.length !== 0
        };
        // console.log(valid);

        //validate open not necessary

        for (let k in valid) {
            if (valid[k] === false) {
                this.setState({ valid });
                console.log('invalid');
                return;
            }
            if (Array.isArray(valid[k])) {
                for (let i in valid[k]) {
                    if (valid[k][i] === false) {
                        this.setState({ valid });
                        console.log('invalid');
                        return;
                    }
                }
            }
        }
        // console.log(valid);

        let product_service;
        for (let k in product_services) {
            product_service = product_services[k];
            params['product_service_id_' + k] = product_service;
        }
        delete params['product_services'];
        // console.log(params);

        let i = 0;
        let key;
        // console.log(images);
        let file, blob;
        i = 0;
        for (let k in images) {
            if (!isEmpty(images[k]) && typeof images[k] === 'object') {
                continue;
            }
            blob = await fetch(images[k]).then(r => r.blob());
            file = new File([blob], 'image_' + i + '.jpg', { type: 'image/jpg' });
            params['image_' + i] = file;
            i++;
        }
        if (params['remove_images']) {
            params['remove_images'] = params['remove_images'].toString();
        }
        delete params['images'];

        i = 0;
        let paragraph;
        let pimages;
        for (let k in paragraphs) {
            paragraph = paragraphs[k];
            params['paragraph_id_' + i] = paragraph['id'];
            params['paragraph_description_' + i] = paragraph['description'];
            pimages = paragraph.images;
            for (let l in pimages) {
                if (!isEmpty(pimages[l]) && typeof pimages[l] === 'object') {
                    continue;
                }
                blob = await fetch(pimages[l]).then(r => r.blob());
                file = new File([blob], 'paragraph_image_' + i + '.jpg', { type: 'image/jpg' });
                params['paragraph_image_' + i] = file;
                break;
            }
            i++;
        }
        if (params['remove_paragraphs']) {
            params['remove_paragraphs'] = params['remove_paragraphs'].toString();
        }
        delete params['paragraphs'];

        for (let k in this.days) {
            key = this.days[k].id;
            if (open[key].checked && open[key].start_time && open[key].end_time) {
                params['start_time_' + key] = open[key].start_time;
                params['end_time_' + key] = open[key].end_time;
            }
        }
        delete params['open'];

        const formData = new FormData();
        let value;
        for (let key in params) {
            value = params[key];
            if (isEmpty(value)) {
                continue;
            }
            formData.append(key, value);
        }

        // console.log(params);
        // return;

        if (this.isEdit) {
            this.controller.putMultipathData(API.API_USER_ACTIVITY + '/' + this.id, formData,
                (data) => {
                    this.props.history.replace(System.resolvePath('user/activity'));
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        } else {
            this.controller.postMultipathData(API.API_USER_ACTIVITY, formData,
                (data) => {
                    this.props.history.replace(System.resolvePath('user/activity'));
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        }

    }

    render() {
        if (this.isError) {
            return null;
        }
        // console.log('UserShopFormPage render');
        const c = this.controller;
        const data = c.all();
        let { product_services, paragraphs, status, lat, lng, open } = data;
        const { valid, post, activityService, showMapDialog, openDeleteDialog, destroy } = this.state;
        const loading = (post && post.loading) || (destroy && destroy.loading);
        // console.log(paragraphs);
        return (
            <div className="section-content no-nav">
                <Form
                    loading={post.loading}
                    className="form-register">
                    <AppBar
                        title={L('ข้อมูลกิจกรรม')}
                        subTitle={L('กรอกข้อมูลกิจกรรมและคำอธิบาย')}
                        showLeftArrow={true}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <InputImageBox
                                path="product"
                                name="images"
                                description={L('รูปภาพ: คุณสามารถอัพรูปปกเพื่อ แนะนำกิจกรรมของคุณเองได้ ขนาดของรูปที่เหมาะสมคือ 1280x480 พิกเซลและอัพได้อย่างน้อย 1 รูปแต่ไม่เกิน 5 รูป')}
                                max={5}
                                controller={c}
                                label={L('รูปปกกิจกรรม*')}
                                isError={valid.image === false}
                                errorMessage={L('กรุณาเพิ่มรูปปกกิจกรรม')} />
                        </div>
                        <div className="col-12">
                            <InputBox
                                label={L('ชื่อกิจกรรม*')}
                                placeholder={L('ชื่อกิจกรรม')}
                                name="name"
                                controller={c}
                                isError={valid.name === false}
                                errorMessage={L('กรุณากรอกชื่อกิจกรรม')} />
                        </div>
                        <div className="col-12">
                            <TextAreaBox
                                label={L('คำอธิบายกิจกรรม*')}
                                placeholder={L('ลักษณะ, จุดเด่น, กิจกรรมที่ขาย')}
                                name="description"
                                controller={c}
                                isError={valid.description === false}
                                errorMessage={L('กรุณากรอกคำอธิบายกิจกรรม')} />
                        </div>
                    </div>

                    <FormTitle label={L('รายละเอียดกิจกรรม')} />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <TextAreaBox
                                label={L('ส่งที่ลูกค้าจะได้พบ')}
                                placeholder={L('ประโยชน์, การใช้งาน, การดูแลรักษา, กิจกรรมที่ขาย')}
                                name="detail"
                                rows={6}
                                controller={c} />
                        </div>
                        <div className="col-12">
                            <FormTitle
                                label={L('คำอธิบายพร้อมรูปภาพ')}
                                subLabel="รูปภาพ: รูปเกี่ยวกับกิจกรรม ขนาดของรูปที่เหมาะสมคือ 1024x720 พิกเซล"
                                onButtonPress={() => {
                                    //test
                                    paragraphs.push({
                                        id: 'paragraph_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000),
                                        description: '',
                                        images: [],
                                    });
                                    c.set('paragraphs', paragraphs, true);
                                }} />
                        </div>
                        <div className="col-12">
                            <div className="form-row form-section-register">
                                <div className="col-12">
                                    {paragraphs && paragraphs.map((v, i) => <ParagraphForm
                                        key={v.id}
                                        index={i}
                                        controller={c}
                                        path="product"
                                        closeButtonClickHandler={this.closeButtonClickHandler.bind(this)}
                                        showCloseButton={paragraphs.length > 1} />)}
                                </div>
                            </div>
                        </div>
                    </div>

                    <FormTitle label={L('ราคาและบริการ')} />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <SelectionInput
                                data={activityService?.response ?? []}
                                selecteds={product_services}
                                label={L('บริการ*')}
                                clickHandler={(item) => {
                                    const index = product_services.indexOf(parseInt(item.id));
                                    if (index === -1) {
                                        product_services.push(parseInt(item.id));
                                    } else {
                                        product_services.splice(index, 1);
                                    }
                                    // console.log(product_services);
                                    c.set('product_services', product_services, true);
                                }}
                                isError={valid.product_services === false}
                                errorMessage={L('กรุณาเลือกบริการ')} />
                        </div>
                        <div className="col-12">
                            <InputBox
                                label={L('ค่าบริการ (บาท)')}
                                placeholder={L('ราคาค่าบริการในกิจกรรม ไม่ระบุถือว่าฟรี')}
                                type="number"
                                name="price"
                                controller={c}
                                isError={valid.price === false}
                                errorMessage={L('กรุณากรอกค่าบริการ')} />
                        </div>
                        <div className="col-6">
                            <InputBox
                                label={L('จำนวน')}
                                placeholder={L('จำนวนที่เหลือให้บริการ')}
                                type="number"
                                name="quantity"
                                controller={c}
                                isError={valid.quantity === false}
                                errorMessage={L('กรุณากรอกจำนวนที่เหลือให้บริการ')} />
                        </div>
                        <div className="col-6">
                            <InputSwitch
                                name="status"
                                checked={parseInt(status) === 1}
                                label={L('สถานะกิจกรรม')}
                                text={L('แสดงในหน้าแสดงกิจกรรม')}
                                controller={c} />
                        </div>
                    </div>

                    <FormTitle
                        label={L('เวลาทำการ')}
                        isError={valid.open === false}
                        errorMessage={L('กรุณากำหนดเวลาเปิดและเวลาปิดให้ถูกต้อง')} />
                    <div className="form-row form-section-register">
                        {this.days.map((item) => {
                            return <React.Fragment key={'day_' + item.id}>
                                <div className="col-4 d-flex align-items-center">
                                    <div className="custom-control custom-checkbox mb-2">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={'ch_' + item.id}
                                            checked={open[item.id].checked}
                                            onChange={(event) => {
                                                open[item.id].checked = event.target.checked;
                                                c.set('open', open, true);
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor={'ch_' + item.id}>{L(item.name)}</label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <input
                                            type="time"
                                            className="form-control"
                                            id={'st_' + item.id}
                                            value={open[item.id].start_time}
                                            onChange={(event) => {
                                                open[item.id].start_time = event.target.value;
                                                c.set('open', open, true);
                                            }} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                        <input
                                            type="time"
                                            className="form-control"
                                            id={'et' + item.id}
                                            value={open[item.id].end_time}
                                            onChange={(event) => {
                                                open[item.id].end_time = event.target.value;
                                                c.set('open', open, true);
                                            }} />
                                    </div>
                                </div>
                                <div className="w-100"></div>
                            </React.Fragment>
                        })}
                    </div>

                    <FormTitle label={L('ตำแหน่งและที่ตั้ง')} />
                    <div className="form-row form-section-register">
                        <div className="col-6">
                            <InputBox
                                label={L('ละติจูด')}
                                name="lat"
                                controller={c}
                                placeholder={L('xx.xxxxxxxxxx')} />
                        </div>
                        <div className="col-6 position-relative">
                            <InputBox
                                label={L('ลองติจูด')}
                                name="lng"
                                controller={c}
                                placeholder={L('xx.xxxxxxxxxx')} />
                            <img
                                className="btn-pin-farm"
                                src={Icon.get('pin')}
                                alt={L('เลือกพิกัดจากแผนที่')}
                                onClick={this.openMapDialog.bind(this)} />
                        </div>
                    </div>

                    <div className="form-row mt-4">
                        {this.isEdit && <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => this.delete()}>
                                {L('ลบข้อมูล')}
                            </Button>
                        </div>}
                        <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.submit()}>
                                {L('บันทึกข้อมูล')}
                            </Button>
                        </div>
                    </div>

                </Form>

                {openDeleteDialog && <div id="deleteModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('ลบข้อมูล')}</h5>
                            </div>
                            <div className="modal-body">
                                <p>{L('คุณต้องการลบข้อมูลนี้ใช่หรือไม่')}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-3"
                                    onClick={() => {
                                        window.hideModalDialog('deleteModal');
                                        this.setState({ openDeleteDialog: false });
                                    }}>{L('ยกเลิก')}</button>
                                <button
                                    type="button"
                                    className="btn btn-primary mr-3"
                                    onClick={() => {
                                        this.confirmDelete();
                                    }}>{L('ลบข้อมูล')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

                {showMapDialog && <div
                    id="mapModal"
                    className="modal modal-full"
                    tabIndex="-1"
                    role="dialog"
                    data-keyboard="false"
                    data-backdrop="static">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('เลือกพิกัดจากแผนที่')}</h5>
                            </div>
                            <div className="modal-body modal-body-map">
                                <MapSelector
                                    lat={lat}
                                    lng={lng}
                                    onChange={(data) => {
                                        this.lat = data.lat;
                                        this.lng = data.lng;
                                    }} />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.closeMapDialog();
                                    }}>{L('ยืนยัน')}</button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        this.lat = null;
                                        this.lng = null;
                                        this.closeMapDialog();
                                    }}>{L('ยกเลิก')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}

export default withRouter(UserActivityFormPage);