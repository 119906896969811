import React, { Component } from 'react';
import ErrorText from './ErrorText';

class InputPasswordBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
        };
        if (this.props.name) {
            this.id = 'input_' + this.props.name + '_' + (this.props.index ?? '');
        } else {
            this.id = 'input_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000);
        }
    };

    onChange(e) {
        if (this.props.controller) {
            this.props.controller.set(this.props.name, e.target.value, true);
        } else {
            if (this.props.onChange) {
                this.props.onChange(e);
            }
        }
    }

    render() {
        const { show } = this.state;
        const content = <>
            <div className="input-group">
                <input
                    type={show ? "text" : "password"}
                    className="form-control"
                    id={this.id}
                    placeholder={this.props.placeholder}
                    value={this.props.controller ? this.props.controller.get(this.props.name) : this.props.value}
                    onChange={(e) => this.onChange(e)}
                />
                <div
                    className={`password-eye ${(show ? 'active' : '')} ${this.props.eyeSize ?? ''}`}
                    onClick={() => this.setState({ show: !show })}></div>
            </div>
            <ErrorText isError={this.props.isError}>{this.props.errorMessage}</ErrorText>
        </>;
        if (!this.props.label) {
            return content;
        }
        return (
            <div className="form-group">
                <label htmlFor={this.id}>{this.props.label}</label>
                {content}
            </div>
        );
    }
}

export default InputPasswordBox;
