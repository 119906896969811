import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import EmptyBox from '../components/EmptyBox';

import API from '../models/API';
import Controller from '../models/Controller';
import System from '../models/System';
import { get as L } from '../models/Label';
import Icon from '../models/Icon';
import ChatBox from '../components/ChatBox';
import { validateNumber } from '../utils/UValidate';
import Event from '../models/Event';
import { Carousel } from 'react-responsive-carousel';
import Chat from '../models/Chat';
import { isMobile } from 'react-device-detect';

class ChatPage extends Component {

    constructor(props) {
        super(props);

        //check id
        const _path = System.matchPath(this.props.location.pathname, 'chat/user/:userId');
        if (_path) {
            this.userId = validateNumber(_path.params.userId);
        }

        this.state = {
            openImage: false,
            images: [],
            path: '',
            selectedIndex: -1,
        };

        this.controller = new Controller(this, {
            user_id: this.userId
        });
    }

    componentDidMount() {
        //check chat room
        this.saveChatRoom();
    }

    componentDidUpdate() {
        //check diff user and rerender
        // console.log('componentDidUpdate');
        //check id
        let userId = 0;
        const oldUserId = this.userId;
        const _path = System.matchPath(this.props.location.pathname, 'chat/user/:userId');
        if (_path) {
            userId = validateNumber(_path.params.userId);
        }
        this.userId = userId;
        if (userId) {
            if (userId !== oldUserId) {
                // console.log('change room');
                this.controller.set('user_id', userId);
                this.saveChatRoom();
            }
        } else {
            this.controller.commit();
        }
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    saveChatRoom() {
        if (this.controller.get('user_id')) {
            this.controller.postData(API.API_CHAT, (data) => {
                // console.log(data);
                if (data['new']) {
                    Event.dispatch(Event.CHAT_CONTACT_CHANGE);
                }
            }, (error) => {
                this.showError();
            });
        }
    }

    showError() {
        window.openMessageModalDialog('พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ', () => this.props.history.replace('home'));
    }

    showImage(openImage, images, path, selectedIndex) {
        // console.log(images);
        this.setState({
            openImage,
            images,
            path,
            selectedIndex,
        });
    }

    render() {

        const cls = isMobile ? 'fit' : '';

        if (!this.userId) {
            return <div className={"section-content " + cls}>
                <div className="chat-container">
                </div>
            </div>;
        }

        if (!Chat.isAllow()) {
            Chat.askForPermission()
            return <div className={"section-content " + cls}>
                <div className="chat-container">
                    <EmptyBox
                        className="mt-5"
                        title={L('กรุณาเปิดการแจ้งเตือน')}
                        icon={Icon.get('chat')}
                        description={L('ท่านต้องเปิดการแจ้งเตือนก่อนเข้าใช้งานการสนทนา')}
                    // buttonLabel={L('เปิดการแจ้งเตือน')}
                    // onButtonClick={() => {
                    //     Chat.askForPermission();
                    // }}
                    />
                </div>
            </div>;
        }

        //check logged
        if (!System.isLogged()) {
            return <div className={"section-content " + cls}>
                <div className="chat-container">
                    <EmptyBox
                        className="mt-5"
                        title={L('กรุณาสมัครเป็นสมาชิก')}
                        icon={Icon.get('chat')}
                        description={L('ท่านต้องเป็นสมาชิกก่อนเข้าใช้งานการสนทนา')}
                        buttonLabel={L('สมัครสมาชิคตอนนี้')}
                        onButtonClick={() => {
                            // console.log('onButtonClick');
                            this.props.history.replace(System.resolvePath('register'));
                        }}
                    />
                </div>
            </div>;
        }

        const { post, openImage, images, path, selectedIndex } = this.state;
        // console.log(post?.response?.id);
        return (
            <div className={"section-content " + cls}>
                <div className="chat-container">
                    <ChatBox
                        data={post?.response}
                        showImageHandler={this.showImage.bind(this)}
                        onBackPress={() => this.props.history.goBack()}
                    >
                    </ChatBox>
                </div>
                {(openImage && images.length !== 0) && <div className="fill-window fit-window">
                    <Carousel
                        showStatus={false}
                        showIndicators={false}
                        showArrows={true}
                        infiniteLoop={true}
                        selectedItem={selectedIndex}
                    >
                        {images.map((item) => <div className="fit-window-item p-4">
                            <img src={System.resolveImagePath(item, path)} className="fit-window-image" />
                        </div>)}
                    </Carousel>
                    <div className="btn-icon pointer" onClick={() => this.showImage(false, null, null, null)}>
                        <img src={Icon.get('close', 'white')} alt={L('ปิด')} />
                    </div>
                </div>}
            </div>
        );
    }
}

export default withRouter(ChatPage);
