import React, { PureComponent } from 'react';
import Icon from '../models/Icon';
// import System from '../models/System';
// import Avatar from './Avatar';
import { get as L } from '../models/Label';

class TagBox extends PureComponent {

    constructor(props) {
        super(props);
    };

    getItem(item) {
        // let avatar;
        // if (item.logo) {
        //     avatar = System.resolveImagePath(item.logo, 'product');
        // } else {
        //     avatar = System.resolveThumnailPath(item.thumbnail, 'product');
        // }
        return <div key={'tag_box_item_' + item.id} className="tag-item pl-2 mr-2 mb-2">
            {/* <Avatar
                className="mr-2"
                src={avatar}
                style={menuItemStyle}
                iconFill
            /> */}
            <div className="flex-1 ellipsis mr-2">
                <div className="order-user-name font-size-0 ellipsis">{item.name}</div>
            </div>
            {this.props.closeHandler && <div className="btn-icon"
                onClick={() => this.props.closeHandler(item)}>
                <img src={Icon.get('close')} alt={L('ลบ')} />
            </div>}
        </div>;
    }

    render() {
        const { items } = this.props ?? [];
        return (
            <div className="tag-box">
                {items.map((item) => {
                    return this.getItem(item);
                })}
            </div>
        );
    }
}

const menuItemStyle = { width: '32px', height: '32px' };

export default TagBox;
