import React, { Component } from 'react';

import System from './models/System';
import MainPage from './pages/MainPage';

class App extends Component {

    constructor(props) {
        super(props);
        // console.log('Start App');
        System.init();
    };   

    render() {
        return <MainPage />;
    }
}

export default App;
