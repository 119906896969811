import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Controller from '../models/Controller';
import { get as L } from '../models/Label';
import System from '../models/System';
import logo from '../assets/images/logo_256.png';
import Button from '../components/Button';
import Form from '../components/Form';
import AppBar from '../components/AppBar';
import { validatePassword } from '../utils/UValidate';
import InputPasswordBox from '../components/InputPasswordBox';
import API from '../models/API';

class ResetPasswordPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            valid: {},
        };

        const params = new URLSearchParams(this.props.location.search);
        this.token = params.get('token');
        const formData = {
            password: '',
            confirm_password: '',
            token: this.token,
        }

        this.controller = new Controller(this, formData);

        if (!this.token) {
            this.props.history.replace(System.resolvePath('home'));
            return;
        }

    };

    componentWillUnmount() {
        this.controller.destroy();
    }

    submit() {

        this.setState({ valid: {} });

        const c = this.controller;
        const params = c.all();

        const token = params['token'];
        const password = params['password'];
        const confirmPassword = params['confirm_password'];

        const valid = {
            password: validatePassword(confirmPassword, password),
            token,
        };

        for (let k in valid) {
            if (valid[k] === false) {
                this.setState({ valid });
                return;
            }
        }

        this.controller.postData(API.API_RESET_PASSWORD,
            (data) => {
                window.openMessageModalDialog(L('การกำหนดรหัสใหม่สำเร็จ'), () => this.props.history.replace(System.resolvePath('login')));
            },
            (error) => {
                window.openMessageModalDialog(error);
            });

    }

    render() {
        const { post, valid } = this.state;

        if (!this.token) {
            return null;
        }

        return (
            <div className="page-login container">
                <div className="box-login">
                    <AppBar
                        title={L('กำหนดรหัสผ่านใหม่')}
                        subTitle={L('ตั้งรหัสผ่านปลอดภัยและจำได้ง่าย')}
                        showLeftArrow={false}
                    />
                    <Form loading={post.loading} className="form-row m-auto">

                        <div className="col-6 col-sm-4 mb-5">
                            <img className="w-100" src={logo} alt={L('โลโก้')} />
                        </div>

                        <div className="col-12 col-sm-8 mb-5">
                            <h2 className="color-black-1 mb-4">{L('นอร์ทออร์แกนิคฟู้ด')}</h2>
                            <h5>{L('โครงการการพัฒนาเพื่อเกษตรอินทรีย์')}</h5>
                            <h5>{L('เพื่อการพัฒนาเกษตรกรในภาคเหนือตอนบน 2')}</h5>
                        </div>

                        <div className="col-12 mb-3">
                            <InputPasswordBox
                                placeholder={L('รหัสผ่านใหม่')}
                                isError={valid.username === false}
                                errorMessage={L('กรุณากรอกรหัสใหม่')}
                                name="password"
                                eyeSize="lg"
                                controller={this.controller} />
                        </div>
                        <div className="col-12 mb-5">
                            <InputPasswordBox
                                placeholder={L('ยืนยันรหัสผ่าน')}
                                isError={valid.username === false}
                                errorMessage={L('กรุณากรอกรหัสผ่านถูกต้อง')}
                                name="confirm_password"
                                eyeSize="lg"
                                controller={this.controller} />
                        </div>
                        <div className="col-12">
                            <Button loading={post.loading} className="btn-primary" onClick={() => this.submit()}>{L('กำหนดรหัสผ่านใหม่')}</Button>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

export default withRouter(ResetPasswordPage);
