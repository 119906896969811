import React, { Component } from 'react';
import { get as L } from '../models/Label';

import logo from '../assets/images/logo_256.png';
import { Link } from 'react-router-dom';
import System from '../models/System';

class HeaderLeftSection extends Component {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className="header-section-left">
                <Link to={System.resolvePath('')}>
                    <img src={logo} className="header-logo pointer mr-2" alt={L('โลโก้')} />
                </Link>
                <input type="text" className="form-control input-search input-search-header" placeholder={L('ค้นหาในระบบ')} />
            </div>
        );
    }
}

export default HeaderLeftSection;
