export const getBone = (w, h, mb, cls = '') => {
    const style = {};
    style['width'] = '100%';
    if (w) {
        style['maxWidth'] = w + 'px';
    }
    if (h) {
        style['height'] = h + 'px';
    }
    if (mb) {
        style['marginBottom'] = mb + 'px';
    }
    return <div className={`skeleton-box ${cls}`} style={style}></div>
};

export const getBoneFix = (w, h, mb, cls = '') => {
    const style = {};
    style['width'] = w + 'px';
    style['height'] = h + 'px';
    if (mb) {
        style['marginBottom'] = mb + 'px';
    }
    return <div className={`skeleton-box ${cls}`} style={style}></div>
};