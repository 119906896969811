import React, { Component } from 'react';

import Button from '../components/Button';
import API from '../models/API';
import Controller from '../models/Controller';
import { get as L } from '../models/Label';
import System from '../models/System';
import TextAreaBox from '../components/TextAreaBox';
import { validateEmptyValue, validateNumber } from '../utils/UValidate';
import { withRouter } from 'react-router';
import Label from '../components/Label';
import MenuItem from '../components/MenuItem';
import HLine from '../components/HLine';

import { address, nf } from '../utils/UObj';
import AppBar from '../components/AppBar';
import ShopItem from '../components/ShopItem';
import LinkIcon from '../components/LinkIcon';

import chatIcon from '../assets/images/chat-icon.svg';
import ListItemCart from '../components/ListItemCart';

class UserOrderDetailPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            order: {
                response: {
                    order_products: [
                        { id: 'dummy_' + 1, product: 'dummy', },
                        { id: 'dummy_' + 2, product: 'dummy', },
                        { id: 'dummy_' + 3, product: 'dummy', }
                    ]
                },
            },
            valid: {},
        };
        this.controller = new Controller(this, {});

        //for sure allow only user
        if (!System.isLogged()) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('home'));
            return;
        }

        //check what view point
        let path = 'user/order/:orderId';
        if (this.props.location.pathname.indexOf('customer/order') !== -1) {
            this.isMerchant = true;
            path = 'user/customer/order/:orderId';
            if (!System.isFarmer()) {
                this.isError = true;
                this.props.history.replace(System.resolvePath('home'));
                return;
            }
        }

        //check id
        const _path = System.matchPath(this.props.location.pathname, path);
        // console.log(_path);
        if (_path) {
            this.id = validateNumber(_path.params.orderId);
        }
        // console.log(this.id);
        const redirect = this.isMerchant ? 'user/customer/order' : 'user/order';
        if (!this.id) {
            this.isError = true;
            this.props.history.replace(System.resolvePath(redirect));
            return;
        }

        this.badgesMap = {
            '1': { label: 'รอการตอบกลับ', className: 'badge badge-info' },
            '2': { label: 'ขายแล้ว', className: 'badge badge-primary' },
            '3': { label: 'ยกเลิก', className: 'badge badge-danger' }
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    loadData() {
        if (this.isError) {
            return;
        }
        const api = this.isMerchant ? API.API_USER_ORDER : API.API_ORDER;
        this.controller.loadData(api + '/' + this.id, 'order', null, (data) => {
            // console.log(data);
        }, () => {
            this.isError = true;
            const redirect = this.isMerchant ? 'user/customer/order' : 'user/order';
            this.props.history.replace(System.resolvePath(redirect));
        });
    }

    async delete() {
        await this.setState({ openDeleteDialog: true });
        window.openModalDialog('deleteModal', () => {
            this.setState({ openDeleteDialog: false });
        });
    }

    confirmDelete() {
        const valid = { notice: validateEmptyValue(this.controller.get('notice')) };
        this.setState({ valid });
        if (valid.notice === false) {
            // console.log('invalid');
            return;
        }
        const api = this.isMerchant ? API.API_USER_ORDER : API.API_ORDER;
        this.controller.postData(api + '/' + this.id + '/reject', (data) => {
            const redirect = this.isMerchant ? 'user/customer/order' : 'user/order';
            this.props.history.replace(System.resolvePath(redirect));
        }, () => { });
        window.hideModalDialog('deleteModal');
    }

    accept() {
        this.controller.postData(API.API_USER_ORDER + '/' + this.id + '/accept', (data) => {
            this.props.history.replace(System.resolvePath('user/customer/order'));
        }, (error) => {
            window.openMessageModalDialog(error);
        });
    }

    render() {

        if (this.isError) {
            return <div className="section-content"><div className="cart-list"></div></div>;
        }

        const { order, openDeleteDialog, loading, valid } = this.state;
        const data = order?.response ?? {};
        // console.log(data.order_products);
        const badges = this.badgesMap[data.status];
        const subtitle = this.isMerchant ? 'กรุณาตรวจสอบข้อมูลก่อนยอมรับหรือปฏิเสธรายการสั่งซื้อสินค้า' : 'กรุณาตรวจสอบข้อมูลก่อนปฏิเสธรายการสั่งซื้อสินค้า';
        return (
            <div className="section-content">
                <div className="cart-list">
                    <AppBar
                        title={L('ข้อมูลรายการสั่งซื้อสินค้า')}
                        subTitle={L(subtitle)}
                        showLeftArrow={true}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />

                    {this.isMerchant
                        ? <>
                            <Label>{L('ลูกค้า')}</Label>
                            <MenuItem
                                iconFill
                                src={System.resolveImagePath(data.user?.profile_image, 'profile')}
                                label={(data.user?.first_name ?? '') + ' ' + (data.user?.last_name ?? '')}
                                trailing={<LinkIcon className="ml-3" to={System.resolvePath('chat/user/' + data.user?.id)} icon={chatIcon}>{L('แชท')}</LinkIcon>}
                            />
                        </>
                        : <>
                            <Label>{L('ร้านค้า')}</Label>
                            <ShopItem data={data.shop} />
                        </>}

                    <HLine className="my-2"></HLine>

                    {data.location && <>
                        <Label>{L('สถานที่จัดส่งสินค้า')}</Label>
                        <div className="list-tile-sublabel dark">{L('ผู้รับ')} {data.recipient_name ?? '-'} {L('โทร.')} {data.phone_number ?? '-'}</div>
                        <div>{address(data)}</div>
                        <HLine className="my-2"></HLine>
                    </>}

                    <Label>{L('รายการสินค้า')}</Label>
                    <div key={'s_' + data.id} className="form-row">
                        {data.order_products && data.order_products.map((obj) =>
                            <ListItemCart
                                key={'p_' + obj.id}
                                quantity={obj.quantity}
                                icon={System.resolveThumnailPath(obj.product?.thumbnail?.image, 'product')}
                                className="col-12"
                                data={obj.product} />)}
                    </div>

                    <HLine className="my-2"></HLine>
                    <div className="text-right">
                        {parseInt(data.status) !== 1 && <span className={'list-tile-badges mr-3 ' + badges?.className}>{badges?.label}</span>}
                        {L('ยอดซื้อทั้งหมด')} <b className="list-tile-price">฿{nf(data.amount, 2)}</b>
                    </div>
                    <div className="text-right form-desc">*{L('ยอดทั้งหมดไม่รวมการขนส่ง การขนส่งผู้ขายต้องตกลงกับลูกค้าเอง')}</div>
                    <HLine className="my-2"></HLine>

                    {data.notice && <>
                        <Label>{L('*หมายเหตุ')}</Label>
                        <div>{data.notice}</div>
                        <HLine className="my-2"></HLine>
                    </>}

                    {parseInt(data.status) === 1 && <div className="form-row mt-5">
                        <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => this.delete()}>
                                {L('ปฏิเสธรายการ')}
                            </Button>
                        </div>
                        {this.isMerchant && <div className="col">
                            <Button
                                onClick={() => this.accept()}
                                className="btn btn-primary"
                            >{L('ยอมรับรายการ')}</Button>
                        </div>}
                    </div>}

                </div>

                {openDeleteDialog && <div id="deleteModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('ปฏิเสธรายการ')}</h5>
                            </div>
                            <div className="modal-body">
                                <p>{L('คุณต้องการปฏิเสธรายการนี้ใช่หรือไม่')}</p>
                                <TextAreaBox
                                    label={L('เหตุผลการปฏิเสธรายการ')}
                                    placeholder={L('ประโยชน์, การใช้งาน, การดูแลรักษา, สินค้าที่ขาย')}
                                    name="notice"
                                    isError={valid['notice'] === false}
                                    errorMessage={L('กรุณากรอกเหตุผลการปฏิเสธรายการสั่งซื้อสินค้า')}
                                    controller={this.controller} />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-3"
                                    onClick={() => {
                                        window.hideModalDialog('deleteModal');
                                        this.setState({ openDeleteDialog: false });
                                    }}>{L('ยกเลิก')}</button>
                                <button
                                    type="button"
                                    className="btn btn-primary mr-3"
                                    onClick={() => {
                                        this.confirmDelete();
                                    }}>{L('ปฏิเสธรายการ')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}

export default withRouter(UserOrderDetailPage);