import React, { Component } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Carousel } from 'react-responsive-carousel';
import Icon from '../models/Icon';
import System from '../models/System';
import { get as L } from '../models/Label';
import Controller from '../models/Controller';
import API from '../models/API';

class FeedView extends Component {

    constructor(props) {
        super(props);
        this.controller = new Controller(this, {});
    };

    componentDidMount() {
        this.controller.loadData(API.API_FEED + '/' + this.props.data.id, 'feed', null);
    }

    componentWillUnmount() {
        if (this.controller) {
            this.controller.destroy();
        }
    }

    getMediaItem(item) {
        const image = item?.image;
        if (!image) {
            return null;
        }
        if (image.indexOf('.mp4') !== -1 || image.indexOf('.mov') !== -1) {
            return <div
                key={image}
                className="fit-window-item p-4">
                <ReactPlayer
                    width="100%"
                    height="100%"
                    url={System.resolveImagePath(image, 'feed')}
                    controls={true}
                />
            </div>;
        }
        return <div key={image} className="fit-window-item p-4">
            <img src={System.resolveImagePath(image, 'feed')} className="fit-window-image" />
        </div>
    }

    render() {
        const { selectedIndex, data, closeHandler } = this.props;
        const { feed } = this.state;
        const { images } = data;
        // console.log(feed);
        return (
            <div className="fill-window d-flex">
                <div className="fit-window flex-1">
                    <Carousel
                        showStatus={false}
                        showIndicators={false}
                        showArrows={true}
                        infiniteLoop={true}
                        selectedItem={selectedIndex}
                        showThumbs={true}
                    >
                        {images.map((item) => this.getMediaItem(item))}
                    </Carousel>
                </div>
                <div
                    className="btn-icon pointer"
                    onClick={closeHandler} >
                    <img src={Icon.get('close', 'white')} alt={L('ปิด')} />
                </div>
            </div>
        );
    }
}

export default FeedView;
