import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';

import Controller from '../models/Controller';
import Form from '../components/Form';
import Button from '../components/Button';

import { validateNumber } from '../utils/UValidate';

import AppBar from '../components/AppBar';
import System from '../models/System';
import InputBox from '../components/InputBox';
import ListItemCheck from '../components/ListItemCheck';
import FormTitle from '../components/FormTitle';
import API from '../models/API';

class LogisticsPage extends Component {

    constructor(props) {
        super(props);
        // console.log(props);

        if (!System.isFarmer()) {
            this.replace = true;
            this.props.history.replace(System.resolvePath('home'));
            return;
        }

        if (!this.props?.location?.formData) {
            this.replace = true;
            this.props.history.replace(System.resolvePath('user/product'));
            return;
        }

        this.state = {
            valid: {},
            selecteds: {},
            logistics: {
                response: ['dummy', 'dummy', 'dummy', 'dummy', 'dummy']
            },
        };

        const logisticsData = this.props.location.formData.logistics;
        // console.log(logisticsData);
        this.controller = new Controller(this, {
            logistics_companies: {},
            ...logisticsData
        });

    };

    componentDidMount() {
        if(this.replace) {
            return;
        }
        this.loadLogisticsData();
    }

    loadLogisticsData() {
        this.controller.loadData(API.API_LOGISTICS_COMPANY, 'logistics', null, (data) => {
        });
    }

    componentWillUnmount() {
        if(this.replace) {
            return;
        }
        this.controller.destroy();
    }

    checkChangeHandler(event) {
        const id = event.target.id;
        // console.log(id);
        const selecteds = this.controller.get('logistics_companies');
        if (selecteds[id]) {
            delete selecteds[id];
        } else {
            selecteds[id] = true;
        }
        this.controller.set('logistics_companies', selecteds, true);
    }

    async submit() {
        this.setState({ valid: {} });

        const c = this.controller;
        const params = { ...c.all() };

        const weight = params['weight'] ?? '';
        const width = params['width'] ?? '';
        const height = params['height'] ?? '';
        const length = params['length'] ?? '';
        const logistics_companies = params['logistics_companies'] ?? {};

        const valid = {
            weight: validateNumber(weight),
            width: validateNumber(width),
            height: validateNumber(height),
            length: validateNumber(length),
            logistics_companies: false,
        };

        for (let k in logistics_companies) {
            valid.logistics_companies = true;
            break;
        }

        for (let k in valid) {
            if (valid[k] === false) {
                this.setState({ valid });
                return;
            } else {
                if (Array.isArray(valid[k])) {
                    for (let i in valid[k]) {
                        if (valid[k][i] === false) {
                            this.setState({ valid });
                            return;
                        }
                    }
                }
            }
        }

        if (this.props?.location?.formData) {
            this.props.location.formData['logistics'] = params;
            this.props.history.replace({
                pathname: System.resolvePath(this.props.location.parantPath),
                formData: this.props.location.formData,
            });
        } else {
            this.props.history.push('user/profile');
        }

    }

    render() {

        if(this.replace) {
            return null;
        }

        // console.log('LogisticsPage render');
        const c = this.controller;
        const data = c.all();
        const { logistics_companies } = data;
        // let {  } = data;
        const { post, logistics, valid } = this.state;
        // console.log(logistics);
        // console.log(data);
        return (
            <div className="section-content no-nav">
                <Form
                    loading={post.loading}
                    className="form-register">
                    <AppBar
                        title={L('ค่าจัดส่ง')}
                        subTitle={L('กำหนดค่าจัดส่งตาม ขนาด/น้ำหนัก')}
                        showLeftArrow={true}
                        onBackPress={() => {
                            this.props.history.replace({
                                pathname: System.resolvePath(this.props.location?.parantPath),
                                formData: this.props.location?.formData
                            });
                        }}
                    />
                    <FormTitle label={L('รายละเอียดพัสดุ')} />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <InputBox
                                label={L('น้ำหนักสินค้า (กิโลกรัม)*')}
                                placeholder={L('น้ำหนักสินค้าหน่วยกิโลกรัม')}
                                name="weight"
                                type="number"
                                controller={this.controller}
                                isError={valid.weight === false}
                                errorMessage={L('กรุณากรอกน้ำหนักสินค้า')} />
                        </div>
                        <div className="col-4">
                            <InputBox
                                label={L('กว้าง (ซม.)*')}
                                placeholder={L('ความกว้าง')}
                                name="width"
                                type="number"
                                controller={this.controller}
                                isError={valid.width === false}
                                errorMessage={L('กรุณากรอกความกว้าง')} />
                        </div>
                        <div className="col-4">
                            <InputBox
                                label={L('ยาว (ซม.)*')}
                                placeholder={L('ความยาว')}
                                name="length"
                                type="number"
                                controller={this.controller}
                                isError={valid.length === false}
                                errorMessage={L('กรุณากรอกความยาว')} />
                        </div>
                        <div className="col-4">
                            <InputBox
                                label={L('สูง (ซม.)*')}
                                placeholder={L('ความสูง')}
                                name="height"
                                type="number"
                                controller={this.controller}
                                isError={valid.length === false}
                                errorMessage={L('กรุณากรอกความสูง')} />
                        </div>
                    </div>

                    <FormTitle
                        label={L('เลือกผู้ให้บริการ การขนส่ง*')}
                        isError={valid.logistics_companies === false}
                        errorMessage={L('กรุณาเลือกผู้ให้บริการ')}
                    />
                    <div className="form-row form-section-register">
                        {logistics?.response && logistics.response.map((item, index) =>
                            <ListItemCheck
                                key={"ch_" + index}
                                id={"ch_" + item.id}
                                data={item}
                                checked={logistics_companies[item.id] ?? false}
                                onChange={this.checkChangeHandler.bind(this)} />)}
                    </div>

                    <FormTitle label={L('ค่าจัดส่ง')} />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <InputBox
                                label={L('ค่าจัดส่ง (บาท)')}
                                placeholder={L('ตั้งค่าจัดส่ง ถ้าส่งฟรีไม่ต้องกำหนด')}
                                name="price"
                                type="number"
                                controller={this.controller} />
                        </div>
                    </div>

                    <div className="form-row mt-4">
                        <div className="col-12">
                            <Button
                                loading={post.loading}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.submit()}>
                                {L('กำหนดค่าจัดส่ง')}
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export default withRouter(LogisticsPage);
