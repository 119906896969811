import React, { Component } from 'react';
import { get as L } from '../models/Label';
import System from '../models/System';
import { getBone } from '../utils/URender';

class ImageViewer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: 0
        };

    }

    itemClickHandler(selectedIndex) {
        this.setState({ selectedIndex });
    }

    getCurrentImage() {

    }

    getGallery(selectedIndex, images, path) {
        return <div className="viewer-images mt-2">
            {images.map((v, i) =>
                <div
                    key={'th_' + i}
                    className={selectedIndex === i ? "viewer-item-box-active" : "viewer-item-box"}
                    onMouseOver={() => this.itemClickHandler(i)}>
                    <img
                        className="viewer-item"
                        src={System.resolveThumnailPath(images[i]?.image, path)}
                        alt={L('รูปที่') + ' ' + (i + 1)}
                    />
                </div>)
            }
        </div>;
    }

    render() {
        const { selectedIndex } = this.state;
        const { images, showGallery, loading, path } = this.props;
        let _images = images ?? [];
        let width = '450';
        // console.log(path, _images[selectedIndex]?.image);
        return (
            <>
                <div className="viewer-image-container">
                    {loading
                        ? getBone(null, width, 'viewer-images')
                        : <img
                            className="viewer-image"
                            src={System.resolveImagePath(_images[selectedIndex]?.image, path)}
                            alt={L('รูปที่') + ' ' + (selectedIndex + 1)}
                            onClick={() => {
                                if (this.props.onClick) {
                                    this.props.onClick(selectedIndex);
                                }
                            }}
                        />}
                </div>
                {showGallery && (loading ? getBone(null, '70', null, 'mt-2') : this.getGallery(selectedIndex, _images, path))}
            </>
        );
    }

}

export default ImageViewer;