import React, { Component } from 'react';
import { get as L } from '../models/Label';

import nextIcon from '../assets/images/next-icon.svg';
import prevIcon from '../assets/images/prev-icon.svg';

class Pagination extends Component {

    static PAGE_SIZE_CHANGE = '1';
    static PAGE_CHANGE = '2';

    constructor(props) {
        super(props);
        // this.state = {
        //     dataLength: 100,
        //     currentPage: 10,
        //     pageSize: 30
        // };
        // console.log( this.props.data);
    };

    changePage(p) {
        if (this.props.data.currentPage === p) {
            return;
        }
        const currentPage = p;
        this.setState({ currentPage });
        if (this.props.onChange) {
            this.props.onChange(Pagination.PAGE_CHANGE, currentPage);
        }
    }

    next() {
        let { dataLength, currentPage } = this.props.data;
        currentPage = Math.min(parseInt(currentPage) + 1, dataLength);
        this.setState({ dataLength, currentPage });
        if (this.props.onChange) {
            this.props.onChange(Pagination.PAGE_CHANGE, currentPage);
        }
    }

    prev() {
        let { currentPage } = this.props.data;
        currentPage = Math.max(parseInt(currentPage) - 1, 1);
        this.setState({ currentPage });
        if (this.props.onChange) {
            this.props.onChange(Pagination.PAGE_CHANGE, currentPage);
        }
    }

    render() {
        // const { dataLength } = this.props;
        // console.log(window.innerWidth);
        // console.log(this.props.data);
        let { dataLength, currentPage, pageSize } = this.props.data;
        const allPages = Math.max(1, Math.ceil(dataLength / pageSize));

        let prevClass = 'page-item';
        let nextClass = 'page-item';
        if (currentPage >= allPages) {
            currentPage = allPages;
            nextClass += ' disabled';
        }
        if (currentPage <= 1) {
            currentPage = 1;
            prevClass += ' disabled';
        }

        const maxPage = Math.max(1, Math.min(window.innerWidth < 480 ? 3 : 5, allPages));
        const mid = Math.floor(maxPage / 2);
        const startPage = Math.max(1, Math.min(allPages, currentPage - maxPage + 1));
        const pages = [...Array(maxPage)];

        let p;

        return <div className="d-flex noselect">
            {/* <select
                className="form-control btn-sm mr-2"
                style={{ width: '96px' }}
                value={pageSize}
                onChange={(event) => {
                    if (this.props.onChange) {
                        this.props.onChange(Pagination.PAGE_SIZE_CHANGE, parseInt(event.target.value));
                    }
                    this.setState({ pageSize: event.target.value });
                }}
            >
                <option value='15'>{L('15 หน้า')}</option>
                <option value='30'>{L('30 หน้า')}</option>
                <option value='50'>{L('50 หน้า')}</option>
                <option value='100'>{L('100 หน้า')}</option>
            </select> */}
            <nav aria-label="...">
                <ul className="pagination pagination-sm">
                    <li className={prevClass}>
                        <span className="page-link" tabIndex="-1"
                            onClick={() => {
                                this.prev();
                            }}><img src={prevIcon} alt={L('ก่อนหน้า')} />
                        </span>
                    </li>
                    {pages.map((e, i) => {
                        p = startPage + i;
                        return <li key={this.props.id + '_' + i} className={p === currentPage ? 'page-item active' : 'page-item'}>
                            <span className="page-link" onClick={() => this.changePage(startPage + i)}>
                                {p}{p === currentPage && <span className="sr-only">(current)</span>}
                            </span>
                        </li>
                    })}
                    {allPages > 5 && <li className="page-item disabled"><a className="page-link" href="#">...</a></li>}
                    <li className={nextClass}>
                        <span className="page-link"
                            onClick={() => {
                                this.next();
                            }}><img src={nextIcon} alt={L('ถัดไป')} />
                        </span>
                    </li>
                </ul>
            </nav>
        </div>
    }
}

export default Pagination;
