import React, { PureComponent } from 'react';
import Avatar from './Avatar';

import { Link } from 'react-router-dom';
import { getBone } from '../utils/URender';
import System from '../models/System';
import moment from 'moment';
import Chat from '../models/Chat';

// const dateOption = { type: 'short', noTime: true,noDay };

class ChatContactItem extends PureComponent {

    constructor(props) {
        super(props);
    };

    getDummy() {
        return <div className="chat-item menu-item d-flex align-items-center inactive">
            <Avatar />
            <div className={`flex-1 mx-3 ellipsis`}>
                <div className={`color-black-1 font-size-0 ellipsis`}>
                    {getBone(150, 18, 4)}
                </div>
                <div className="sublabel">{getBone(280, 15, 3)}</div>
            </div>
        </div>;
    }

    render() {
        // console.log(this.props);
        // console.log(this.props.label);
        // console.log(this.props.badges);
        const { data } = this.props;

        if (data.type === 'dummy') {
            return this.getDummy();
        }
        // console.log(this.props);

        // console.log(data);
        const { members } = data;
        const member = members[0];
        const user = member.user;
        const shop = user.shop;
        const avatar = shop ? System.resolveImagePath(shop.logo, 'shop') : System.resolveImagePath(user.profile_image, 'profile');
        const name = shop ? shop.name : user.first_name + ' ' + user.last_name;
        const message = data.message;
        const badges = Math.min(999, parseInt(data.unread_count));
        return <Link
            to={System.resolvePath('chat/user/' + user.id)}
            onClick={() => {
                if (parseInt(data.unread_count) !== 0) {
                    Chat.setUnreadCount(Chat.unreadCount - data.unread_count);
                    data.unread_count = 0;
                    this.setState({});
                    if(this.props.onClick){
                        this.props.onClick(data);
                    }
                }
            }}>
            <div
                className="chat-item menu-item d-flex align-items-center">
                <Avatar
                    src={avatar}
                    iconFill />
                <div className={`flex-1 mx-3 ellipsis`}>
                    <div
                        className={`color-black-1 font-size-0 ellipsis`}>
                        {name}
                    </div>
                    {message
                        ? <div className="sublabel ellipsis">{message}</div>
                        : <div className="sublabel">&nbsp;</div>}
                </div>
                <div className="feed-box-date">{moment(data.updated_at).fromNow()}</div>
                {badges >= 1 && <span className="badge">{badges}</span>}
            </div>
        </Link>;
    }
}

export default ChatContactItem;
