import React, { PureComponent } from 'react';

class ImageBackground extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        const style = {
            ...this.props.style,
            width: this.props.width,
            height: this.props.height,
            borderRadius: this.props.borderRadius ?? 0,
            backgroundImage: 'url("' + this.props.src + '")',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        };
        return (
            <div
                className={this.props.className}
                style={style}
                onClick={this.props.onClick ? this.props.onClick.bind(this) : null}>
            </div>
        );
    }
}

export default ImageBackground;
