import React, { PureComponent } from 'react';

class Form extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className={this.props.className} disabled={this.props.loading}>
                {this.props.children}
            </div>
        );
    }
}

export default Form;
