import React, { Component } from 'react';
import HeaderContentSection from './HeaderContentSection';
import HeaderLeftSection from './HeaderLeftSection';
import HeaderRightSection from './HeaderRightSection';

class Header extends Component {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className="header">
                <HeaderLeftSection></HeaderLeftSection>
                <HeaderContentSection path={this.props.path}></HeaderContentSection>
                <HeaderRightSection></HeaderRightSection>
            </div>
        );
    }
}

export default Header;
