import API from "./API";
import Event from "./Event";
import Request from "./Request";

export default class Inbox {

    static unreadCount = 0;

    static loadUnreadCount() {
        Request.get(API.API_INBOX + '/unread-count', null, (data) => {
            this.unreadCount = parseInt(data);
            Event.dispatch(Event.INBOX_CHANGE);
        });
    }

    static setUnreadCount(count) {
        this.unreadCount = count;
        Event.dispatch(Event.INBOX_CHANGE);
    }

    static read(item) {
        if (parseInt(item.status) === 0) {
            Request.pos(API.API_INBOX + '/' + item.id + '/read', null, () => {
                item.status = 1;
                this.loadUnreadCount();
            });
        }
    }

    static update() {
        this.loadUnreadCount();
    }
}