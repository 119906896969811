import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import StarRatings from 'react-star-ratings';

import System from '../models/System';
import MenuItem from './MenuItem';
import { get as L } from '../models/Label';
import Label from './Label';
import RangeInput from './RangeInput';
import SelectionInput from './SelectionInput';
import Controller from '../models/Controller';
import API from '../models/API';

import loginIcon from '../assets/images/login-icon.svg';
import Event from '../models/Event';
import Icon from '../models/Icon';
import Button from './Button';
import { isEmpty } from '../utils/UObj';

class MarketLeftSection extends Component {

    constructor(props) {
        super(props);

        const isAdmin = System.isAdmin();
        if (!isAdmin) {
            this.menus = System.userMenus;
        } else {
            this.menus = [];
        }

        this.controller = new Controller(this, {
            ...this.props.data
        });

        // console.log(this.props.data);
    };

    cartChangeHandler(value) {
        const isAdmin = System.isAdmin();
        if (isAdmin) {
            return;
        }
        this.menus = System.userMenus;
        this.controller.commit();
    }

    componentDidMount() {
        this.cartEventId = Event.add(Event.CART_CHANGE, this.cartChangeHandler.bind(this));
        this.loadProductcategory();
    }

    componentWillUnmount() {
        Event.remove(this.cartEventId);
        this.controller.destroy();
    }

    loadProductcategory() {
        this.controller.loadData(API.API_PRODUCT_CATEGORY, 'productCategory', { filter: 0 }, (data) => {
            data.unshift({ id: -1, name: 'ทั้งหมด' });
            const selecteds = this.controller.get('product_categories') ?? [];
            if (!selecteds.length) {
                for (let k in data) {
                    if (selecteds.indexOf(data[k].id.toString()) === -1) {
                        selecteds.push(data[k].id);
                    }
                }
            } else {
                for (let k in selecteds) {
                    selecteds[k] = parseInt(selecteds[k]);
                }
            }
            this.controller.set('product_categories', selecteds);
        });
    }

    userClick() {
        if (System.isLogged()) {
            this.props.history.push(System.resolvePath('user/profile'));
        } else {
            this.props.history.push(System.resolvePath('login'));
        }
    }

    sortChange(e) {
        this.controller.set('sort', e.target.value);
        this.dispatch();
    }

    ratingChange(e) {
        // console.log(e.target.value);
        this.controller.set('rating', e.target.value);
        this.dispatch();
    }

    dispatch() {
        // console.log('dispatch');
        if (this.props.onChange) {
            this.props.onChange(this.controller.all());
        }
    }

    render() {
        // console.log('MarketLeftSection render');
        const logged = System.isLogged();

        const controller = this.controller;
        const data = controller.all();
        let { product_categories, search_string, min_price, max_price, rating, sort } = data;
        const { productCategory } = this.state;
        const minRange = 0;
        const maxRange = 10000;

        // console.log('rating', rating);
        // console.log(product_categories);

        return (
            <Fragment key="m_l_s_c_f">
                {this.props.hideProfile !== true && <>
                    <div className="px-3 pb-3">
                        <MenuItem
                            iconFill={logged}
                            src={logged ? System.profile.profile_image : loginIcon}
                            label={logged ? System.profile.display_name : L('เข้าสู่ระบบ')}
                            onClick={() => this.userClick()} />
                        {this.menus.map((value) => {
                            if (!logged && value.login) {
                                return null;
                            }
                            return <MenuItem
                                key={value.label}
                                src={Icon.get(value.icon)}
                                label={value.label}
                                path={value.path}
                                badges={value.badges}
                            />
                        })}
                    </div>
                    <div className="line-h"></div>
                </>}
                <div className={this.props.hideProfile ? 'px-3 pb-3' : 'p-3'}>
                    {this.props.hideSearch !== true && <input
                        type="text"
                        className="form-control input-search mb-3"
                        style={{ backgroundColor: '#FAFAFA' }}
                        placeholder={L('ค้นหาสินค้า,ร้านค้า')}
                        defaultValue={search_string}
                        onChange={(e) => {
                            controller.set('search_string', e.target.value);
                            this.dispatch();
                        }} />}
                    <Label>{L('หมวดหมู่สินค้า')}</Label>
                    <SelectionInput
                        className="filter mt-2 mb-3"
                        data={productCategory?.response ?? []}
                        selecteds={product_categories}
                        clickHandler={(item) => {
                            let index = product_categories.indexOf(parseInt(item.id));
                            if (index === -1) { //going to active
                                const data = productCategory?.response;
                                if (item.id === -1) {
                                    if (product_categories.indexOf(-1) !== -1) {
                                        return; //all already active
                                    }
                                    const selecteds = [];
                                    for (let k in data) {
                                        selecteds.push(data[k].id);
                                    }
                                    product_categories = selecteds;
                                } else {
                                    product_categories.push(parseInt(item.id));
                                    if (product_categories.length === data.length - 1) {
                                        product_categories.push(-1);
                                    }
                                }
                            } else { //going to unactive
                                if (item.id !== -1) {
                                    product_categories.splice(index, 1);
                                    index = product_categories.indexOf(-1);
                                    if (index !== -1) {
                                        product_categories.splice(index, 1);
                                    }
                                } else {
                                    return; //all already active
                                }
                            }
                            controller.set('product_categories', product_categories, true);
                            this.dispatch();
                        }} />
                </div>
                <div className="line-h"></div>
                <div className="p-3">
                    <Label>{L('การเรียงลำดับ')}</Label>
                    <div className="sort-group mt-2">
                        <div className="custom-control custom-radio mb-2">
                            <input type="radio" id="sort1" name="sortRadio" value="sd" defaultChecked={sort === "sd"} className="custom-control-input" onChange={this.sortChange.bind(this)} />
                            <label className="custom-control-label" htmlFor="sort1">{L('สินค้าขายดี')}</label>
                        </div>
                        <div className="custom-control custom-radio mb-2">
                            <input type="radio" id="sort2" name="sortRadio" value="rd" defaultChecked={sort === "rd"} className="custom-control-input" onChange={this.sortChange.bind(this)} />
                            <label className="custom-control-label" htmlFor="sort2">{L('คะแนนนิยม')}</label>
                        </div>
                        <div className="custom-control custom-radio mb-2">
                            <input type="radio" id="sort3" name="sortRadio" value="pd" defaultChecked={sort === "pd"} className="custom-control-input" onChange={this.sortChange.bind(this)} />
                            <label className="custom-control-label" htmlFor="sort3">{L('ราคาจากมากไปน้อย')}</label>
                        </div>
                        <div className="custom-control custom-radio mb-2">
                            <input type="radio" id="sort4" name="sortRadio" value="pa" defaultChecked={sort === "pa"} className="custom-control-input" onChange={this.sortChange.bind(this)} />
                            <label className="custom-control-label" htmlFor="sort4">{L('ราคาจากน้อยไปมาก')}</label>
                        </div>
                    </div>
                </div>
                <div className="line-h"></div>
                <div className="p-3">
                    <Label>{L('ช่วงราคา')}</Label>
                    <div className="py-3">
                        <RangeInput
                            min={minRange}
                            max={maxRange}
                            step={(maxRange - minRange) / 100}
                            values={[(min_price ?? minRange), (max_price ?? maxRange)]}
                            onChange={(values) => {
                                controller.set('min_price', values[0]);
                                controller.set('max_price', values[1]);
                                this.dispatch();
                            }} />
                    </div>
                </div>
                <div className="line-h"></div>
                <div className="p-3">
                    <Label>{L('ค่าคะแนน')}</Label>
                    <div className="sort-group mt-2">
                        <div className="custom-control custom-radio mb-2">
                            <input type="radio" id="rating1" name="ratingRadio" value="5" defaultChecked={parseInt(rating) === 5} className="custom-control-input" onChange={this.ratingChange.bind(this)} />
                            <label className="custom-control-label" htmlFor="rating1">
                                <StarRatings starDimension="20px" starSpacing="4px" rating={5} starRatedColor="#ffce3d" numberOfStars={5} />
                            </label>
                        </div>
                        <div className="custom-control custom-radio mb-2">
                            <input type="radio" id="rating2" name="ratingRadio" value="4" defaultChecked={parseInt(rating) === 4} className="custom-control-input" onChange={this.ratingChange.bind(this)} />
                            <label className="custom-control-label" htmlFor="rating2">
                                <StarRatings starDimension="20px" starSpacing="4px" rating={4} starRatedColor="#ffce3d" numberOfStars={5} /> {L('ขึ้นไป')}
                            </label>
                        </div>
                        <div className="custom-control custom-radio mb-2">
                            <input type="radio" id="rating3" name="ratingRadio" value="3" defaultChecked={parseInt(rating) === 3} className="custom-control-input" onChange={this.ratingChange.bind(this)} />
                            <label className="custom-control-label" htmlFor="rating3">
                                <StarRatings starDimension="20px" starSpacing="4px" rating={3} starRatedColor="#ffce3d" numberOfStars={5} /> {L('ขึ้นไป')}
                            </label>
                        </div>
                        <div className="custom-control custom-radio mb-2">
                            <input type="radio" id="rating4" name="ratingRadio" value="2" defaultChecked={parseInt(rating) === 2} className="custom-control-input" onChange={this.ratingChange.bind(this)} />
                            <label className="custom-control-label" htmlFor="rating4">
                                <StarRatings starDimension="20px" starSpacing="4px" rating={2} starRatedColor="#ffce3d" numberOfStars={5} /> {L('ขึ้นไป')}
                            </label>
                        </div>
                        <div className="custom-control custom-radio mb-2">
                            <input type="radio" id="rating5" name="ratingRadio" value="1" defaultChecked={parseInt(rating) === 1} className="custom-control-input" onChange={this.ratingChange.bind(this)} />
                            <label className="custom-control-label" htmlFor="rating5">
                                <StarRatings starDimension="20px" starSpacing="4px" rating={1} starRatedColor="#ffce3d" numberOfStars={5} /> {L('ขึ้นไป')}
                            </label>
                        </div>
                    </div>
                </div>
                {this.props.showButton === true && <>
                    <div className="line-h"></div>
                    <div className="p-3">
                        <Button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                //creaet query string
                                const data = { ...this.controller.all() };
                                for (let k in data) {
                                    if (isEmpty(data[k])) {
                                        delete data[k];
                                    }
                                }
                                // console.log(data);
                                this.props.history.push({
                                    pathname: System.resolvePath('market'),
                                    search: "?" + new URLSearchParams(data).toString()
                                });
                            }}>
                            {L('ค้นหา')}
                        </Button>
                    </div>
                </>}
            </Fragment>
        );
    }
}

export default withRouter(MarketLeftSection);
