import axios from 'axios';
import System from './System';
import Error from './Error';
import API from './API';
import { isEmpty } from '../utils/UObj';
import { get as L } from './Label';

export default class Request {

    static getQueryString(params) {
        var queryString = Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        return '?' + queryString;
    }

    static unauthorization() {
        //try auto login
        API.logout();
    }

    static errorResponse(error, errorCallback) {
        if (!isEmpty(error.response)) {
            if (error.response.status === 403) {
                Request.unauthorization();
                return;
            }
            try {
                let msg = error.response.data.message ?? '';
                if (!msg) {
                    const errorData = error.response.data;
                    for (let k in errorData) {
                        msg += errorData[k] + ',';
                    }
                }
                Error.error(msg);
                if (errorCallback) {
                    errorCallback(msg);
                }
            } catch (error) {
                if (errorCallback) {
                    errorCallback(error.toString());
                }
            }
        } else {
            Error.error(error.toString());
            if (errorCallback) {
                errorCallback(error.toString());
            }
        }
    }

    static get(api, params, callback, errorCallback) {
        if (params) {
            api += this.getQueryString(params);
        }
        const headers = {
            'Authorization': System.getToken()
        };
        // console.log(headers);
        axios['get'](System.API_URL + api, { headers })
            .then(function (response) {
                // console.log(response.data);
                if (response && response.data && response.data.status === 'success') {
                    // console.log(response.data);
                    callback(response.data.data);
                } else {
                    Error.error(response.data.message);
                    if (errorCallback) {
                        errorCallback(response.data);
                    }
                }
            })
            .catch(function (error) {
                Request.errorResponse(error, errorCallback);
            });
    }

    static _post(method, api, params, callback, errorCallback) {
        const headers = {
            'Authorization': System.getToken()
        };
        axios[method](System.API_URL + api, params, { headers })
            .then(function (response) {
                if (response && response.data && response.data.status === 'success') {
                    callback(response.data.data);
                } else {
                    Error.error(response.data.message);
                    if (errorCallback) {
                        errorCallback(response.data);
                    }
                }
            })
            .catch(function (error) {
                Request.errorResponse(error, errorCallback);
            });
    }

    static multiPart(api, formData, callback, errorCallback, method = 'post') {
        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': System.getToken()
        };
        // console.log(System.API_URL + api, method);
        //console.log('Data ',formData);
        axios[method](System.API_URL + api, formData, { headers })
            .then(function (response) {
                if (response.data && response.data.status === 'success') {
                    // console.log(response);
                    callback(response.data.data);
                } else {
                    Error.error(response.data.message);
                    errorCallback(response.data.message);
                }
            })
            .catch(function (error) {
                // console.log(response);
                // if ('response' in error) {
                //     console.log(error.response?.data);
                // } else {
                //     console.log(error);
                // }
                if (error.response && error.response.data.status === "fail") {
                    Error.error(error.response.data);
                    errorCallback(error.response.data.message);
                } else {
                    Error.error(error);
                    errorCallback(error);
                }
            });
    }

    static pos(api, params, callback, errorCallback) {
        this._post('post', api, params, callback, errorCallback);
    }

    static put(api, params, callback, errorCallback) {
        this._post('put', api, params, callback, errorCallback);
    }

    static del(api, callback, errorCallback) {
        const headers = {
            'Authorization': System.getToken()
        };
        axios.delete(System.API_URL + api, { headers })
            .then(function (response) {
                if (response && response.data && response.data.status === 'success') {
                    callback(response.data.data);
                } else {
                    Error.error(response.data.message);
                    if (errorCallback) {
                        errorCallback(response.data);
                    }
                }
            })
            .catch(function (error) {
                Request.errorResponse(error, errorCallback);
            });
    }

    static download(api, params, fileName, callback, errorCallback) {
        axios({
            method: 'POST',
            url: System.API_URL + api,
            responseType: 'blob', // important
            headers: { 'Authorization': System.getToken() },
            data: params,
        })
            .then(response => {
                if (response && response.data && response.data.status === 'fail') {
                    if (errorCallback) {
                        errorCallback(L('ไม่สามารถดาวน์โหลดไฟล์ได้'));
                    }
                    return;
                }
                const url = window.URL.createObjectURL(new Blob([response.data]));
                // console.log(response);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();

                if (callback) {
                    callback();
                }

            })
            .catch(function (error) {
                Request.errorResponse(error, errorCallback);
            });
    }

    static uploadImage(api, params, files, callback, errorCallback, method = 'POST') {
        var formData = new FormData();

        formData.append('image', files[0]);

        if (method === 'PUT') {
            formData.append('_method', 'PUT');
        }
        for (let key in params) {
            if (Array.isArray(params[key])) {
                for (let k in params[key]) {
                    formData.append(key + '[]', params[key][k]);
                }
            } else {
                formData.append(key, params[key]);
            }
        }
        axios({
            method: 'POST',
            url: System.API_URL + api,
            headers: { 'Authorization': System.getToken(), 'Content-Type': 'multipart/form-data' },
            data: formData,
        })
            .then(response => {
                if (response && response.data && response.data.status === 'fail') {
                    if (errorCallback) {
                        errorCallback(response.data.message);
                    }
                    return;
                }
                if (callback) {
                    callback(response.data);
                }
            })
            .catch(function (error) {
                Request.errorResponse(error, errorCallback);
            });
    }

}
