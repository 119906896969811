import React, { PureComponent } from 'react';
import { getBone } from '../utils/URender';

class ListTile extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        // console.log(this.props);
        const { data, icon } = this.props;
        if (data === 'dummy') {
            return <div className={'list-tile py-2 ' + (this.props.className ?? '')}>
                {icon && getBone(24, 24)}
                <div className="w-100">
                    <div className="list-tile-label">{getBone(240, 20, 4)}</div>
                    <div className="list-tile-sublabel">{getBone(280, 18, 3)}</div>
                </div>
            </div>;
        }

        return (
            <div className={'list-tile py-2 ' + (this.props.className ?? '')}>
                {icon && <img src={icon} className="mr-4" alt={data.label} />}
                <div>
                    <div className="list-tile-label">{data.label}</div>
                    <div className="list-tile-sublabel">{data.sublabel}</div>
                </div>
            </div>
        );
    }
}

export default ListTile;
