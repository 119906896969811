import Cart from "./Cart";
import Request from "./Request";
import System from "./System";

export default class API {

    static API_LOGIN = 'login';
    static API_REGISTER = 'register';
    static API_PROFILE = 'profile';
    static API_RESET_PASSWORD = 'reset-password';
    static API_FORGET_PASSWORD = 'forget-password';

    static API_USER = 'users';

    static API_CHECK_OUT = 'checkouts';

    static API_USER_SHOP = 'user/shops';
    static API_USER_PRODUCT = 'user/products';
    static API_USER_ACTIVITY = 'user/activities';
    static API_USER_ORDER = 'user/orders';
    static API_USER_CART = 'user/carts';
    static API_ORDER_LOCATION = 'order-locations';
    static API_USER_FARM = 'user/farms';
    static API_USER_FARM_FIELD = 'user/farms/fields';
    static API_USER_NOTI_TOKEN = 'notification-tokens';

    static API_ORDER = 'orders';
    static API_SHOP = 'shops';
    static API_FEED = 'feeds';
    static API_FEEDBACK = 'feedback';
    static API_FEEDBACK_REPLY = 'feedback-replies';
    static API_PRODUCT = 'products';
    static API_ACTIVITY = 'activities';

    static API_PRODUCT_RECCOMMEND = 'product-recommend';
    static API_ACTIVITY_RECCOMMEND = 'activity-recommend';

    static API_NEWS = 'news';
    // static API_REVIEW_PRODUCT = 'products';
    static API_PRODUCT_CATEGORY = 'product-categories';
    static API_ACTIVITY_SERVICE = 'activity-services';
    static API_PLANT_CATEGORY = 'plant-categories';
    static API_PLANT_GROUP = 'plant-groups';
    static API_PLANT = 'plants';
    static API_UNIT = 'units';
    static API_LOGISTICS_COMPANY = 'logistics-companies';
    static API_PROVINCE = 'provinces';
    static API_INBOX = 'inboxes';
    static API_CHAT = 'chats';

    static API_ADMIN_NEWS = 'admin/news';

    static logout() {
        System.setToken('');
        Cart.clear();
    }

    static changeRoleToUser(userId) {
        Request.pos('users/' + userId + '/change-to-user');
    }

    static changeRoleToFarmer(userId) {
        Request.pos('users/' + userId + '/change-to-farmer');
    }



}