import React, { Component } from 'react';
import { get as L } from '../models/Label';
import Label from './Label';

import commentIcon from '../assets/images/chat-icon.svg';
import notiIcon from '../assets/images/bell-icon.svg';

import Recommendtem from './Recommendtem';
import MenuItem from './MenuItem';
import Controller from '../models/Controller';
import System from '../models/System';
import Event from '../models/Event';
import Inbox from '../models/Inbox';
import API from '../models/API';
import Chat from '../models/Chat';

class HomeRightSection extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.controller = new Controller(this, {});
        // console.log('HomeRightSection');
    };

    componentDidMount() {
        this.loggedEvent = Event.add(Event.LOGIN, () => {
            // console.log('MainPage loggedEvent');
            this.controller.commit();
            this.inboxEventId = Event.add(Event.INBOX_CHANGE, () => {
                if (this.controller.isDestroyed !== true) {
                    this.controller.commit();
                }
            });
        });

        this.controller.loadData(API.API_ACTIVITY_RECCOMMEND, 'activity');
        this.controller.loadData(API.API_PRODUCT_RECCOMMEND, 'product');
    }

    componentWillUnmount() {
        Event.remove(this.loggedEvent);
        Event.remove(this.inboxEventId);
        this.controller.destroy();
    }

    render() {
        const { product, activity } = this.state;
        const profile = System.profile;
        const plist = product?.response;
        const alist = activity?.response;
        const cls = this.props.visible !== true ? 'd-none' : '';
        return (
            <div className={'section-right ' + cls}>
                <div className="position-sticky py-3 scroll-inner" style={{ top: '64px', height: 'calc(100vh - 64px)' }}>

                    <div className="px-3 pb-3">
                        <Label>{L('สินค้าแนะนำ')}</Label>
                        {plist && plist.map((item) => <Recommendtem key={item.id} data={item} path={System.resolvePath('market/product/' + item.id)} />)}
                    </div>
                    <div className="line-h mb-3"></div>

                    {(alist && alist.length !== 0) && <>
                        <div className="px-3 pb-3">
                            <Label>{L('กิจกรรมแนะนำ')}</Label>
                            {alist.map((item) => <Recommendtem key={item.id} data={item} path={System.resolvePath('activity/' + item.id)} />)}
                        </div>
                        <div className="line-h mb-3"></div>
                    </>}

                    {(profile && profile.shop) && <div className="px-3 pb-3">
                        <Label>{L('ร้านค้าของคุณ')}</Label>
                        <MenuItem
                            iconFill
                            src={System.resolveImagePath(profile.shop.logo, 'shop')}
                            label={profile.shop.name} />
                        <div className="pl-3">
                            <MenuItem
                                iconStyle={menuItemStyle}
                                labelStyle={menuLableStyle}
                                iconWidth='16px'
                                iconHeight='16px'
                                path={System.resolvePath('chat')}
                                src={commentIcon}
                                iconBackgroundColor="#EBEBEB"
                                label={(Chat.unreadCount ?? 0) + ' ' + L('ข้อความสนทนา')} />
                            <MenuItem
                                iconStyle={menuItemStyle}
                                labelStyle={menuLableStyle}
                                iconWidth='16px'
                                iconHeight='16px'
                                path={System.resolvePath('user/inbox')}
                                src={notiIcon}
                                iconBackgroundColor="#EBEBEB"
                                label={(Inbox.unreadCount ?? 0) + ' ' + L('การแจ้งเตือน')} />
                        </div>
                    </div>}

                </div>
            </div>
        );
    }
}

const menuItemStyle = { width: '24px', height: '24px' };
const menuLableStyle = { fontSize: '14px', color: '#828282' }

export default HomeRightSection;
