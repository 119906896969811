import React, { PureComponent } from 'react';
import { get as L } from '../models/Label';

import addImageIcon from '../assets/images/add-photo-icon.svg';
import removeImageIcon from '../assets/images/remove-icon.svg';
import System from '../models/System';
import { isEmpty } from '../utils/UObj';

class AddImageButton extends PureComponent {

    constructor(props) {
        super(props);
    };

    getThumbnail(src, isVideo) {
        if (isVideo) {
            return <div
                className="btn-add-image-src position-relative"
                style={{
                    borderRadius: '6px',
                    width: '150px',
                    height: '150px',
                    overflow: 'hidden',
                }}>
                <video className="" src={src} width="150" height="150"></video>
                <img
                    className="btn-remove-image"
                    src={removeImageIcon}
                    alt={L('ลบ')}
                    onClick={this.props.onClick} />
            </div>;
        }
        return <div
            className="btn-add-image-src position-relative"
            style={{
                backgroundImage: `url(${src})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: '6px',
                width: '150px',
                height: '150px'
            }}>
            <img
                className="btn-remove-image"
                src={removeImageIcon}
                alt={L('ลบ')}
                onClick={this.props.onClick} />
        </div>;
    }

    getVideo() {

    }

    render() {
        let { src, isVideo } = this.props;
        //check object
        if (!isEmpty(src) && typeof src === 'object') {
            // console.log(src, this.props.path);
            src = System.resolveImagePath(src.image, this.props.path ?? '');
        }
        // console.log(this.props.path);
        return (
            <div className="btn-add-image mr-2 mb-2" type={src ?? 'add'}>
                {src
                    ? this.getThumbnail(src, isVideo)
                    : <img src={addImageIcon} alt={L('เพิ่มรูป')} />}
            </div>
        );
    }
}

export default AddImageButton;
