import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';

import InputBox from '../components/InputBox';
import InputImageBox from '../components/InputImageBox';
import FormTitle from '../components/FormTitle';
import Controller from '../models/Controller';
import Form from '../components/Form';
import Button from '../components/Button';

import AddressForm from '../components/AddressForm';

import pinIcon from '../assets/images/pin-icon.svg';
import MapSelector from '../components/MapSelector';
import { validateEmail, validateEmptyValue, validateNumber, validatePhoneNumber } from '../utils/UValidate';
import { isEmpty } from '../utils/UObj';

import { isMobile } from 'react-device-detect';
import AppBar from '../components/AppBar';
import System from '../models/System';
import TextAreaBox from '../components/TextAreaBox';
import SelectionInput from '../components/SelectionInput';
import API from '../models/API';

class UserShopFormPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            valid: {
                fields: []
            },
            showMapDialog: false
        };
        this.controller = new Controller(this, {
            images: [],
            logos: [],
            product_categories: [],
        });
        if (!System.isFarmer()) {
            this.props.history.replace(System.resolvePath('home'));
            return;
        }
    };

    componentDidMount() {
        this.loadProductcategory();
        this.loadShopData();
    }

    loadProductcategory() {
        this.controller.loadData(API.API_PRODUCT_CATEGORY, 'productCategory', { filter: 0 }, (data) => {
            // this.productCategorie2Index = {};
            // for (let k in data) {
            //     this.productCategorie2Index[data[k].id] = parseInt(k);
            // }
        });
    }

    loadShopData() {
        this.controller.loadData(API.API_USER_SHOP, 'shop', null, (data) => {
            // const categories = data.;
            const selecteds = [];
            for (let k in data?.product_categories) {
                selecteds.push(data.product_categories[k]?.pivot?.product_category_id);
            }
            // console.log(selecteds);
            data.product_categories = selecteds;
            if (data.logo) {
                data.logos = [{ id: 'logo', image: data.logo }];
            }
            this.controller.append(data);
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    async openMapDialog() {
        this.lat = null;
        this.lng = null;
        await this.setState({ showMapDialog: true });
        window.openModalDialog('mapModal', () => {
            this.setState({ showMapDialog: false });
        });
    }

    closeMapDialog() {
        // console.log(this.lat, this.lng);
        if (this.lat && this.lng) {
            this.controller.set('lat', this.lat);
            this.controller.set('lng', this.lng);
        }
        window.hideModalDialog('mapModal');
        this.setState({ showMapDialog: false });
    }

    async submit() {
        this.setState({ valid: {} });

        const c = this.controller;
        const params = { ...c.all() };

        const name = params['name'] ?? '';
        const description = params['description'] ?? '';
        const moo = params['moo'] ?? '';
        const images = params['images'] ?? [];
        const house_number = params['house_number'] ?? '';
        const location_id = params['location_id'] ?? '';
        const product_categories = params['product_categories'];
        const logo = params['logos'] ? params['logos'][0] : null;
        const phone_number = params['phone_number'] ?? '';
        const email = params['email'] ?? '';

        const valid = {
            name: validateEmptyValue(name),
            description: validateEmptyValue(description),
            // moo: validateEmptyValue(moo),
            house_number: validateEmptyValue(house_number),
            location_id: validateEmptyValue(location_id),
            product_categories: product_categories.length !== 0,
            phone_number: validatePhoneNumber(phone_number),
            email: !validateEmptyValue(email) || validateEmail(email),
            images: images.length !== 0
            // logo: validateEmptyValue(logo),
        };

        for (let k in valid) {
            if (valid[k] === false) {
                this.setState({ valid });
                return;
            }
            if (Array.isArray(valid[k])) {
                for (let i in valid[k]) {
                    if (valid[k][i] === false) {
                        this.setState({ valid });
                        return;
                    }
                }
            }
        }

        let product_category;
        for (let k in product_categories) {
            product_category = product_categories[k];
            params['product_category_id_' + k] = product_category;
        }
        delete params['product_categories'];

        // console.log(images);
        let file, blob;
        let i = 0;
        for (let k in images) {
            if (!isEmpty(images[k]) && typeof images[k] === 'object') {
                continue;
            }
            blob = await fetch(images[k]).then(r => r.blob());
            file = new File([blob], 'image_' + i + '.jpg', { type: 'image/jpg' });
            params['image_' + i] = file;
            i++;
        }
        if (params['remove_images']) {
            params['remove_images'] = params['remove_images'].toString();
        }

        //logo
        if (logo) {
            if (!isEmpty(logo) && typeof logo === 'object') {
                delete params['logo'];
            } else {
                blob = await fetch(logo).then(r => r.blob());
                file = new File([blob], 'logo.jpg', { type: 'image/jpg' });
                params['logo'] = file;
            }
        } else {
            delete params['logo'];
        }

        delete params['images'];
        delete params['logos'];
        delete params['location'];

        const formData = new FormData();
        let value;
        for (let key in params) {
            value = params[key];
            if (isEmpty(value)) {
                continue;
            }
            formData.append(key, value);
        }

        this.controller.postMultipathData(API.API_USER_SHOP, formData,
            (data) => {
                if (isMobile) {
                    this.props.history.goBack();
                    return;
                }
                this.controller.set('remove_images', null);
                const selecteds = [];
                for (let k in data?.product_categories) {
                    selecteds.push(data.product_categories[k]?.pivot?.product_category_id);
                }
                // console.log(selecteds);
                data.product_categories = selecteds;
                this.controller.append(data);
            },
            (error) => {
                window.openMessageModalDialog(error);
            });
    }

    render() {
        // console.log('UserShopFormPage render');
        const c = this.controller;
        const data = c.all();
        let { product_categories, lat, lng } = data;
        const { valid, post, showMapDialog, zipCode, productCategory } = this.state;
        if (!lat && !lng && zipCode && zipCode.response) {
            // lat = zipCode.response.lat;
            // lng = zipCode.response.lng;
        }
        // console.log(product_categories);
        return (
            <div className="section-content no-nav">
                <Form
                    loading={post.loading}
                    className="form-register">
                    <AppBar
                        title={L('ข้อมูลร้านค้า')}
                        subTitle={L('กรอกข้อมูลร้านค้าและคำอธิบาย')}
                        showLeftArrow={isMobile}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <InputImageBox
                                path="shop"
                                name="logos"
                                description={L('รูปภาพ: คุณสามารถอัพรูปโลโก้ร้านเพื่อง่ายต่อลูกค้าในการจดจำร้านของคุณได้ง่าย ขนาดของรูปที่เหมาะสมคือ 256x256 พิกเซล')}
                                max={1}
                                controller={c}
                                label={L('รูปโลโก้ร้านค้า')} />
                        </div>
                        <div className="col-12">
                            <InputImageBox
                                path="shop"
                                name="images"
                                description={L('รูปภาพ: คุณสามารถอัพรูปปกเพื่อ แนะนำร้านค้าของคุณเองได้ ขนาดของรูปที่เหมาะสมคือ 1280x480 พิกเซลและอัพได้อย่างน้อย 1 รูปแต่ไม่เกิน 5 รูป')}
                                max={5}
                                controller={c}
                                label={L('รูปปกร้านค้า*')}
                                isError={valid.image === false}
                                errorMessage={L('กรุณาเพิ่มรูปปกร้านค้า')} />
                        </div>
                        <div className="col-12">
                            <InputBox
                                label={L('ชื่อร้านค้า*')}
                                placeholder={L('ชื่อร้านค้า')}
                                name="name"
                                controller={this.controller}
                                isError={valid.name === false}
                                errorMessage={L('กรุณากรอกชื่อร้านค้า')} />
                        </div>
                        <div className="col-12">
                            <TextAreaBox
                                label={L('คำอธิบายร้านค้า*')}
                                placeholder={L('ลักษณะ, จุดเด่น, สินค้าที่ขาย')}
                                name="description"
                                controller={this.controller}
                                isError={valid.description === false}
                                errorMessage={L('กรุณากรอกคำอธิบายร้านค้า')} />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputBox
                                label={L('เบอร์โทรศัพท์*')}
                                placeholder={L('0xx-XXXXXXX')}
                                name="phone_number"
                                controller={this.controller}
                                isError={valid.phone_number === false}
                                errorMessage={L('กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง')} />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputBox
                                label={L('Email')}
                                placeholder={L('email@example.com')}
                                name="email"
                                controller={this.controller}
                                isError={valid.email === false}
                                errorMessage={L('กรุณากรอก Email ให้ถูกต้อง')} />
                        </div>
                    </div>

                    <FormTitle
                        className="mb-0"
                        label={L('หมวดหมู่สินค้า*')}
                        subLabel={L('เลือกได้มากกว่า 1 ชนิด')}
                        isError={valid.product_categories === false}
                        errorMessage={L('กรุณาเลือกหมวดหมู่สินค้า')} />
                    <div className="form-row form-section-register">
                        <div className="col-12 form-group">
                            <SelectionInput
                                data={productCategory?.response ?? []}
                                selecteds={product_categories}
                                clickHandler={(item) => {
                                    const index = product_categories.indexOf(parseInt(item.id));
                                    if (index === -1) {
                                        product_categories.push(parseInt(item.id));
                                    } else {
                                        product_categories.splice(index, 1);
                                    }
                                    c.set('product_categories', product_categories, true);
                                }} />
                        </div>
                    </div>

                    <FormTitle
                        label={L('ข้อมูลที่ตั้งร้านค้า')}
                        isError={valid.location_id === false}
                        errorMessage={L('กรุณากรอกข้อมูลที่อยู่ให้ครบ')} />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <InputBox
                                label={L('หมู่บ้าน')}
                                placeholder={L('ชื่อหมู่บ้าน')}
                                name="village_name"
                                controller={this.controller} />
                        </div>
                        <div className="col-6">
                            <InputBox
                                label={L('เลขที่*')}
                                placeholder={L('บ้านเลขที่')}
                                name="house_number"
                                controller={this.controller}
                                isError={valid.house_number === false}
                                errorMessage={L('กรุณากรอกบ้านเลขที่')} />
                        </div>
                        <div className="col-6">
                            <InputBox
                                label={L('หมู่ที่')}
                                placeholder={L('หมู่ที่')}
                                name="moo"
                                controller={this.controller}
                                isError={valid.moo === false}
                                errorMessage={L('กรุณากรอกหมู่ที่')} />
                        </div>

                        <AddressForm
                            controller={this.controller} data={data.location} />

                        <div className="col-6">
                            <InputBox
                                label={L('ละติจูด')}
                                name="lat"
                                controller={this.controller}
                                placeholder={L('xx.xxxxxxxxxx')} />
                        </div>
                        <div className="col-6 position-relative">
                            <InputBox
                                label={L('ลองติจูด')}
                                name="lng"
                                controller={this.controller}
                                placeholder={L('xx.xxxxxxxxxx')} />
                            <img
                                className="btn-pin-farm"
                                src={pinIcon}
                                alt={L('เลือกพิกัดจากแผนที่')}
                                onClick={this.openMapDialog.bind(this)} />
                        </div>
                    </div>

                    <div className="form-row mt-4">
                        <div className="col-12">
                            <Button
                                loading={post.loading}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.submit()}>
                                {L('บันทึกข้อมูล')}
                            </Button>
                        </div>
                    </div>

                </Form>

                {showMapDialog && <div
                    id="mapModal"
                    className="modal modal-full"
                    tabIndex="-1"
                    role="dialog"
                    data-keyboard="false"
                    data-backdrop="static">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('เลือกพิกัดจากแผนที่')}</h5>
                            </div>
                            <div className="modal-body modal-body-map">
                                <MapSelector
                                    lat={lat}
                                    lng={lng}
                                    onChange={(data) => {
                                        this.lat = data.lat;
                                        this.lng = data.lng;
                                    }} />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.closeMapDialog();
                                    }}>{L('ยืนยัน')}</button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        this.lat = null;
                                        this.lng = null;
                                        this.closeMapDialog();
                                    }}>{L('ยกเลิก')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}

export default withRouter(UserShopFormPage);
