import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';

import InputBox from '../components/InputBox';
import InputImageBox from '../components/InputImageBox';
import FormTitle from '../components/FormTitle';
import Controller from '../models/Controller';
import Form from '../components/Form';
import Button from '../components/Button';

import { validateEmptyValue, validateNumber } from '../utils/UValidate';
import { isEmpty } from '../utils/UObj';

import { isMobile } from 'react-device-detect';
import AppBar from '../components/AppBar';
import System from '../models/System';
import TextAreaBox from '../components/TextAreaBox';
import SelectionInput from '../components/SelectionInput';
import InputDropListBox from '../components/InputDropListBox';
import rightIcon from '../assets/images/arrow-right-icon.svg';
import API from '../models/API';
import InputSwitch from '../components/InputSwitch';

class UserProductFormPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            valid: {},
        };

        // console.log(this.props);

        const formData = this.props.location?.formData;
        // console.log(formData);
        this.controller = new Controller(this, {
            images: [],
            status: 1,
            ...formData
        });
        if (!System.isFarmer()) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('home'));
            return;
        }

        this.qualities = [
            { id: 1, name: 'สินค้าใหม่' },
            { id: 2, name: 'สินค้ามือสอง' },
        ];

        //check id
        const _path = System.matchPath(this.props.location.pathname, 'user/product/:productId');
        if (_path) {
            this.id = validateNumber(_path.params.productId);
            if (this.id) {
                this.isEdit = true;
            }
        }
    };

    componentDidMount() {
        this.loadProductcategory();
        this.loadUnit();
        this.loadProductData();
    }

    loadProductcategory() {
        this.controller.loadData(API.API_PRODUCT_CATEGORY, 'productCategory', { filter: 0 }, (data) => {
        });
    }

    loadUnit() {
        this.controller.loadData(API.API_UNIT + '?filter=0', 'unit', null, (data) => {
        });
    }

    loadProductData() {
        if (!this.isEdit) {
            return;
        }

        //check loaded
        if (this.controller.get('name')) {
            return;
        }

        this.controller.loadData(API.API_USER_PRODUCT + '/' + this.id, 'shop', null, (data) => {
            if (!data) {
                this.isError = true;
                this.props.history.replace(System.resolvePath('user/product'));
                return;
            }
            const newData = {
                images: data['images'],
                name: data['name'],
                description: data['description'],
                detail: data['detail'],
                product_category_id: parseInt(data['product_category_id']),
                price: data['price'],
                unit_id: data['unit_id'],
                quality: parseInt(data['quality']),
                quantity: data['quantity'],
                status: data['status'],
            };
            if (data['logistics_height']) {
                const logistics = {
                    price: data['freight'],
                    height: data['logistics_height'],
                    length: data['logistics_length'],
                    weight: data['logistics_weight'],
                    width: data['logistics_width'],
                    logistics_companies: {},
                };
                for (let k in data['logistics_companies']) {
                    logistics['logistics_companies'][data['logistics_companies'][k].id] = 1;
                }
                newData['logistics'] = logistics;
                // console.log(logistics);
            }
            this.controller.append(newData);
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    async delete() {
        await this.setState({ openDeleteDialog: true });
        window.openModalDialog('deleteModal', () => {
            this.setState({ openDeleteDialog: false });
        });
    }

    confirmDelete() {
        this.controller.deleteData(API.API_USER_PRODUCT + '/' + this.id,
            (data) => {
                window.hideModalDialog('deleteModal');
                this.setState({ openDeleteDialog: false });
                this.props.history.goBack();
            },
            (error) => {
                window.openMessageModalDialog(error);
            });
    }

    async submit() {
        this.setState({ valid: {} });

        const c = this.controller;
        const params = { ...c.all() };

        const name = params['name'] ?? '';
        const description = params['description'] ?? '';
        const images = params['images'] ?? [];
        const product_category = params['product_category_id'];
        const price = params['price'];
        const logistics = params['logistics'];
        const quantity = params['quantity'];
        const unit = params['unit_id'];
        const quality = params['quality'];

        const valid = {
            name: validateEmptyValue(name),
            description: validateEmptyValue(description),
            product_category: validateEmptyValue(product_category),
            price: validateNumber(price),
            quantity: validateNumber(quantity),
            unit: validateNumber(unit),
            quality: validateNumber(quality),
            image: images.length !== 0
        };
        // console.log(valid);

        for (let k in valid) {
            if (valid[k] === false) {
                this.setState({ valid });
                return;
            }
            if (Array.isArray(valid[k])) {
                for (let i in valid[k]) {
                    if (valid[k][i] === false) {
                        this.setState({ valid });
                        return;
                    }
                }
            }
        }

        let i = 0;
        if (logistics) {
            params['freight'] = logistics['price'];
            params['logistics_weight'] = logistics['weight'];
            params['logistics_width'] = logistics['width'];
            params['logistics_height'] = logistics['height'];
            params['logistics_length'] = logistics['length'];
            for (let k in logistics['logistics_companies']) {
                params['logistics_company_id_' + i] = k;
                i++;
            }
        }
        delete params['logistics'];

        // console.log(images);
        let file, blob;
        i = 0;
        for (let k in images) {
            if (!isEmpty(images[k]) && typeof images[k] === 'object') {
                continue;
            }
            blob = await fetch(images[k]).then(r => r.blob());
            file = new File([blob], 'image_' + i + '.jpg', { type: 'image/jpg' });
            params['image_' + i] = file;
            i++;
        }
        if (params['remove_images']) {
            params['remove_images'] = params['remove_images'].toString();
        }
        delete params['images'];

        const formData = new FormData();
        let value;
        for (let key in params) {
            value = params[key];
            if (isEmpty(value)) {
                continue;
            }
            formData.append(key, value);
        }

        if (this.isEdit) {
            this.controller.putMultipathData(API.API_USER_PRODUCT + '/' + this.id, formData,
                (data) => {
                    this.props.history.replace(System.resolvePath('user/product'));
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        } else {
            this.controller.postMultipathData(API.API_USER_PRODUCT, formData,
                (data) => {
                    this.props.history.replace(System.resolvePath('user/product'));
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        }

    }

    render() {
        if (this.isError) {
            return null;
        }
        // console.log('UserShopFormPage render');
        const c = this.controller;
        const data = c.all();
        let { product_category_id, quality, status } = data;
        const { valid, post, productCategory, unit, openDeleteDialog, destroy } = this.state;
        const loading = (post && post.loading) || (destroy && destroy.loading);
        // console.log(product_categories);
        const selfPath = this.isEdit ? ("user/product/" + this.id) : "user/product/create";
        const logisticsPath = selfPath + "/logistics";
        const lg = data['logistics'];
        let logistics = 'ค่าส่งฟรี';
        if (lg && lg['price']) {
            logistics = lg['price'] + ' ' + L('บาท') + ' ' + L('ต่อ') + ' ' + lg['weight'] + ' ' + L('กิโลกรัม');
        }
        return (
            <div className="section-content no-nav">
                <Form
                    loading={post.loading}
                    className="form-register">
                    <AppBar
                        title={L('ข้อมูลสินค้า')}
                        subTitle={L('กรอกข้อมูลสินค้าและคำอธิบาย')}
                        showLeftArrow={true}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <InputImageBox
                                path="product"
                                name="images"
                                description={L('รูปภาพ: กรุณาใช้รูปภาพสินค้าที่เห็นชัดเจน และกรุณาอัปโหลดรูปสินค้า ขนาดของรูปที่เหมาะสมคือ 1024x1024 พิกเซลและอัพได้อย่างน้อย 1 รูปแต่ไม่เกิน 10 รูป')}
                                max={10}
                                controller={c}
                                label={L('รูปปกสินค้า*')}
                                isError={valid.image === false}
                                errorMessage={L('กรุณาเพิ่มรูปสินค้า')} />
                        </div>
                        <div className="col-12">
                            <InputBox
                                label={L('ชื่อสินค้า*')}
                                placeholder={L('ชื่อสินค้า')}
                                name="name"
                                controller={this.controller}
                                isError={valid.name === false}
                                errorMessage={L('กรุณากรอกชื่อสินค้า')} />
                        </div>
                        <div className="col-12">
                            <TextAreaBox
                                label={L('คำอธิบายสินค้า*')}
                                placeholder={L('ลักษณะ, จุดเด่น, สินค้าที่ขาย')}
                                name="description"
                                controller={this.controller}
                                isError={valid.description === false}
                                errorMessage={L('กรุณากรอกคำอธิบายสินค้า')} />
                        </div>
                    </div>

                    <FormTitle label={L('รายละเอียดสินค้า')} />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <TextAreaBox
                                label={L('รายละเอียดสินค้า')}
                                placeholder={L('ประโยชน์, การใช้งาน, การดูแลรักษา, สินค้าที่ขาย')}
                                name="detail"
                                rows={6}
                                controller={this.controller} />
                        </div>
                        <div className="col-12">
                            <SelectionInput
                                data={productCategory?.response ?? []}
                                selecteds={product_category_id}
                                label={L('หมวดหมู่สินค้า*')}
                                isError={valid.product_category === false}
                                errorMessage={L('กรุณาเลือกหมวดหมู่สินค้า')}
                                clickHandler={(item) => {
                                    c.set('product_category_id', parseInt(item.id), true);
                                }} />
                        </div>
                        <div className="col-12">
                            <InputBox
                                label={L('ราคา (บาท)*')}
                                placeholder={L('ราคาสินค้าต่อ 1 หน่วยที่เลือก')}
                                type="number"
                                name="price"
                                controller={this.controller}
                                isError={valid.price === false}
                                errorMessage={L('กรุณากรอกราคาสินค้า')} />
                        </div>
                        <div className="col-6">
                            <InputBox
                                label={L('วัสดุคงคลัง*')}
                                placeholder={L('จำนวนวัสดุคงคลัง')}
                                type="number"
                                name="quantity"
                                controller={this.controller}
                                isError={valid.quantity === false}
                                errorMessage={L('กรุณากรอกจำนวนวัสดุคงคลัง')} />
                        </div>
                        <div className="col-6">
                            <InputDropListBox
                                label={L('หน่วย*')}
                                placeholder={L('เลือกหน่วย')}
                                name="unit_id"
                                controller={this.controller}
                                isError={valid.unit === false}
                                errorMessage={L('กรุณาเลือกหน่วย')}
                                data={unit?.response} />
                        </div>
                        <div className="col-6">
                            <SelectionInput
                                data={this.qualities}
                                selecteds={quality}
                                label={L('สภาพสินเค้า*')}
                                isError={valid.quality === false}
                                errorMessage={L('กรุณาเลือกสภาพสินเค้า')}
                                clickHandler={(item) => {
                                    c.set('quality', parseInt(item.id), true);
                                }} />
                        </div>
                        <div className="col-6">
                            <InputSwitch
                                name="status"
                                checked={parseInt(status) === 1}
                                label={L('สถานะสินค้า')}
                                text={L('แสดงในหน้าแสดงสินค้า')}
                                controller={this.controller} />
                        </div>
                    </div>

                    <FormTitle label={L('ค่าจัดส่ง*')} subLabel={L('กำหนดค่าจัดส่งตาม ขนาด/น้ำหนัก')} />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <Link to={{
                                pathname: System.resolvePath(logisticsPath),
                                parantPath: selfPath,
                                formData: data,
                            }}>
                                <div className="input-next mb-3">
                                    <div>{logistics}</div>
                                    <img src={rightIcon} alt={logistics} />
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="form-row mt-4">
                        {this.isEdit && <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => this.delete()}>
                                {L('ลบข้อมูล')}
                            </Button>
                        </div>}
                        <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.submit()}>
                                {L('บันทึกข้อมูล')}
                            </Button>
                        </div>
                    </div>

                </Form>

                {openDeleteDialog && <div id="deleteModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('ลบข้อมูล')}</h5>
                            </div>
                            <div className="modal-body">
                                <p>{L('คุณต้องการลบข้อมูลนี้ใช่หรือไม่')}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-3"
                                    onClick={() => {
                                        window.hideModalDialog('deleteModal');
                                        this.setState({ openDeleteDialog: false });
                                    }}>{L('ยกเลิก')}</button>
                                <button
                                    type="button"
                                    className="btn btn-primary mr-3"
                                    onClick={() => {
                                        this.confirmDelete();
                                    }}>{L('ลบข้อมูล')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}

export default withRouter(UserProductFormPage);
