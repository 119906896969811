import React, { PureComponent } from 'react';
import System from '../models/System';
import StarRatings from 'react-star-ratings';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { get as L } from '../models/Label';

import Label from './Label';
import ShopItem from './ShopItem';
import { address } from '../utils/UObj';

import pinIcon from '../assets/images/farm-marker-icon.svg';
import addressIcon from '../assets/images/direction-icon.svg';
import phoneIcon from '../assets/images/phone-icon.svg';
import emailIcon from '../assets/images/email-icon.svg';
import ratingIcon from '../assets/images/rating-icon.svg';

import GoogleMapReact from 'google-map-react';
import { isMobile } from 'react-device-detect';
const AnyReactComponent = ({ text }) => <div className="marker"><img src={pinIcon} alt="พิกัด" /></div>;

class ShopCover extends PureComponent {

    constructor(props) {
        super(props);
    };

    renderArrowPrev() {

    }

    renderArrowNext() {

    }

    render() {

        const { data } = this.props;
        if (!data) {
            return null;
        }

        const { location, images, logo, id } = data ?? {};

        let center;
        let select;
        let zoom = 9;
        if (location) {
            center = {
                lat: parseFloat(location.lat ?? 19.9105),
                lng: parseFloat(location.lng ?? 99.8406)
            };
            if (location.lat && location.lng) {
                select = center;
            }
        } else {
            center = {
                lat: parseFloat(19.9105),
                lng: parseFloat(99.8406)
            };
        }

        return (
            <div className="mb-4">
                {(images && images.length) &&
                    <div className="box-shadow box-clip mb-4">
                        <Carousel
                            showThumbs={false}
                            showStatus={false}
                            showIndicators={false}
                            showArrows={true}
                            autoPlay={true}
                            infiniteLoop={true}
                        >
                            {images.map((item, i) =>
                                <div
                                    key={'s_i_' + i}
                                    style={{
                                        width: '100%',
                                        height: '280px',
                                        backgroundImage: 'url("' + System.resolveImagePath(item.image, 'shop') + '")',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                    }} />)}
                        </Carousel>
                    </div>
                }
                <div className="form-row">
                    <div className="col-12 mb-4">
                        <div className="card-box p-3">
                            <ShopItem data={data} clip={false} showShop={false} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-4 mb-md-0">
                        <div className="w-100 box-clip box-shadow" style={{ minHeight: isMobile ? '250px' : '175px', height: '100%' }}>
                            {data.id && <GoogleMapReact
                                key={'m_s_' + data.id}
                                options={{ mapTypeControl: false }}
                                bootstrapURLKeys={{ key: 'AIzaSyBYl4VWLo7Lj2VYK4Pd-uri1LNH2G6c8J8' }}
                                // yesIWantToUseGoogleMapApiInternals
                                defaultCenter={center}
                                defaultZoom={zoom}
                            >
                                {select && <AnyReactComponent
                                    lat={select.lat}
                                    lng={select.lng}
                                    text="My Marker"
                                />}
                            </GoogleMapReact>}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="card-box p-3" style={{ minHeight: '175px' }}>
                            <Label className="underline pb-1">{L('ข้อมูลร้านค้า')}</Label>
                            <div className="form-row label-shop-info">
                                <div className="col-auto mr-3"><img src={addressIcon} alt={L('ที่อยู่ร้าน')} /></div>
                                <div className="col">{address(data)}</div>
                            </div>
                            <div className="form-row label-shop-info">
                                <div className="col-auto mr-3"><img src={phoneIcon} alt={L('เบอร์โทรศัพท์')} /></div>
                                <div className="col"><a href={'tel:' + data.phone_number}>{data.phone_number}</a></div>
                            </div>
                            <div className="form-row label-shop-info">
                                <div className="col-auto mr-3"><img src={emailIcon} alt={L('อิเมล')} /></div>
                                <div className="col">{data.email ?? '-'}</div>
                            </div>
                            <div className="form-row label-shop-info">
                                <div className="col-auto mr-3"><img src={ratingIcon} alt={L('คะแนนรีวิว')} /></div>
                                <div className="col">
                                    <span>
                                        <StarRatings
                                            starDimension="12px"
                                            starSpacing="2px"
                                            rating={data.rating ?? 0}
                                            starRatedColor="#FFCE3D"
                                            numberOfStars={5} />
                                    </span>
                                    <span className="ml-2">{data.rating ?? L('ยังไม่มีคะแนน')} {L('ให้คะแนน ') + data.review + ' คน'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ShopCover;
