import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';
import System from '../models/System';
import Avatar from '../components/Avatar';
import FeedBox from '../components/FeedBox';
import Card from '../components/Card';

import API from '../models/API';
import Controller from '../models/Controller';
import FeedView from '../components/FeedView';
import Request from '../models/Request';

import { isMobile } from 'react-device-detect';

class HomePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openDeleteDialog: false,
            preload: false,
            feeds: [
                { id: 'dummy_' + 1, type: 'dummy' },
                { id: 'dummy_' + 2, type: 'dummy' },
                { id: 'dummy_' + 3, type: 'dummy' },
                { id: 'dummy_' + 4, type: 'dummy' },
                { id: 'dummy_' + 5, type: 'dummy' },
            ],
            totalFeeds: 0,
            //fullscreen image
            openImage: false,
            feedData: null,
            selectedIndex: -1,
        };

        this.controller = new Controller(this, {
            offset: 0,
        });
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this), true);
        this.loadData();
    }

    componentWillUnmount() {
        if (this.controller) {
            this.controller.destroy();
        }
        window.removeEventListener('scroll', this.handleScroll.bind(this), true);
    }

    loadData() {
        const { preload } = this.state;
        if (preload) {
            return;
        }
        this.setState({ preload: true });
        const offset = this.controller.get('offset');
        // console.log('start load');
        Request.get(API.API_FEED,
            { offset: offset },
            (data) => {
                let { feeds } = this.state;
                const list = data.list ?? [];
                if (feeds[0].type === 'dummy') {
                    feeds = [];
                }
                feeds = feeds.concat(list);
                this.controller.set('offset', feeds.length);
                // console.log(feeds);
                this.setState({ preload: false, feeds, totalFeeds: data.total_rows ?? 0 });
            },
            (error) => {
                this.loading = false;
                this.setState({ preload: false });
            });
    }

    handleScroll(event) {
        let target = event.target;
        const scrollHeight = window.scrollHeight || target.scrollHeight || document?.scrollHeight || target.body?.scrollHeight;
        const offsetHeight = window.offsetHeight || target.offsetHeight || document?.offsetHeight || target.body?.offsetHeight;
        const top = window.scrollTop || window.pageYOffset || target.scrollTop || document?.scrollTop || document?.documentElement?.scrollTop || document.body?.scrollTop;
        const maxScroll = scrollHeight - offsetHeight;
        if (top >= maxScroll) {
            const { feeds, totalFeeds, preload } = this.state;
            // console.log(feeds.length, totalFeeds);
            if (feeds.length < totalFeeds) {
                if (!preload) {
                    this.loadData();
                }
            }
        }
    }

    showFeedView(feedData, selectedIndex) {
        // console.log(feedData);
        this.lastScrollY = window.scrollY;
        this.setState({
            openImage: true,
            feedData,
            selectedIndex,
        });
    }

    async closeFeedView() {
        // console.log(images);
        await this.setState({
            openImage: false,
            feedData: null,
            selectedIndex: -1,
        });
        window.scroll({
            top: this.lastScrollY,
        });
    }

    getFeedItem(item, index) {
        // console.log(item, index);
        return <FeedBox
            key={'feed_' + item.id}
            index={index}
            data={item}
            showImageHandler={this.showFeedView.bind(this)}
            deleteHandler={this.delete.bind(this)} />
    }

    async delete(item, index) {
        // console.log(item, index);
        // return;
        this.id = item.id;
        this.index = index;
        await this.setState({ openDeleteDialog: true });
        window.openModalDialog('deleteModal', () => {
            this.setState({ openDeleteDialog: false });
        });
    }

    confirmDelete() {
        this.controller.deleteData(API.API_FEED + '/' + this.id,
            (data) => {
                window.hideModalDialog('deleteModal');
                const { feed } = this.state;
                const list = feed?.response?.list ?? [];
                list.splice(this.index, 1);
                this.setState({ openDeleteDialog: false, feed });
            },
            (error) => {
                window.openMessageModalDialog(error);
            });
    }

    render() {
        const { feeds, openDeleteDialog, openImage, feedData, selectedIndex, preload } = this.state;
        let name = '';
        const profile = System.profile;
        if (profile) {
            name = profile.first_name + ' ' + profile.last_name;
        }
        const offset = this.controller.get('offset');

        const list = feeds ?? [];
        // console.log(list);

        const cls = openImage ? 'd-none' : '';

        return (
            <div className="section-content">
                <div className={"feed-list " + cls}>
                    {System.isLogged() && <Card className="mb-4">
                        <div className="card-inner">
                            <div className="row no-gutters">
                                <div className="col-auto mr-2">
                                    <Avatar
                                        iconFill
                                        src={System.profile.profile_image} />
                                </div>
                                <div className="col ellipsis">
                                    <Link to={System.resolvePath('user/feed/create')}>
                                        <div className="input-feed pointer ellipsis">{L('คุณคิดอะไรอยู่')} {name}</div>
                                    </Link>
                                </div>
                            </div>
                            {/* <div className="line-h mt-3 mb-3"></div>
                            <div className="d-flex justify-content-around">
                                <MenuItem
                                    iconStyle={menuItemStyle}
                                    iconBackgroundColor="#F2F2F2"
                                    src={imageIcon}
                                    label={L('รูปภาพ / วิดีโอ')} />
                                <div className="line-v"></div>
                                <MenuItem
                                    iconStyle={menuItemStyle}
                                    iconBackgroundColor="#F2F2F2"
                                    src={pinIcon}
                                    label={L('แชร์สถานที่')} />
                            </div> */}
                        </div>
                    </Card>}
                    {list.map((item, index) => this.getFeedItem(item, index))}
                    {offset !== 0 && preload && <div className="w-100 text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>}
                </div>

                {openImage && <FeedView
                    closeHandler={this.closeFeedView.bind(this)}
                    key={'feed_view'}
                    data={feedData}
                    selectedIndex={selectedIndex}>
                </FeedView>}

                {openDeleteDialog && <div id="deleteModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('ลบโพสต์')}</h5>
                            </div>
                            <div className="modal-body">
                                <p>{L('คุณต้องการลบโพสต์นี้ใช่หรือไม่')}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-3"
                                    onClick={() => {
                                        window.hideModalDialog('deleteModal');
                                        this.setState({ openDeleteDialog: false });
                                    }}>{L('ยกเลิก')}</button>
                                <button
                                    type="button"
                                    className="btn btn-primary mr-3"
                                    onClick={() => {
                                        this.confirmDelete();
                                    }}>{L('ลบโพสต์')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}

// const menuItemStyle = { width: '32px', height: '32px' };

export default withRouter(HomePage);
