import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';
import SelectionInput from '../components/SelectionInput';
import System from '../models/System';
import Controller from '../models/Controller';
import Pagination from '../components/Pagination';
import { Link } from 'react-router-dom';
import API from '../models/API';
import ProductList from '../components/ProductList';
import { isMobile } from 'react-device-detect';
import AppBar from '../components/AppBar';

class UserProductPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            product: {
                response: {
                    page: 1,
                    total_rows: 12,
                    list: [
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' }
                    ]
                }
            }
        };

        this.controller = new Controller(this, {
            search_string: '',
            search_columns: 'name,description',
            page: 1,
            limit: 20,
            product_categories: [],
            // min_price
            // max_price
            // product_categories
            // rating
            // sorts
            // search_columns
        });
        if (!System.isFarmer()) {
            this.props.history.replace(System.resolvePath('home'));
            return;
        }
    }

    componentDidMount() {
        this.loadData();
        this.loadProductcategory();
    }

    loadData() {
        this.setState({
            product: {
                response: {
                    page: 1,
                    total_rows: 12,
                    list: [
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' }
                    ]
                }
            }
        });
        const params = { ...this.controller.all() };
        const index = params['product_categories'].indexOf(-1);
        if (index !== -1) {
            delete params['product_categories'];
        }
        this.controller.loadData(API.API_USER_PRODUCT, 'product', params, (data) => {
            // console.log(data);
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    loadProductcategory() {
        this.controller.loadData(API.API_PRODUCT_CATEGORY, 'productCategory', { filter: 0 }, (data) => {
            data.unshift({ id: -1, name: 'ทั้งหมด' });
            const selecteds = [];
            for (let k in data) {
                selecteds.push(data[k].id);
            }
            this.controller.set('product_categories', selecteds);
        });
    }

    search(time = 800) {
        // console.log(text);
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            // console.log('timer');
            this.timer = null;
            this.loadData();
        }, time);
    }

    getAppBar() {
        if (!isMobile) {
            return null;
        }
        return <AppBar
            title={L('รายการสินค้า')}
            showLeftArrow={true}
            onBackPress={() => {
                this.props.history.goBack();
            }}
        />;
    }

    render() {
        const controller = this.controller;
        const data = controller.all();
        let { product_categories, search_string, page } = data;
        const { product, productCategory } = this.state;
        const response = product?.response ?? {};
        const { list, total_rows } = response;

        // console.log(p);
        return (
            <div className="section-content">
                <div className="section-market flex-1">

                    {this.getAppBar()}

                    <div className="row">
                        <div className="col-6">
                            <input
                                defaultValue={search_string}
                                type="text"
                                className="form-control input-search"
                                style={{ backgroundColor: '#FAFAFA' }}
                                placeholder={L('ค้นหาสินค้า')}
                                onChange={(event) => {
                                    this.controller.set('search_string', event.target.value);
                                    this.search()
                                }} />
                        </div>
                        <div className="col-auto d-flex align-items-center">
                            <Link to={System.resolvePath('user/product/create')}>
                                <button type="button" className="btn btn-primary">{L('เพิ่มสินค้า')}</button>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <SelectionInput
                                className="filter mt-2 mb-3"
                                data={productCategory?.response ?? []}
                                selecteds={product_categories}
                                clickHandler={(item) => {
                                    let index = product_categories.indexOf(parseInt(item.id));
                                    if (index === -1) { //going to active
                                        const data = productCategory?.response;
                                        if (item.id === -1) {
                                            if (product_categories.indexOf(-1) !== -1) {
                                                return; //all already active
                                            }
                                            const selecteds = [];
                                            for (let k in data) {
                                                selecteds.push(data[k].id);
                                            }
                                            product_categories = selecteds;
                                        } else {
                                            product_categories.push(parseInt(item.id));
                                            if (product_categories.length === data.length - 1) {
                                                product_categories.push(-1);
                                            }
                                        }
                                    } else { //going to unactive
                                        if (item.id !== -1) {
                                            product_categories.splice(index, 1);
                                            index = product_categories.indexOf(-1);
                                            if (index !== -1) {
                                                product_categories.splice(index, 1);
                                            }
                                        } else {
                                            return; //all already active
                                        }
                                    }
                                    controller.set('product_categories', product_categories, true);
                                    this.search(search_string);
                                }} />
                        </div>
                    </div>

                    <ProductList data={list} path="user/product/" />

                    <div className="py-3">
                        <Pagination
                            id="pagination_product"
                            onChange={(name, value) => {
                                if (name === Pagination.PAGE_CHANGE) {
                                    if (value !== page) {
                                        // console.log(value);
                                        this.controller.set('page', value);
                                        this.search(0);
                                        System.scrollTo(0, 0);
                                    }
                                }
                            }}
                            data={{
                                pageSize: System.MAX_ROWS,
                                dataLength: total_rows ?? 0,
                                currentPage: page,
                            }} />
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(UserProductPage);
