import React, { Component } from 'react';
import { Range } from 'rc-slider';
import { nf } from '../utils/UObj';

class RangeInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            values: this.props.values
        };
    };

    render() {
        return (
            <div style={{ paddingLeft: '12px', paddingRight: '12px' }}>
                <Range
                    min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}
                    defaultValue={this.state.values}
                    onChange={(values) => {
                        this.setState({ values });
                        if (this.props.onChange) {
                            this.props.onChange(values);
                        }
                    }}
                />
                <div className="d-flex justify-content-between">
                    <div className="slider-value text-right">{nf(this.state.values[0], 0)}</div>
                    <div className="slider-value text-right">{nf(this.state.values[1], 0)}</div>
                </div>
            </div>
        );
    }
}

export default RangeInput;
