import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';

import InputImageBox from '../components/InputImageBox';
import FormTitle from '../components/FormTitle';
import Controller from '../models/Controller';
import Form from '../components/Form';
import Button from '../components/Button';

import { validateEmptyValue, validateNumber } from '../utils/UValidate';
import { isEmpty } from '../utils/UObj';

import { isMobile } from 'react-device-detect';
import AppBar from '../components/AppBar';
import System from '../models/System';
import TextAreaBox from '../components/TextAreaBox';
import rightIcon from '../assets/images/arrow-right-icon.svg';
import API from '../models/API';
import TagBox from '../components/TagBox';

class FeedFormPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            valid: {},
        };

        // console.log(this.props);

        const formData = this.props.location?.formData;
        // console.log(formData);
        this.controller = new Controller(this, {
            images: [],
            ['tag_' + API.API_SHOP]: [],
            ['tag_' + API.API_PRODUCT]: [],
            ['tag_' + API.API_ACTIVITY]: [],
            status: 1,
            ...formData
        });

        if (!System.isLogged()) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('home'));
            return;
        }

        //check id
        const _path = System.matchPath(this.props.location.pathname, 'user/feed/:feedId');
        if (_path) {
            this.id = validateNumber(_path.params.feedId);
            if (this.id) {
                this.isEdit = true;
            }
        }
    };

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        if (!this.isEdit) {
            return;
        }
        if (this.controller.get('loaded') === 1) {
            return;
        }
        this.controller.loadData(API.API_FEED + '/' + this.id, 'shop', { 'feedback': 0 }, (data) => {
            if (!data || parseInt(data.user_id) !== parseInt(System.profile?.id)) {
                this.isError = true;
                this.props.history.replace(System.resolvePath('home'));
                return;
            }
            const formData = {
                message: data['message'],
                images: data['images'],
                tag_shops: data['shops'],
                tag_products: data['products'],
                tag_activities: data['activities'],
                // check_in: data['check_in'],
                status: data['status'],
                loaded: 1,
            };
            this.controller.append(formData);
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    async delete() {
        // console.log('delete');
        await this.setState({ openDeleteDialog: true });
        window.openModalDialog('deleteModal', () => {
            this.setState({ openDeleteDialog: false });
        });
    }

    confirmDelete() {
        this.controller.deleteData(API.API_FEED + '/' + this.id,
            async (data) => {
                window.hideModalDialog('deleteModal');
                await this.setState({ openDeleteDialog: false });
                this.props.history.replace(System.resolvePath('home'));
            },
            (error) => {
                window.openMessageModalDialog(error);
            });
    }

    async submit() {
        this.setState({ valid: {} });

        const c = this.controller;
        const params = { ...c.all() };

        const message = params['message'] ?? '';
        const images = params['images'] ?? [];

        const valid = {
            form: images.length !== 0 || validateEmptyValue(message)
        };
        // console.log(valid);

        for (let k in valid) {
            if (valid[k] === false) {
                this.setState({ valid });
                return;
            }
            if (Array.isArray(valid[k])) {
                for (let i in valid[k]) {
                    if (valid[k][i] === false) {
                        this.setState({ valid });
                        return;
                    }
                }
            }
        }

        let i = 0;
        // console.log(images);
        let file, blob;
        i = 0;
        for (let k in images) {
            if (!isEmpty(images[k]) && typeof images[k] === 'object') {
                continue;
            }
            blob = await fetch(images[k]).then(r => r.blob());
            if (blob.type === "video/mp4") {
                file = new File([blob], 'image_' + i + '.mp4', { type: 'video/mp4' });
            } else {
                file = new File([blob], 'image_' + i + '.jpg', { type: 'image/jpg' });
            }
            params['image_' + i] = file;
            i++;
        }
        if (params['remove_images']) {
            params['remove_images'] = params['remove_images'].toString();
        }
        delete params['images'];

        const s = params['tag_' + API.API_SHOP].concat();
        const p = params['tag_' + API.API_PRODUCT].concat();
        const a = params['tag_' + API.API_ACTIVITY].concat();

        const keys = ['tag_' + API.API_SHOP, 'tag_' + API.API_PRODUCT, 'tag_' + API.API_ACTIVITY];
        const props = ['shop_id_', 'product_id_', 'activity_id_'];
        let key;
        for (let k in keys) {
            key = keys[k];
            let arr = params[key];
            for (let i = 0; i < arr.length; i++) {
                params[props[k] + i] = arr[i].id
            }
            delete params[key];
        }

        const formData = new FormData();
        let value;
        for (let key in params) {
            value = params[key];
            if (isEmpty(value)) {
                continue;
            }
            formData.append(key, value);
        }

        if (this.isEdit) {
            this.controller.putMultipathData(API.API_FEED + '/' + this.id, formData,
                (data) => {
                    this.props.history.replace(System.resolvePath('home'));
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        } else {
            this.controller.postMultipathData(API.API_FEED, formData,
                (data) => {
                    this.props.history.replace(System.resolvePath('home'));
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        }

    }

    render() {
        if (this.isError) {
            return null;
        }
        // console.log('UserShopFormPage render');
        const c = this.controller;
        const data = c.all();
        const loading = c.executing();
        const { valid, openDeleteDialog } = this.state;
        // console.log(product_categories);
        const selfPath = this.isEdit ? ('user/feed/' + this.id) : 'user/feed/create';
        const tagPath = selfPath + '/tag';
        // const loacationPath = selfPath + "/location";

        const s = data['tag_' + API.API_SHOP].concat();
        const p = data['tag_' + API.API_PRODUCT].concat();
        const a = data['tag_' + API.API_ACTIVITY].concat();
        const tags = s.concat(p, a);

        return (
            <div className="section-content no-nav">
                <Form
                    loading={loading}
                    className="form-register">
                    <AppBar
                        title={L('แชร์ข้อมูลและความคิดเห็น')}
                        subTitle={L('กรอกข้อมูลและรูปภาพหรือวิดีโอที่ต้องการแชร์')}
                        showLeftArrow={true}
                        onBackPress={() => {
                            this.props.history.replace('home');
                        }}
                    />

                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <InputImageBox
                                path="feed"
                                name="images"
                                description={L('รูปภาพ/วิดีโอ: รูปภาพและวิดีโอไม่เกิน 12 ไฟล์')}
                                allowVideo={false}
                                allowGIF
                                max={12}
                                controller={c}
                                // isError={valid['form'] === false}
                                // errorMessage={L('กรุณากรอกความคิดเห็นหรือเพิ่มรูปภาพอย่างน้อย 1 ช่อง')}
                                label={L('รูปภาพและวิดีโอ')} />
                        </div>
                        <div className="col-12">
                            <TextAreaBox
                                label={L('ความคิดเห็นและข้อมูล')}
                                placeholder={L('กรุณาพิมพ์ข้อความด้วยถ้อยคำที่สุภาพ')}
                                name="message"
                                rows={6}
                                isError={valid['form'] === false}
                                errorMessage={L('กรุณากรอกความคิดเห็นหรือเพิ่มรูปภาพอย่างน้อย 1 ช่อง')}
                                controller={this.controller} />
                        </div>
                    </div>

                    <FormTitle label={L('แท็ก')} subLabel={L('แท็ก ร้านค้า, สินค้าและกิจกรรม')} />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <Link to={{
                                pathname: System.resolvePath(tagPath),
                                parantPath: selfPath,
                                formData: data,
                            }}>
                                <div className="input-next mb-3">
                                    {tags.length > 0 ? <TagBox items={tags} /> : <div />}
                                    <img src={rightIcon} alt={L('แท็ก')} />
                                </div>
                            </Link>
                        </div>
                    </div>

                    {/* <FormTitle label={L('แชร์สถานที่')} subLabel={L('แชร์สถานที่ร้านหรือกิจกรรม')} />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <Link to={{
                                pathname: System.resolvePath(loacationPath),
                                parantPath: selfPath,
                                formData: data,
                            }}>
                                <div className="input-next mb-3">
                                    <div></div>
                                    <img src={rightIcon} alt={L('แชร์สถานที่')} />
                                </div>
                            </Link>
                        </div>
                    </div> */}

                    <div className="form-row mt-4">
                        {this.isEdit && <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => this.delete()}>
                                {L('ลบข้อมูล')}
                            </Button>
                        </div>}
                        <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.submit()}>
                                {L('บันทึกข้อมูล')}
                            </Button>
                        </div>
                    </div>

                </Form>

                {openDeleteDialog && <div id="deleteModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('ลบข้อมูล')}</h5>
                            </div>
                            <div className="modal-body">
                                <p>{L('คุณต้องการลบข้อมูลนี้ใช่หรือไม่')}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-3"
                                    onClick={() => {
                                        window.hideModalDialog('deleteModal');
                                        this.setState({ openDeleteDialog: false });
                                    }}>{L('ยกเลิก')}</button>
                                <button
                                    type="button"
                                    className="btn btn-primary mr-3"
                                    onClick={() => {
                                        this.confirmDelete();
                                    }}>{L('ลบข้อมูล')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}

export default withRouter(FeedFormPage);
