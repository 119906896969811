import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import API from '../models/API';
import Controller from '../models/Controller';
import { get as L } from '../models/Label';
import System from '../models/System';
import Pagination from '../components/Pagination';
import { isEmpty, nf } from '../utils/UObj';
import InputDropListBox from '../components/InputDropListBox';
import { getDateString } from '../utils/UDate';
import moment from 'moment';
import { Link } from 'react-router-dom';
import HLine from '../components/HLine';
import AppBar from '../components/AppBar';
import { isMobile } from 'react-device-detect';

class UserOrderPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            order: {
                response: {
                    page: 1,
                    total_row: 1,
                    list: [
                        {
                            id: 'dummy',
                            order_products: [
                                { id: 'dummy' },
                                { id: 'dummy' },
                                { id: 'dummy' },
                            ]
                        },
                    ]
                }
            }
        };

        //init query string
        const params = new URLSearchParams(this.props.location.search);
        const formData = {
            search_string: params.get('search_string') ?? '',
            page: params.get('page') ?? 1,
        };
        if (params.get('status')) {
            formData['status'] = params.get('status');
        }

        this.controller = new Controller(this, {
            search_columns: 'user.first_name,user.last_name,code',
            limit: 20,
            ...formData
        });

        this.dateOption = { type: 'short', noDay: true };
        this.badgesMap = {
            '1': { label: 'รอการตอบกลับ', className: 'badge badge-info' },
            '2': { label: 'ขายแล้ว', className: 'badge badge-primary' },
            '3': { label: 'ยกเลิก', className: 'badge badge-danger' }
        };

        //for sure allow only user
        if (!System.isLogged()) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('home'));
            return;
        }

        //check what view point
        if (this.props.location.pathname.indexOf('customer/order') !== -1) {
            this.isMerchant = true;
            if (!System.isFarmer()) {
                this.isError = true;
                this.props.history.replace(System.resolvePath('home'));
                return;
            }
        }

    }

    componentDidMount() {
        this.loadData();
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    loadData() {
        const api = this.isMerchant ? API.API_USER_ORDER : API.API_ORDER;
        this.controller.loadData(api, 'order', this.controller.all(), (data) => {
            // console.log(data);
        }, () => {

        });
    }

    search(time = 800) {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            // console.log('timer');
            this.timer = null;

            //create query string
            const data = { ...this.controller.all() };
            for (let k in data) {
                if (isEmpty(data[k])) {
                    delete data[k];
                }
            }
            // console.log(data);
            this.props.history.push({
                search: "?" + new URLSearchParams(data).toString()
            });
            this.loadData();
        }, time);
    }

    getItem(item) {
        // console.log(item);
        if (item.id === 'dummy') {
            return null;
        }
        const badges = this.badgesMap[item.status];
        const path = this.isMerchant ? 'user/customer/order/' : 'user/order/';
        return <Link key={'s_' + item.id} to={System.resolvePath(path + item.id)}>
            <div className="order-item form-row p-2">
                <div className="col">
                    <div className="order-item-code">{L('รหัสออเดอร์')} {item.code}</div>
                    {this.isMerchant
                        ? <div className="">{L('รายการสั่งซื้อจากคุณ')} "<b className="order-user-name">{item.user.first_name} {item.user.last_name}"</b></div>
                        : <div className="">{L('รายการสั่งซื้อกับร้านค้า')} "<b className="order-user-name">{item.shop.name}"</b></div>
                    }
                    <div className="d-flex flex-wrap mt-2">
                        {item.order_products && item.order_products.map((obj) =>
                            <img
                                className="order-product-thumbail mr-3"
                                src={System.resolveThumnailPath(obj.product.thumbnail.image, 'product')}></img>)}
                    </div>
                </div>
                <div className="col-auto">
                    <div className="text-right">{L('ยอดซื้อรวม')} <span className="list-tile-price">฿{nf(item.amount)}</span></div>
                    <div className="list-tile-date text-right">
                        {getDateString(moment(item.created_at), this.dateOption)}
                        <div className="text-right">
                            <span className={'list-tile-badges ' + badges.className}>{badges.label}</span>
                        </div>
                    </div>
                </div>
            </div>
            <HLine className="border-ligth my-2" />
        </Link>;
    }

    getAppBar() {
        if (!isMobile) {
            return null;
        }
        return <AppBar
            title={L('รายการประวัติการซื้อ')}
            showLeftArrow={true}
            onBackPress={() => {
                this.props.history.goBack();
            }}
        />;
    }

    render() {

        const { order } = this.state;
        const c = this.controller;
        const { page, search_string, status } = c.all();
        const { list, total_rows } = order?.response ?? {};

        return (
            <div className="section-content">
                <div className="cart-list">
                    {this.getAppBar()}
                    <div className="form-row mb-4">
                        <div className="col">
                            <input
                                type="text"
                                className="form-control input-search flex-1 mr-3"
                                style={{ backgroundColor: '#FAFAFA' }}
                                placeholder={L('ชื่อลูกค้า, ร้านค้า, รหัสออเดอร์')}
                                defaultValue={search_string}
                                onChange={(event) => {
                                    c.set('search_string', event.target.value);
                                    this.search();
                                }}
                            />
                        </div>
                        <div className="col-auto">
                            <InputDropListBox
                                key="status"
                                placeholder={L('เลือกสถานะ')}
                                name="status"
                                value={status}
                                data={[
                                    { id: '', label: L('ทั้งหมด') },
                                    { id: 1, label: L('รอการตอบกลับ') },
                                    { id: 2, label: L('ขายแล้ว') },
                                    { id: 3, label: L('ยกเลิก') }
                                ]}
                                onChange={(event) => {
                                    c.set('status', event.target.value);
                                    this.search(0);
                                }} />
                        </div>
                    </div>

                    {list && list.map((item) => this.getItem(item))}

                    <div className="py-3">
                        <Pagination
                            id="pagination_product"
                            onChange={(name, value) => {
                                if (name === Pagination.PAGE_CHANGE) {
                                    if (value !== page) {
                                        // console.log(value);
                                        this.controller.set('page', value);
                                        this.search(0);
                                        System.scrollTo(0, 0);
                                    }
                                }
                            }}
                            data={{
                                pageSize: System.MAX_ROWS,
                                dataLength: total_rows ?? 0,
                                currentPage: page,
                            }} />
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(UserOrderPage);