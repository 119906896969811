import React, { Component } from 'react';
import { get as L } from '../models/Label';

import InputBox from '../components/InputBox';

import removeImageIcon from '../assets/images/close-icon.svg';
import TextAreaBox from './TextAreaBox';
import InputImageBox from './InputImageBox';

class ParagraphForm extends Component {

    constructor(props) {
        super(props);
        // this.state = this.props.data;
    };

    onChangeHandler(value, name) {
        // console.log(value, name);
        // this.setState({ [name]: value });
    }

    render() {
        const { controller, index, path } = this.props;
        // console.log(this.props);
        return <div className="position-relative form-dynamic form-register-farm">
            <div className="form-row">
                <div className="col-12">
                    <InputImageBox
                        path={path}
                        name={'paragraphs.images'}
                        index={index}
                        max={1}
                        controller={controller}
                        label={L('รูปภาพ')} />
                </div>
                <div className="col-12">
                    <TextAreaBox
                        label={L('คำอธิบาย')}
                        placeholder={L('อธิบายเกี่ยวกับรูป')}
                        name={'paragraphs.description'}
                        index={index}
                        controller={controller} />
                </div>
                {this.props.showCloseButton === true && <img
                    className="btn-remove-form"
                    src={removeImageIcon}
                    alt={L('ลบ')}
                    onClick={this.props.closeButtonClickHandler.bind(this, this.props.index)} />}
            </div>
        </div>;
    }
}

export default ParagraphForm;
