import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Event from '../models/Event';
import System from '../models/System';
import RegisterProfileContent from '../components/RegisterProfileContent';
import { isEmpty } from '../utils/UObj';
import { isMobile } from 'react-device-detect';

class UserProfilePage extends Component {

    constructor(props) {
        super(props);
        if (!System.isLogged()) {
            this.props.history.replace(System.resolvePath('home'));
            return;
        }
    }

    success(data) {
        // console.log('UserProfilePage success => ', data);
        System.setProfile(data, false);
        Event.dispatch(Event.LOGIN); //to refresh all field to show profile data
        if (isMobile) {
            this.props.history.goBack();
        }
    }

    render() {
        // const { } = this.state;
        // console.log(System.profile);
        // <div className="page-register container"></div>
        return (
            <div className={'section-content' + (isMobile ? ' no-nav' : '')}>
                <RegisterProfileContent
                    data={isEmpty(System.profile?.id) ? null : System.profile}
                    passwordType="option"
                    showLeftArrow={isMobile}
                    success={(data) => this.success(data)}
                    onBackPress={() => this.props.history.goBack()} />
            </div>
        );
    }
}

export default withRouter(UserProfilePage);
