import React, { Component } from 'react';
import ReactPlayer from 'react-player/lazy';
import Card from './Card';
import { get as L } from '../models/Label';
import ImageBackground from './ImageBackground';
import Avatar from './Avatar';

import optionIcon from '../assets/images/option-icon.svg';
import likeIcon from '../assets/images/like-icon.svg';
import likeFillIcon from '../assets/images/like-fill-icon.svg';
import commentIcon from '../assets/images/comment-icon.svg';

import MenuItem from './MenuItem';
import System from '../models/System';
import moment from 'moment';
import FeedbackBox from './FeedbackBox';
import Request from '../models/Request';
import API from '../models/API';
import { Link } from 'react-router-dom';

class FeedBox extends Component {

    constructor(props) {
        super(props);

        const { data } = this.props;
        this.state = {
            data,
            status: data?.status ?? 0,
            commentCount: data?.comment_count ?? 0,
            likeCount: data?.like_count ?? 0,
            like: data?.like ?? null,
            showMenu: false,
            limit: 3,
        };

        // console.log(this.state);

    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        // console.log(this.props.data);
        if (this.props.data?.id !== prevProps.data?.id) {
            this.fetchData(this.props.data);
        }
    }

    fetchData(data) {
        this.setState({
            data,
            status: data?.status ?? 0,
            commentCount: data?.comment_count ?? 0,
            likeCount: data?.like_count ?? 0,
            like: data?.like ?? null,
            limit: null,
        });
        // console.log(this.state);
    }

    commentHandler() {
        if (!this.props.data?.id || this.state.limit === null) {
            return;
        }
        //load more comment
        Request.get(API.API_FEED + '/' + this.props.data.id, null, (data) => {
            this.fetchData(data);
        }, () => { })
    }

    openFullscreen(index) {
        // console.log(index);
        const { data } = this.props;
        this.props.showImageHandler(data, index);
    }

    getMediaItem(images, index, s) {
        const image = images[index]?.image;
        if (!image) {
            return null;
        }
        if (image.indexOf('.mp4') !== -1 || image.indexOf('.mov') !== -1) {
            if (images.length === 1 && s === 0) {
                return <ReactPlayer
                    width="100%"
                    height="100%"
                    url={System.resolveImagePath(image, 'feed')}
                    controls={true}
                />;
            } else {
                return <div
                    className="pointer h-100"
                    onClick={this.openFullscreen.bind(this, index + s)}>
                    <ReactPlayer
                        width="100%"
                        height="100%"
                        url={System.resolveImagePath(image, 'feed')}
                        controls={false}
                    />
                </div>;
            }
        }
        return <ImageBackground
            className="pointer"
            onClick={this.openFullscreen.bind(this, index + s)}
            src={System.resolveThumnailPath(image, 'feed')}
            width={'100%'} height={'100%'} />
    }

    getImageLayout(images) {

        if (!images || images.length === 0) {
            return null;
        }

        const firstImage = images[0];
        let content;
        const len = images.length;

        const s1 = [];
        for (let i = 0; i < len; i++) {
            if (i === 2) {
                break;
            }
            s1.push(images[i]);
        }
        const s2 = [];
        for (let i = 2; i < len; i++) {
            if (i === 5) {
                break;
            }
            s2.push(images[i]);
        }

        // check what layout
        if (parseInt(firstImage.height) > parseInt(firstImage.width)) {
            content = <div className="row no-gutters h-100">
                <div className="col row no-gutters">
                    {s1.map((_, index) => {
                        let cls = index === s1.length - 1 ? '' : 'pb-1';
                        return <div key={s1[index].image} className={'col-12 ' + cls}>
                            {this.getMediaItem(s1, index, 0)}
                        </div>;
                    })}
                </div>
                {s2.length !== 0 && <div className="col row no-gutters pl-1">
                    {s2.map((_, index) => {
                        let cls = index === s2.length - 1 ? '' : 'pb-1';
                        let showNumber = false;
                        if (len > 5 && index === 2) { //show number
                            cls = 'position-relative';
                            showNumber = true;
                        }
                        // console.log(len, index);
                        return <div key={s2[index].image} className={'col-12 ' + cls}>
                            {this.getMediaItem(s2, index, 2)}
                            {showNumber && <div
                                onClick={this.openFullscreen.bind(this, 4)}
                                className="feed-image-cover-number pointer">+{len - 5}</div>}
                        </div>;
                    })}
                </div>}
            </div>;
        } else {
            content = <div className="row no-gutters h-100">
                <div className="col-12 row no-gutters">
                    {s1.map((_, index) => {
                        let cls = index === s1.length - 1 ? '' : 'pr-1';
                        return <div key={s1[index].image} className={'col ' + cls}>
                            {this.getMediaItem(s1, index, 0)}
                        </div>;
                    })}
                </div>
                {s2.length !== 0 && <div className="col-12 row no-gutters pt-1">
                    {s2.map((_, index) => {
                        let cls = index === s2.length - 1 ? '' : 'pr-1';
                        let showNumber = false;
                        if (len > 5 && index === 2) { //show number
                            cls = 'position-relative';
                            showNumber = true;
                        }
                        return <div key={s2[index].image} className={'col ' + cls}>
                            {this.getMediaItem(s2, index, 2)}
                            {showNumber && <div
                                onClick={this.openFullscreen.bind(this, 4)}
                                className="feed-image-cover-number pointer">+{len - 5}</div>}
                        </div>;
                    })}
                </div>}
            </div>;
        }

        return <div className="feed-box-image">
            {content}
        </div>;
    }

    update(data) {
        this.setState({ commentCount: data?.comment_count ?? 0 });
    }

    delete() {
        // console.log('delete');
        if (this.props.deleteHandler) {
            // console.log(this.props.data, this.props.index);
            this.props.deleteHandler(this.props.data, this.props.index);
        }
    }

    block() {
        const { data } = this.props;
        const { status, limit } = this.state;
        const newStatus = parseInt(status) === 2 ? 1 : 2;
        Request.pos(API.API_FEED + '/' + data.id + '/block', { status: newStatus, limit: limit }, (data) => {
            this.setState({ status: data ?? 1 });
        }, () => { });
    }

    likeHandler(item) {
        const { likeCount, like, showMenu } = this.state;
        let token;
        if (like && parseInt(like['user_id']) === parseInt(System.profile?.id)) {
            token = 0;
            this.setState({ likeCount: parseInt(likeCount) - 1, like: null });
        } else {
            token = 1;
            this.setState({ likeCount: parseInt(likeCount) + 1, like: { user_id: System.profile?.id } });
        }
        Request.pos(API.API_FEED + '/' + item.id + '/like', { like: token }, () => { }, () => { });
    }

    render() {
        const { data } = this.state;
        if (data.type === 'dummy') {
            return null;
        }
        const { status, likeCount, commentCount, like, showMenu, limit } = this.state;
        const user = data.user;
        const logged = System.isLogged();
        const isOwner = parseInt(System.profile?.id) === parseInt(data.user_id);
        const showOption = logged && (isOwner || System.isAdmin());

        const isTag = data.shops?.length > 0 || data.products?.length > 0 || data.activities?.length > 0;
        const showFeedback = logged || data?.feedback?.length !== 0;

        const blocked = parseInt(status) === 2;
        const style = blocked ? { opacity: 0.6 } : null;

        return (
            <Card className="mb-4" style={style}>
                <div className="feed-box-header">
                    <Avatar
                        iconFill
                        style={{ marginRight: '10px' }}
                        src={System.resolveImagePath(user.profile_image, 'profile')} />
                    <div className="flex-1">
                        <div className="feed-box-user-name">{user.first_name + ' ' + user.last_name}</div>
                        <div className="feed-box-date">{moment(data.created_at).fromNow()}</div>
                    </div>

                    {showOption && <div className="dropdown">
                        <div
                            className="btn-icon"
                            id={"dropdownMenuButton_f_" + data.id}
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            onClick={() => { this.setState({ showMenu: true }) }}>
                            <img src={optionIcon} alt={L('จัดการโพสต์')} />
                        </div>
                        {showMenu && <div className="dropdown-menu" aria-labelledby={"dropdownMenuButton_f_" + data.id}>
                            {System.isAdmin()
                                ?
                                <>
                                    {isOwner && <>
                                        <Link
                                            className="dropdown-item pointer"
                                            to={System.resolvePath('user/feed/' + data.id)}>
                                            {L('แก้ไขโพสต์')}
                                        </Link>
                                        <div className="dropdown-item pointer" onClick={this.delete.bind(this)}>{L('ลบโพสต์')}</div>
                                        <div className="line-h my-2" style={{ backgroundColor: '#DADADA' }}></div>
                                    </>}
                                    <div
                                        className="dropdown-item pointer"
                                        onClick={this.block.bind(this)}>
                                        {blocked ? L('อันบล็อกโพสต์') : L('บล็อกโพสต์')}
                                    </div>
                                </>
                                : <>
                                    <Link
                                        className="dropdown-item pointer"
                                        to={System.resolvePath('user/feed/' + data.id)}>
                                        {L('แก้ไขโพสต์')}
                                    </Link>
                                    <div className="dropdown-item pointer" onClick={this.delete.bind(this)}>{L('ลบโพสต์')}</div>
                                </>}
                        </div>}
                    </div>}

                </div>

                {data.message && <div className="feed-box-message">
                    {blocked && <div
                        className="text-danger font-size-3 font-weight-3">
                        *{L('โพสนี้ถูกบล็อคเนื่องจากคุณได้ละเมิดนโยบายการโพสต์ของ Noth Organic​ Food')}
                    </div>}
                    <div className="pre-line">{data.message}</div>
                    {isTag && <div className="feed-box-tag">
                        {data.shops.map((item) => {
                            return <Link key={'s_' + item.id} className="feed-tag" to={System.resolvePath('shop/' + item.id)}>#{item.name}</Link>;
                        })}
                        {data.products.map((item) => {
                            return <Link key={'p_' + item.id} className="feed-tag" to={System.resolvePath('product/' + item.id)}>#{item.name}</Link>;
                        })}
                        {data.activities.map((item) => {
                            return <Link key={'a_' + item.id} className="feed-tag" to={System.resolvePath('activity/' + item.id)}>#{item.name}</Link>;
                        })}
                    </div>}
                </div>}
                {this.getImageLayout(data.images)}
                <div className={`feed-box-action ${showFeedback ? 'border-tb' : 'border-t'}`}>
                    <MenuItem
                        iconStyle={menuItemStyle}
                        iconBackgroundColor="#F2F2F2"
                        src={like && parseInt(like['user_id']) === parseInt(System.profile?.id) ? likeFillIcon : likeIcon}
                        label={L('ถูกใจ') + ' (' + likeCount + ')'}
                        onClick={logged ? this.likeHandler.bind(this, data) : null}
                    />
                    <MenuItem
                        iconStyle={menuItemStyle}
                        iconBackgroundColor="#F2F2F2"
                        src={commentIcon}
                        onClick={this.commentHandler.bind(this)}
                        label={L('ความคิดเห็น') + ' (' + commentCount + ')'} />
                </div>
                {showFeedback && <div className="card-inner">
                    <FeedbackBox
                        key={'feeback_box' + data.id}
                        data={data}
                        limit={limit}
                        updateHandler={this.update.bind(this)} />
                </div>}
            </Card>
        );
    }
}

const menuItemStyle = { width: '32px', height: '32px' };

export default FeedBox;