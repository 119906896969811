import React, { Component } from 'react';
import StarRatings from 'react-star-ratings';
import ImageViewer from '../components/ImageViewer';
import API from '../models/API';
import System from '../models/System';
import Controller from '../models/Controller';
import { withRouter } from 'react-router';
import { validateNumber } from '../utils/UValidate';
import IncreaseInput from '../components/IncreaseInput';
import { get as L } from '../models/Label';
import Button from '../components/Button';

// import markerIcon from '../assets/images/marker-icon.svg';
// import logisticsIcon from '../assets/images/logistics-icon.svg';
import cartIcon from '../assets/images/cart-icon.svg';

import ListTile from '../components/ListTile';
import Label from '../components/Label';
import Cart from '../models/Cart';
import { nf } from '../utils/UObj';
import ShopItem from '../components/ShopItem';
import AppBar from '../components/AppBar';
import FormTitle from '../components/FormTitle';
import ReviewItem from '../components/ReviewItem';
import { Carousel } from 'react-responsive-carousel';
import { isMobile } from 'react-device-detect';

class ProductPage extends Component {

    constructor(props) {
        super(props);

        //check id
        const _path = System.matchPath(this.props.location.pathname, 'market/product/:productId');
        if (_path) {
            this.id = validateNumber(_path.params.productId);
        }
        if (!this.id) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('market'));
            return;
        }

        this.state = {
            product: {
                loading: true,
            },
        };
        this.controller = new Controller(this, {
            quantity: 1
        });

        this.logisticsData = 'dummy';

        this.dateOption = { type: 'short', noDay: true };
    }

    componentDidMount() {
        this.loadProductData();
    }

    componentWillUnmount() {
        if (this.controller) {
            this.controller.destroy();
        }
    }

    loadProductData() {
        if (this.isError) {
            return;
        }
        this.controller.loadData(API.API_PRODUCT + '/' + this.id, 'product', null, (data) => {
            if (!data) {
                this.isError = true;
                this.props.history.replace(System.resolvePath('market'));
                return;
            }
            this.logisticsData = {};
            if (data.freight) {
                this.logisticsData.label = 'ค่าจัดส่ง ' + nf(data.freight) + ' บาท';
            } else {
                this.logisticsData.label = 'ค่าจัดส่งฟรี';
            }
            if (data.logistics_weight) {
                const w = data.logistics_width;
                const l = data.logistics_length;
                const h = data.logistics_height;
                this.logisticsData.sublabel = 'สำหรับน้ำหนัก ' + data.logistics_weight + ' กล. ';
                this.logisticsData.sublabel += 'ขนาด กว้าง ' + w + ' ยาว ' + l + ' สูง ' + h + ' ซม.';
            } else {
                this.logisticsData.sublabel = 'ซื้อสินค้าไม่มีขั้นต่ำ ส่งฟรี รอรับออเดอร์ถึงหน้าบ้าน';
            }

        });
    }

    addToCart(id, quantity) {
        Cart.add({ id, quantity });
    }

    checkout(id, quantity) {
        Cart.reset({ id, quantity });
        this.props.history.replace(System.resolvePath('user/cart'));
    }

    render() {
        // console.log('ProductPage render');
        if (this.isError) {
            return null;
        }

        const { product } = this.state;
        const loading = product.loading;
        const data = product.response;

        if (!data) {
            return null;
        }

        const logistics = data.logistics_companies;
        const c = this.controller;
        const { quantity } = c.all();
        const profile = System.profile;
        // console.log(data);
        // console.log(profile?.id, data.shop?.user_id);
        return (
            <div className="section-content">
                <div className="container pb-5">
                    <AppBar
                        title={L('ข้อมูลสินค้า')}
                        showLeftArrow={true}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <div className="row mb-5">
                        {isMobile
                            ? <div className="col-12 mb-3">
                                <Carousel
                                    showStatus={true}
                                    showIndicators={true}
                                    showArrows={true}
                                    infiniteLoop={false}
                                    showThumbs={false}
                                    autoPlay={false}
                                >
                                    {data?.images.map((item, i) =>
                                        <img src={System.resolveImagePath(item.image, 'product')} alt={L('รูปที่') + ' ' + (i + 1)} />)}
                                </Carousel>
                            </div>
                            : <div className="col">
                                <ImageViewer
                                    loading={loading}
                                    path="product"
                                    images={data?.images}
                                    showGallery
                                ></ImageViewer>
                            </div>}
                        <div className="col">
                            <div className="form-row">
                                <div className="col-12 label-product-name">{data.name}</div>
                                <div className="col-12 label-product-desc">{data.description}</div>

                                <div className="col-12 form-row label-rating py-2">
                                    <div className="col-auto">
                                        <span>
                                            <StarRatings
                                                starDimension="12px"
                                                starSpacing="2px"
                                                rating={parseFloat(data.rating ?? 0)}
                                                starRatedColor="#ffce3d"
                                                numberOfStars={5}
                                            />
                                        </span>
                                        <span className="ml-2">{data.rating}</span>
                                    </div>
                                    <div className="col-auto">{L('ให้คะแนน ') + data.review + ' คน'}</div>
                                    <div className="col-auto">{L('ขายแล้ว ') + data.sold + ' ' + data.unit.name}</div>
                                </div>

                                <div className="col-12"></div>
                                <div className="col-12"><span className="label-product-price">฿{nf(data.price)}</span> / {data.unit.name}</div>
                                <div className="col-auto">{L('หมวดหมู่สินค้า')}:</div>
                                <div className="col">{data.product_category.name}</div>
                                <div className="col-12 list-item-border mt-2"></div>
                                <ListTile
                                    className="col-12 noborder"
                                    key="logistics"
                                    // icon={logisticsIcon}
                                    data={this.logisticsData}>
                                </ListTile>
                                <div className="col-12">
                                    <Label noMargin>{L('ผู้ให้บริการ')}</Label>
                                    {logistics && logistics.map((item, index) =>
                                        <ListTile key={"ch_" + index}
                                            data={{
                                                label: item.name,
                                                sublabel: item.description,
                                            }}
                                        />)}
                                </div>
                                <div className="col-12 list-item-border mb-3"></div>
                                {parseInt(data.quantity) !== 0
                                    ? <>
                                        {parseInt(System.profile?.id) !== parseInt(data.shop?.user_id) ?
                                            <>
                                                <div className="col-auto">
                                                    <IncreaseInput
                                                        name="quantity"
                                                        min={1}
                                                        max={data.quantity}
                                                        controller={c}
                                                    />
                                                </div>
                                                <div className="col-auto d-flex align-items-center">
                                                    {L('จากทั้งหมด')} {nf(data.quantity)} {data.unit.name}
                                                </div>
                                                <div className="col-12 mb-3"></div>
                                                <div className="col">
                                                    <Button
                                                        onClick={() => this.checkout(data.id, quantity)}
                                                        className="btn btn-primary"
                                                    >{L('สั่งซื้อสินค้า')}
                                                    </Button>
                                                </div>
                                                <div className="col-auto">
                                                    <Button
                                                        onClick={() => Cart.add(data.id, quantity)}
                                                        className="btn btn-primary"
                                                    >{L('เพิ่มในตะกร้า')}
                                                    </Button>
                                                </div>
                                            </>
                                            : <div className="col-auto d-flex align-items-center">
                                                {L('สินค้าคงเหลือ')} {nf(data.quantity)} {data.unit.name}
                                            </div>
                                        }
                                    </>
                                    : <div className="col-12">{L('สินค้าหมด')}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col">
                            <ShopItem data={data?.shop} />
                        </div>
                    </div>

                    {data?.detail && <div className="row mb-4">
                        <div className="col">
                            <Label>{L('รายละเอียดสินค้า')}</Label>
                            <div className="pre-line font-size-0">{data?.detail}</div>
                        </div>
                    </div>}

                    <div className="row mb-5">
                        <div className="col">
                            <FormTitle
                                label={L('คะแนนของสินค้า')}
                                path={profile ? System.resolvePath('market/product/' + data.id + '/review/create') : null} />
                            {data.reviews && data.reviews.map((item) =>
                                <ReviewItem
                                    key={'p_r_' + item.id}
                                    data={item}
                                    profile={System.profile}
                                    path={System.resolvePath('market/product/' + this.id + '/review/' + item.id)}
                                />)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ProductPage);
