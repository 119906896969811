export default class Icon {

    static iconMap;

    static init() {
        // console.log('init');
        this.iconMap = {};
        // const r = require.context('../assets/images/icons', false, /\.(png|jpe?g|svg)$/);
        const r = require.context('../assets/images/icons', false, /\.(png|jpe?g|svg)$/);
        // console.log('init', r);
        let name;
        r.keys().map((item) => {
            name = item.replace(/(.\/|.svg|.png)/g, '');
            // console.log(name);
            this.iconMap[name] = r(item);
        });
    }

    static get(name, color = '') {
        // console.log(name + '-icon' + (color ? '-' + color : ''));
        // console.log(this.iconMap[name + '-icon' + (color ? '-' + color : '')]);
        const module = this.iconMap[name + '-icon' + (color ? '-' + color : '')];
        // console.log(module);
        // console.log(color);
        if (module) {
            return module.default;
        }
        return null;
    }
}