import React, { Component } from 'react';
import Controller from '../models/Controller';
import API from '../models/API';
import Event from '../models/Event';
import ChatContactItem from './ChatContactItem';

class ChatRightSectionContent extends Component {

    constructor(props) {
        super(props);

        const response = [];
        for (let i = 0; i < 10; i++) {
            response.push({ id: 'dummy_' + i, type: 'dummy' });
        }

        this.state = {
            'chat': { response },
        };

        this.controller = new Controller(this, {});

    };

    componentDidMount() {
        this.event = Event.add(Event.CHAT_CONTACT_CHANGE, () => {
            this.loadData();
        });
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.visible !== prevProps.visible) {
            this.loadData();
        }
    }

    componentWillUnmount() {
        this.controller.destroy();
        // console.log('clearInterval');
        clearInterval(this.interval);
        Event.remove(this.event);
    }

    loadData() {
        clearInterval(this.interval);
        if (!this.props.visible) {
            return;
        }
        // console.log('loadData', this.props.id);
        this.controller.loadData(API.API_CHAT, 'chat', null, (data) => {
            // console.log(data);
            this.interval = setInterval(() => {
                // console.log('This will run every 1 minute!');
                this.loadData();
            }, 60000);
        }, () => { })
    }

    render() {
        const { chat } = this.state;
        const list = chat?.response ?? [];
        // console.log(chat);
        return (
            <>
                {list.map((item) => <ChatContactItem
                    key={'contact_' + item.id}
                    data={item}
                />)}
            </>
        );
    }
}

export default ChatRightSectionContent;
