import React, { Component } from 'react';

import HomeLeftSectionContent from './HomeLeftSectionContent';

class HomeLeftSection extends Component {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className="section-left">
                <div className="position-sticky py-3 scroll-inner" style={{ top: '64px', height: 'calc(100vh - 64px)' }}>
                    <HomeLeftSectionContent key={'h_l_s_c'} />
                </div>
            </div>
        );
    }
}

export default HomeLeftSection;
