import React, { PureComponent } from 'react';

class TableFoot extends PureComponent {
    render() {
        return (
            <tfoot></tfoot>
        );
    }
}

export default TableFoot;
