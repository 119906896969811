import React, { PureComponent } from 'react';

class ErrorText extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <>{this.props.isError && <div className="text-error-1">{this.props.children}</div>}</>
        );
    }
}

export default ErrorText;
