import API from "./API";
import Event from "./Event";
import Request from "./Request";

export default class Chat {

    static unreadCount = 0;

    static loadUnreadCount() {
        Request.get(API.API_CHAT + '/unread-count', null, (data) => {
            this.unreadCount = parseInt(data);
            Event.dispatch(Event.CHAT_READ_CHANGE);
        });
    }

    static setUnreadCount(count) {
        this.unreadCount = Math.max(0, count);
        Event.dispatch(Event.CHAT_READ_CHANGE);
    }

    static read(item) {
        Request.pos(API.API_CHAT + '/' + item.id + '/read', null, () => {
            // this.loadUnreadCount();
            // console.log('load new chat room');
            Event.dispatch(Event.CHAT_CONTACT_CHANGE);
        });
    }

    static isAllow() {
        // console.log('isAllow', Notification.permission);
        return Notification.permission === 'granted';
    }

    static askForPermission() {
        Notification.requestPermission();
    }

    static update() {
        this.loadUnreadCount();
    }

}