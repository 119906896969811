import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { get as L } from '../models/Label';
import Pagination from '../components/Pagination';
import ProductList from '../components/ProductList';
import API from '../models/API';
import Controller from '../models/Controller';
import System from '../models/System';
import { isEmpty } from '../utils/UObj';
import ShopCover from '../components/ShopCover';
import { validateNumber } from '../utils/UValidate';
import Label from '../components/Label';
import ActivityList from '../components/ActivityList';

class ShopPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            shop: {},
            product: {
                response: {
                    page: 1,
                    total_rows: 5,
                    list: [
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                    ]
                }
            },
            activity: {
                response: {
                    page: 1,
                    total_rows: 3,
                    list: [
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                    ]
                }
            }
        };

        //check id
        const _path = System.matchPath(this.props.location.pathname, 'shop/:shopId');
        if (_path) {
            this.id = validateNumber(_path.params.shopId);
        }
        if (!this.id) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('home'));
            return;
        }

        //init query string
        const params = new URLSearchParams(this.props.location.search);
        const formData = {
            // search_string: params.get('search_string'),
            activity_page: params.get('activity_page') ?? 1,
            product_page: params.get('product_page') ?? 1,
            // min_price: params.get('min_price'),
            // max_price: params.get('max_price'),
            // product_categories: params.get('product_categories')?.split(',') ?? [],
            // rating: params.get('rating'),
            // sort: params.get('sort'),
        };

        this.controller = new Controller(this, {
            // search_columns: 'name,description',
            limit: 20,
            ...formData
            // min_price
            // max_price
            // product_categories
            // rating
            // sorts
            // search_columns
        });
    }

    componentDidMount() {
        if (this.isError) {
            return;
        }
        this.loadShopData();
        this.loadData();
    }

    loadShopData() {
        this.controller.loadData(API.API_SHOP + '/' + this.id, 'shop', null, (data) => {
            // console.log(data);
        });
    }

    loadData() {
        this.setState({
            product: {
                response: {
                    page: 1,
                    total_rows: 5,
                    list: [
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                    ]
                }
            },
            activity: {
                response: {
                    page: 1,
                    total_rows: 3,
                    list: [
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                    ]
                }
            }
        });
        const data = this.controller.all();

        this.controller.loadData(API.API_PRODUCT, 'product', {
            shop_id: this.id,
            page: data.product_page,
            limit: 20,
        }, (data) => {
            // console.log(data);
        });
        this.controller.loadData(API.API_ACTIVITY, 'activity', {
            shop_id: this.id,
            page: data.activity_page,
            limit: 20,
        }, (data) => {
            // console.log(data);
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    search(time = 800) {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            // console.log('timer');
            this.timer = null;

            //creaet query string
            const data = { ...this.controller.all() };
            for (let k in data) {
                if (isEmpty(data[k])) {
                    delete data[k];
                }
            }
            // console.log(data);
            this.props.history.push({
                search: "?" + new URLSearchParams(data).toString()
            });
            this.loadData();
        }, time);
    }

    render() {
        const controller = this.controller;
        const data = controller.all();
        let { activity_page, product_page } = data;
        const { activity, product, shop } = this.state;
        const prp = product?.response ?? {};
        const arp = activity?.response ?? {};

        return (
            <div className="section-content">
                <div className="section-market flex-1">

                    <ShopCover data={shop?.response} />

                    {/* {isMobile && <MarketFilter />} */}

                    <Label>{L('สินค้าของร้านค้า')}</Label>
                    <ProductList data={prp.list} path="market/product/" />
                    <div className="py-3">
                        <Pagination
                            id="pagination_product"
                            onChange={(name, value) => {
                                if (name === Pagination.PAGE_CHANGE) {
                                    if (value !== product_page) {
                                        // console.log(value);
                                        this.controller.set('product_page', value);
                                        this.search(0);
                                        System.scrollTo(0, 0);
                                    }
                                }
                            }}
                            data={{
                                pageSize: System.MAX_ROWS,
                                dataLength: prp.total_rows ?? 0,
                                currentPage: product_page,
                            }} />
                    </div>

                    <Label>{L('กิจกรรมของร้านค้า')}</Label>
                    <ActivityList data={arp.list} path="activity/" />
                    <div className="py-3">
                        <Pagination
                            id="pagination_activity"
                            onChange={(name, value) => {
                                if (name === Pagination.PAGE_CHANGE) {
                                    if (value !== activity_page) {
                                        // console.log(value);
                                        this.controller.set('activity_page', value);
                                        this.search(0);
                                        System.scrollTo(0, 0);
                                    }
                                }
                            }}
                            data={{
                                pageSize: System.MAX_ROWS,
                                dataLength: arp.total_rows ?? 0,
                                currentPage: activity_page,
                            }} />
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(ShopPage);
