import React, { PureComponent } from 'react';
import Avatar from './Avatar';

class DashboardScalar extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        const { value, label, description, icon, iconBackgroundColor } = this.props;
        return (
            <div className="dashboard-scalar p-2">
                {icon && <div className="pr-3">
                    <Avatar
                        src={icon}
                        iconFill={false}
                        iconWidth='24px'
                        iconHeight='24px'
                        backgroundColor={iconBackgroundColor}
                    />
                </div>}
                <div className="flex-1">
                    <div className="dashboard-scalar-value">{value}</div>
                    <div className="dashboard-scalar-label">{label}</div>
                    {description && <div className="dashboard-scalar-desc">{description}</div>}
                </div>
            </div>
        );
    }
}

export default DashboardScalar;
