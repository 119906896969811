import React, { Component } from 'react';

import chatIcon from '../assets/images/chat-icon.svg';
import bellIcon from '../assets/images/bell-icon.svg';
import loginIcon from '../assets/images/login-icon.svg';
import downIcon from '../assets/images/arrow-down-icon.svg';
import logoutIcon from '../assets/images/logout-icon.svg';

import System from '../models/System';
import Request from '../models/Request';
import MenuItem from './MenuItem';
import { get as L } from '../models/Label';
import Avatar from './Avatar';
import { Link } from 'react-router-dom';
import Controller from '../models/Controller';
import API from '../models/API';
import Event from '../models/Event';
import Inbox from '../models/Inbox';
import ChatContactItem from './ChatContactItem';
import Chat from '../models/Chat';

const menus = [
    { id: 'rmenu_1', label: L('สนทนา'), icon: chatIcon, popup: 'chat' },
    { id: 'rmenu_2', label: L('แจ้งเตือน'), icon: bellIcon, popup: 'notification' },
    { id: 'rmenu_3', label: L('รายการอื่นๆ'), icon: downIcon, popup: 'other' },
];

const login = { id: 'rmenu_4', label: L('เข้าสู่ระบบ'), icon: loginIcon, path: 'login' };

class HeaderRightSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            'rmenu_1': {
                response: [
                    { id: 'dummy_' + 1, type: 'dummy' },
                    { id: 'dummy_' + 2, type: 'dummy' },
                    { id: 'dummy_' + 3, type: 'dummy' }
                ],
            },
            'rmenu_2': {
                response: {
                    list: [
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                    ],
                }
            },
        };
        this.controller = new Controller(this, {});

    };

    componentDidMount() {
        if (System.isLogged()) {
            this.inboxEventId = Event.add(Event.INBOX_CHANGE, () => {
                // console.log('Event.INBOX_CHANGE');
                if (this.controller.isDestroyed !== true) {
                    this.controller.commit();
                }
            });
            this.chatEventId = Event.add(Event.CHAT_READ_CHANGE, () => {
                // console.log('Event.INBOX_CHANGE');
                if (this.controller.isDestroyed !== true) {
                    this.controller.commit();
                }
            });

            Inbox.loadUnreadCount();
            Chat.loadUnreadCount();

        }
    }

    componentWillUnmount() {
        this.controller.destroy();
        Event.remove(this.inboxEventId);
        Event.remove(this.inboxEventId);
    }

    async onItemClickHandler(item) {
        switch (item.id) {
            case 'rmenu_1':
                await this.setState({
                    'rmenu_1': {
                        response: [
                            { id: 'dummy_' + 1, type: 'dummy' },
                            { id: 'dummy_' + 2, type: 'dummy' },
                            { id: 'dummy_' + 3, type: 'dummy' }
                        ],
                    },
                });
                this.controller.loadData(API.API_CHAT, 'rmenu_1', { limit: 5, });
                break;
            case 'rmenu_2':
                await this.setState({
                    'rmenu_2': {
                        response: {
                            list: [
                                { id: 'dummy_' + 1, name: 'dummy' },
                                { id: 'dummy_' + 2, name: 'dummy' },
                                { id: 'dummy_' + 3, name: 'dummy' }
                            ],
                        }
                    },
                });
                this.controller.loadData(API.API_INBOX, 'rmenu_2', { page: 1, limit: 5, sorts: 'status:ASC,created_at:DESC', });
                break;
            default:
                break;
        }
    }

    onInboxItemClickHanler(item) {
        Inbox.read(item);
    }

    onChatItemClickHanler(item) {
        Chat.read(item);
    }

    getMenuItem(item, badges) {
        // console.log('getMenuItem', badges);
        let icon = item.icon;
        const el = <Avatar
            // key={'a_menu_' + item.id}
            className="menu-right pointer"
            src={icon}
            badges={badges}
            onMouseDown={this.onItemClickHandler.bind(this, item)}
        />
        if (!item.path) {
            return el;
        }
        return <Link to={System.resolvePath(item.path)}>{el}</Link>;
    }

    logout() {
        Request.pos('logout', null, () => { });
        System.logout();
    }

    render() {
        const logged = System.isLogged();
        const inboxCount = Inbox.unreadCount;
        const chatCount = Chat.unreadCount;
        const { rmenu_2, rmenu_1 } = this.state;
        // console.log(unreadCount);
        return (
            <div className="header-section-right">
                <div className="d-flex align-items-center h-100">
                    {logged
                        ? <>
                            <Link to={System.resolvePath('user/profile')}>
                                <MenuItem
                                    labelMargin="ml-2"
                                    iconFill
                                    src={System.profile.profile_image}
                                    label={System.profile.first_name} />
                            </Link>
                            <div className="dropdown mr-3">
                                <div
                                    className="menu-right-dropdown-toggle"
                                    id={menus[0].id} 
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                    {this.getMenuItem(menus[0], chatCount ?? 0)}
                                </div>
                                <div
                                    className="dropdown-menu"
                                    aria-labelledby={menus[0].id}
                                    style={{ minWidth: '360px' }}>
                                    {rmenu_1?.response && rmenu_1?.response.map((item, index) => <ChatContactItem
                                        key={'rmenu_1_' + index}
                                        data={item}
                                        onClick={this.onChatItemClickHanler.bind(this, item)}
                                    />)}
                                    <div className="line-h my-2" style={{ backgroundColor: '#DADADA' }}></div>
                                    <Link to={System.resolvePath('chat')}>
                                        <div className="menu-item w-100 text-center">{L('ดูทั้งหมดใน ห้องสนทนา')}</div>
                                    </Link>
                                </div>
                            </div>
                            <div className="dropdown mr-3">
                                <div
                                    className="menu-right-dropdown-toggle"
                                    id={menus[0].id}
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                    {this.getMenuItem(menus[1], inboxCount ?? 0)}
                                </div>
                                <div
                                    className="dropdown-menu"
                                    aria-labelledby={menus[1].id}
                                    style={{ minWidth: '400px' }}>
                                    {rmenu_2?.response?.list && rmenu_2?.response?.list.map((item, index) => {
                                        let icon = null;
                                        let path = null;
                                        if (item.shop) {
                                            icon = System.resolveImagePath(item.shop.logo, 'shop');
                                            if (item.order_id) {
                                                path = System.resolvePath('user/order/' + item.order_id);
                                            } else {
                                                if (item.product_id) {
                                                    path = System.resolvePath('market/product/' + item.product_id);
                                                }
                                            }
                                        } else if (item.user) {
                                            if (item.order_id) {
                                                path = System.resolvePath('user/customer/order/' + item.order_id);
                                            } else {
                                                if (item.product_id) {
                                                    path = System.resolvePath('market/product/' + item.product_id);
                                                }
                                            }
                                            icon = System.resolveImagePath(item.user.profile_image, 'profile');
                                        }
                                        // console.log(icon);
                                        return <MenuItem
                                            name={item.name}
                                            key={'rmenu_2_' + index}
                                            className="font-size-05"
                                            iconFill
                                            src={icon}
                                            label={item.title}
                                            sublabel={item.description}
                                            path={path}
                                            iconBackgroundColor="#F2F2F2"
                                            onClick={this.onInboxItemClickHanler.bind(this, item)}
                                        />
                                    })}
                                    <div className="line-h my-2" style={{ backgroundColor: '#DADADA' }}></div>
                                    <Link to={System.resolvePath('user/inbox')}>
                                        <div className="menu-item w-100 text-center">{L('ดูทั้งหมดใน กล่องข้อความ')}</div>
                                    </Link>
                                </div>
                            </div>
                            <div className="dropdown">
                                <div
                                    className="menu-right-dropdown-toggle"
                                    id={menus[0].id}
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                    {this.getMenuItem(menus[2])}
                                </div>
                                <div
                                    className="dropdown-menu"
                                    aria-labelledby={menus[2].id}
                                    style={{ minWidth: '250px' }}>
                                    <MenuItem
                                        iconFill
                                        src={System.profile.profile_image}
                                        label={System.profile.display_name}
                                        sublabel={L('ดูโปรไฟล์ของคุณ')}
                                        path={System.resolvePath('user/profile')}
                                    />
                                    <div className="line-h my-2" style={{ backgroundColor: '#DADADA' }}></div>
                                    <MenuItem
                                        src={logoutIcon}
                                        iconBackgroundColor="#F2F2F2"
                                        label={L('ออกจากระบบ')}
                                        onClick={() => {
                                            this.logout();
                                        }} />
                                </div>
                            </div>
                        </>
                        : <>{this.getMenuItem(login)}</>}
                </div>
            </div>
        );
    }
}

export default HeaderRightSection;
