import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { get as L } from '../models/Label';
import Pagination from '../components/Pagination';
import ProductList from '../components/ProductList';
import API from '../models/API';
import Controller from '../models/Controller';
import System from '../models/System';
import MarketLeftSection from '../components/MarketLeftSection';
import { isEmpty } from '../utils/UObj';
import MarketFilter from '../components/MarketFilter';

class MarketPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            product: {
                response: {
                    page: 1,
                    total_rows: 12,
                    list: [
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' }
                    ]
                }
            }
        };

        //init query string
        const params = new URLSearchParams(this.props.location.search);
        const formData = {
            search_string: params.get('search_string'),
            page: params.get('page') ?? 1,
            min_price: params.get('min_price'),
            max_price: params.get('max_price'),
            product_categories: params.get('product_categories')?.split(',') ?? [],
            rating: params.get('rating'),
            sort: params.get('sort'),
        };

        this.controller = new Controller(this, {
            search_columns: 'name,description',
            limit: 20,
            ...formData
            // min_price
            // max_price
            // product_categories
            // rating
            // sorts
            // search_columns
        });
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({
            product: {
                response: {
                    page: 1,
                    total_rows: this.total_rows ?? 12,
                    list: [
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' }
                    ]
                }
            }
        });
        const data = this.controller.all();
        const params = {};
        const props = [
            'page',
            'search_columns',
            'search_string',
            'product_categories',
            'min_price',
            'max_price',
            'rating',
            'sort',
        ];
        for (let k in props) {
            let key = props[k];
            if (!isEmpty(data[key])) {
                params[key] = data[key];
            }
        }
        const index = params['product_categories'].indexOf(-1);
        if (index !== -1 || params['product_categories'].length === 0) {
            delete params['product_categories'];
        }

        switch (params['sort']) {
            case 'sd': {
                params['sorts'] = 'sold:DESC';
                break;
            }
            case 'rd': {
                params['sorts'] = 'rating:DESC';
                break;
            }
            case 'pd': {
                params['sorts'] = 'price:DESC';
                break;
            }
            case 'pa': {
                params['sorts'] = 'price:ASC';
                break;
            }
        }
        delete params['sort'];

        params['limit'] = System.MAX_ROWS;

        this.controller.loadData(API.API_PRODUCT, 'product', params, (data) => {
            // console.log(data);
            this.total_rows = data['total_rows'];
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    onFilterChange(filter) {
        this.controller.set('page', 1);
        this.controller.append(filter);
        this.search();
    }

    search(time = 800) {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            // console.log('timer');
            this.timer = null;

            //creaet query string
            const data = { ...this.controller.all() };
            for (let k in data) {
                if (isEmpty(data[k])) {
                    delete data[k];
                }
            }
            // console.log(data);
            this.props.history.push({
                search: "?" + new URLSearchParams(data).toString()
            });
            this.loadData();
        }, time);
    }

    onFilterClick() {
        // console.log('onFilterClick');
        this.props.history.push({
            pathname: System.resolvePath('market/filter'),
            state: { ...this.controller.all() },
        });
    }

    render() {
        const controller = this.controller;
        const data = controller.all();
        let { page } = data;
        const { product } = this.state;
        const response = product?.response ?? {};
        const { list, total_rows } = response;

        return (
            <>
                {!isMobile && <MarketLeftSection data={data} onChange={this.onFilterChange.bind(this)} />}
                <div className="section-content">
                    <div className="section-market flex-1">

                        {isMobile && <MarketFilter
                            placeholder={L('ค้นหาสินค้า,ร้านค้า')}
                            onClick={this.onFilterClick.bind(this)}
                            searchString={controller.get('search_string')}
                            onChange={(value) => {
                                controller.set('page', 1);
                                controller.set('search_string', value);
                                this.search();
                            }}
                        />}

                        <ProductList data={list} path="market/product/" />
                        <div className="py-3">
                            <Pagination
                                id="pagination_product"
                                onChange={(name, value) => {
                                    if (name === Pagination.PAGE_CHANGE) {
                                        if (value !== page) {
                                            // console.log(value);
                                            this.controller.set('page', value);
                                            this.search(0);
                                            if (isMobile) {
                                                const root = document.getElementById('root');
                                                if (root) {
                                                    root.scrollTo(0, 0);
                                                }
                                            } else {
                                                System.scrollTo(0, 0);
                                            }
                                        }
                                    }
                                }}
                                data={{
                                    pageSize: System.MAX_ROWS,
                                    dataLength: total_rows ?? 0,
                                    currentPage: page,
                                }} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(MarketPage);
