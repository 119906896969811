import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import System from '../models/System';
import MenuItem from './MenuItem';
import { get as L } from '../models/Label';
import Label from './Label';
import Event from '../models/Event';
import Icon from '../models/Icon';
import { isMobile } from 'react-device-detect';
import Request from '../models/Request';

class HomeLeftSectionContent extends Component {

    constructor(props) {
        super(props);

        const isAdmin = System.isAdmin();

        if (!isAdmin) {
            this.mainMenus = System.userMenus;
        } else {
            this.mainMenus = [];
        }

        this.farmerMenus = [
            { id: 'item_menu_2', label: L('ฟาร์มและแปลงผลิต'), icon: 'farm', path: System.resolvePath('user/farm') },
            { id: 'item_menu_3', label: L('พืชและการเก็บเกี่ยว'), icon: 'plant', path: System.resolvePath('user/field') },
            // { id: 'item_menu_2', label: L('ฤดูกาลเก็บเกี่ยวผลผลิต'), icon: harvestIcon },
        ];

        this.merchantMenus = [
            { id: 'item_menu_01', label: L('แดชบอร์ด'), icon: 'activity', path: System.resolvePath('user/dashboard') },
            { id: 'item_menu_0', label: L('รายการสั่งซื้อสินค้า'), icon: 'order', path: System.resolvePath('user/customer/order') },
            { id: 'item_menu_4', label: L('ร้านค้า'), icon: 'market', path: System.resolvePath('user/shop') },
            { id: 'item_menu_5', label: L('ผลิตภัณฑ์และสินค้า'), icon: 'product', path: System.resolvePath('user/product') },
            { id: 'item_menu_6', label: L('กิจกรรมและบริการ'), icon: 'activity', path: System.resolvePath('user/activity') },
        ];

        this.adminMenus = [
            { id: 'item_menu_26', label: L('แดชบอร์ด'), icon: 'activity', path: System.resolvePath('user/dashboard') },
            { id: 'item_menu_27', label: L('ผู้ใช้งาน'), icon: 'user', path: System.resolvePath('admin/user') },
            { id: 'item_menu_28', label: L('โพสต์'), icon: 'news', path: System.resolvePath('user/feed') },
            { id: 'item_menu_21', label: L('ข่าวสาร'), icon: 'news', path: System.resolvePath('admin/news') },
            { id: 'item_menu_22', label: L('ชนิดพันธุ์พืช'), icon: 'farm', path: System.resolvePath('admin/plant') },
            { id: 'item_menu_22', label: L('กลุ่มพันธุ์พืช'), icon: 'plant', path: System.resolvePath('admin/plant-group') },
            { id: 'item_menu_23', label: L('ประเภทสินค้า'), icon: 'product', path: System.resolvePath('admin/product-category') },
            { id: 'item_menu_24', label: L('ประเภทบริการ'), icon: 'activity', path: System.resolvePath('admin/activity-service') },
            { id: 'item_menu_25', label: L('หน่วยสินค้า'), icon: 'activity', path: System.resolvePath('admin/unit') },
        ];

        if (!isAdmin) {
            this.otherMenus = [
                { id: 'item_menu_7', label: L('เพื่อน'), icon: 'friend' },
                { id: 'item_menu_8', label: L('สนทนา'), icon: 'chat' },
                { id: 'item_menu_9', label: L('ตั้งค่า'), icon: 'setting' }
            ];
        } else {
            this.otherMenus = [
                { id: 'item_menu_8', label: L('สนทนา'), icon: 'chat' },
                { id: 'item_menu_9', label: L('ตั้งค่า'), icon: 'setting' }
            ];
        }
        // console.log('HomeLeftSection constructor');
    };

    cartChangeHandler(value) {
        const isAdmin = System.isAdmin();
        if (isAdmin) {
            return;
        }
        this.mainMenus = System.userMenus;
        // console.log(this.mainMenus);
        this.setState({});
    }

    componentDidMount() {
        this.cartEventId = Event.add(Event.CART_CHANGE, this.cartChangeHandler.bind(this));
    }

    componentWillUnmount() {
        Event.remove(this.cartEventId);
    }

    userClick() {
        if (System.isLogged()) {
            this.props.history.push(System.resolvePath('user/profile'));
        } else {
            this.props.history.push(System.resolvePath('login'));
        }
    }

    getItem() {

    }

    render() {
        // console.log('HomeLeftSection render');
        const logged = System.isLogged();
        const isFarmer = System.isFarmer();
        const isAdmin = System.isAdmin();
        // console.log(farmerMenus);
        return (
            <Fragment key={'h_l_s_c_f'}>
                <div className="px-3 pb-3">
                    <MenuItem
                        iconFill={logged}
                        src={logged ? System.profile.profile_image : Icon.get('login')}
                        label={logged ? System.profile.display_name : L('เข้าสู่ระบบ')}
                        onClick={() => this.userClick()} />
                    {this.mainMenus.map((value) => {
                        if (!logged && value.login) {
                            return null;
                        }
                        return <MenuItem key={value.id} src={Icon.get(value.icon)} label={value.label} path={value.path} badges={value.badges} />
                    })}
                </div>

                {isFarmer && <>
                    <div className="line-h mb-3"></div>
                    <div className="px-3 pb-3">
                        <Label>{L('สินค้าและบริการ')}</Label>
                        {this.merchantMenus.map((value) => <MenuItem key={value.id} src={Icon.get(value.icon)} label={value.label} path={value.path} badges={value.badges} />)}
                    </div>
                    <div className="line-h mb-3"></div>
                    <div className="px-3 pb-3">
                        <Label>{L('ข้อมูลเกษตรกร')}</Label>
                        {this.farmerMenus.map((value) => <MenuItem key={value.id} src={Icon.get(value.icon)} label={value.label} path={value.path} badges={value.badges} />)}
                    </div>
                </>}

                {/* Addmin */}
                {isAdmin && <>
                    <div className="line-h mb-3"></div>
                    <div className="px-3 pb-3">
                        <Label>{L('จัดการระบบ')}</Label>
                        {this.adminMenus.map((value) => <MenuItem key={value.id} src={Icon.get(value.icon)} label={value.label} path={value.path} badges={value.badges} />)}
                    </div>
                </>}

                {/* {logged && <>
                        <div className="line-h mb-3"></div>
                        <div className="px-3 pb-3">
                            <Label>{L('อื่นๆ')}</Label>
                            {this.otherMenus.map((value) => <MenuItem key={value.id} src={Icon.get(value.icon)} label={value.label} path={value.path} badges={value.badges} />)}
                        </div>
                    </>} */}


                {(logged && isMobile) && <>
                    <div className="line-h mb-3"></div>
                    <div className="px-3 pb-3">
                        <MenuItem src={Icon.get('logout')} label={L('ออกจากระบบ')} onClick={() => {
                            Request.pos('logout', null, () => { });
                            System.logout();
                        }} />
                    </div>
                </>}

            </Fragment>
        );
    }
}

export default withRouter(HomeLeftSectionContent);
