import React, { PureComponent } from 'react';
import System from '../models/System';
import Avatar from './Avatar';
import LinkIcon from './LinkIcon';
import { get as L } from '../models/Label';
import chatIcon from '../assets/images/chat-icon.svg';
import shopIcon from '../assets/images/shop-icon-black.svg';

class ShopItem extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        const { data, showShop, showChat, clip } = this.props;

        let subLabelClass = '';
        if (clip !== false) {
            subLabelClass = 'label-clip'
        }
        // console.log(data);

        const _showChat = showChat !== false && data?.user_id && parseInt(System.profile?.id) !== parseInt(data?.user_id);
        const _showShop = showShop !== false;

        return (
            <div className="form-row">
                <div className="col-auto mr-2">
                    <Avatar
                        src={System.resolveImagePath(data?.logo, 'shop')}
                        iconFill
                        style={{ width: '64px', height: '64px' }} />
                </div>

                <div className="col">
                    <div className="list-tile-label">{data?.name}</div>
                    <div className={'list-tile-sublabel ' + subLabelClass}>{data?.description}</div>
                </div>

                <div className="form-row col-12 col-md-auto mt-3 mt-md-0">
                    {/* check is owner */}
                    {_showChat && <div className="auto">
                        <LinkIcon to={System.resolvePath('chat/user/' + data?.user_id)} icon={chatIcon}>{L('แชท')}</LinkIcon>
                    </div>}
                    {_showShop && <div className="auto">
                        <LinkIcon to={System.resolvePath('shop/' + data?.id)} icon={shopIcon} className="ml-3">{L('ร้านค้า')}</LinkIcon>
                    </div>}
                </div>

            </div>
        );
    }
}

export default ShopItem;
