import React, { Component } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';

import {
    isBrowser
} from "react-device-detect";

import { get as L } from '../models/Label';

import Header from '../components/Header';
import MHeader from '../components/MHeader';
import Left from '../components/Left';
import Right from '../components/Right';
import Footer from '../components/Footer';
import Event from '../models/Event';
import System from '../models/System';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import UserFarmPage from './UserFarmPage';
import HomePage from './HomePage';
import NewsPage from './NewsPage';
import MarketPage from './MarketPage';
import ActivityPage from './ActivityPage';
import ButtomNavigator from '../components/ButtomNavigator';
import OtherPage from './OtherPage';
import Controller from '../models/Controller';
import UserProfilePage from './UserProfilePage';
import UserFieldPage from './UserFieldPage';
import UserPlantPage from './UserPlantPage';
import AdminUserPage from './AdminUserPage';
import UserShopFormPage from './UserShopFormPage';
import UserProductPage from './UserProductPage';
import UserProductFormPage from './UserProductFormPage';
import LogisticsPage from './LogisticsPage';
import API from '../models/API';
import ProductPage from './ProductPage';
import UserCartPage from './UserCartPage';
import UserOrderPage from './UserOrderPage';
import UserOrderDetailPage from './UserOrderDetailPage';
import UserLogisticsListPage from './UserLogisticsListPage';
import UserLogisticsFormPage from './UserLogisticsFormPage';
import ShopPage from './ShopPage';
import UserInboxPage from './UserInboxPage';
import ProductReviewFormPage from './ProductReviewFormPage';
import UserActivityFormPage from './UserActivityFormPage';
import UserActivityPage from './UserActivityPage';
import ActivityDetailPage from './ActivityDetailPage';
import AdminPlantPage from './AdminPlantPage';
import AdminPlantFormPage from './AdminPlantFormPage';
import AdminPlantGroupPage from './AdminPlantGroupPage';
import AdminPlantGroupFormPage from './AdminPlantGroupFormPage';
import AdminProductCategoryPage from './AdminProductCategoryPage';
import AdminProductCategoryFormPage from './AdminProductCategoryFormPage';
import AdminActivityServicePage from './AdminActivityServicePage';
import AdminActivityServiceFormPage from './AdminActivityServiceFormPage';
import AdminNewsPage from './AdminNewsPage';
import AdminNewsFormPage from './AdminNewsFormPage';
import NewDetailPage from './NewDetailPage';
import ResetPasswordPage from './ResetPasswordPage';
import ForgetPasswordPage from './ForgetPasswordPage';
import ChatPage from './ChatPage';
import FeedFormPage from './FeedFormPage';
import TagListPage from './TagListPage';
import UserFeedPage from './UserFeedPage';
import UserDashboardPage from './UserDashboardPage';
import MarketFilterPage from './MarketFilterPage';
import ActivityFilterPage from './ActivityFilterPage';
import AdminUnitFormPage from './AdminUnitFormPage';
import AdminUnitPage from './AdminUnitPage';
import ChatContactPage from './ChatContactPage';

class MainPage extends Component {

    constructor(props) {
        super(props);
        this.history = createHistory();

        const self = this;
        if (this.history) {
            this.unlisten = this.history.listen(location => {
                self.validatePathName(location.pathname);
            });
        }

        this.state = {
            path: this.history.location.pathname,
        };

        this.noHeaderMap = {
            [System.resolvePath('login')]: 1,
            [System.resolvePath('register')]: 1,
            [System.resolvePath('reset-password')]: 1,
            [System.resolvePath('forget-password')]: 1,
        };

        this.showBottomNavMap = {
            [System.resolvePath('')]: 1,
            [System.resolvePath('home')]: 1,
            [System.resolvePath('news')]: 1,
            [System.resolvePath('shop')]: 1,
            [System.resolvePath('market')]: 1,
            [System.resolvePath('activity')]: 1,
            [System.resolvePath('other')]: 1,
            [System.resolvePath('user/feed')]: 1,
        };

        this.controller = new Controller(this, {});
    };

    componentDidMount() {
        this.loggedEvent = Event.add(Event.LOGIN, () => {
            // console.log('MainPage loggedEvent');
            if (!this.loadProfile()) {
                this.setState({});
            }
        });
        this.loadProfile();
    }

    loadProfile() {
        if (System.isLogged()) {
            this.controller.loadData(API.API_PROFILE, 'profile', null, (data) => {
                System.setProfile(data);
            }, (error) => {
                API.logout();
            });
            return true;
        }
        return false;
    }

    componentWillUnmount() {
        Event.remove(this.loggedEvent);
        this.controller.destroy();
    }

    validatePathName(path) {
        // console.log(path);
        if (!path) {
            return;
        }
        this.setState({ path });
    }

    render() {
        let { path, profile } = this.state;
        if (System.isLogged()) {
            if (!profile || profile.loading) {
                // console.log('MainPage render loading');
                return null;
            }
        } else {

        }
        // console.log('MainPage render loaded');
        // console.log(System.publicPath);
        // console.log(path);

        const isShowHeader = this.noHeaderMap[path] !== 1;
        const isShowBottomNav = this.showBottomNavMap[path] === 1;
        // const isShowBottomNav = true;

        // console.log('isShowHeader,isBrowser', isShowHeader, isBrowser);
        return <Router history={this.history}>

            {isShowHeader ? (isBrowser ? <Header path={path} /> : <MHeader path={path} />) : null}

            <div className="main" style={!isShowHeader ? { paddingTop: 0 } : null}>

                {isBrowser && <Left key="left_section" path={path} />}

                <Switch>
                    <Route path={System.resolvePath('user/dashboard')} exact={true} component={UserDashboardPage} />
                    <Route path={System.resolvePath('user/feed')} exact={true} component={UserFeedPage} />
                    <Route path={System.resolvePath('user/feed/create')} exact={true} component={FeedFormPage} />
                    <Route path={System.resolvePath('user/feed/:feedId([0-9]+)')} exact={true} component={FeedFormPage} />
                    <Route path={System.resolvePath('user/feed/create/tag')} exact={true} component={TagListPage} />
                    <Route path={System.resolvePath('user/feed/:feedId([0-9]+)/tag')} exact={true} component={TagListPage} />
                    <Route path={System.resolvePath('news')} exact={true} component={NewsPage} />
                    <Route path={System.resolvePath('news/:newsId([0-9]+)')} exact={true} component={NewDetailPage} />
                    <Route path={System.resolvePath('market')} exact={true} component={MarketPage} />
                    <Route path={System.resolvePath('market/product/:productId([0-9]+)')} exact={true} component={ProductPage} />
                    <Route path={System.resolvePath('market/product/:productId([0-9]+)/review/create')} exact={true} component={ProductReviewFormPage} />
                    <Route path={System.resolvePath('market/product/:productId([0-9]+)/review/:reviewId([0-9]+)')} exact={true} component={ProductReviewFormPage} />
                    <Route path={System.resolvePath('market/filter')} exact={true} component={MarketFilterPage} />
                    <Route path={System.resolvePath('shop/:shopId([0-9]+)')} component={ShopPage} />
                    <Route path={System.resolvePath('activity')} exact={true} component={ActivityPage} />
                    <Route path={System.resolvePath('activity/:activityId([0-9]+)')} exact={true} component={ActivityDetailPage} />
                    <Route path={System.resolvePath('activity/:productId([0-9]+)/review/create')} exact={true} component={ProductReviewFormPage} />
                    <Route path={System.resolvePath('activity/:productId([0-9]+)/review/:reviewId([0-9]+)')} exact={true} component={ProductReviewFormPage} />
                    <Route path={System.resolvePath('activity/filter')} exact={true} component={ActivityFilterPage} />
                    <Route path={System.resolvePath('chat/user/:userId([0-9]+)')} exact={true} component={ChatPage} />
                    <Route path={System.resolvePath('chat/contact')} exact={true} component={ChatContactPage} />
                    <Route path={System.resolvePath('chat/')} exact={true} component={ChatPage} />
                    <Route path={System.resolvePath('user/inbox')} exact={true} component={UserInboxPage} />
                    <Route path={System.resolvePath('user/cart')} exact={true} component={UserCartPage} />
                    <Route path={System.resolvePath('user/cart/location')} exact={true} component={UserLogisticsListPage} />
                    <Route path={System.resolvePath('user/cart/location/:locationId([0-9]+)')} exact={true} component={UserLogisticsFormPage} />
                    <Route path={System.resolvePath('user/cart/location/create')} exact={true} component={UserLogisticsFormPage} />
                    <Route path={System.resolvePath('user/order')} exact={true} component={UserOrderPage} key="merchant_order" />
                    <Route path={System.resolvePath('user/order/:orderId([0-9]+)')} exact={true} key="merchant_order_detail" component={UserOrderDetailPage} />
                    <Route path={System.resolvePath('user/customer/order')} exact={true} component={UserOrderPage} />
                    <Route path={System.resolvePath('user/customer/order/:orderId([0-9]+)')} exact={true} component={UserOrderDetailPage} />
                    <Route path={System.resolvePath('user/farm')} exact={true} component={UserFarmPage} />
                    <Route path={System.resolvePath('user/field')} exact={true} component={UserFieldPage} />
                    <Route path={System.resolvePath('user/field/:fieldId([0-9]+)/plant')} exact={true} component={UserPlantPage} />
                    <Route path={System.resolvePath('user/field/:fieldId([0-9]+)/plant/:plantId([0-9]+)')} exact={true} component={UserPlantPage} />
                    <Route path={System.resolvePath('user/shop')} exact={true} component={UserShopFormPage} />
                    <Route path={System.resolvePath('user/activity')} exact={true} component={UserActivityPage} />
                    <Route path={System.resolvePath('user/activity/:activityId([0-9]+)')} exact={true} component={UserActivityFormPage} />
                    <Route path={System.resolvePath('user/activity/create')} exact={true} component={UserActivityFormPage} />
                    <Route path={System.resolvePath('user/product/:productId([0-9]+)')} exact={true} component={UserProductFormPage} />
                    <Route path={System.resolvePath('user/product/create')} exact={true} component={UserProductFormPage} />
                    <Route path={System.resolvePath('user/product/create/logistics')} exact={true} component={LogisticsPage} />
                    <Route path={System.resolvePath('user/product/:productId([0-9]+)/logistics')} exact={true} component={LogisticsPage} />
                    <Route path={System.resolvePath('user/product')} exact={true} component={UserProductPage} />
                    <Route path={System.resolvePath('user/profile')} exact={true} component={UserProfilePage} />
                    <Route path={System.resolvePath('other')} exact={true} component={OtherPage} />

                    <Route path={System.resolvePath('login')} component={LoginPage} />
                    <Route path={System.resolvePath('register')} component={RegisterPage} />
                    <Route path={System.resolvePath('reset-password')} component={ResetPasswordPage} />
                    <Route path={System.resolvePath('forget-password')} component={ForgetPasswordPage} />

                    <Route path={System.resolvePath('admin/user')} exact={true} component={AdminUserPage} />
                    <Route path={System.resolvePath('admin/news')} exact={true} component={AdminNewsPage} />
                    <Route path={System.resolvePath('admin/news/create')} exact={true} component={AdminNewsFormPage} />
                    <Route path={System.resolvePath('admin/news/:newId([0-9]+)')} exact={true} component={AdminNewsFormPage} />
                    <Route path={System.resolvePath('admin/plant')} exact={true} component={AdminPlantPage} />
                    <Route path={System.resolvePath('admin/plant/:plantId([0-9]+)')} exact={true} component={AdminPlantFormPage} />
                    <Route path={System.resolvePath('admin/plant/create')} exact={true} component={AdminPlantFormPage} />
                    <Route path={System.resolvePath('admin/plant-group')} exact={true} component={AdminPlantGroupPage} />
                    <Route path={System.resolvePath('admin/plant-group/:plantId([0-9]+)')} exact={true} component={AdminPlantGroupFormPage} />
                    <Route path={System.resolvePath('admin/plant-group/create')} exact={true} component={AdminPlantGroupFormPage} />
                    <Route path={System.resolvePath('admin/product-category')} exact={true} component={AdminProductCategoryPage} />
                    <Route path={System.resolvePath('admin/product-category/:catId([0-9]+)')} exact={true} component={AdminProductCategoryFormPage} />
                    <Route path={System.resolvePath('admin/product-category/create')} exact={true} component={AdminProductCategoryFormPage} />
                    <Route path={System.resolvePath('admin/activity-service')} exact={true} component={AdminActivityServicePage} />
                    <Route path={System.resolvePath('admin/activity-service/:serviceId([0-9]+)')} exact={true} component={AdminActivityServiceFormPage} />
                    <Route path={System.resolvePath('admin/activity-service/create')} exact={true} component={AdminActivityServiceFormPage} />
                    <Route path={System.resolvePath('admin/unit')} exact={true} component={AdminUnitPage} />
                    <Route path={System.resolvePath('admin/unit/:unitId([0-9]+)')} exact={true} component={AdminUnitFormPage} />
                    <Route path={System.resolvePath('admin/unit/create')} exact={true} component={AdminUnitFormPage} />

                    <Route path={System.publicPath} exact={true} component={HomePage} />

                    <Redirect to={System.publicPath} />
                </Switch>

                {isBrowser && <Right key="right_section" path={path} />}

            </div>

            {(isShowBottomNav && !isBrowser) && <ButtomNavigator key="nav_" path={path} />}

            {/* <Footer /> */}

            <div id="errorModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{L('แจ้งเตือน')}</h5>
                        </div>
                        <div className="modal-body">
                            <p id="errorModalContent"></p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary mr-3" data-dismiss="modal">{L('ปิด')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Router>;
    }
}

export default MainPage;
