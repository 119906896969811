import System from "./System";
// import Request from "./Request";

const en = {
}

const labelMap = {
  en,
};

export function get(s) {
  // console.log(s);
  //send to save to db
  // Request.posData('translators', { 'thai': s });
  if (labelMap[System.lang] && labelMap[System.lang][s]) {
    return labelMap[System.lang][s];
  }
  return s;
}

const labels = {
  get,
}

export default labels;