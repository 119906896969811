import moment from "moment";
import Avatar from "../components/Avatar";
import System from "../models/System";
import { getDateString } from "./UDate";
import { nf } from "./UObj";

export const renderFullName = (row, col, i, j) => {
    return (row['title_name'] ? row['title_name'] + ' ' : '') + row['first_name'] + (row['last_name'] ? ' ' + row['last_name'] : '');
}

export const renderAddress = (row, col, i, j) => {
    const location = row.location;
    if (!location) {
        return '';
    }
    return location['district'] + ' ' + location['province'];
}

export const renderProfile = (row, col, i, j) => {
    return <Avatar src={row.profile_image} iconFill={true} iconStyle={avatarSize} />;
}

export const renderImage = (row, col, i, j) => {
    const image = row.thumbnail?.image ? row.thumbnail.image : row.thumbnail;
    return <img src={System.resolveThumnailPath(image, col.path)} className="image-cover" style={col.imageSize} />;
}

export const renderIndex = (row, col, i, j) => {
    return i+1;
}

export const renderDate = (row, col, i, j) => {
    const key = col.data;
    const dateOption = col.dateOption;
    // console.log(row[key], dateOption);
    return getDateString(moment(row[key]), dateOption);
}

export const genDummyCells = (rowNumber, data, cols) => {
    const len = data.length;
    for (let i = len; i < rowNumber; i++) {
        data[i] = { id: 'dummy' };
    }
    return data;
}

export const renderNumberFormat = (row, col, i, j) => {
    const key = col.data;
    return nf(row[key]);
}

const avatarSize = { width: '32px', height: '32px' };