import React, { Component } from 'react';
import ErrorText from './ErrorText';

class InputDropListBox extends Component {

    constructor(props) {
        super(props);
        if (this.props.name) {
            this.id = 'input_' + this.props.name + '_' + (this.props.index ?? '');
        } else {
            this.id = 'input_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000);
        }
    };

    onChange(e) {
        if (this.props.controller) {
            // console.log(e.target);
            // console.log('InputBox onChange:' + this.props.index + ' =>', this.props.name, e.target.value);
            //check nestest
            const tmp = this.props.name.split('.');
            if (tmp && tmp[1]) {
                const parent = this.props.controller.get(tmp[0]);
                parent[this.props.index][tmp[1]] = e.target.value;
                this.props.controller.set(tmp[0], parent, true);
                // console.log('InputBox after changed =>', this.props.name, this.props.controller.get(tmp[0])[this.props.index][tmp[1]]);
            } else {
                this.props.controller.set(this.props.name, e.target.value, true);
                // console.log('InputBox after changed =>', this.props.name, this.props.controller.get(this.props.name));
            }
        } else {
            if (this.props.onChange) {
                this.props.onChange(e);
            }
        }
    }

    render() {
        const { data } = this.props;
        let label;
        let value;

        const content = <>
            <select
                className="form-control"
                id={this.id}
                placeholder={this.props.placeholder}
                value={this.props.controller ? this.props.controller.get(this.props.name) : this.props.value}
                onChange={(e) => this.onChange(e)}
            >
                {this.props.placeholder && <option value=''>{this.props.placeholder}</option>}
                {data && data.map((v, i) => {
                    label = v['label'] ?? (v['name'] ?? v);
                    value = v['id'] ?? v;
                    return <option
                        key={label}
                        value={value}>{label}</option>
                })}
            </select>
            <ErrorText isError={this.props.isError}>{this.props.errorMessage}</ErrorText>
        </>;

        if (!this.props.label) {
            return content;
        }

        return (
            <div className="form-group">
                <label htmlFor={this.id}>{this.props.label}</label>
                {content}
            </div>
        );
    }
}

export default InputDropListBox;
