import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';
import StarRatings from 'react-star-ratings';

import InputImageBox from '../components/InputImageBox';
import Controller from '../models/Controller';
import Form from '../components/Form';
import Button from '../components/Button';

import { validateEmptyValue, validateNumber } from '../utils/UValidate';
import { isEmpty } from '../utils/UObj';

import { isMobile } from 'react-device-detect';
import AppBar from '../components/AppBar';
import System from '../models/System';
import TextAreaBox from '../components/TextAreaBox';
import API from '../models/API';
import ErrorText from '../components/ErrorText';

class ProductReviewFormPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            valid: {},
        };

        // console.log(this.props);

        const formData = this.props.location?.formData;
        // console.log(formData);
        this.controller = new Controller(this, {
            images: [],
            status: 1,
            ...formData
        });

        //check id
        this.isActivity = false;
        this.redirectPath = 'market';
        let _path = System.matchPath(this.props.location.pathname, 'market/product/:productId/review/:reviewId');
        if (!_path) {
            _path = System.matchPath(this.props.location.pathname, 'activity/:productId/review/:reviewId');
            this.isActivity = true;
        }

        if (!System.isLogged() || !_path) {
            this.isError = true;
            this.props.history.replace(System.resolvePath(this.redirectPath));
            return;
        }

        this.productId = validateNumber(_path.params.productId);
        if (!this.productId) {
            this.props.history.replace(System.resolvePath(this.redirectPath));
            return;
        }
        this.id = validateNumber(_path.params.reviewId);
        if (this.id) {
            this.isEdit = true;
        }

        this.qualities = [
            { id: 1, name: 'สินค้าใหม่' },
            { id: 2, name: 'สินค้ามือสอง' },
        ];

    };

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        if (!this.isEdit) {
            return;
        }

        this.controller.loadData(API.API_PRODUCT + '/' + this.productId + '/reviews/' + this.id, 'review', null, (data) => {
            if (!data) {
                this.isError = true;
                this.props.history.replace(System.resolvePath('market'));
                return;
            }
            const formData = {
                images: data['images'],
                name: data['name'],
                description: data['description'],
                rating: data['rating'],
            };
            this.controller.append(formData);
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    async delete() {
        await this.setState({ openDeleteDialog: true });
        window.openModalDialog('deleteModal', () => {
            this.setState({ openDeleteDialog: false });
        });
    }

    confirmDelete() {
        this.controller.deleteData(API.API_USER_PRODUCT + '/' + this.id,
            (data) => {
                window.hideModalDialog('deleteModal');
                this.setState({ openDeleteDialog: false });
                this.props.history.goBack();
            },
            (error) => {
                window.openMessageModalDialog(error);
            });
    }

    async submit() {
        this.setState({ valid: {} });

        const c = this.controller;
        const params = { ...c.all() };

        const rating = params['rating'] ?? '';
        const description = params['description'] ?? '';
        const images = params['images'] ?? [];

        const valid = {
            rating: validateNumber(rating) > 0,
            description: validateEmptyValue(description),
        };
        // console.log(valid);

        for (let k in valid) {
            if (valid[k] === false) {
                this.setState({ valid });
                return;
            }
            if (Array.isArray(valid[k])) {
                for (let i in valid[k]) {
                    if (valid[k][i] === false) {
                        this.setState({ valid });
                        return;
                    }
                }
            }
        }

        let i = 0;

        // console.log(images);
        let file, blob;
        i = 0;
        for (let k in images) {
            if (!isEmpty(images[k]) && typeof images[k] === 'object') {
                continue;
            }
            blob = await fetch(images[k]).then(r => r.blob());
            file = new File([blob], 'image_' + i + '.jpg', { type: 'image/jpg' });
            params['image_' + i] = file;
            i++;
        }
        if (params['remove_images']) {
            params['remove_images'] = params['remove_images'].toString();
        }
        delete params['images'];

        const formData = new FormData();
        let value;
        for (let key in params) {
            value = params[key];
            if (isEmpty(value)) {
                continue;
            }
            formData.append(key, value);
        }

        if (this.isEdit) {
            this.controller.putMultipathData(API.API_PRODUCT + '/' + this.productId + '/reviews/' + this.id, formData,
                (data) => {
                    this.props.history.goBack();
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        } else {
            this.controller.postMultipathData(API.API_PRODUCT + '/' + this.productId + '/reviews', formData,
                (data) => {
                    this.props.history.goBack();
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        }

    }

    render() {
        if (this.isError) {
            return null;
        }
        // console.log('UserShopFormPage render');
        const c = this.controller;
        const { rating } = c.all();
        const { valid, post, openDeleteDialog, destroy } = this.state;
        const loading = (post && post.loading) || (destroy && destroy.loading);
        const ac = this.isActivity;
        const type = ac ? 'กิจกรรม' : 'สินค้า';
        const placeholder = ac ? 'คำติชม, การให้บริการ, เกี่ยวกับสถานที่' : 'คำติชม, การบริการจัดส่ง, ปัญหาของสินค้า';
        return (
            <div className="section-content no-nav">
                <Form
                    loading={post.loading}
                    className="form-register">
                    <AppBar
                        title={L('แสดงความคิดเห็น')}
                        subTitle={L('กรอกข้อมูลความคิดเห็นเกี่ยวกับ' + type)}
                        showLeftArrow={true}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <InputImageBox
                                path="review"
                                name="images"
                                description={L('รูปภาพ: กรุณาใช้รูปภาพสินค้าที่เห็นชัดเจน อัพได้ไม่เกิน 5 รูป')}
                                max={10}
                                controller={c}
                                label={L('รูปประกอบความคิดเห็น*')} />
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label>{L('คะแนน' + type)}</label>
                                <div>
                                    <StarRatings
                                        starDimension="32px"
                                        starSpacing="10px"
                                        rating={parseFloat(rating ?? 0)}
                                        starRatedColor="#ffce3d"
                                        numberOfStars={5}
                                        changeRating={(value) => c.set('rating', value, true)}
                                    />
                                    <ErrorText isError={valid.raing === false}>{L('กรุณาให้คะแนน' + type)}</ErrorText>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <TextAreaBox
                                label={L('ความคิดเห็น*')}
                                placeholder={L(placeholder)}
                                name="description"
                                rows={5}
                                controller={this.controller}
                                isError={valid.description === false}
                                errorMessage={L('กรุณากรอกคำอธิบาย' + type)} />
                        </div>
                    </div>

                    <div className="form-row mt-4">
                        {this.isEdit && <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => this.delete()}>
                                {L('ลบข้อมูล')}
                            </Button>
                        </div>}
                        <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.submit()}>
                                {L('บันทึกข้อมูล')}
                            </Button>
                        </div>
                    </div>

                </Form>

                {openDeleteDialog && <div id="deleteModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('ลบข้อมูล')}</h5>
                            </div>
                            <div className="modal-body">
                                <p>{L('คุณต้องการลบข้อมูลนี้ใช่หรือไม่')}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-3"
                                    onClick={() => {
                                        window.hideModalDialog('deleteModal');
                                        this.setState({ openDeleteDialog: false });
                                    }}>{L('ยกเลิก')}</button>
                                <button
                                    type="button"
                                    className="btn btn-primary mr-3"
                                    onClick={() => {
                                        this.confirmDelete();
                                    }}>{L('ลบข้อมูล')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}

export default withRouter(ProductReviewFormPage);
