import Request from './Request';

export default class Controller {

    constructor(instance, props) {
        this.instance = instance;
        this.props = props ?? {};
        if (instance) {
            instance.state = {
                ...instance.state,
                ...props,
                post: {
                    loading: false,
                    success: false,
                    response: null,
                },
                update: {
                    loading: false,
                    success: false,
                    response: null,
                },
                destroy: {
                    loading: false,
                    success: false,
                    response: null,
                },
            };
        }
    }

    set(key, value, render = false) {
        this.props[key] = value;
        if (render !== false) {
            this.instance.setState({ [key]: value });
        }
    }

    append(data, render = false) {
        this.props = { ...this.props, ...data };
        if (this.instance) {
            this.instance.state = {
                ...this.instance.state,
                ...this.props,
            }
        }
        if (render !== false) {
            this.instance.setState({});
        }
    }

    get(key) {
        return this.props[key];
    }

    all() {
        return this.props;
    }

    reset(render) {
        this.props = {};
        if (render === true) {
            this.instance.setState({});
        }
    }

    commit() {
        this.instance.setState({});
    }

    destroy() {
        this.isDestroyed = true;
    }

    loadData(path, prop, param = null, callback, errorCallback) {
        if (!param || param.preload !== false) {
            this.instance.setState({ [prop]: { ...this.instance.state[prop], loading: true, success: false, loaded: false } });
        }
        if (path) {
            Request.get(path,
                param,
                (data) => {
                    if (this.isDestroyed) {
                        return;
                    }
                    if (callback) {
                        callback(data, prop);
                    }
                    this.instance.setState({ [prop]: { ...this.instance.state[prop], response: data, loading: false, success: true, loaded: true } });
                },
                (error) => {
                    if (this.isDestroyed) {
                        return;
                    }
                    if (errorCallback) {
                        errorCallback(error, prop);
                    }
                    this.instance.setState({ [prop]: { ...this.instance.state[prop], response: null, loading: false, success: false, loaded: true } });
                });
        }
    }

    unloadData(props) {
        const data = {};
        for (let key in props) {
            data[props[key]] = null;
        }
        // console.log(data);
        this.instance.setState({ ...data });
    }

    postData(path, callback, errorCallback) {
        this.instance.setState({ post: { ...this.instance.state.post, loading: true, success: false } });
        if (path) {
            Request.pos(path,
                this.props,
                (data) => {
                    if (this.isDestroyed) {
                        return;
                    }
                    if (callback) {
                        callback(data);
                    }
                    this.instance.setState({ post: { ...this.instance.state.post, response: data, loading: false, success: true } });
                },
                (error) => {
                    if (this.isDestroyed) {
                        return;
                    }
                    if (errorCallback) {
                        errorCallback(error);
                    }
                    this.instance.setState({ post: { ...this.instance.state.post, response: null, loading: false, success: false } });
                });
        }
    }

    postMultipathData(path, formData, callback, errorCallback) {
        this.instance.setState({ post: { ...this.instance.state.post, loading: true, success: false } });
        if (path) {
            Request.multiPart(path,
                formData,
                (data) => {
                    if (this.isDestroyed) {
                        return;
                    }
                    if (callback) {
                        callback(data);
                    }
                    this.instance.setState({ post: { ...this.instance.state.post, response: data, loading: false, success: true } });
                },
                (error) => {
                    if (this.isDestroyed) {
                        return;
                    }
                    if (errorCallback) {
                        errorCallback(error);
                    }
                    this.instance.setState({ post: { ...this.instance.state.post, response: null, loading: false, success: false } });
                });
        }
    }

    putMultipathData(path, formData, callback, errorCallback) {
        if (formData) {
            formData.append('_method', 'PUT');
        }
        // console.log('Controller putMultipathData');
        this.postMultipathData(path, formData, callback, errorCallback);
    }

    updateData(path, callback, errorCallback) {
        this.instance.setState({ update: { ...this.instance.state.update, loading: true, success: false } });
        if (path) {
            Request.put(path,
                this.props,
                (data) => {
                    if (this.isDestroyed) {
                        return;
                    }
                    if (callback) {
                        callback(data);
                    }
                    this.instance.setState({ update: { ...this.instance.state.update, response: data, loading: false, success: true } });
                },
                (error) => {
                    if (this.isDestroyed) {
                        return;
                    }
                    if (errorCallback) {
                        errorCallback(error);
                    }
                    this.instance.setState({ update: { ...this.instance.state.update, response: null, loading: false, success: false } });
                });
        }
    }

    deleteData(path, callback, errorCallback) {
        this.instance.setState({ destroy: { ...this.instance.state.destroy, loading: true, success: false } });
        if (path) {
            Request.del(path,
                (data) => {
                    if (this.isDestroyed) {
                        return;
                    }
                    if (callback) {
                        callback(data);
                    }
                    this.instance.setState({ destroy: { ...this.instance.state.destroy, response: data, loading: false, success: true } });
                },
                (error) => {
                    if (this.isDestroyed) {
                        return;
                    }
                    if (errorCallback) {
                        errorCallback(error);
                    }
                    this.instance.setState({ destroy: { ...this.instance.state.destroy, response: null, loading: false, success: false } });
                });
        }
    }

    executing() {
        if (!this.instance.state) {
            return;
        }
        const s = this.instance.state;
        return (s.post && s.post.loading) ||
            (s.update && s.update.loading) ||
            (s.post && s.destroy.loading);
    }

}