import React, { Component } from 'react';

import System from '../models/System';
import ChatRightSection from './ChatRightSection';
import HomeRightSection from './HomeRightSection';

class Right extends Component {

    constructor(props) {
        super(props);
        // console.log('Right');
        this.home = 0;
        this.chat = 0;
    };

    checkContent() {
        if (System.matchPaths(this.props.path, [
            '',
            'home',
            'news',
            'news/:newsId([0-9]+)',
            'user/feed',
            'user/feed/create',
            'user/feed/create/tag',
            'user/feed/create/location',
            'user/feed/:feedId([0-9]+)',
            'user/feed/:feedId([0-9]+)/tag',
            'user/feed/:feedId([0-9]+)/location',
            'user/inbox',
            'user/cart',
            'user/cart/location',
            'user/cart/location/:locationId([0-9]+)',
            'user/cart/location/create',
            'user/order',
            'user/order/:orderId([0-9]+)',
            'user/customer/order',
            'user/customer/order/:orderId([0-9]+)',
            'user/profile',
            'user/farm',
            'user/field',
            'user/field/:fieldId([0-9]+)/plant',
            'user/field/:fieldId([0-9]+)/plant/:plantId([0-9]+)',
            'user/product/create',
            'user/product/:productId([0-9]+)',
            'user/product/create/logistics',
            'user/product/:productId([0-9]+)/logistics',
            'user/shop',
            'user/activity/create',
            'user/activity/:activityId([0-9]+)',
            'shop'
        ])) {
            this.home = 1;
            return 1;
        } else if (System.matchPaths(this.props.path, [
            'chat',
            'chat/user/:userId([0-9]+)',
        ])) {
            this.chat = 1;
            return 2;
        }
        return null;
    }

    render() {
        const checkContent = this.checkContent();
        // console.log(checkContent);
        return (
            <>
                {this.home === 1 && <HomeRightSection key="right_section_home" visible={checkContent === 1} />}
                {this.chat === 1 && <ChatRightSection key="right_section_chat" visible={checkContent === 2} />}
            </>
        );
    }
}

export default Right;