export const nf = (a, digit) => {
    // console.log(a);
    if (isEmpty(a)) {
        a = 0;
    } else {
        a = parseFloat(a);
    }
    if (digit === 0) {
        return a.toLocaleString();
    }
    return a.toLocaleString(undefined, { minimumFractionDigits: digit ?? 0 });
};

export const isEmpty = (data) => data === '' || data === undefined || data === null;

export const address = (l) => l.village_name + ' เลขที่ ' + (l.house_number ?? '-') + ' หมู่ที่ ' + (l.moo ?? '-') + ' ต.' + (l.location.subdistrict ?? '-') + ' อ.' + l.location.district + ' จ.' + l.location.province + ' ' + l.location.zip_code;