import React, { PureComponent } from 'react';
import { isBrowser } from "react-device-detect";
import ProductItem from './ProductItem';

class ProductList extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        const list = this.props.data ?? [];
        return <div className="row">
                {list.map((item, i) => <div key={'p_' + i} className="col-xxl-2-4 col-xl-3 col-lg-4 col-6 mb-3">
                    <ProductItem data={item} path={this.props.path} />
                </div>)}
            </div>;
    }
}

export default ProductList;
