import React, { Component } from 'react';
import { get as L } from '../models/Label';

import backIcon from '../assets/images/back-icon.svg';

class AppBar extends Component {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className={`bar-app mb-3 ${this.props.className ?? ''}`}>
                {this.props.showLeftArrow !== false && <div className="btn-icon mr-3"
                    onClick={() => this.props.onBackPress()}>
                    <img src={backIcon} alt={L('ย้อนกลับ')} />
                </div>}
                <div className="bar-title pb-3">
                    <h5 className="color-black-1">{this.props.title}</h5>
                    {this.props.subTitle && <div className="color-black-2">{this.props.subTitle}</div>}
                </div>
                {this.props.children}
            </div>
        );
    }
}

export default AppBar;
