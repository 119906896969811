import React, { Component } from 'react';
import { get as L } from '../models/Label';

import InputDropListBox from '../components/InputDropListBox';
import InputBox from '../components/InputBox';
import Controller from '../models/Controller';
import API from '../models/API';

class AddressForm extends Component {

    constructor(props) {
        super(props);
        this.controller = new Controller(this, {
            ...this.props.data
        });
        // console.log('AddressForm', this.props.data);
    };

    componentWillUnmount() {
        this.controller.destroy();
    }

    componentDidMount() {
        this.loadProvince();
        this.validateLocation();
    }

    componentDidUpdate() {
        this.validateLocation();
    }

    validateLocation() {
        const location = this.props.data;
        const id = location?.id;
        if (id && this.id !== id) {
            // console.log('id', this.id);
            this.id = id;
            const c = this.controller;
            c.set('province', location.province);
            c.set('district', location.district);
            c.set('subdistrict', location.subdistrict);
            c.set('zip_code', location.zip_code);
            this.loadDistrict();
            this.loadSubdistrict();
            // this.loadZipCode();
        }
    }

    loadProvince() {
        this.controller.loadData(API.API_PROVINCE, 'provinces');
    }

    loadDistrict() {
        const c = this.controller;
        const pId = c.get('province');
        if (pId) {
            c.loadData(API.API_PROVINCE + '/' + pId + '/districts', 'districts');
        }
    }

    loadSubdistrict() {
        const c = this.controller;
        const pId = c.get('province');
        const dId = c.get('district');
        if (pId && dId) {
            c.loadData(API.API_PROVINCE + '/' + pId + '/districts/' + dId + '/subdistricts', 'subdistricts');
        }
    }

    loadZipCode() {
        const c = this.controller;
        const pId = c.get('province');
        const dId = c.get('district');
        const sdId = c.get('subdistrict');
        if (pId && dId && sdId) {
            c.loadData(API.API_PROVINCE + '/' + pId + '/districts/' + dId + '/subdistricts/' + sdId + '/zip-code', 'zipCode',
                null, (data) => {
                    this.controller.set('location_id', data.id);
                    if (this.props.controller) {
                        this.props.controller.set('location_id', data.id);
                    }
                });
        }
    }

    resetDistrict() {
        const c = this.controller;
        c.set('district', null);
        c.set('subdistrict', null);
        c.set('zip_code', '');
        c.set('location_id', null);
        c.unloadData(['districts', 'subdistricts', 'zipCode']);
    }

    resetSubdistrict() {
        const c = this.controller;
        c.set('subdistrict', null);
        c.set('zip_code', '');
        c.set('location_id', null);
        c.unloadData(['subdistricts', 'zipCode']);
    }

    resetZipCode() {
        const c = this.controller;
        c.set('zip_code', '');
        c.set('location_id', null);
        c.unloadData(['zipCode']);
    }

    render() {
        const {
            provinces,
            districts,
            subdistricts,
            zipCode } = this.state;
        const c = this.controller;
        const data = c.all();
        let z = data.zip_code;
        if (zipCode) {
            z = zipCode?.response?.zip_code;
        }
        return (<>
            <div className="col-6">
                <InputDropListBox
                    label={L('จังหวัด*')}
                    placeholder={L('เลือกจังหวัด')}
                    data={provinces?.response}
                    value={data.province}
                    onChange={(event) => {
                        const value = event.target.value;
                        c.set('province', value);
                        this.resetDistrict();
                        this.loadDistrict();
                    }} />
            </div>
            <div className="col-6">
                <InputDropListBox
                    label={L('อำเภอ*')}
                    placeholder={L('เลือกอำเภอ')}
                    data={districts?.response}
                    value={data.district}
                    onChange={(event) => {
                        const value = event.target.value;
                        c.set('district', value);
                        this.resetSubdistrict();
                        this.loadSubdistrict();
                    }} />
            </div>
            <div className="col-6">
                <InputDropListBox
                    label={L('ตำบล*')}
                    placeholder={L('เลือกตำบล')}
                    data={subdistricts?.response}
                    value={data.subdistrict}
                    onChange={(event) => {
                        const value = event.target.value;
                        c.set('subdistrict', value);
                        this.resetZipCode();
                        this.loadZipCode();
                    }} />
            </div>
            <div className="col-6">
                <InputBox
                    label={L('รหัสไปรษณีย์*')}
                    placeholder={L('10000')}
                    value={z} />
            </div>
        </>
        );
    }
}

export default AddressForm;
