import React, { PureComponent } from 'react';
import StarRatings from 'react-star-ratings';
import { get as L } from '../models/Label';
import { nf } from '../utils/UObj';

import dummyImage from '../assets/images/dummy.jpg';
import System from '../models/System';
import { Link } from 'react-router-dom';

class ProductItem extends PureComponent {

    constructor(props) {
        super(props);
    };

    getBone(w, h) {
        return <div className={`skeleton-box`} style={{ width: w + '%', height: h + 'px', marginBottom: '2px' }}></div>;
    }

    render() {
        const { data } = this.props;
        if (!data) {
            return <div></div>;
        }

        if (data.id === 'dummy') {
            return (
                <div>
                    <img className="image-product" src={dummyImage} alt={L('รูปสินค้า')} />
                    <div className="product-name">{this.getBone('75', 25)}</div>
                    {/* <div>{data.description}</div> */}
                    <div className="product-price">{this.getBone('70', 22)}</div>
                    <div className="product-sale">{this.getBone('80', 18)}</div>
                    <div className="product-location">{this.getBone('60', 16)}</div>
                </div>
            );
        }

        let location = '';
        const shop = data.shop;
        if (shop && shop.location) {
            location = shop.location.district + ' ' + shop.location.province;
        }
        return (
            <Link className="product-item" to={System.resolvePath(this.props.path + data.id)}>
                <img className="image-product" src={System.resolveThumnailPath(data.thumbnail?.image, 'product')} alt={data.name} />
                <div className="product-name">{data.name}</div>
                {/* <div>{data.description}</div> */}
                <div className="product-price">฿{nf(data.price)} /{data.unit?.name}</div>
                <div className="product-sale">{data.rating && <StarRatings starDimension="10px" starSpacing="0px" rating={parseFloat(data.rating ?? 0)} starRatedColor="#ffce3d" numberOfStars={5} />}{data.sold ? L('ขายแล้ว') + ' ' + data.sold + ' ' + L('ชิ้น') : ''}</div>
                {location && <div className="product-location">{location}</div>}
            </Link>
        );
    }
}

export default ProductItem;
