import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import API from '../models/API';
import Controller from '../models/Controller';
import { get as L } from '../models/Label';
import System from '../models/System';
import { isEmpty } from '../utils/UObj';
import HLine from '../components/HLine';
import AppBar from '../components/AppBar';

import InputDropListBox from '../components/InputDropListBox';
import Avatar from '../components/Avatar';
import TagBox from '../components/TagBox';

class TagListPage extends Component {

    constructor(props) {
        super(props);

        const formData = this.props.location.formData;
        // console.log(formData);
        if (!formData) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('home'));
            return;
        }

        this.state = {
            [API.API_SHOP]: {
                response: {
                    page: 1,
                    total_row: 1,
                    list: [
                        { id: 'dummy_1', type: 'dummy' },
                        { id: 'dummy_2', type: 'dummy' },
                        { id: 'dummy_3', type: 'dummy' },
                        { id: 'dummy_4', type: 'dummy' },
                        { id: 'dummy_5', type: 'dummy' },
                    ]
                }
            },
            [API.API_PRODUCT]: {
                response: {
                    page: 1,
                    total_row: 1,
                    list: [
                        { id: 'dummy_1', type: 'dummy' },
                        { id: 'dummy_2', type: 'dummy' },
                        { id: 'dummy_3', type: 'dummy' },
                        { id: 'dummy_4', type: 'dummy' },
                        { id: 'dummy_5', type: 'dummy' },
                    ]
                }
            },
            [API.API_ACTIVITY]: {
                response: {
                    page: 1,
                    total_row: 1,
                    list: [
                        { id: 'dummy_1', type: 'dummy' },
                        { id: 'dummy_2', type: 'dummy' },
                        { id: 'dummy_3', type: 'dummy' },
                        { id: 'dummy_4', type: 'dummy' },
                        { id: 'dummy_5', type: 'dummy' },
                    ]
                }
            },
            ['tag_' + API.API_SHOP]: formData['tag_' + API.API_SHOP] ?? [],
            ['tag_' + API.API_PRODUCT]: formData['tag_' + API.API_PRODUCT] ?? [],
            ['tag_' + API.API_ACTIVITY]: formData['tag_' + API.API_ACTIVITY] ?? [],
        };

        //init query string
        const params = new URLSearchParams(this.props.location.search);
        const qdata = {
            search_string: params.get('search_string') ?? '',
            page: params.get('page') ?? 1,
            type: params.get('type') ?? API.API_SHOP,
        };
        this.controller = new Controller(this, {
            search_columns: 'name,description',
            limit: 10,
            type: API.API_SHOP,
            ...qdata
        });

        //check prop
        // if (!this.props.location.parantPath) {
        //     this.isError = true;
        //     this.props.history.replace(System.resolvePath('home'));
        //     return;
        // }

    }

    componentDidMount() {
        this.loadData();
    }

    componentWillUnmount() {
        if (this.controller) {
            this.controller.destroy();
        }
    }

    loadData() {
        if (this.isError) {
            return;
        }
        const type = this.controller.get('type');
        this.controller.loadData(type, type, this.controller.all(), (data) => {
            // console.log(data);
        }, () => {

        });
    }

    search(time = 800) {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            // console.log('timer');
            this.timer = null;

            //create query string
            const data = { ...this.controller.all() };
            for (let k in data) {
                if (isEmpty(data[k])) {
                    delete data[k];
                }
            }
            // console.log(data);
            this.props.history.push({
                search: "?" + new URLSearchParams(data).toString(),
                parantPath: this.props.location.parantPath,
                formData: this.props.location.formData,
            });
            this.loadData();
        }, time);
    }

    getIndexById(id) {
        const type = this.controller.get('type');
        const selects = this.state['tag_' + type];
        for (let i = 0; i < selects.length; i++) {
            if (parseInt(selects[i].id) === parseInt(id)) {
                return i;
            }
        }
        return -1;
    }

    removeSelect(item) {
        const keys = ['tag_' + API.API_SHOP, 'tag_' + API.API_PRODUCT, 'tag_' + API.API_ACTIVITY];
        let key, s, index;
        for (let k in keys) {
            key = keys[k];
            s = this.state[key];
            index = s.indexOf(item);
            if (index !== -1) {
                s.splice(index, 1);
                this.setState({ [key]: s });
                return;
            }
        }
    }

    itemCheckHandler(item, index) {
        const type = this.controller.get('type');
        const selects = this.state['tag_' + type];
        if (index === -1) {
            selects.push(item);
        } else {
            selects.splice(index, 1);
        }
        this.setState({ ['tag_' + type]: selects });
    }

    getItem(item) {
        // console.log(item);
        if (item.type === 'dummy') {
            return null;
        }

        const index = this.getIndexById(item.id);
        if (index !== -1) {
            return null;
        }

        let avatar;
        if (item.logo) {
            avatar = System.resolveImagePath(item.logo, 'shop');
        } else {
            avatar = System.resolveThumnailPath(item.thumbnail.image, 'product');
        }

        return <Fragment key={'tag_item_' + item.id} htmlFor={'tag_item_' + item.id}>
            <div
                className="order-item form-row p-2"
                onClick={this.itemCheckHandler.bind(this, item, index)}>
                <Avatar
                    className="mr-3"
                    src={avatar}
                    iconFill
                />
                <div className="col mr-3">
                    <div className="order-user-name">{item.name}</div>
                    <div className="list-tile-sublabel">{item.description}</div>
                </div>
                {/* <div className="col-auto">
                    <div className="custom-control custom-checkbox">
                        <input
                            className="custom-control-input"
                            type="checkbox"
                            id={'tag_item_' + item.id}
                            value="1"
                            checked={index !== -1}
                            onChange={(event) => {

                            }}
                        />
                        <label className="custom-control-label" htmlFor={'tag_item_' + item.id}></label>
                    </div>
                </div> */}
            </div>
            <HLine className="border-ligth my-2" />
        </Fragment>;
    }

    submit() {
        const formData = this.props.location.formData;
        if (!formData) {
            this.props.history.replace(System.resolvePath('home'));
            return;
        }
        const keys = ['tag_' + API.API_SHOP, 'tag_' + API.API_PRODUCT, 'tag_' + API.API_ACTIVITY];
        let key;
        for (let k in keys) {
            key = keys[k];
            formData[key] = this.state[key];
        }
        this.props.history.replace({
            pathname: System.resolvePath(this.props.location.parantPath),
            formData,
        });
    }

    render() {

        if (this.isError) {
            return null;
        }

        const { type } = this.state;
        const c = this.controller;
        const { page, search_string } = c.all();
        const { list, total_rows } = this.state[type]?.response ?? {}

        const s = this.state['tag_' + API.API_SHOP].concat();
        const p = this.state['tag_' + API.API_PRODUCT].concat();
        const a = this.state['tag_' + API.API_ACTIVITY].concat();
        const items = s.concat(p, a);

        return (
            <div className="section-content">
                <div className="cart-list">

                    <AppBar
                        title={L('รายการแฮชแทค(#)')}
                        subTitle={L('เลือกแท็ก ร้านค้า, สินค้าและกิจกรรม')}
                        showLeftArrow
                        onBackPress={() => {
                            // console.log(this.props.location.parantPath);
                            if (this.props.location.parantPath) {
                                this.props.history.replace({
                                    pathname: System.resolvePath(this.props.location.parantPath),
                                    formData: this.props.location.formData,
                                });
                            } else {
                                this.props.history.goBack();
                            }
                        }}
                    />

                    <div className="form-row mb-4">
                        <div className="col">
                            <input
                                type="text"
                                className="form-control input-search flex-1 mr-3"
                                style={{ backgroundColor: '#FAFAFA' }}
                                placeholder={L('ชื่อ, รายละเอียด')}
                                defaultValue={search_string}
                                onChange={(event) => {
                                    c.set('search_string', event.target.value);
                                    this.search();
                                }}
                            />
                        </div>
                        <div className="col-auto">
                            <InputDropListBox
                                key="type"
                                value={type}
                                data={[
                                    { id: API.API_SHOP, label: L('ร้านค้า') },
                                    { id: API.API_PRODUCT, label: L('สินค้า') },
                                    { id: API.API_ACTIVITY, label: L('กิจกรรม') },
                                ]}
                                onChange={(event) => {
                                    // c.set('search_string', '', true);
                                    c.set('type', event.target.value, true);
                                    this.search(0);
                                }} />
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-primary" onClick={() => this.submit()}>{L('เสร็จสิ้น')}</button>
                        </div>

                        {items.length > 0 && <div className="col-12 mt-3">
                            <TagBox items={items} closeHandler={(item) => this.removeSelect(item)} />
                        </div>}

                    </div>

                    {list && list.map((item) => this.getItem(item))}

                    {/* <div className="py-3">
                        <Pagination
                            id="pagination_product"
                            onChange={(name, value) => {
                                if (name === Pagination.PAGE_CHANGE) {
                                    if (value !== page) {
                                        // console.log(value);
                                        this.controller.set('page', value);
                                        this.search(0);
                                        System.scrollTo(0, 0);
                                    }
                                }
                            }}
                            data={{
                                pageSize: System.MAX_ROWS,
                                dataLength: total_rows ?? 0,
                                currentPage: page,
                            }} />
                    </div> */}
                </div>

            </div>
        );
    }
}

export default withRouter(TagListPage);