import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';

import ActivityLeftSectionContent from '../components/ActivityLeftSectionContent';
import AppBar from '../components/AppBar';

class ActivityFilterPage extends PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className="section-content">
            <div className="flex-1">

                <div className="px-3">
                    <AppBar
                        title={L('กรองการแสดงกิจกรรม')}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />
                </div>

                <ActivityLeftSectionContent
                    key="left_section_activity"
                    data={this.props.location?.state}
                    hideProfile={true}
                    hideSearch={true}
                    showButton={true}
                />
            </div>
        </div>;
    }
}

export default withRouter(ActivityFilterPage);
