import React, { PureComponent } from 'react';
import { isBrowser } from "react-device-detect";
import ActivityItem from './ActivityItem';

class ActivityList extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        const list = this.props.data ?? [];
        return <div className="row">
            {list.map((item, i) => <div key={'p_' + i} className="col-lg-4 col-12 mb-3">
                <ActivityItem data={item} path={this.props.path} />
            </div>)}
        </div>;
    }
}

export default ActivityList;
