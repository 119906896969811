import moment from "moment";
import { isEmpty } from "./UObj";

export const validateEmptyValue = (value) => {
    if (isEmpty(value) || value.toString().trim() === '') {
        return false;
    }
    return value;
}

export const validateEmail = (value) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(value).toLowerCase())) {
        return false;
    }
    return value;
}

export const validateUsernameValue = (value) => {
    const re = /^[a-zA-Z0-9]{6,}$/;
    if (!re.test(value)) {
        // console.log(1);
        return false;
    }
    return value;
}

export const validatePassword = (value1, value2) => {
    if (!value1 || value1.length < 6) {
        return false;
    }
    if (value1 !== value2) {
        return false;
    }
    return value1;
}

export const validateIdCard = (value) => {
    if (!value || value.length !== 13 || value.charAt(0) === '0' || value.charAt(0) === '9') {

    } else {
        let i = 0;
        let len = 12;
        let total = 0;
        let n;
        for (i = 0; i < len; i++) {
            n = parseInt(value.charAt(i));
            total += n * (len - i + 1);
        }
        let lastDigit = total % 11;
        let chk = 11 - lastDigit;

        if (chk === 10) {
            chk = 0;
        } else if (chk === 11) {
            chk = 1;
        }

        if (parseInt(value.charAt(12)) !== chk) {

        } else {
            return value;
        }
    }
    return false;
}

export const validatePhoneNumber = (value) => {
    const phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4,5})[-. ]?([0-9]{4})$/;
    if (!value || !value.match(phoneno)) {
        return false;
    }
    return value;
}

export const validateNumber = (value) => {
    const n = /^(0|[1-9]\d*)(\.\d+)?$/;
    if (!value || !value.toString().match(n)) {
        return false;
    }
    return value;
}

export const validateVehiclePlatePrefixNumber = (value) => {
    const thaiChar = 'กขฃคฅฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรลวศษสหฬอฮ';
    const number = '1234567890';
    const len = value.length;
    for (let i = 0; i < len; i++) {
        if (number.indexOf(value.charAt(i)) === -1 && thaiChar.indexOf(value.charAt(i)) === -1) {
            return false;
        }
    }
    return true;
}

export const validateVehiclePlateNumber = (value) => {
    // console.log(value);
    if (!value || value.indexOf(" ") !== -1) {
        return false;
    }
    const thaiChar = ["ก", "ข", "ค", "ฆ", "ง", "จ", "ฉ", "ช", "ฌ", "ญ", "ฎ", "ฐ", "ฒ", "ณ", "ด", "ต", "ถ",
        "ท", "ธ", "น", "บ", "ผ", "พ", "ภ", "ม", "ย", "ร", "ล", "ว", "ศ", "ษ", "ส", "ฬ", "อ", "ฮ"];
    const number = '1234567890';
    if (thaiChar.indexOf(value.charAt(1)) === -1) {

    } else if (thaiChar.indexOf(value.charAt(2)) !== -1) {
        //first digits 3
        if (number.indexOf(value.charAt(0)) === -1) {

        }
    }
    //after 3 digits must be number
    var len = value.length - 3;
    for (var i = 3; i < len; i++) {
        if (number.indexOf(value.charAt(i)) === -1) {
            return false;
        }
    }
    return value;
}