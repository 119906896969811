import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';

import InputBox from '../components/InputBox';
import InputImageBox from '../components/InputImageBox';
import FormTitle from '../components/FormTitle';
import Controller from '../models/Controller';
import Form from '../components/Form';
import Button from '../components/Button';

import AddressForm from '../components/AddressForm';
import FarmFieldForm from '../components/FarmFieldForm';

import pinIcon from '../assets/images/pin-icon.svg';
import MapSelector from '../components/MapSelector';
import { validateEmptyValue, validateNumber } from '../utils/UValidate';
import { isEmpty } from '../utils/UObj';

import { isMobile } from 'react-device-detect';
import AppBar from '../components/AppBar';
import System from '../models/System';
import API from '../models/API';

class UserFarmPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            valid: {
                fields: []
            },
            showMapDialog: false
        };
        this.controller = new Controller(this, {
            images: [],
            fields: [{
                id: 'field_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000),
                name: '',
                rai: '',
                ngan: '',
                square_wa: ''
            }],
        });
        if (!System.isFarmer()) {
            this.props.history.replace(System.resolvePath('home'));
            return;
        }
    };

    componentDidMount() {
        this.loadFarmData();
    }

    loadFarmData() {
        this.controller.loadData(API.API_USER_FARM, 'farm', null, (data) => {
            if (data && (!data.fields || !data.fields.length)) {
                data.fields = [{
                    id: 'field_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000),
                    name: '',
                    rai: '',
                    ngan: '',
                    square_wa: ''
                }];
            }
            this.controller.append(data);
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    closeButtonClickHandler(index) {
        // console.log(index);
        const fields = this.controller.get('fields');
        const field = fields[index];
        if (validateNumber(field.id)) {
            const removeFields = this.controller.get('remove_fields') ?? [];
            removeFields.push(field.id);
            this.controller.set('remove_fields', removeFields);
        }
        fields.splice(index, 1);
        this.controller.set('fields', fields, true);
    }

    async openMapDialog() {
        this.lat = null;
        this.lng = null;
        await this.setState({ showMapDialog: true });
        window.openModalDialog('mapModal', () => {
            this.setState({ showMapDialog: false });
        });
    }

    closeMapDialog() {
        // console.log(this.lat, this.lng);
        if (this.lat && this.lng) {
            this.controller.set('lat', this.lat);
            this.controller.set('lng', this.lng);
        }
        window.hideModalDialog('mapModal');
        this.setState({ showMapDialog: false });
    }

    async submit() {
        this.setState({ valid: {} });

        const c = this.controller;
        const params = { ...c.all() };

        const name = params['name'] ?? '';
        const moo = params['moo'] ?? '';
        const images = params['images'] ?? [];
        const house_number = params['house_number'] ?? '';
        const location_id = params['location_id'] ?? '';

        const valid = {
            name: validateEmptyValue(name),
            // moo: validateEmptyValue(moo),
            house_number: validateEmptyValue(house_number),
            location_id: validateEmptyValue(location_id),
            fields: []
        };

        //check field
        const { fields } = params;
        let field;
        for (let key in fields) {
            field = fields[key];
            if (!validateEmptyValue(field.name)) {
                valid.fields[key] = false;
            } else {
                //check area
                let pass1 = !validateEmptyValue(field.rai) || validateNumber(field.rai);
                let pass2 = !validateEmptyValue(field.ngan) || validateNumber(field.ngan);
                let pass3 = !validateEmptyValue(field.square_wa) || validateNumber(field.square_wa);
                // console.log(validateNumber(field.square_wa));
                if (!pass1 || !pass2 || !pass3) {
                    valid.fields[key] = false;
                }
            }
        }

        for (let k in valid) {
            if (valid[k] === false) {
                this.setState({ valid });
                return;
            }
            if (Array.isArray(valid[k])) {
                for (let i in valid[k]) {
                    if (valid[k][i] === false) {
                        this.setState({ valid });
                        return;
                    }
                }
            }
        }

        for (let k in fields) {
            field = fields[k];
            params['field_id_' + k] = field.id;
            params['field_name_' + k] = field.name;
            params['field_rai_' + k] = field.rai;
            params['field_ngan_' + k] = field.ngan;
            params['field_square_wa_' + k] = field.square_wa;
        }
        delete params['fields'];

        // console.log(images);
        let file, blob;
        let i = 0;
        for (let k in images) {
            if (!isEmpty(images[k]) && typeof images[k] === 'object') {
                continue;
            }
            blob = await fetch(images[k]).then(r => r.blob());
            file = new File([blob], 'image_' + i + '.jpg', { type: 'image/jpg' });
            params['image_' + i] = file;
            i++;
        }
        if (params['remove_images']) {
            params['remove_images'] = params['remove_images'].toString();
        }
        delete params['images'];
        delete params['location'];

        const formData = new FormData();
        let value;
        for (let key in params) {
            value = params[key];
            if (isEmpty(value)) {
                continue;
            }
            formData.append(key, value);
        }

        this.controller.postMultipathData(API.API_USER_FARM, formData,
            (data) => {
                if (isMobile) {
                    this.props.history.goBack();
                    return;
                }
                //replace field id
                // this.loadFarmData();
                this.controller.set('remove_images', null);
                this.controller.set('remove_fields', null);
                this.controller.append(data);
            },
            (error) => {
                window.openMessageModalDialog(error);
            });
    }

    render() {
        // console.log('UserFarmPage render');
        const c = this.controller;
        const data = c.all();
        let { fields, lat, lng } = data;
        const { valid, post, showMapDialog, zipCode } = this.state;
        if (!lat && !lng && zipCode && zipCode.response) {
            // lat = zipCode.response.lat;
            // lng = zipCode.response.lng;
        }

        return (
            <div className="section-content no-nav">
                <Form
                    loading={post.loading}
                    className="form-register">
                    <AppBar
                        title={L('ข้อมูลฟาร์มและแปลงผลิต')}
                        subTitle={L('กรอกข้อมูลฟาร์มและแปลงผลิต')}
                        showLeftArrow={isMobile}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <InputImageBox
                                path="farm"
                                name="images"
                                controller={c}
                                label={L('รูปถ่ายแปลง')} />
                        </div>
                        <div className="col-12">
                            <InputBox
                                label={L('ชื่อฟาร์มหรือสถานที่*')}
                                placeholder={L('ชื่อแปลงหรือฟาร์มที่ผลิต')}
                                name="name"
                                controller={this.controller}
                                isError={valid.name === false}
                                errorMessage={L('กรุณากรอกชื่อแปลงหรือฟาร์มที่ผลิต')} />
                        </div>
                    </div>

                    <FormTitle label={L('ข้อมูลแปลงเพาะปลูก')}
                        onButtonPress={() => {
                            //test
                            fields.push({
                                id: 'field_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000),
                                name: '',
                                rai: '',
                                ngan: '',
                                square_wa: ''
                            });
                            this.controller.set('fields', fields, true);
                        }} />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            {fields && fields.map((v, i) => <FarmFieldForm
                                key={v.id}
                                index={i}
                                data={v}
                                isError={valid.fields && valid.fields[i] === false}
                                controller={c}
                                closeButtonClickHandler={this.closeButtonClickHandler.bind(this)}
                                showCloseButton={fields.length > 1} />)}
                        </div>
                    </div>

                    <FormTitle
                        label={L('ข้อมูลที่ตั้งฟาร์มผลิต')}
                        isError={valid.location_id === false}
                        errorMessage={L('กรุณากรอกข้อมูลที่อยู่ให้ครบ')} />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <InputBox
                                label={L('หมู่บ้าน')}
                                placeholder={L('ชื่อหมู่บ้าน')}
                                name="village_name"
                                controller={this.controller} />
                        </div>
                        <div className="col-6">
                            <InputBox
                                label={L('เลขที่*')}
                                placeholder={L('บ้านเลขที่')}
                                name="house_number"
                                controller={this.controller}
                                isError={valid.house_number === false}
                                errorMessage={L('กรุณากรอกบ้านเลขที่')} />
                        </div>
                        <div className="col-6">
                            <InputBox
                                label={L('หมู่ที่')}
                                placeholder={L('หมู่ที่')}
                                name="moo"
                                controller={this.controller}
                                isError={valid.moo === false}
                                errorMessage={L('กรุณากรอกหมู่ที่')} />
                        </div>

                        <AddressForm
                            controller={this.controller} data={data.location} />

                        <div className="col-6">
                            <InputBox
                                label={L('ละติจูด')}
                                name="lat"
                                controller={this.controller}
                                placeholder={L('xx.xxxxxxxxxx')} />
                        </div>
                        <div className="col-6 position-relative">
                            <InputBox
                                label={L('ลองติจูด')}
                                name="lng"
                                controller={this.controller}
                                placeholder={L('xx.xxxxxxxxxx')} />
                            <img
                                className="btn-pin-farm"
                                src={pinIcon}
                                alt={L('เลือกพิกัดจากแผนที่')}
                                onClick={this.openMapDialog.bind(this)} />
                        </div>
                    </div>

                    <div className="form-row mt-4">
                        <div className="col-12">
                            <Button
                                loading={post.loading}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.submit()}>
                                {L('บันทึกข้อมูล')}
                            </Button>
                        </div>
                    </div>

                </Form>

                {showMapDialog && <div
                    id="mapModal"
                    className="modal modal-full"
                    tabIndex="-1"
                    role="dialog"
                    data-keyboard="false"
                    data-backdrop="static">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('เลือกพิกัดจากแผนที่')}</h5>
                            </div>
                            <div className="modal-body modal-body-map">
                                <MapSelector
                                    lat={lat}
                                    lng={lng}
                                    onChange={(data) => {
                                        this.lat = data.lat;
                                        this.lng = data.lng;
                                    }} />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.closeMapDialog();
                                    }}>{L('ยืนยัน')}</button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        this.lat = null;
                                        this.lng = null;
                                        this.closeMapDialog();
                                    }}>{L('ยกเลิก')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}

export default withRouter(UserFarmPage);
