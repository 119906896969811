import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import API from '../models/API';
import Controller from '../models/Controller';
import { get as L } from '../models/Label';
import System from '../models/System';
import Pagination from '../components/Pagination';
import { address, isEmpty, nf } from '../utils/UObj';
import { Link } from 'react-router-dom';
import HLine from '../components/HLine';
import AppBar from '../components/AppBar';

import editIcon from '../assets/images/edit-item-icon.svg';
import LinkIcon from '../components/LinkIcon';

class UserLogisticsListPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            location: {
                response: {
                    page: 1,
                    total_row: 1,
                    list: [
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                    ]
                }
            }
        };

        //init query string
        const params = new URLSearchParams(this.props.location.search);
        const formData = {
            search_string: params.get('search_string') ?? '',
            page: params.get('page') ?? 1,
        };
        this.controller = new Controller(this, {
            search_columns: 'name,house_number,village_name,location.province,location.district,location.subdistrict',
            limit: 20,
            ...formData
        });

        //check prop
        // if (!this.props.location.parantPath) {
        //     this.isError = true;
        //     this.props.history.replace(System.resolvePath('home'));
        //     return;
        // }
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    loadData() {
        this.controller.loadData(API.API_ORDER_LOCATION, 'location', this.controller.all(), (data) => {
            // console.log(data);
        }, () => {

        });
    }

    search(time = 800) {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            // console.log('timer');
            this.timer = null;

            //create query string
            const data = { ...this.controller.all() };
            for (let k in data) {
                if (isEmpty(data[k])) {
                    delete data[k];
                }
            }
            // console.log(data);
            this.props.history.push({
                search: "?" + new URLSearchParams(data).toString()
            });
            this.loadData();
        }, time);
    }

    getItem(item) {
        // console.log(item);
        if (item.id === 'dummy') {
            return null;
        }
        return <Link key={'l_' + item.id} to={{
            pathname: System.resolvePath('user/cart'),
            formData: {
                ...this.props.location.formData,
                location_id: item.id,
            },
        }}>
            <div className="order-item form-row p-2">
                <div className="col mr-3">
                    <div className="order-user-name">{item.name}</div>
                    <div className="list-tile-sublabel dark">{L('ผู้รับ')} {item.recipient_name ?? '-'} {L('โทร.')} {item.phone_number ?? '-'}</div>
                    <div className="list-tile-sublabel">{address(item)}</div>
                </div>
                <div className="col-auto">
                    <LinkIcon
                        to={{
                            pathname: System.resolvePath('user/cart/location/' + item.id),
                            parantPath: 'user/cart/location',
                        }}
                        icon={editIcon}>{L('แก้ไข')}</LinkIcon>
                </div>
            </div>
            <HLine className="border-ligth my-2" />
        </Link>;
    }

    render() {

        const { location } = this.state;
        const c = this.controller;
        const { page, search_string } = c.all();
        const { list, total_rows } = location?.response ?? {};

        return (
            <div className="section-content">
                <div className="cart-list">

                    <AppBar
                        title={L('รายการสถานที่จัดส่งสินค้า')}
                        subTitle={L('เลือกสถานที่การจัดส่งสินค้า')}
                        showLeftArrow
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />

                    <div className="form-row mb-4">
                        <div className="col">
                            <input
                                type="text"
                                className="form-control input-search flex-1 mr-3"
                                style={{ backgroundColor: '#FAFAFA' }}
                                placeholder={L('ชื่อ, ชื่อหมู่บ้าน, ตำบล, อำเภอ, จังหวัด')}
                                defaultValue={search_string}
                                onChange={(event) => {
                                    c.set('search_string', event.target.value);
                                    this.search();
                                }}
                            />
                        </div>
                    </div>

                    {list && list.map((item) => this.getItem(item))}

                    <div className="py-3">
                        <Pagination
                            id="pagination_product"
                            onChange={(name, value) => {
                                if (name === Pagination.PAGE_CHANGE) {
                                    if (value !== page) {
                                        // console.log(value);
                                        this.controller.set('page', value);
                                        this.search(0);
                                        System.scrollTo(0, 0);
                                    }
                                }
                            }}
                            data={{
                                pageSize: System.MAX_ROWS,
                                dataLength: total_rows ?? 0,
                                currentPage: page,
                            }} />
                    </div>
                </div>

            </div>
        );
    }
}

export default withRouter(UserLogisticsListPage);