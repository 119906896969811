import React, { Component } from 'react';

import ascSortIcon from '../assets/images/sort-up-solid.svg';
import descSortIcon from '../assets/images/sort-down-solid.svg';
import sortIcon from '../assets/images/empty.svg';

const sortIconMap = {
	'asc': ascSortIcon,
	'desc': descSortIcon,
	'def': sortIcon
};

class TableHead extends Component {

	headerClickHandler(item) {
		if (this.props.headerClickHandler) {
			this.props.headerClickHandler(item);
		}
	}

	getHeader(item) {
		const style = {};
		if (item.width) {
			style.width = item.width;
		}
		return (
			<th key={this.props.id + '_' + item.id} className={item.className} style={style}>
				{item.sortEnabled ? (
					<span className='pointer noselect' onClick={this.headerClickHandler.bind(this, item)}>
						{item.title}
						<img className='sort-icon' src={sortIconMap[item.sort]} alt="" />
					</span>
				) : (
					item.title
				)}
			</th>
		);
	}

	render() {
		//console.log("THead render");
		const { data } = this.props;
		return (
			<thead>
				<tr>{data.map((item) => this.getHeader(item))}</tr>
			</thead>
		);
	}
}

export default TableHead;
