import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

export default class LinkIcon extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className={'link-icon ' + (this.props.className ?? '')}>
                <Link to={this.props.to}>
                    {this.props.icon && <img className="mr-2" src={this.props.icon}></img>}
                    {this.props.children}
                </Link>
            </div>
        );
    }
}
