import React, { Component } from 'react';
import { get as L } from '../models/Label';

import addProfile from '../assets/images/avatar-icon.png';
import InputDropListBox from '../components/InputDropListBox';
import InputBox from '../components/InputBox';
import FormTitle from '../components/FormTitle';
import Button from './Button';
import Form from './Form';
import ImageCropDialog from './ImageCropDialog';
import Controller from '../models/Controller';
import InputPasswordBox from './InputPasswordBox';
import {
    validateEmail,
    validateEmptyValue,
    validatePassword,
    validatePhoneNumber
} from '../utils/UValidate';
import { isEmpty } from '../utils/UObj';
import AppBar from './AppBar';
import API from '../models/API';
import InputSwitch from './InputSwitch';

const prefixNames = [
    { id: 'นาง', label: 'นาง' },
    { id: 'นางสาว', label: 'นางสาว' },
    { id: 'นาย', label: 'นาย' },
];

class RegisterProfileContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showImageCropDialog: false,
            valid: {},
            avatar: null,
        };
        // console.log(this.props.data);
        this.id = this.props.data?.id;
        this.controller = new Controller(this, {
            ...this.props.data,
            change_password: this.props.passwordType !== 'option' ? 1 : 0,
        });
    };

    componentWillUnmount() {
        this.controller.destroy();
    }

    componentDidUpdate() {
        if (this.state.showImageCropDialog) {
            if (!this.dialogOnState) {
                this.dialogOnState = true;
                window.openModalDialog('imageCropModal');
            }
        } else {
            this.dialogOnState = false;
        }
        //not loaded yet
        const id = this.props.data?.id;
        if (!isEmpty(id) && this.id !== id) {
            this.id = id;
            this.controller.append(this.props.data);
            // console.log('componentDidUpdate =>', this.controller.all());
            //load more
            this.loadDistrict();
            this.loadSubdistrict();
        }
    }

    componentDidMount() {
        this.loadProvince();
        const id = this.props.data?.id;
        if (!isEmpty(id)) {
            this.loadDistrict();
            this.loadSubdistrict();
        }
    }

    loadProvince() {
        this.controller.loadData(API.API_PROVINCE, 'provinces');
    }

    loadDistrict() {
        const c = this.controller;
        const pId = c.get('province');
        c.loadData(API.API_PROVINCE + '/' + pId + '/districts', 'districts');
    }

    loadSubdistrict() {
        const c = this.controller;
        const pId = c.get('province');
        const dId = c.get('district');
        c.loadData(API.API_PROVINCE + '/' + pId + '/districts/' + dId + '/subdistricts', 'subdistricts');
    }

    loadZipCode() {
        const c = this.controller;
        const pId = c.get('province');
        const dId = c.get('district');
        const sdId = c.get('subdistrict');
        c.loadData(API.API_PROVINCE + '/' + pId + '/districts/' + dId + '/subdistricts/' + sdId + '/zip-code', 'zipCode',
            null, (data) => {
                this.controller.set('location_id', data.id);
            });
    }

    resetDistrict() {
        const c = this.controller;
        c.set('district', null);
        c.set('subdistrict', null);
        c.set('zip_code', '');
        c.set('location_id', null);
        c.unloadData(['districts', 'subdistricts', 'zipCode']);
    }

    resetSubdistrict() {
        const c = this.controller;
        c.set('subdistrict', null);
        c.set('zip_code', '');
        c.set('location_id', null);
        c.unloadData(['subdistricts', 'zipCode']);
    }

    resetZipCode() {
        const c = this.controller;
        c.set('zip_code', '');
        c.set('location_id', null);
        c.unloadData(['zipCode']);
    }

    cropImage(avatar) {
        window.hideModalDialog('imageCropModal');
        this.setState({ showImageCropDialog: false, avatar });
    }

    onImagechange(e) {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            const reader = new FileReader();
            const seft = this;
            reader.onload = function (e) {
                seft.setState({ showImageCropDialog: true, avatar: e.target.result });
            }
            reader.readAsDataURL(file);
        }
    }

    async submit() {
        // console.log('RegisterProfileContent submit');
        this.setState({ valid: {} });

        const c = this.controller;
        const params = { ...c.all() };

        const title_name = params['title_name'] ?? '';
        const first_name = params['first_name'] ?? '';
        const last_name = params['last_name'] ?? '';
        const phone_number = params['phone_number'] ?? '';
        const email = params['email'] ?? '';
        const moo = params['moo'] ?? '';
        const house_number = params['house_number'] ?? '';
        const password = params['password'] ?? '';
        const cpassword = params['confirm_password'] ?? '';
        const location_id = params['location_id'] ?? '';
        const change_password = params['change_password'] ?? 0;

        const valid = {
            title_name: validateEmptyValue(title_name),
            first_name: validateEmptyValue(first_name),
            last_name: validateEmptyValue(last_name),
            phone_number: validatePhoneNumber(phone_number),
            email: !validateEmptyValue(email) || validateEmail(email),
            // moo: validateEmptyValue(moo),
            house_number: validateEmptyValue(house_number),
            password: change_password && validatePassword(password, cpassword),
            location_id: validateEmptyValue(location_id),
        };
        // console.log(valid);
        for (let k in valid) {
            if (valid[k] === false) {
                this.setState({ valid });
                return;
            }
        }

        // console.log(this.state.avatar);
        if (this.state.avatar) {
            let blob = await fetch(this.state.avatar).then(r => r.blob());
            const file = new File([blob], 'profile.jpg', { type: 'image/jpg' });
            params['profile_image'] = file;
        } else {
            delete params['profile_image'];
        }
        delete params['location'];

        const formData = new FormData();
        let value;
        for (let key in params) {
            value = params[key];
            formData.append(key, value);
        }

        if (isEmpty(this.id)) {
            this.controller.postMultipathData(API.API_REGISTER, formData,
                (data) => {
                    this.props.success(data);
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        } else {
            this.controller.postMultipathData(API.API_PROFILE, formData,
                (data) => {
                    this.props.success(data);
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        }
    }

    getPassword() {
        const { valid } = this.state;
        const c = this.controller;
        const type = this.props.passwordType;
        // console.log(c.get('change_password'));
        if (type === "no") {
            return null;
        } else {
            return <>

                <FormTitle label={L('รหัสผ่าน')} >
                    {type === 'option' && <div class="custom-control custom-checkbox">
                        <InputSwitch
                            name="change_password"
                            text={L('ต้องการเปลี่ยนรหัสผ่าน')}
                            checked={c.get('change_password')}
                            controller={c}
                        />
                    </div>}
                </FormTitle>
                <div className="form-row form-section-register">
                    {c.get('change_password') !== 0 && <>
                        <div className="col-12 col-sm-6">
                            <InputPasswordBox
                                label={L('รหัสผ่านใหม่*')}
                                placeholder={L('กำหนดรหัสผ่านอย่างน้อย 6 ตัว')}
                                name="password"
                                controller={this.controller}
                                isError={valid.password === false}
                                errorMessage={L('กรุณากรอกรหัสผ่านให้ถูกต้อง')} />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputPasswordBox
                                label={L('ยืนยันรหัสผ่าน*')}
                                type="password"
                                placeholder={L('กำหนดรหัสผ่านอย่างน้อย 6 ตัว')}
                                name="confirm_password"
                                controller={this.controller} />
                        </div>
                    </>}
                </div>
            </>;
        }
    }

    render() {
        const {
            showImageCropDialog,
            valid,
            avatar,
            provinces,
            districts,
            subdistricts,
            zipCode,
            post } = this.state;
        const c = this.controller;
        const data = c.all();
        const profileImage = avatar ?? data.profile_image;
        let z = data.zip_code;
        if (zipCode) {
            z = zipCode?.response?.zip_code;
        }
        return (
            <Form
                loading={post.loading}
                className="form-register">
                <AppBar
                    title={L('แก้ไขข้อมูลส่วนตัว')}
                    subTitle={L('กรอกข้อมูลของท่าน')}
                    showLeftArrow={this.props.showLeftArrow}
                    onBackPress={() => {
                        this.props.onBackPress();
                    }}
                />
                <div className="form-row form-section-register">
                    <div className="col-12 d-flex justify-content-center">
                        <label htmlFor="upload">
                            <div
                                className="img-register-profile pointer mb-4"
                                style={{
                                    backgroundImage: `url(${profileImage ?? addProfile})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    borderRadius: '50%',
                                    width: '128px',
                                    height: '128px'
                                }}
                            />
                            <input
                                type="file"
                                id="upload"
                                accept="image/x-png,image/jpeg"
                                className="d-none"
                                onChange={this.onImagechange.bind(this)}
                                capture
                            />
                        </label>
                    </div>
                    <div className="col-12 col-sm-auto">
                        <InputDropListBox
                            label={L('คำนำหน้า*')}
                            placeholder={L('คำนำหน้าชื่อ')}
                            name="title_name"
                            controller={this.controller}
                            isError={valid.title_name === false}
                            errorMessage={L('กรุณาเลือกคำนำหน้าชื่อ')}
                            data={prefixNames} />
                    </div>
                    <div className="col-12 col-sm">
                        <InputBox
                            label={L('ชื่อ*')}
                            name="first_name"
                            controller={this.controller}
                            placeholder={L('ชื่อจริง')}
                            isError={valid.first_name === false}
                            errorMessage={L('กรุณากรอกชื่อ')} />
                    </div>
                    <div className="col-12 col-sm">
                        <InputBox
                            label={L('นามสกุล*')}
                            name="last_name"
                            controller={this.controller}
                            placeholder={L('นามสกุลจริง')}
                            isError={valid.last_name === false}
                            errorMessage={L('กรุณากรอกนามสกุล')} />
                    </div>
                </div>

                <FormTitle
                    label={L('ที่อยู่ที่สามารถติดต่อได้')}
                    isError={valid.location_id === false}
                    errorMessage={L('กรุณากรอกข้อมูลที่อยู่ให้ครบ')}
                />
                <div className="form-row form-section-register">
                    <div className="col-6">
                        <InputBox
                            label={L('เบอร์โทรศัพท์*')}
                            placeholder={L('0xx-XXXXXXX')}
                            name="phone_number"
                            controller={this.controller}
                            isError={valid.phone_number === false}
                            errorMessage={L('กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง')} />
                    </div>
                    <div className="col-6">
                        <InputBox
                            label={L('หมู่บ้าน')}
                            placeholder={L('ชื่อหมู่บ้าน')}
                            name="village_name"
                            controller={this.controller} />
                    </div>
                    <div className="col-6">
                        <InputBox
                            label={L('เลขที่*')}
                            placeholder={L('บ้านเลขที่')}
                            name="house_number"
                            controller={this.controller}
                            isError={valid.house_number === false}
                            errorMessage={L('กรุณากรอกบ้านเลขที่')} />
                    </div>
                    <div className="col-6">
                        <InputBox
                            label={L('หมู่ที่')}
                            placeholder={L('หมู่ที่')}
                            name="moo"
                            controller={this.controller}
                            isError={valid.moo === false}
                            errorMessage={L('กรุณากรอกหมู่ที่')} />
                    </div>
                    <div className="col-6">
                        <InputDropListBox
                            label={L('จังหวัด*')}
                            placeholder={L('เลือกจังหวัด')}
                            data={provinces?.response}
                            value={data.province}
                            onChange={(event) => {
                                const value = event.target.value;
                                c.set('province', value);
                                this.resetDistrict();
                                this.loadDistrict();
                            }} />
                    </div>
                    <div className="col-6">
                        <InputDropListBox
                            label={L('อำเภอ*')}
                            placeholder={L('เลือกอำเภอ')}
                            data={districts?.response}
                            value={data.district}
                            onChange={(event) => {
                                const value = event.target.value;
                                c.set('district', value);
                                this.resetSubdistrict();
                                this.loadSubdistrict();
                            }} />
                    </div>
                    <div className="col-6">
                        <InputDropListBox
                            label={L('ตำบล*')}
                            placeholder={L('เลือกตำบล')}
                            data={subdistricts?.response}
                            value={data.subdistrict}
                            onChange={(event) => {
                                const value = event.target.value;
                                c.set('subdistrict', value);
                                this.resetZipCode();
                                this.loadZipCode();
                            }} />
                    </div>
                    <div className="col-6">
                        <InputBox
                            label={L('รหัสไปรษณีย์*')}
                            placeholder={L('10000')}
                            name="zip_code"
                            value={z} />
                    </div>
                </div>

                <FormTitle label={L('ช่องทาง Socials')} />
                <div className="form-row form-section-register">
                    <div className="col-12 col-sm-6">
                        <InputBox
                            label={L('เว็ปไซต์')}
                            placeholder={L('www.example.com')}
                            name="web_site"
                            controller={this.controller} />
                    </div>
                    <div className="col-12 col-sm-6">
                        <InputBox
                            label={L('Facebook')}
                            placeholder={L('www.facebook.com/username')}
                            name="facebook"
                            controller={this.controller} />
                    </div>
                    <div className="col-12 col-sm-6">
                        <InputBox
                            label={L('Email')}
                            placeholder={L('email@example.com')}
                            name="email"
                            controller={this.controller}
                            isError={valid.email === false}
                            errorMessage={L('กรุณากรอก Email ให้ถูกต้อง')} />
                    </div>
                    <div className="col-12 col-sm-6">
                        <InputBox
                            label={L('Line')}
                            placeholder={L('line.me/username')}
                            name="line"
                            controller={this.controller} />
                    </div>
                </div>

                {this.getPassword()}

                <div className="form-row mt-4">
                    <div className="col-12">
                        <Button
                            loading={post.loading}
                            type="button"
                            className="btn btn-primary"
                            onClick={() => this.submit()}>
                            {L('บันทึกข้อมูล')}
                        </Button>
                    </div>
                </div>

                {showImageCropDialog && <ImageCropDialog src={profileImage} submit={(data) => this.cropImage(data)} />}

            </Form>
        );
    }
}

export default RegisterProfileContent;
