import React, { PureComponent } from 'react';
import Avatar from './Avatar';
import { get as L } from '../models/Label';
import filterIcon from '../assets/images/filter-icon.svg';

const menuItemStyle = { width: '32px', height: '32px' };

class MarketFilter extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className="d-flex align-items-center mb-4">
                <input
                    type="text"
                    className="form-control input-search flex-1 mr-3"
                    style={{ backgroundColor: '#FAFAFA' }}
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.searchString ?? ''}
                    onChange={(event) => {
                        if (this.props.onChange) {
                            this.props.onChange(event.target.value);
                        }
                    }} />
                <Avatar
                    style={menuItemStyle}
                    src={filterIcon}
                    iconWidth='18px'
                    iconHeight='18px'
                    backgroundColor='#E0E0E0'
                    onClick={() => {
                        if (this.props.onClick) {
                            this.props.onClick();
                        }
                    }} />
            </div>
        );
    }
}

export default MarketFilter;
