import React, { PureComponent } from 'react';
import StarRatings from 'react-star-ratings';
import moment from 'moment';
import HLine from '../components/HLine';
import MenuItem from '../components/MenuItem';
import { getDateString } from '../utils/UDate';
import editIcon from '../assets/images/edit-item-icon.svg';
import LinkIcon from '../components/LinkIcon';
import { get as L } from '../models/Label';
import System from '../models/System';

class ReviewItem extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        const { data, profile, path } = this.props;
        const isOwner = parseInt(data.user_id) === parseInt(profile?.id);
        // console.log(path);
        return (
            <>
                <div id={'review_' + data.id} className="review-item form-row p-2">
                    <div className="col-12">
                        <div className="col feed-box-date">{getDateString(moment(data.created_at), this.dateOption)}</div>
                        <MenuItem
                            iconFill
                            src={System.resolveImagePath(data.user?.profile_image, 'profile')}
                            label={(data.user?.first_name ?? '') + ' ' + (data.user?.last_name ?? '')}
                            sublabel={
                                <StarRatings
                                    starDimension="12px"
                                    starSpacing="2px"
                                    rating={parseFloat(data.rating ?? 0)}
                                    starRatedColor="#ffce3d"
                                    numberOfStars={5}
                                />
                            }
                            trailing={<div className="d-flex">
                                {/* <LinkIcon to="#" icon={chatIcon}>{L('แชท')}</LinkIcon> */}
                                {(isOwner !== false && path) &&
                                    <LinkIcon
                                        to={path}
                                        icon={editIcon}
                                        className="ml-3">
                                        {L('แก้ไข')}
                                    </LinkIcon>}
                            </div>}
                        />
                        <p className="p-2">{data.description}</p>
                        {(data.images && data.images.length !== 0) && <div className="d-flex flex-wrap">
                            {data.images && data.images.map((obj) =>
                                <img
                                    className="order-product-thumbail mr-3"
                                    src={System.resolveThumnailPath(obj.image, 'review')}>
                                </img>)}
                        </div>}
                    </div>
                </div>
                <HLine className="border-ligth my-2" />
            </>
        );
    }
}

export default ReviewItem;
