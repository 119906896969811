import React, { PureComponent } from 'react';
import Card from './Card';
import System from '../models/System';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import ChatHeader from './ChatHeader';
import Controller from '../models/Controller';
import API from '../models/API';
import { isEmpty } from '../utils/UObj';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

class ChatBox extends PureComponent {

    constructor(props) {
        super(props);

        this.controller = new Controller(this, {
            // message: '',
            // images: [],
        });

        this.count = 0;
        this.q = [];

        // console.log(this.props.data);
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        // console.log(prevProps, this.props);
    }

    componentWillUnmount() {

    }

    async sendHandler(message, images) {

        // console.log('sendHandler');

        const { data } = this.props;
        if (!data?.id || (!message && !images)) {
            return;
        }

        const c = this.controller;
        c.set('message', message);
        c.set('images', images);

        const params = { ...c.all() };

        let file, blob;
        let i = 0;
        for (let k in images) {
            if (!isEmpty(images[k]) && typeof images[k] === 'object') {
                continue;
            }
            blob = await fetch(images[k]).then(r => r.blob());
            file = new File([blob], 'image_' + i + '.jpg', { type: 'image/jpg' });
            params['image_' + i] = file;
            i++;
        }
        delete params['images'];

        const formData = new FormData();
        let value;
        for (let key in params) {
            value = params[key];
            if (isEmpty(value)) {
                continue;
            }
            formData.append(key, value);
        }
        this.q.push(formData);

        //create dummy item
        const itemId = 'unsend_' + (this.count++);
        formData.append('item_id', itemId);
        this.message.addMessage(itemId, {
            chat_room_id: data.id,
            created_at: moment().format(),
            id: itemId,
            images,
            message,
            user_id: System.profile?.id,
            process: 1,
        });
        this.roll();
        //test net slow
        // setTimeout(() => {
        //     this.roll();
        // }, 5000);
    }

    roll() {
        if (this.rolling) {
            return;
        }
        const formData = this.q.shift();
        if (formData) {
            const { data } = this.props;
            this.rolling = true;
            this.controller.postMultipathData(API.API_CHAT + '/' + data.id + '/messages/', formData,
                (data) => {
                    this.rolling = false;
                    //sync item
                    this.message.syncMessage(formData.get('item_id'), data);
                    this.roll();
                },
                (error) => {
                    //clear
                    this.rolling = false;
                    this.q = [];
                    window.openMessageModalDialog(error);
                });
        }
    }

    render() {
        const { data, showImageHandler } = this.props;
        // console.log(data);
        // console.log(showImageHandler);
        const h = isMobile ? '100%' : 'calc(100vh - 125px)';
        return (
            <Card className="d-flex flex-column" style={{ height: h }}>
                <ChatHeader data={data} onBackPress={this.props.onBackPress}></ChatHeader>
                <ChatMessage
                    data={data}
                    ref={(el) => this.message = el}
                    showImageHandler={showImageHandler.bind(this)}>
                </ChatMessage>
                <ChatInput sendHandler={this.sendHandler.bind(this)}></ChatInput>
            </Card>
        );
    }
}

export default ChatBox;