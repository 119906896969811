import React, { PureComponent } from 'react';
import { get as L } from '../models/Label';

import addFormIcon from '../assets/images/add-form-icon.svg';
import ErrorText from './ErrorText';
import { Link } from 'react-router-dom';

class FormTitle extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className={"form-title d-flex justify-content-between align-items-center " + (this.props.className ?? '')}>
                <div>
                    {this.props.label}
                    {this.props.subLabel && <div className="subtitle">{this.props.subLabel}</div>}
                    <ErrorText isError={this.props.isError}>{this.props.errorMessage}</ErrorText>
                </div>
                {this.props.onButtonPress &&
                    <img
                        className="pointer"
                        src={addFormIcon}
                        alt={L('เพิ่ม')}
                        onClick={() => this.props.onButtonPress()} />}
                {this.props.path &&
                    <Link to={this.props.path}>
                        <img
                            className="pointer"
                            src={addFormIcon}
                            alt={L('เพิ่ม')} />
                    </Link>}
                {this.props.addTo &&
                    <Link to={this.props.addTo}>
                        <img
                            className="pointer"
                            src={addFormIcon}
                            alt={L('เพิ่ม')}
                        />
                    </Link>}
                {this.props.children}
            </div>
        );
    }
}

export default FormTitle;
