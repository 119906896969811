import React, { PureComponent } from 'react';

class Label extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        const cls = this.props.noMargin ? '' : 'mb-2';
        return (
            <div className={"label-title " + cls + ' ' + (this.props.className ?? '')}>{this.props.children}</div>
        );
    }
}

export default Label;
