import React, { Component } from 'react';
import { get as L } from '../models/Label';

import InputBox from '../components/InputBox';

import removeImageIcon from '../assets/images/close-icon.svg';
import ErrorText from '../components/ErrorText';

class FarmFieldForm extends Component {

    constructor(props) {
        super(props);
        // this.state = this.props.data;
    };

    onChangeHandler(value, name) {
        // console.log(value, name);
        // this.setState({ [name]: value });
    }

    render() {
        const { controller, index } = this.props;
        // const { name, rai, ngan, square_wa } = this.state;
        // console.log(name, rai, ngan, square_wa);
        return <div className="position-relative form-dynamic form-register-farm">
            <div className="form-row">
                <div className="col-6">
                    <InputBox
                        label={L('ชื่อแปลง*')}
                        placeholder={L('ชื่อแปลงที่เพาะปลูก')}
                        name={'fields.name'}
                        index={index}
                        controller={controller}
                    // value={name}
                    // onChange={this.onChangeHandler.bind(this)}
                    />
                </div>
                <div className="col-6">
                    <InputBox
                        label={L('ไร่')}
                        placeholder={L('จำนวนไร่')}
                        name={'fields.rai'}
                        index={index}
                        controller={controller}
                    // value={rai}
                    // onChange={this.onChangeHandler.bind(this)}
                    />
                </div>
                <div className="col-6">
                    <InputBox
                        label={L('งาน')}
                        placeholder={L('จำนวนงาน')}
                        name={'fields.ngan'}
                        index={index}
                        controller={controller}
                    // value={ngan}
                    // onChange={this.onChangeHandler.bind(this)}
                    />
                </div>
                <div className="col-6">
                    <InputBox
                        label={L('ตารางวา')}
                        placeholder={L('จำนวนตารางวา')}
                        name={'fields.square_wa'}
                        index={index}
                        controller={controller}
                    // value={square_wa}
                    // onChange={this.onChangeHandler.bind(this)}
                    />
                </div>
                {this.props.showCloseButton === true && <img
                    className="btn-remove-form"
                    src={removeImageIcon}
                    alt={L('ลบ')}
                    onClick={this.props.closeButtonClickHandler.bind(this, this.props.index)} />}
                <ErrorText isError={this.props.isError}>{L('กรุณากรอกข้อมูลแปลงเพาะปลูกให้ถูกต้อง')}</ErrorText>
            </div>
        </div>;
    }
}

export default FarmFieldForm;
