export default class Event {

    static LOGIN = 'EVENT_LOGIN';
    static CART_CHANGE = 'CART_CHANGE';
    static INBOX_CHANGE = 'INBOX_CHANGE';
    static CHAT_CONTACT_CHANGE = 'CHAT_CONTACT_CHANGE';
    static CHAT_MESSAGE_CHANGE = 'CHAT_MESSAGE_CHANGE';
    static CHAT_READ_CHANGE = 'CHAT_READ_CHANGE';

    static count = 1;
    static map = {};

    static add(name, f) {
        this.count++;
        if (!this.map[name]) {
            this.map[name] = {};
        }
        this.map[name][this.count] = f;
        return name + ':' + this.count;
    }

    static remove(id) {
        if (id) {
            const tmp = id.split(':');
            delete this.map[tmp[0]][tmp[1]];
        }
    }

    static dispatch(name, param) {
        for (let k in this.map[name]) {
            if (param) {
                this.map[name][k](param);
            } else {
                this.map[name][k]();
            }
        }
    }

}