import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { get as L } from '../models/Label';
import API from '../models/API';
import Controller from '../models/Controller';
import System from '../models/System';
import { isEmpty, nf } from '../utils/UObj';
import { validateNumber } from '../utils/UValidate';
import { Carousel } from 'react-responsive-carousel';
import StarRatings from 'react-star-ratings';
import ShopItem from '../components/ShopItem';
import Label from '../components/Label';
import AppBar from '../components/AppBar';
import FormTitle from '../components/FormTitle';
import IncreaseInput from '../components/IncreaseInput';
import Cart from '../models/Cart';
import Button from '../components/Button';
import Icon from '../models/Icon';
import pinIcon from '../assets/images/farm-marker-icon.svg';
import GoogleMapReact from 'google-map-react';
import ReviewItem from '../components/ReviewItem';
const AnyReactComponent = ({ text }) => <div className="marker"><img src={pinIcon} alt="พิกัด" /></div>;

class NewDetailPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activity: {},
        };

        //check id
        const _path = System.matchPath(this.props.location.pathname, 'activity/:activityId');
        if (_path) {
            this.id = validateNumber(_path.params.activityId);
        }
        if (!this.id) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('home'));
            return;
        }

        //init query string
        const params = new URLSearchParams(this.props.location.search);
        const formData = {
        };

        this.controller = new Controller(this, {
            quantity: 1
        });

        this.days = {
            'mon': 'วันจันทร์',
            'tue': 'วันอังคาร',
            'wen': 'วันพุธ',
            'thu': 'วันพฤหัสบดี',
            'fri': 'วันศุกร์',
            'sat': 'วันเสาร์',
            'sun': 'วันอาทิตย์',
        };
    }

    componentDidMount() {
        if (this.isError) {
            return;
        }
        this.loadData();
    }

    loadData() {
        this.controller.loadData(API.API_ACTIVITY + '/' + this.id, 'activity', null, (data) => {
            // console.log(data);
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    addToCart(id, quantity) {
        Cart.add({ id, quantity });
    }

    checkout(id, quantity) {
        Cart.reset({ id, quantity });
        this.props.history.replace(System.resolvePath('user/cart'));
    }

    search(time = 800) {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            // console.log('timer');
            this.timer = null;

            //creaet query string
            const data = { ...this.controller.all() };
            for (let k in data) {
                if (isEmpty(data[k])) {
                    delete data[k];
                }
            }
            // console.log(data);
            this.props.history.push({
                search: "?" + new URLSearchParams(data).toString()
            });
            this.loadData();
        }, time);
    }

    resolveTime(t) {
        const tmp = t.split(':');
        return tmp[0] + ':' + tmp[1];
    }

    render() {
        const c = this.controller;
        const { activity } = this.state;
        const data = activity?.response;
        if (!data) {
            return null;
        }
        const { images, paragraphs, opens, lat, lng } = data;
        const { quantity } = c.all();

        const zoom = 9;
        const center = {
            lat: parseFloat(19.9105),
            lng: parseFloat(99.8406)
        };

        const profile = System.profile;
        // console.log(data.rating??0);
        return (
            <div className="section-content">
                <div className="container pb-5">
                    <AppBar
                        title={L('ข้อมูลกิจกรรม')}
                        showLeftArrow={true}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <div className="mb-4">
                        {(images && images.length) &&
                            <div className="box-shadow box-clip mb-4">
                                <Carousel
                                    showThumbs={false}
                                    showStatus={false}
                                    showIndicators={false}
                                    showArrows={true}
                                    autoPlay={true}
                                    infiniteLoop={true}
                                >
                                    {images.map((item, i) =>
                                        <div
                                            key={'a_i_' + i}
                                            style={{
                                                width: '100%',
                                                height: '320px',
                                                backgroundImage: 'url("' + System.resolveImagePath(item.image, 'product') + '")',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                            }} />)}
                                </Carousel>
                            </div>
                        }
                        <div className="row mb-4">
                            <div className="col-12 label-activity-name">{data.name}</div>
                            <div className="col-12 label-activity-desc">{data.description}</div>

                            <div className="col-12 form-row label-rating py-2">
                                <div className="col-auto">
                                    <span>
                                        <StarRatings
                                            starDimension="12px"
                                            starSpacing="2px"
                                            rating={parseFloat(data.rating ?? 0)}
                                            starRatedColor="#ffce3d"
                                            numberOfStars={5}
                                        />
                                    </span>
                                    <span className="ml-2">{data.rating}</span>
                                </div>
                                <div className="col-auto">{L('ให้คะแนน ') + data.review + ' คน'}</div>
                                <div className="col-auto">{L('ขายแล้ว ') + data.sold + ' ' + data.unit.name}</div>
                            </div>

                        </div>

                        <div className="row mb-4">
                            <div className="col-12 list-item-border mt-2 mb-3"></div>
                            <div className="col-12">
                                <ShopItem data={data.shop} />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-12 list-item-border mt-2 mb-3"></div>
                            {opens && opens.length !== 0 &&
                                <div className="col-12 col-md">
                                    <div className="row">
                                        <Label className="col-12">{L('เวลาทำการ')}</Label>
                                        {opens.map((item, index) => <React.Fragment key={"open_" + index}>
                                            <div className="col-auto font-size-0" style={{ width: '120px' }}>{this.days[item.day]}</div>
                                            <div className="col-auto font-size-0" style={{ width: '100%', maxWidth: '140px' }}>{L('เวลา')} {this.resolveTime(item.start_time)} {L('น.')}</div>
                                            <div className="col-auto font-size-0">{L('ถึง')} {this.resolveTime(item.end_time)} {L('น.')}</div>
                                            <div className="w-100"></div>
                                        </React.Fragment>)}
                                    </div>
                                </div>
                            }
                            <div className="col-12 col-md mt-3 mt-md-0">
                                <div className="form-row">
                                    <Label className="col-12">{L('สั่งจองกิจกรรม')}</Label>
                                    <div className="col-12 my-2"><span className="label-product-price">฿{nf(data.price)}</span> / {data.unit.name}</div>
                                    {parseInt(data.quantity) !== 0
                                        ? <>
                                            <div className="col-auto">
                                                <IncreaseInput
                                                    name="quantity"
                                                    min={1}
                                                    max={data.quantity}
                                                    controller={c}
                                                />
                                            </div>
                                            <div className="col-auto d-flex align-items-center">{L('จากทั้งหมด')} {nf(data.quantity)} {data.unit.name}</div>
                                            <div className="col-12 mb-3"></div>
                                            <div className="col">
                                                <Button
                                                    onClick={() => this.checkout(data.id, quantity)}
                                                    className="btn btn-primary"
                                                >{L('จองตอนนี้')}
                                                </Button>
                                            </div>
                                            <div className="col-auto">
                                                <Button
                                                    onClick={() => Cart.add(data.id, quantity)}
                                                    className="btn btn-primary"
                                                >{L('เพิ่มในตะกร้า')}
                                                </Button>
                                            </div>
                                        </>
                                        : <div className="col-12">{L('สินค้าหมด')}</div>}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 list-item-border mt-2 mb-3"></div>
                            {data?.detail && <>
                                <Label className="col-12">{L('รายละเอียดกิจกรรม')}</Label>
                                <div className="col-12 pre-line font-size-1 mb-5">{data?.detail}</div>
                            </>}
                            {paragraphs?.map((item, index) =>
                                <React.Fragment key={"paragraph_" + index}>
                                    <div className="col-12 d-flex justify-content-center mb-3">
                                        <img className="image-activity" src={System.resolveImagePath(item.image, 'product')} alt={L(item.description)} />
                                    </div>
                                    <div className="col-12 pre-line font-size-1 text-center mb-4">{item.description}</div>
                                </React.Fragment>
                            )}
                        </div>

                        {(data.lat && data.lng) && <div className="row mb-4">
                            <div className="col-12 list-item-border mt-2 mb-3"></div>
                            <Label className="col-12">{L('ตำแหน่งที่ตั้ง')}</Label>
                            <div className="w-100" style={{ height: '320px' }}>
                                <GoogleMapReact
                                    key={'m_s_' + data.id}
                                    options={{ mapTypeControl: false }}
                                    bootstrapURLKeys={{ key: 'AIzaSyBYl4VWLo7Lj2VYK4Pd-uri1LNH2G6c8J8' }}
                                    // yesIWantToUseGoogleMapApiInternals
                                    defaultCenter={center}
                                    defaultZoom={zoom}
                                >
                                    <AnyReactComponent
                                        lat={lat}
                                        lng={lng}
                                        text="My Marker"
                                    />
                                </GoogleMapReact>
                            </div>
                        </div>}

                        <div className="row mb-5">
                            <div className="col">
                                <FormTitle
                                    label={L('คะแนนของกิจกรรม')}
                                    path={profile ? System.resolvePath('activity/' + data.id + '/review/create') : null}
                                />
                                {data.reviews && data.reviews.map((item) => <ReviewItem
                                    key={'a_r_' + item.id} data={item}
                                    profile={System.profile}
                                    path={System.resolvePath('activity/' + this.id + '/review/' + item.id)}
                                />)}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(NewDetailPage);
