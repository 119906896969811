import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';

import InputBox from '../components/InputBox';
import Controller from '../models/Controller';
import Form from '../components/Form';
import Button from '../components/Button';

import { validateEmptyValue, validateNumber } from '../utils/UValidate';

import { isMobile } from 'react-device-detect';
import AppBar from '../components/AppBar';
import System from '../models/System';
import TextAreaBox from '../components/TextAreaBox';
import API from '../models/API';

class AdminProductCategoryFormPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            valid: {},
        };

        this.controller = new Controller(this, {
            images: [],
        });

        if (!System.isAdmin()) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('home'));
            return;
        }

        //check id
        const _path = System.matchPath(this.props.location.pathname, 'admin/product-category/:catId');
        if (_path) {
            this.id = validateNumber(_path.params.catId);
            if (this.id) {
                this.isEdit = true;
            }
        }
    };

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        if (!this.isEdit) {
            return;
        }
        this.controller.loadData(API.API_PRODUCT_CATEGORY + '/' + this.id, 'productCategory', null, (data) => {
            if (!data) {
                this.isError = true;
                this.props.history.replace(System.resolvePath('admin/product-category'));
                return;
            }
            this.controller.append(data);
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    async delete() {
        await this.setState({ openDeleteDialog: true });
        window.openModalDialog('deleteModal', () => {
            this.setState({ openDeleteDialog: false });
        });
    }

    confirmDelete() {
        this.controller.deleteData(API.API_PRODUCT_CATEGORY + '/' + this.id,
            (data) => {
                window.hideModalDialog('deleteModal');
                this.setState({ openDeleteDialog: false });
                this.props.history.goBack();
            },
            (error) => {
                window.openMessageModalDialog(error);
            });
    }

    async submit() {
        this.setState({ valid: {} });

        const c = this.controller;
        const params = { ...c.all() };

        const name = params['name'] ?? '';
        const description = params['description'] ?? '';

        const valid = {
            name: validateEmptyValue(name),
        };
        // console.log(valid);

        for (let k in valid) {
            if (valid[k] === false) {
                this.setState({ valid });
                return;
            }
            if (Array.isArray(valid[k])) {
                for (let i in valid[k]) {
                    if (valid[k][i] === false) {
                        this.setState({ valid });
                        return;
                    }
                }
            }
        }

        if (this.isEdit) {
            this.controller.updateData(API.API_PRODUCT_CATEGORY + '/' + this.id,
                (data) => {
                    this.props.history.goBack();
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        } else {
            this.controller.postData(API.API_PRODUCT_CATEGORY,
                (data) => {
                    this.props.history.goBack();
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        }

    }

    render() {
        if (this.isError) {
            return null;
        }
        // console.log('AdminPlantFormPage render');
        const c = this.controller;
        const { valid, post, update, destroy, openDeleteDialog } = this.state;
        const loading = (post && post.loading) || (update && update.loading) || (destroy && destroy.loading);

        return (
            <div className="section-content no-nav">
                <Form
                    loading={post.loading}
                    className="form-register">
                    <AppBar
                        title={L('ข้อมูลประเภทสินค้า')}
                        subTitle={L('กรอกข้อมูลประเภทสินค้าและคำอธิบาย')}
                        showLeftArrow={true}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <InputBox
                                label={L('ชื้อประเภทสินค้า*')}
                                placeholder={L('ชื้อประเภทสินค้า')}
                                name="name"
                                controller={c}
                                isError={valid.name === false}
                                errorMessage={L('กรุณากรอกชื้อประเภทสินค้า')} />
                        </div>
                        <div className="col-12">
                            <TextAreaBox
                                label={L('คำอธิบายชื้อประเภทสินค้า')}
                                placeholder={L('ลักษณะ, จุดเด่น')}
                                name="description"
                                controller={c}
                                isError={valid.description === false}
                                errorMessage={L('กรุณากรอกคำอธิบายชื้อประเภทสินค้า')} />
                        </div>
                    </div>

                    <div className="form-row mt-4">
                        {this.isEdit && <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => this.delete()}>
                                {L('ลบข้อมูล')}
                            </Button>
                        </div>}
                        <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.submit()}>
                                {L('บันทึกข้อมูล')}
                            </Button>
                        </div>
                    </div>

                </Form>

                {openDeleteDialog && <div id="deleteModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('ลบข้อมูล')}</h5>
                            </div>
                            <div className="modal-body">
                                <p>{L('คุณต้องการลบข้อมูลนี้ใช่หรือไม่')}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-3"
                                    onClick={() => {
                                        window.hideModalDialog('deleteModal');
                                        this.setState({ openDeleteDialog: false });
                                    }}>{L('ยกเลิก')}</button>
                                <button
                                    type="button"
                                    className="btn btn-primary mr-3"
                                    onClick={() => {
                                        this.confirmDelete();
                                    }}>{L('ลบข้อมูล')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}

export default withRouter(AdminProductCategoryFormPage);
