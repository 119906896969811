import React, { Component } from 'react';
import { get as L } from '../models/Label';
import Label from './Label';
import ChatRightSectionContent from './ChatRightSectionContent';

class ChatRightSection extends Component {

    constructor(props) {
        super(props);
    };

    render() {
        const cls = this.props.visible !== true ? 'd-none' : '';
        // console.log(chat);
        return (
            <div className={'section-right ' + cls}>
                <div className="position-sticky d-flex flex-column py-3 px-3" style={{ top: '64px', height: 'calc(100vh - 64px)' }}>
                    <Label>{L('รายชื่อผู้สนทนา')}</Label>
                    <div className="chat-contact-list scroll-inner">
                        <ChatRightSectionContent key='c_r_s' visible={this.props.visible}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChatRightSection;
