import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import HomeLeftSectionContent from '../components/HomeLeftSectionContent';

class OtherPage extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className="section-content">
            <div className="flex-1">
                <HomeLeftSectionContent key="left_section_home" />
            </div>
        </div>;
    }
}

export default withRouter(OtherPage);
