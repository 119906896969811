import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';

import InputBox from '../components/InputBox';
import InputImageBox from '../components/InputImageBox';
import FormTitle from '../components/FormTitle';
import Controller from '../models/Controller';
import Form from '../components/Form';
import Button from '../components/Button';

import AddressForm from '../components/AddressForm';

import pinIcon from '../assets/images/pin-icon.svg';
import MapSelector from '../components/MapSelector';
import { validateEmptyValue, validateNumber } from '../utils/UValidate';
import { isEmpty } from '../utils/UObj';

import { isMobile } from 'react-device-detect';
import AppBar from '../components/AppBar';
import System from '../models/System';
import API from '../models/API';

class UserLogisticsFormPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            valid: {},
            showMapDialog: false
        };

        //check id
        const _path = System.matchPath(this.props.location.pathname, 'user/cart/location/:productId');
        if (_path) {
            this.id = validateNumber(_path.params.productId);
            if (this.id) {
                this.isEdit = true;
            }
        }


        this.controller = new Controller(this, {
            ...this.props.location.formData
        });


        //check prop
        if (!this.props.location.parantPath) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('user/cart'));
            return;
        }
    };

    componentDidMount() {
        if (this.isError) {
            return;
        }
        this.loadLocationData();
    }

    loadLocationData() {
        if (this.isEdit) {
            this.controller.loadData(API.API_ORDER_LOCATION + '/' + this.id, 'location', null, (data) => {
                this.controller.append(data);
            });
        }
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    async openMapDialog() {
        this.lat = null;
        this.lng = null;
        await this.setState({ showMapDialog: true });
        window.openModalDialog('mapModal', () => {
            this.setState({ showMapDialog: false });
        });
    }

    closeMapDialog() {
        // console.log(this.lat, this.lng);
        if (this.lat && this.lng) {
            this.controller.set('lat', this.lat);
            this.controller.set('lng', this.lng);
        }
        window.hideModalDialog('mapModal');
        this.setState({ showMapDialog: false });
    }

    async submit() {
        this.setState({ valid: {} });

        const c = this.controller;
        const params = { ...c.all() };

        const name = params['name'] ?? '';
        // const moo = params['moo'] ?? '';
        const house_number = params['house_number'] ?? '';
        const location_id = params['location_id'] ?? '';

        const valid = {
            name: validateEmptyValue(name),
            // moo: validateEmptyValue(moo),
            house_number: validateEmptyValue(house_number),
            location_id: validateEmptyValue(location_id),
        };

        for (let k in valid) {
            if (valid[k] === false) {
                this.setState({ valid });
                return;
            }
            if (Array.isArray(valid[k])) {
                for (let i in valid[k]) {
                    if (valid[k][i] === false) {
                        this.setState({ valid });
                        return;
                    }
                }
            }
        }

        if (this.isEdit) {
            this.controller.updateData(API.API_ORDER_LOCATION + '/' + this.id,
                (data) => {
                    this.props.history.replace({
                        pathname: System.resolvePath(this.props.location.parantPath),
                        formData: {
                            ...this.props.location.formData,
                            location_id: data.id,
                        },
                    });
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        } else {
            this.controller.postData(API.API_ORDER_LOCATION,
                (data) => {
                    this.props.history.replace({
                        pathname: System.resolvePath(this.props.location.parantPath),
                        formData: {
                            ...this.props.location.formData,
                            location_id: data.id,
                        }
                    });
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        }
    }

    render() {

        if (this.isError) {
            return null;
        }

        // console.log('UserFarmPage render');
        const c = this.controller;
        const data = c.all();
        let { lat, lng } = data;
        const { valid, post, update, showMapDialog, zipCode } = this.state;
        if (!lat && !lng && zipCode && zipCode.response) {
            // lat = zipCode.response.lat;
            // lng = zipCode.response.lng;
        }

        return (
            <div className="section-content no-nav">
                <Form
                    loading={post.loading}
                    className="form-register">
                    <AppBar
                        title={L('ข้อมูลที่อยู่การจัดส่งสินค้า')}
                        subTitle={L('กรอกข้อมูลที่อยู่การจัดส่งสินค้า')}
                        showLeftArrow
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />

                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <InputBox
                                label={L('ชื่อสถานที่*')}
                                placeholder={L('กรอกชื่อสถานที่')}
                                name="name"
                                controller={this.controller}
                                isError={valid.name === false}
                                errorMessage={L('กรุณากรอกชื่อสถานที่')} />
                        </div>
                    </div>

                    <FormTitle
                        label={L('ข้อมูลผู้รับสินค้า')} />
                    <div className="form-row form-section-register">
                        <div className="col-6">
                            <InputBox
                                label={L('ชื่อผู้รับสินค้า')}
                                placeholder={L('ชื่อ-นามสกุล,ชื่อเล่น')}
                                name="recipient_name"
                                controller={this.controller}
                            />
                        </div>
                        <div className="col-6">
                            <InputBox
                                label={L('เบอร์โทรศัพท์')}
                                placeholder={L('0XX-XXXXXXX')}
                                name="phone_number"
                                controller={this.controller}
                            />
                        </div>
                    </div>

                    <FormTitle
                        label={L('ข้อมูลที่ตั้งสถานที่จัดส่งสินค้า')}
                        isError={valid.location_id === false}
                        errorMessage={L('กรุณากรอกข้อมูลที่อยู่ให้ครบ')} />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            <InputBox
                                label={L('หมู่บ้าน')}
                                placeholder={L('ชื่อหมู่บ้าน')}
                                name="village_name"
                                controller={this.controller} />
                        </div>
                        <div className="col-6">
                            <InputBox
                                label={L('เลขที่*')}
                                placeholder={L('บ้านเลขที่')}
                                name="house_number"
                                controller={this.controller}
                                isError={valid.house_number === false}
                                errorMessage={L('กรุณากรอกบ้านเลขที่')} />
                        </div>
                        <div className="col-6">
                            <InputBox
                                label={L('หมู่ที่')}
                                placeholder={L('หมู่ที่')}
                                name="moo"
                                controller={this.controller}
                                isError={valid.moo === false}
                                errorMessage={L('กรุณากรอกหมู่ที่')} />
                        </div>

                        <AddressForm
                            controller={this.controller} data={data.location} />

                        <div className="col-6">
                            <InputBox
                                label={L('ละติจูด')}
                                name="lat"
                                controller={this.controller}
                                placeholder={L('xx.xxxxxxxxxx')} />
                        </div>
                        <div className="col-6 position-relative">
                            <InputBox
                                label={L('ลองติจูด')}
                                name="lng"
                                controller={this.controller}
                                placeholder={L('xx.xxxxxxxxxx')} />
                            <img
                                className="btn-pin-farm"
                                src={pinIcon}
                                alt={L('เลือกพิกัดจากแผนที่')}
                                onClick={this.openMapDialog.bind(this)} />
                        </div>
                    </div>

                    <div className="form-row mt-4">
                        <div className="col-12">
                            <Button
                                loading={post.loading || update.loading}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.submit()}>
                                {L('บันทึกข้อมูล')}
                            </Button>
                        </div>
                    </div>

                </Form>

                {showMapDialog && <div
                    id="mapModal"
                    className="modal modal-full"
                    tabIndex="-1"
                    role="dialog"
                    data-keyboard="false"
                    data-backdrop="static">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('เลือกพิกัดจากแผนที่')}</h5>
                            </div>
                            <div className="modal-body modal-body-map">
                                <MapSelector
                                    lat={lat}
                                    lng={lng}
                                    onChange={(data) => {
                                        this.lat = data.lat;
                                        this.lng = data.lng;
                                    }} />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.closeMapDialog();
                                    }}>{L('ยืนยัน')}</button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        this.lat = null;
                                        this.lng = null;
                                        this.closeMapDialog();
                                    }}>{L('ยกเลิก')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}

export default withRouter(UserLogisticsFormPage);
