import firebase from 'firebase/app';
import 'firebase/messaging';
import API from './API';
import Chat from './Chat';
import Event from './Event';
import Inbox from './Inbox';
import Request from './Request';

export default class Notification {

    static messaging;
    static isOnline = false;

    static init() {
        // console.log('Notification init');
        const firebaseConfig = {
            apiKey: "AIzaSyCd3ylD12uoE_k1MNKsKh-Pbe-O9lRRPrM",
            authDomain: "northorganicfood-32de4.firebaseapp.com",
            projectId: "northorganicfood-32de4",
            storageBucket: "northorganicfood-32de4.appspot.com",
            messagingSenderId: "1059158178327",
            appId: "1:1059158178327:web:50ce00f3ec59f362777a52",
            measurementId: "G-2G3DS18SKX"
        };
        // Initialize Firebase
        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        } else {
            firebase.app(); // if already initialized, use that one
        }
        // console.log('firebase.messaging', firebase.messaging);
        if (firebase.messaging && firebase.messaging.isSupported()) {
            this.messaging = firebase.messaging();
        }

    }

    static commitToken() {
        if (!this.messaging) {
            console.log('No messaging available.');
            return;
        }
        const vapidKey = 'BC9vxA7tvv-MC6B6jUcD3ugdPzJdw8f6HpRJ05LEqZHMBk57aU6aK54vtCYnyL1bF8Si2iplkbFdzNoy_SmyKMM';
        this.messaging.getToken({ vapidKey }).then((currentToken) => {
            if (currentToken) {
                // Send the token to your server and update the UI if necessary
                // ...
                // console.log(currentToken);
                Request.pos(API.API_USER_NOTI_TOKEN, { token: currentToken },
                    (data) => {
                        // console.log('update token complete');
                        this.isOnline = true;
                        this.litsen();
                    }, (error) => {
                        console.log(error);
                    });
            } else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
                // ...
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // ...
        });
    }

    static handler(event) {
        switch (event) {
            case 'inbox':
                Inbox.loadUnreadCount();
                break;
            case 'message':
                // console.log('onMessage message');
                // should check in current room before update
                Event.dispatch(Event.CHAT_MESSAGE_CHANGE);
                // should check in chat page to show contact before update
                Event.dispatch(Event.CHAT_CONTACT_CHANGE);
                Chat.loadUnreadCount(); //must update
                break;
            default:
                break;
        }
    }

    static litsen() {
        if (!this.messaging) {
            console.log('No messaging available.');
            return;
        }
        this.messaging.onMessage((payload) => {
            // console.log('Message received. ', payload);
            // ...
            // console.log(payload.data);
            this.handler(payload?.data?.event);
        });

        //background
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('message', function (event) {
                // console.log('event listener', event);
                Notification.handler(event.data);
            });
        }
    }

    static async askForPermissionToReceiveNotifications() {
        try {
            await this.messaging.requestPermission();
        } catch (error) {
            console.error(error);
        }
    }

}