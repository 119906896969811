import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Button from '../components/Button';
import EmptyBox from '../components/EmptyBox';
import ListItemCart from '../components/ListItemCart';
import API from '../models/API';
import Cart from '../models/Cart';
import Controller from '../models/Controller';
import { get as L } from '../models/Label';
import System from '../models/System';
import basketIcon from '../assets/images/basket-icon-color.svg';
import rightIcon from '../assets/images/arrow-right-icon.svg';
import HLine from '../components/HLine';
import ShopItem from '../components/ShopItem';
import FormTitle from '../components/FormTitle';
import { address, nf } from '../utils/UObj';
import { isMobile } from 'react-device-detect';
import AppBar from '../components/AppBar';

class UserCartPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            cart: {
                response: [
                    {
                        id: 'dummy',
                        products: [
                            { id: 'dummy' },
                            { id: 'dummy' },
                            { id: 'dummy' },
                        ]
                    },
                ]
            }
        };
        const formData = this.props.location?.formData;
        this.controller = new Controller(this, {
            ...formData,
        });
        this.map = {};
    }

    componentDidMount() {
        if (this.isError) {
            return;
        }
        this.loadData();
        this.loadLocation();
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    loadData() {
        const products = [];
        const items = Cart.all();
        this.map = {};
        for (let k in items) {
            this.map[items[k].id] = items[k].quantity;
            products.push(items[k].id);
        }
        if (products.length) {
            this.controller.loadData(API.API_USER_CART, 'cart', { products }, (data) => {
                // console.log(data);
            });
        } else {
            this.setState({
                cart: {
                    response: []
                }
            });
        }
        // console.log(this.map);
    }

    loadLocation() {
        const locationId = this.controller.get('location_id');
        if (locationId) {
            this.controller.loadData(API.API_ORDER_LOCATION + '/' + locationId, 'location', null, (data) => {
                // console.log(data);
            });
        }
    }

    async delete() {
        await this.setState({ openDeleteDialog: true });
        window.openModalDialog('deleteModal', () => {
            this.setState({ openDeleteDialog: false });
        });
    }

    confirmDelete() {
        this.map = {};
        Cart.clear();
        this.setState({
            cart: {
                response: []
            }
        });
        window.hideModalDialog('deleteModal');
    }

    checkout() {

        if (!System.isLogged()) {
            window.openMessageModalDialog(L('กรูณาสมัครเป็นสมาชิกก่อนซื้อสินค้า'));
            return;
        }

        this.controller.set('products', Cart.all());
        this.controller.postData(API.API_CHECK_OUT, (data) => {
            Cart.clear();
            this.props.history.replace(System.resolvePath('market'));
        }, (error) => {
            if (error === 'product not enough') {
                window.openMessageModalDialog(L('สินค้าไม่เพียงพอ'));
            } else {
                window.openMessageModalDialog(error);
            }
        });
    }

    itemOnChangeHandler(id, value) {
        const items = Cart.all();
        for (let k in items) {
            if (parseInt(items[k].id) === parseInt(id)) {
                this.map[items[k].id] = value;
                items[k].quantity = value;
                this.controller.commit();
                break;
            }
        }
    }

    itemOnClickDeleteHandler(id) {
        Cart.remove(id);
        const { cart } = this.state;
        const list = cart?.response ?? [];
        for (let i in list) {
            for (let j in list) {
                if (parseInt(list[i].products[j].id) === parseInt(id)) {
                    list[i].products.splice(j, 1);
                    // console.log(cart);
                    this.setState({ cart });
                    return;
                }
            }
        }
    }

    getItem(item) {
        let amount = 0;
        return <div key={'s_' + item.id} className="mb-4">
            <ShopItem data={item} />
            <HLine className="my-3"></HLine>
            <div className="form-row">
                {item.products && item.products.map((obj) => {
                    amount += obj.price * this.map[obj.id];
                    return <ListItemCart
                        key={'p_' + obj.id}
                        quantity={this.map[obj.id]}
                        icon={System.resolveThumnailPath(obj.thumbnail?.image, 'product')}
                        className="col-12"
                        data={obj}
                        onChange={this.itemOnChangeHandler.bind(this, obj.id)}
                        onDelete={this.itemOnClickDeleteHandler.bind(this, obj.id)}
                    />
                })}
            </div>
            <div className="form-row">
                <div className="col-12 list-tile-total py-2 mt-2">
                    <div className="text-right">{L('ยอดซื้อทั้งหมด')} <b className="list-tile-price">฿{nf(amount, 2)}</b></div>
                    <div className="text-right form-desc">*{L('ยอดทั้งหมดไม่รวมการขนส่ง การขนส่งผู้ขายต้องตกลงกับลูกค้าเอง')}</div>
                </div>
            </div>
        </div>
    }

    getAddress() {
        const l = this.state.location?.response;
        if (l) {
            return <>
                <div>{l.name}</div>
                <div className="list-tile-sublabel dark">{L('ผู้รับ')} {l.recipient_name ?? '-'} {L('โทร.')} {l.phone_number ?? '-'}</div>
                <div className="list-tile-sublabel">{address(l)}</div>
            </>;
        }
        return <>
            <div>{L('ยังไม่ได้ระบุสถานที่ส่ง')}</div>
            <div className="list-tile-sublabel">{L('เลือกสถานที่ส่งหรือ สามารถระบุสถานที่กับร้านค้าได้โดยตรงทางช่องทางแชท')}</div>
        </>;
    }

    getAppBar() {
        if (!isMobile) {
            return null;
        }
        return <AppBar
            title={L('รายการสินค้าในตะกร้า')}
            showLeftArrow={true}
            onBackPress={() => {
                this.props.history.goBack();
            }}
        />;
    }

    render() {

        const { cart, openDeleteDialog, loading } = this.state;
        const list = cart?.response ?? [];

        if (list.length === 0) {
            return <div className="section-content">
                <div className="cart-list">
                    {this.getAppBar()}
                    <EmptyBox
                        className="mt-5"
                        title={L('ไม่พบข้อมูล')}
                        icon={basketIcon}
                        description={L('ไม่พบข้อมูลรายการสินค้าของท่าน')}
                        buttonLabel={L('กลับไปหน้ารายการสินค้า')}
                        onButtonClick={() => {
                            // console.log('onButtonClick');
                            this.props.history.replace(System.resolvePath('market'));
                        }}
                    />
                </div>
            </div>;
        }

        const data = this.controller.all();
        const selfPath = "user/cart";
        const logisticsPath = selfPath + "/location";
        const logistics = this.getAddress();
        const logged = System.isLogged();

        return (
            <div className="section-content">
                <div className="cart-list">

                    {this.getAppBar()}

                    {list && list.map((item) => this.getItem(item))}

                    <FormTitle
                        label={L('สถานที่จัดส่งสินค้า')}
                        subLabel={L('กำหนดสถานที่การจดส่งสินค้า')}
                        addTo={logged ? {
                            pathname: System.resolvePath(logisticsPath + '/create'),
                            parantPath: selfPath,
                            formData: data,
                        } : null} />
                    <div className="form-row form-section-register mb-5">
                        <div className="col-12">
                            {logged
                                ? <Link to={{
                                    pathname: System.resolvePath(logisticsPath),
                                    parantPath: selfPath,
                                    formData: data,
                                }}>
                                    <div className="input-next mb-3">
                                        <div>{logistics}</div>
                                        <img src={rightIcon} alt={logistics} />
                                    </div>
                                </Link>
                                : <div className="pb-2">{L('คุณต้องสมัครเป็นสมาชิกก่อนกำหนดสถานที่จัดส่ง')}</div>}
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => this.delete()}>
                                {L('ลบข้อมูล')}
                            </Button>
                        </div>
                        {logged && <div className="col">
                            <Button
                                onClick={() => this.checkout()}
                                className="btn btn-primary"
                            >{L('สั่งซื้อสินค้า')}</Button>
                        </div>}
                    </div>
                </div>

                {openDeleteDialog && <div id="deleteModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('ลบข้อมูล')}</h5>
                            </div>
                            <div className="modal-body">
                                <p>{L('คุณต้องการลบข้อมูลนี้ใช่หรือไม่')}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-3"
                                    onClick={() => {
                                        window.hideModalDialog('deleteModal');
                                        this.setState({ openDeleteDialog: false });
                                    }}>{L('ยกเลิก')}</button>
                                <button
                                    type="button"
                                    className="btn btn-primary mr-3"
                                    onClick={() => {
                                        this.confirmDelete();
                                    }}>{L('ลบข้อมูล')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}

export default withRouter(UserCartPage);