import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Table from '../components/Table';
import Controller from '../models/Controller';
import { get as L } from '../models/Label';
import System from '../models/System';
import { genDummyCells, renderDate, renderImage } from '../utils/UTable';
import queryString from 'query-string';
import AppBar from '../components/AppBar';
import { isEmpty } from '../utils/UObj';
import Request from '../models/Request';
import Pagination from '../components/Pagination';
import { validateNumber } from '../utils/UValidate';
import API from '../models/API';

class AdminNewsPage extends Component {

    constructor(props) {
        super(props);

        const tableHeaders = [
            { title: L('ลำดับที่'), data: 'row_number', width: '72px' },
            { title: L(''), data: 'thumnail', render: renderImage, path: 'news', width: '128px' },
            { title: L('วันที่'), data: 'created_at', width: '100px', render: renderDate, dateOption: { 'type': 'short', 'noDay': true, 'noTime': true }, className: 'text-center' },
            { title: L('หัวข้อข่าว'), data: 'name' },
            { title: L('จำนวนผู้อ่าน'), data: 'read_count', width: '150px', className: 'text-center' },
        ];

        const { search_string, page, sorts } = queryString.parse(this.props.location.search);

        if (sorts) {
            const sortArr = sorts.split(',');
            const map = {};
            let tmp;
            for (let i in sortArr) {
                tmp = sortArr[i].split(':');
                map[tmp[0]] = tmp[1];
            }
            for (let i in tableHeaders) {
                tmp = tableHeaders[i].data;
                if (tableHeaders[i].sortEnabled) {
                    if (map[tmp]) {
                        tableHeaders[i].sort = map[tmp];
                    } else {
                        tableHeaders[i]['sort'] = 'def';
                    }
                }
            }
        }

        this.state = {
            totalRecords: null,
            totalRows: null,
            totalPages: null,
            row: {
                loading: false,
                response: {
                    list: genDummyCells(System.MAX_ROWS, [], tableHeaders),
                },
            }
        };

        this.controller = new Controller(this, {
            tableHeaders,
            search_string,
            currentPage: parseInt(!validateNumber(page) ? 1 : page),
        });

        if (!System.isAdmin()) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('home'));
            return;
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        // console.log('load data');
        const {
            currentPage,
            tableHeaders,
            search_string,
        } = this.controller.all();
        let h;
        let sorts = [];
        for (let k in tableHeaders) {
            h = tableHeaders[k];
            if (h.sortEnabled && h.sort) {
                sorts.push(h.data + ':' + h.sort);
            }
        }
        let search_columns = '';
        if (!isEmpty(search_string) && search_string.trim()) {
            search_columns = 'name,description';
        }
        const params = {
            search_string: search_string ?? '',
            search_columns,
            sorts: sorts.toString(),
            page: currentPage,
            limit: System.MAX_ROWS,
        }

        this.controller.loadData(API.API_ADMIN_NEWS, 'row', params, (data) => {
            // console.log('load data complete');
            // console.log(data);
            genDummyCells(System.MAX_ROWS, data.list, tableHeaders);
        });

        // console.log('pushPath');
        // this make overflow setState
        this.pushPath = true;
        this.props.history.push({
            search: Request.getQueryString(params),
        });
    }

    shouldComponentUpdate() {
        // console.log(this.pushPath);
        const should = this.pushPath !== true;
        this.pushPath = false;
        return should;
    }

    componentWillUnmount() {
        this.controller.destroy();
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    search(text) {
        // console.log(text);
        this.controller.set('search_string', text);
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            // console.log('timer');
            this.timer = null;
            this.loadData();
        }, 800);
    }

    sortChangeHandler(col) {
        this.loadData();
    }

    rowClickHandler(item) {
        // console.log(item);
        this.props.history.push(System.resolvePath('admin/news/' + item.id));
    }

    render() {
        if (this.isError) {
            return null;
        }
        // console.log('AdminUserPage render');
        const { row } = this.state;
        const data = this.controller.all();
        // console.log(row);
        const { search_string, currentPage, tableHeaders } = data;
        const response = row?.response ?? {};
        const { list, total_rows } = response;
        // console.log(row?.response?.list);
        // console.log(currentPage);
        return (
            <div className="section-content flex-column justify-content-start">
                <AppBar
                    title={L('รายการข่าว')}
                    showLeftArrow={false}>
                    <div className="flex-1 d-flex justify-content-end">
                        <input
                            type="text"
                            className="form-control input-search-table"
                            placeholder={L('หัวข้อข่าว,รายละเอียด')}
                            defaultValue={search_string}
                            onChange={(event) => {
                                this.controller.set('currentPage', 1);
                                this.search(event.target.value);
                            }}
                        />
                    </div>
                </AppBar>
                <div className="d-flex justify-content-between">
                    <Pagination
                        id="pagination_news_1"
                        onChange={(name, value) => {
                            if (name === Pagination.PAGE_CHANGE) {
                                // console.log(value);
                                this.controller.set('currentPage', value);
                                this.loadData();
                                System.scrollTo(0, 0);
                            }
                        }}
                        data={{
                            pageSize: System.MAX_ROWS,
                            dataLength: total_rows ?? 0,
                            currentPage: currentPage,
                        }} />

                    <Link to={System.resolvePath('admin/news/create')}>
                        <button type="button" className="btn btn-primary">{L('เพิ่มข่าว')}</button>
                    </Link>
                </div>
                <Table
                    id="product_category"
                    maxRows={System.MAX_ROWS}
                    loading={row?.loading === true}
                    headers={tableHeaders}
                    data={list ?? []}
                    sortChange={this.sortChangeHandler.bind(this)}
                    onRowClick={this.rowClickHandler.bind(this)} />
                <div className="d-flex justify-content-between pt-3">
                    <Pagination
                        id="pagination_news_2"
                        onChange={(name, value) => {
                            if (name === Pagination.PAGE_CHANGE) {
                                // console.log(value);
                                this.controller.set('currentPage', value);
                                this.loadData();
                                System.scrollTo(0, 0);
                            }
                        }}
                        data={{
                            pageSize: System.MAX_ROWS,
                            dataLength: total_rows ?? 0,
                            currentPage: currentPage,
                        }} />
                </div>
            </div>
        );
    }
}

// const menuItemStyle = {width: '32px', height: '32px' };

export default withRouter(AdminNewsPage);
