import React, { Component } from 'react';

import TableHead from './TableHead';
import TableBody from './TableBody';
import TableFoot from './TableFoot';

class Table extends Component {

	constructor(props) {
		super(props);
	}

	headerClickHandler(item) {
		// console.log(item.sort);
		if (item.sortEnabled) {
			if (!item.sort) {
				item.sort = 'asc';
			} else if (item.sort === 'asc') {
				item.sort = 'desc';
			} else {
				delete item['sort'];
			}
			this.setState({});
			if (this.props.sortChange) {
				this.props.sortChange(item);
			}
		}
	}

	rowClickHandler(item) {
		if (item.id !== 'dummy') {
			if (this.props.onRowClick) {
				this.props.onRowClick(item);
			}
		}
	}

	render() {
		const {
			id,
			headers,
			data,
			footers,
			loading,
		} = this.props;
		// console.log('Table render =>', loading);
		return (
			<div className="table-container">
				<table id={id + '_table'} className="table-hover">
					<TableHead
						id={id + '_table_header'}
						data={headers}
						headerClickHandler={this.headerClickHandler.bind(this)}
					/>
					<TableBody
						id={id + '_table_body'}
						headers={headers}
						data={data}
						rowClickHandler={this.rowClickHandler.bind(this)}
					/>
					{(footers && footers.length) &&
						<TableFoot id={id + '_table_footer'} data={footers} />}
				</table>
			</div>
		);
	}
}

export default Table;
