import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { get as L } from '../models/Label';
import API from '../models/API';
import Controller from '../models/Controller';
import System from '../models/System';
import { validateNumber } from '../utils/UValidate';
import AppBar from '../components/AppBar';
import { getDateString } from '../utils/UDate';
import moment from 'moment';
import pinIcon from '../assets/images/farm-marker-icon.svg';
import ImageBackground from '../components/ImageBackground';

class ActivityDetailPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activity: {},
        };

        //check id
        const _path = System.matchPath(this.props.location.pathname, 'news/:newsId');
        if (_path) {
            this.id = validateNumber(_path.params.newsId);
        }
        if (!this.id) {
            this.isError = true;
            this.props.history.replace(System.resolvePath('home'));
            return;
        }

        //init query string
        const params = new URLSearchParams(this.props.location.search);
        const formData = {
        };

        this.controller = new Controller(this, {
        });
    }

    componentDidMount() {
        if (this.isError) {
            return;
        }
        this.loadData();
    }

    loadData() {
        this.controller.loadData(API.API_NEWS + '/' + this.id, 'news', { read: 1 }, (data) => {
            // console.log(data);
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    render() {
        const c = this.controller;
        const { news } = this.state;
        const data = news?.response;
        if (!data) {
            return <div className="section-content"><div className="container pb-5"></div></div>;
        }
        const { images, paragraphs } = data;
        const dop = { 'type': 'short', 'noDay': true };
        return (
            <div className="section-content">
                <div className="container pb-5">
                    <AppBar
                        title={L('เนื้อหาข่าว')}
                        showLeftArrow={true}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <div className="mb-4">
                        <div className="row mb-4">
                            <div className="col-6 label-new-date">{getDateString(moment(data.created_at), dop)}</div>
                            <div className="col-6 label-new-date text-right">{L('อ่านแล้ว')} {data.read_count} {L('ครั้ง')}</div>
                            <div className="col-12 label-news-name">{data.name}</div>
                            {images && images.length !== 0 && <div className="col-12 my-4">
                                <img width={'100%'} src={System.resolveImagePath(images[0].image, 'news')} alt={data.name} />
                            </div>}
                            <div className="col-12 label-new-desc mb-4">{data.description}</div>
                            {paragraphs?.map((item, index) =>
                                <React.Fragment key={"paragraph_" + index}>
                                    <div className="col-12 d-flex justify-content-center mb-3">
                                        <img className="image-activity" src={System.resolveImagePath(item.image, 'news')} alt={L(item.description)} />
                                    </div>
                                    <div className="col-12 pre-line font-size-1 mb-4">{item.description}</div>
                                </React.Fragment>
                            )}
                            {data.reference && <div className="col-12 label-new-ref">{L('ขอบคุณข่าวจาก')} : {data.reference}</div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ActivityDetailPage);
