import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import ActivityLeftSectionContent from './ActivityLeftSectionContent';

class ActivityLeftSection extends PureComponent {

    constructor(props) {
        super(props);
    }

    // componentDidUpdate() {
    //     console.log(this.props.data);
    // }

    render() {
        // console.log(this.props.data);
        return (
            <div className="section-left">
                <div className="position-sticky py-3 scroll-inner" style={{ top: '64px', height: 'calc(100vh - 64px)' }}>
                    <ActivityLeftSectionContent data={this.props.data} onChange={this.props.onChange} />
                </div>
            </div>
        );
    }
}

export default withRouter(ActivityLeftSection);
