import React, { Component } from 'react';

class TableBody extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		// console.log("TBody render");
		const { id, data, headers } = this.props;
		// const len = headers.length;
		// console.log(data);
		let tmp, value;
		return (
			<tbody>
				{data && data.map((row, i) => {
					return <tr key={id + '_' + i} onClick={this.props.rowClickHandler.bind(this, row)}>
						{row.id === 'dummy' ? <td colSpan={headers.length}></td> : headers.map((col, j) => {
							// console.log(row, col);
							if (col.render) {
								return <td
									key={id + '_' + i + '_' + j}
									className={col.className}>{col.render(row, col, i, j)}</td>;
							}
							tmp = col.data?.split('.');
							if (tmp[1]) {
								value = row[tmp[0]][tmp[1]];
							} else {
								value = row[tmp[0]];
							}
							return <td className={col.className}>{value}</td>;
						})}
					</tr>
				})}
			</tbody>
		);
	}
}

export default TableBody;
