import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import API from '../models/API';
import Controller from '../models/Controller';
import { get as L } from '../models/Label';
import System from '../models/System';
import Pagination from '../components/Pagination';
import { isEmpty } from '../utils/UObj';
import { Link } from 'react-router-dom';
import HLine from '../components/HLine';
import AppBar from '../components/AppBar';

import deleteIcon from '../assets/images/delete-item-icon.svg';
import Inbox from '../models/Inbox';
import MenuItem from '../components/MenuItem';
import Event from '../models/Event';
import { isMobile } from 'react-device-detect';

class UserInboxPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            inbox: {
                response: {
                    page: 1,
                    total_row: 1,
                    list: [
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                    ]
                }
            }
        };

        //init query string
        const params = new URLSearchParams(this.props.location.search);
        const formData = {
            search_string: params.get('search_string') ?? '',
            page: params.get('page') ?? 1,
        };
        this.controller = new Controller(this, {
            search_columns: 'title,description,order.code',
            limit: 20,
            ...formData
        });

        //check prop
        // if (!this.props.location.parantPath) {
        //     this.isError = true;
        //     this.props.history.replace(System.resolvePath('home'));
        //     return;
        // }
    }

    componentDidMount() {
        this.loadData();
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    loadData() {
        const params = { ...this.controller.all() };
        // params['sorts'] = 'created_at:DESC';
        this.controller.loadData(API.API_INBOX, 'inbox', params, (data) => {
            // console.log(data);
        }, () => {

        });
    }

    onInboxItemClickHanler(item) {
        Inbox.read(item);
        // item.status = 1;
        // this.controller.commit();
    }

    search(time = 800) {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            // console.log('timer');
            this.timer = null;

            //create query string
            const data = { ...this.controller.all() };
            for (let k in data) {
                if (isEmpty(data[k])) {
                    delete data[k];
                }
            }
            // console.log(data);
            this.props.history.push({
                search: "?" + new URLSearchParams(data).toString()
            });
            this.loadData();
        }, time);
    }

    confirmDelete() {
        this.controller.deleteData(API.API_INBOX + '/' + this.state.deleteTarget.id, () => {
            this.search(0);
            if (parseInt(this.state.deleteTarget.status) === 1) {
                Inbox.loadUnreadCount();
            }
        });
        window.hideModalDialog('deleteModal');
    }

    async delete(deleteTarget) {
        await this.setState({ openDeleteDialog: true, deleteTarget });
        window.openModalDialog('deleteModal', () => {
            this.setState({ openDeleteDialog: false, deleteTarget: null });
        });
    }

    getItem(item) {
        let icon = null;
        let path = null;
        if (item.shop) {
            icon = System.resolveImagePath(item.shop.logo, 'shop');
            if (item.order_id) {
                path = System.resolvePath('user/order/' + item.order_id);
            } else {
                if (item.product_id) {
                    path = System.resolvePath('market/product/' + item.product_id);
                }
            }
        } else if (item.user) {
            if (item.order_id) {
                path = System.resolvePath('user/customer/order/' + item.order_id);
            } else {
                if (item.product_id) {
                    path = System.resolvePath('market/product/' + item.product_id);
                }
            }
            icon = System.resolveImagePath(item.user.profile_image, 'profile');
        }
        // console.log(icon);
        return <>
            <MenuItem
                name={item.id}
                key={'inbox_' + item.id}
                className="font-size-05"
                iconFill
                src={icon}
                label={item.title}
                sublabel={item.description}
                fullSublabel
                path={path}
                iconBackgroundColor="#F2F2F2"
                iconUnread={parseInt(item.status) === 0}
                trailing={<div className="ml-3">
                    <button
                        className="btn btn-danger btn-image"
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.delete(item);
                        }}>
                        <img width={16} height={16} src={deleteIcon} alt={L('ลบข้อความ')} />
                    </button>
                </div>}
                onClick={this.onInboxItemClickHanler.bind(this, item)}
            />
            <HLine className="border-ligth my-2" />
        </>;
    }

    render() {

        const { inbox, openDeleteDialog } = this.state;
        const c = this.controller;
        const { page, search_string } = c.all();
        const { list, total_rows } = inbox?.response ?? {};

        return (
            <div className="section-content">
                <div className="cart-list">

                    <AppBar
                        title={L('รายการข้อความ')}
                        subTitle={L('กรุณาตรวจสอบข้อความก่อนลบ')}
                        showLeftArrow={isMobile}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />

                    <div className="form-row mb-4">
                        <div className="col">
                            <input
                                type="text"
                                className="form-control input-search flex-1 mr-3"
                                style={{ backgroundColor: '#FAFAFA' }}
                                placeholder={L('ชื่อลูกค้า, สินค้า, ร้านค้า, รหัสรายการสินค้า')}
                                defaultValue={search_string}
                                onChange={(event) => {
                                    c.set('search_string', event.target.value);
                                    this.search();
                                }}
                            />
                        </div>
                    </div>

                    {list && list.map((item) => this.getItem(item))}

                    <div className="py-3">
                        <Pagination
                            id="pagination_inbox"
                            onChange={(name, value) => {
                                if (name === Pagination.PAGE_CHANGE) {
                                    if (value !== page) {
                                        // console.log(value);
                                        this.controller.set('page', value);
                                        this.search(0);
                                        System.scrollTo(0, 0);
                                    }
                                }
                            }}
                            data={{
                                pageSize: System.MAX_ROWS,
                                dataLength: total_rows ?? 0,
                                currentPage: page,
                            }} />
                    </div>
                </div>

                {openDeleteDialog && <div id="deleteModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('ลบข้อมูล')}</h5>
                            </div>
                            <div className="modal-body">
                                <p>{L('คุณต้องการลบข้อมูลนี้ใช่หรือไม่')}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-3"
                                    onClick={() => {
                                        window.hideModalDialog('deleteModal');
                                        this.setState({ openDeleteDialog: false });
                                    }}>{L('ยกเลิก')}</button>
                                <button
                                    type="button"
                                    className="btn btn-primary mr-3"
                                    onClick={() => {
                                        this.confirmDelete();
                                    }}>{L('ลบข้อมูล')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}

export default withRouter(UserInboxPage);