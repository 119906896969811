import System from '../models/System'

const thaiDays = ['', 'อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์'];
export const thaiShortDays = ['', 'อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'];
const thaiFullMonths = ['', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
const thaiShortMonths = ['', 'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];

export const engFullDays = ['', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const engShortDays = ['', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const engFullMonths = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const engShortMonths = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

let thaiMonthData;

const eng2thai = {
    [engFullDays[1]]: thaiDays[1],
    [engFullDays[2]]: thaiDays[2],
    [engFullDays[3]]: thaiDays[3],
    [engFullDays[4]]: thaiDays[4],
    [engFullDays[5]]: thaiDays[5],
    [engFullDays[6]]: thaiDays[6],
    [engFullDays[7]]: thaiDays[7],
};

export const getDateString = (m, options) => {

    if (!m) {
        return '';
    }

    let d;
    let tmp;

    //optinal
    let noTime;
    let noDay;
    let noDate;
    let noMonth;
    let noYear;
    let type;
    if (options) {
        noTime = options['noTime'];
        noDay = options['noDay'];
        noDate = options['noDate'];
        noMonth = options['noMonth'];
        noYear = options['noYear'];
        type = options['type'];
    }

    let str = ''

    try {
        if (System.lang === System.LANG_TH) {
            if (type === 'short') {
                d = m.format('dddd-D-M-YYYY-HH:mm');
                tmp = d.split('-');
                str = '';
                if (!noDay) str += ' ' + eng2thai[tmp[0]] + ',';
                if (!noDate) str += ' ' + tmp[1];
                if (!noMonth) str += ' ' + thaiShortMonths[tmp[2]];
                if (!noYear) str += ' ' + (parseInt(tmp[3]) + 543).toString().substring(2, 4);
                if (!noTime) str += ' เวลา ' + tmp[4] + ' น.';
                str = str.substring(1, str.length);
            } else {
                d = m.format('dddd-D-M-YYYY-HH:mm');
                tmp = d.split('-');
                str = '';
                if (!noDay) str += ' ' + eng2thai[tmp[0]] + ',';
                if (!noDate) str += ' ' + tmp[1];
                if (!noMonth) str += ' ' + thaiFullMonths[tmp[2]];
                if (!noYear) str += ' ' + (parseInt(tmp[3]) + 543);
                if (!noTime) str += ' เวลา ' + tmp[4] + ' น.';
                str = str.substring(1, str.length);
            }
        } else {
            if (type === 'short') {
                d = m.format('dddd-D-M-YYYY-HH:mm');
                tmp = d.split('-');
                str = '';
                if (!noDay) str += ' ' + tmp[0] + ',';
                if (!noDate) str += ' ' + tmp[1];
                if (!noMonth) str += ' ' + engFullMonths[tmp[2]];
                if (!noYear) str += ' ' + tmp[3].toString().substring(2, 4);
                if (!noTime) str += ' ' + tmp[4];
            } else {
                d = m.format('dddd-D-M-YYYY-HH:mm');
                tmp = d.split('-');
                str = '';
                if (!noDay) str += ' ' + tmp[0] + ',';
                if (!noDate) str += ' ' + tmp[1];
                if (!noMonth) str += ' ' + engShortMonths[tmp[2]];
                if (!noYear) str += ' ' + tmp[3];
                if (!noTime) str += ' ' + tmp[4];
            }
        }
    } catch (error) {
        console.log(error);
    }

    return str;
}

export const getTimeOptions = () => {
    const st = 6;
    const len = 48;
    let hour = st;
    let min = '';
    const times = [];
    let tt = '';
    for (let i = 0; i < len; i++) {
        if (i % 2 === 0) {
            min = ':00';
            if (hour < 10) {
                tt = '0' + hour + min;
            } else {
                tt = hour + min;
            }
        } else {
            min = ':30';
            if (hour < 10) {
                tt = '0' + hour + min;
            } else {
                tt = hour + min;
            }
            hour++;
        }
        if (hour > 23) {
            hour = 0;
        }
        times.push(tt);
    }
    return times;
}

export const getMinutes = (m) => {
    // console.log(m.years(), m.months(), m.days(), m.hours(), m.minutes())
    // return moment.duration({
    //     minutes: m.minutes(),
    //     hours: m.hours(),
    //     days: m.days(),
    //     weeks: m.weeks(),
    //     months: m.months(),
    //     years: m.year(),
    // }).asMinutes();
    return Math.floor(m.unix() / 60);
}

export const getThaiMonthData = () => {
    if (thaiMonthData) {
        return thaiMonthData;
    }
    thaiMonthData = [];
    for (let i = 1; i <= 12; i++) {
        thaiMonthData.push({
            id: i,
            label: thaiFullMonths[i]
        });
    }
    return thaiMonthData;
}

export const setTime = (m, time) => {
    if (!time) {
        return m.clone();
    }
    const t = time.split(':');
    t[0] = parseInt(t[0]);
    t[1] = parseInt(t[1]);
    return m.clone().set("hour", t[0]).set("minute", t[1]);
}