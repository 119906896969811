import React, { Component } from 'react';
import { get as L } from '../models/Label';

import InputBox from '../components/InputBox';

import removeImageIcon from '../assets/images/close-icon.svg';
import ErrorText from '../components/ErrorText';
import InputDropListBox from './InputDropListBox';
import { getThaiMonthData } from '../utils/UDate';

class HarvestForm extends Component {

    constructor(props) {
        super(props);
        this.months = getThaiMonthData().concat();
        this.months.push({ id: 13, label: 'ปลูกทั้งปี' });
    };

    render() {
        const { controller, index } = this.props;
        return <div className="position-relative form-dynamic form-register-farm">
            <div className="form-row">
                <div className="col-6">
                    <InputDropListBox
                        label={L('เดือนที่เริ่มปลูก')}
                        placeholder={L('เลือกเดือน')}
                        name="harvests.start_month"
                        index={index}
                        controller={controller}
                        data={this.months} />
                </div>
                <div className="col-6">
                    <InputBox
                        label={L('ระยะเวลาปลูก (วัน)')}
                        placeholder={L('จำนวนวัน')}
                        name={'harvests.duration'}
                        index={index}
                        type="number"
                        controller={controller}
                    />
                </div>
                <div className="col-6">
                    <InputBox
                        label={L('ปริมาณผลผลิต')}
                        placeholder={L('ปริมาณผลผลิต')}
                        name={'harvests.volume'}
                        index={index}
                        type="number"
                        controller={controller}
                    />
                </div>
                <div className="col-6">
                    <InputDropListBox
                        label={L('หน่วย')}
                        placeholder={L('เลือกหน่วย')}
                        name={'harvests.unit_id'}
                        index={index}
                        data={this.props.units}
                        controller={controller}
                    />
                </div>
                {this.props.showCloseButton === true && <img
                    className="btn-remove-form"
                    src={removeImageIcon}
                    alt={L('ลบ')}
                    onClick={this.props.closeButtonClickHandler.bind(this, this.props.index)} />}
                <ErrorText isError={this.props.isError}>{L('กรุณากรอกข้อมูลการเก็บเกี่ยวผลผลิตให้ถูกต้อง')}</ErrorText>
            </div>
        </div>;
    }
}

export default HarvestForm;
