import React, { Component } from 'react';
import { get as L } from '../models/Label';

import logo from '../assets/images/logo_256.png';
import searchIcon from '../assets/images/search-icon-mobile.svg';
import chatIcon from '../assets/images/chat-icon-mobile.svg';
import bellIcon from '../assets/images/bell-icon-mobile.svg';
import loginIcon from '../assets/images/login-icon.svg';

import Avatar from './Avatar';
import MenuItem from './MenuItem';
import System from '../models/System';
import { Link } from 'react-router-dom';

class MHeader extends Component {

    constructor(props) {
        super(props);
    };

    render() {
        const logged = System.isLogged();
        return (
            <div className="header align-items-center">
                <Link to={System.resolvePath('')}><img src={logo} className="header-logo" alt={L('โลโก้')} /></Link>
                {logged
                    ? <div className="d-flex">
                        <Avatar
                            className="mr-3"
                            style={menuItemStyle}
                            src={searchIcon}
                            iconWidth='16px'
                            iconHeight='16px'
                            backgroundColor='#E0E0E0' />
                        <Link to={System.resolvePath('chat/contact')}>
                            <Avatar
                                className="mr-3"
                                style={menuItemStyle}
                                src={chatIcon}
                                iconWidth='16px'
                                iconHeight='16px'
                                backgroundColor='#E0E0E0' />
                        </Link>
                        <Link to={System.resolvePath('user/inbox')}>
                            <Avatar
                                style={menuItemStyle}
                                src={bellIcon}
                                iconWidth='18px'
                                iconHeight='18px'
                                backgroundColor='#E0E0E0' />
                        </Link>
                    </div>
                    : <Link to={System.resolvePath('login')}>
                        <MenuItem
                            labelMargin="ml-2"
                            iconBackgroundColor='#E0E0E0'
                            src={loginIcon}
                            iconWidth='18px'
                            iconHeight='18px'
                            iconStyle={menuItemStyle}
                            label={L('เข้าสู่ระบบ')} />
                    </Link>}
            </div>
        );
    }
}

const menuItemStyle = { width: '32px', height: '32px' };

export default MHeader;
