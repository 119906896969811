import React, { PureComponent } from 'react';
import ErrorText from './ErrorText';

// const slectedStyle = {};
// const style = {};

class SelectionInput extends PureComponent {

    constructor(props) {
        super(props);
        // console.log(this.props.selecteds);
    };

    getItem(item) {
        let active;
        if (Array.isArray(this.props.selecteds)) {
            active = this.props.selecteds.indexOf(parseInt(item.id)) !== -1 ? ' active' : '';
        } else {
            active = this.props.selecteds === parseInt(item.id) ? ' active' : '';
        }
        return <div
            key={item.id}
            className={'input-multi-select-item pointer noselect' + active}
            onClick={this.props.clickHandler.bind(this, item)}
        >
            {item.name}
        </div>;
    }

    render() {
        const { data } = this.props;
        // console.log(data);
        const content = <>
            <div className={'d-flex flex-wrap ' + (this.props.className ?? '')}>
                {data.map((item) => this.getItem(item))}
            </div>
            <ErrorText isError={this.props.isError}>{this.props.errorMessage}</ErrorText>
        </>;

        if (!this.props.label) {
            return content;
        }

        return (
            <div className="form-group">
                <label htmlFor={this.id} className="mb-0">{this.props.label}</label>
                {content}
            </div>
        );

    }
}

export default SelectionInput;
