import React, { PureComponent } from 'react';

class Card extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        const { className } = this.props;
        return (
            <div className={"card-box " + className ?? ''} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}

export default Card;
