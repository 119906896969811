import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';
import SelectionInput from '../components/SelectionInput';
import System from '../models/System';
import Controller from '../models/Controller';
import Pagination from '../components/Pagination';
import { Link } from 'react-router-dom';
import API from '../models/API';
import ActivityList from '../components/ActivityList';
import { isMobile } from 'react-device-detect';
import AppBar from '../components/AppBar';

class UserActivityPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activity: {
                response: {
                    page: 1,
                    total_rows: 12,
                    list: [
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' }
                    ]
                }
            }
        };

        this.controller = new Controller(this, {
            search_string: '',
            search_columns: 'name,description',
            page: 1,
            limit: 20,
            product_services: [],
            // min_price
            // max_price
            // product_services
            // rating
            // sorts
            // search_columns
        });
        if (!System.isFarmer()) {
            this.props.history.replace(System.resolvePath('home'));
            return;
        }
    }

    componentDidMount() {
        this.loadData();
        this.loadActivityService();
    }

    loadData() {
        this.setState({
            activity: {
                response: {
                    page: 1,
                    total_rows: 12,
                    list: [
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' },
                        { id: 'dummy' }
                    ]
                }
            }
        });
        const params = { ...this.controller.all() };
        const index = params['product_services'].indexOf(-1);
        if (index !== -1) {
            delete params['product_services'];
        }
        this.controller.loadData(API.API_USER_ACTIVITY, 'activity', params, (data) => {
            // console.log(data);
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    loadActivityService() {
        this.controller.loadData(API.API_ACTIVITY_SERVICE, 'activityService', { filter: 0 }, (data) => {
            data.unshift({ id: -1, name: 'ทั้งหมด' });
            const selecteds = [];
            for (let k in data) {
                selecteds.push(data[k].id);
            }
            this.controller.set('product_services', selecteds);
        });
    }

    search(time = 800) {
        // console.log(text);
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            // console.log('timer');
            this.timer = null;
            this.loadData();
        }, time);
    }

    getAppBar() {
        if (!isMobile) {
            return null;
        }
        return <AppBar
            title={L('รายการกิจกรรม')}
            showLeftArrow={true}
            onBackPress={() => {
                this.props.history.goBack();
            }}
        />;
    }

    render() {
        const controller = this.controller;
        const data = controller.all();
        let { product_services, search_string, page } = data;
        const { activity, activityService } = this.state;
        const response = activity?.response ?? {};
        const { list, total_rows } = response;

        // console.log(p);
        return (
            <div className="section-content">
                <div className="section-market flex-1">

                    {this.getAppBar()}

                    <div className="row">
                        <div className="col-6">
                            <input
                                defaultValue={search_string}
                                type="text"
                                className="form-control input-search"
                                style={{ backgroundColor: '#FAFAFA' }}
                                placeholder={L('ค้นหากิจกรรม')}
                                onChange={(event) => {
                                    this.controller.set('search_string', event.target.value);
                                    this.search()
                                }} />
                        </div>
                        <div className="col-auto d-flex align-items-center">
                            <Link to={System.resolvePath('user/activity/create')}>
                                <button type="button" className="btn btn-primary">{L('เพิ่มกิจกรรม')}</button>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <SelectionInput
                                className="filter mt-2 mb-3"
                                data={activityService?.response ?? []}
                                selecteds={product_services}
                                clickHandler={(item) => {
                                    let index = product_services.indexOf(parseInt(item.id));
                                    if (index === -1) { //going to active
                                        const data = activityService?.response;
                                        if (item.id === -1) {
                                            if (product_services.indexOf(-1) !== -1) {
                                                return; //all already active
                                            }
                                            const selecteds = [];
                                            for (let k in data) {
                                                selecteds.push(data[k].id);
                                            }
                                            product_services = selecteds;
                                        } else {
                                            product_services.push(parseInt(item.id));
                                            if (product_services.length === data.length - 1) {
                                                product_services.push(-1);
                                            }
                                        }
                                    } else { //going to unactive
                                        if (item.id !== -1) {
                                            product_services.splice(index, 1);
                                            index = product_services.indexOf(-1);
                                            if (index !== -1) {
                                                product_services.splice(index, 1);
                                            }
                                        } else {
                                            return; //all already active
                                        }
                                    }
                                    controller.set('product_services', product_services, true);
                                    this.search(search_string);
                                }} />
                        </div>
                    </div>

                    <ActivityList data={list} path="user/activity/" />

                    <div className="py-3">
                        <Pagination
                            id="pagination_activity"
                            onChange={(name, value) => {
                                if (name === Pagination.PAGE_CHANGE) {
                                    if (value !== page) {
                                        // console.log(value);
                                        this.controller.set('page', value);
                                        this.search(0);
                                        System.scrollTo(0, 0);
                                    }
                                }
                            }}
                            data={{
                                pageSize: System.MAX_ROWS,
                                dataLength: total_rows ?? 0,
                                currentPage: page,
                            }} />
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(UserActivityPage);
