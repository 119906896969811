import React, { Component } from 'react';
import { isEmpty } from '../utils/UObj';
import AddImageButton from './AddImageButton';
import ErrorText from './ErrorText';

class InputImageBox extends Component {

    constructor(props) {
        super(props);
        // console.log(props);
        if (this.props.name) {
            this.id = 'input_' + this.props.name + '_' + (this.props.index ?? '');
        } else {
            this.id = 'input_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000);
        }

        let images;
        const tmp = this.props.name.split('.');
        if (tmp && tmp[1]) {
            this.parent = tmp[0];
            this.prop = tmp[1];
            this.index = this.props.index;
            const parent = this.props.controller.get(this.parent);
            // console.log(parent[this.index][this.prop]);
            images = parent[this.index][this.prop] ?? [];
        } else {
            this.prop = this.props.name;
            images = this.props.controller.get(this.props.name) ?? [];
        }

        let mapVideo = {};
        for (let k in images) {
            let image = images[k].image;
            mapVideo[images[k]] = image.indexOf('.mp4') !== -1 || image.indexOf('.mov') !== -1;
        }

        this.state = {
            images,
            mapVideo,
        };
    };

    componentDidUpdate() {
        this.index = this.props.index;
        let images;
        if (this.parent) {
            const parent = this.props.controller.get(this.parent);
            images = parent[this.index][this.prop];
        } else {
            images = this.props.controller.get(this.prop);
        }
        let mapVideo = {};
        let image;
        for (let k in images) {
            image = images[k];
            if (!isEmpty(image) && typeof image === 'object') { //remove only edit mode
                let image = images[k].image;
                mapVideo[images[k]] = image.indexOf('.mp4') !== -1 || image.indexOf('.mov') !== -1;
            } else {

            }
        }
        if (this.state.images !== images) {
            this.setState({ images, mapVideo });
        }
    }

    itemClick(index) {
        // console.log(index);
        const images = this.state.images;
        const image = images[index];
        if (!isEmpty(image) && typeof image === 'object') { //remove only edit mode
            let removeImages;
            if (this.parent) {
                const parent = this.props.controller.get(this.parent);
                removeImages = parent[this.index]['remove_' + this.prop] ?? [];
                removeImages.push(image.id);
                this.props.controller.set(this.parent, parent);
            } else {
                removeImages = this.props.controller.get('remove_' + this.props.name) ?? [];
                removeImages.push(image.id);
                this.props.controller.set('remove_' + this.props.name, removeImages);
            }
        }
        images.splice(index, 1);
        this.setState({ images: images });
        //set to controller
        if (this.parent) {
            const parent = this.props.controller.get(this.parent);
            parent[this.index][this.prop] = images;
            this.props.controller.set(this.parent, parent);
        } else {
            this.props.controller.set(this.prop, images);
        }
    }

    onImageChange(e) {
        if (e.target.files && e.target.files.length > 0) {
            const max = this.props.max ?? 10;
            const files = e.target.files;
            const self = this;
            const { images, mapVideo } = self.state;
            const len = Math.min(files.length, Math.max(0, max - images.length));
            let file, tmpPath;
            // console.log(len);
            for (let i = 0; i < len; i++) {
                file = files[i];
                // console.log(file);
                tmpPath = URL.createObjectURL(file);
                if (images.indexOf(tmpPath) !== -1) {
                    continue;
                }
                images.push(tmpPath);
                mapVideo[tmpPath] = (file.type ?? '').indexOf('video') !== -1;
                //set to controller
                if (self.parent) {
                    const parent = self.props.controller.get(self.parent);
                    parent[self.index][self.prop] = images;
                    self.props.controller.set(self.parent, parent);
                } else {
                    self.props.controller.set(self.prop, images);
                }
            }
            if (tmpPath) {
                self.setState({ images, mapVideo });
            }
        }
    }

    render() {
        const { images, mapVideo } = this.state;
        const { description, allowVideo, allowGIF } = this.props;
        const max = this.props.max ?? 10;
        let accept = 'image/x-png,image/jpeg,image/jpg';
        if (allowGIF) {
            accept += ",image/gif"
        }
        if (allowVideo) {
            accept += ",video/mp4"
        }
        // console.log(mapVideo);
        return (
            <div className="form-group">
                <label htmlFor={this.id}>{this.props.label}</label>
                {description && <div className="form-desc pb-2">{description}</div>}
                <div className="box-input-image">
                    {images && images.map((v, index) => <AddImageButton
                        key={'btn_image_' + index}
                        src={v}
                        isVideo={mapVideo[v]}
                        path={this.props.path}
                        onClick={this.itemClick.bind(this, index)} />)}

                    {images.length < max && <label htmlFor={"upload_" + this.id}>
                        <AddImageButton />
                        <input
                            type="file"
                            id={"upload_" + this.id}
                            accept={accept}
                            className="d-none"
                            onChange={this.onImageChange.bind(this)}
                            multiple={max > 1}
                        />
                    </label>}
                </div>
                <ErrorText isError={this.props.isError}>{this.props.errorMessage}</ErrorText>
            </div>
        );
    }
}

export default InputImageBox;
