import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import NewsBox from '../components/NewsBox';
import API from '../models/API';
import Controller from '../models/Controller';
import System from '../models/System';

class NewPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.controller = new Controller(this, {
        });
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.controller.loadData(API.API_NEWS, 'news', null, (data) => {
            // console.log(data);
        });
    }

    componentWillUnmount() {
        this.controller.destroy();
    }


    getNewsItem(item, path) {
        return <NewsBox data={item} path={path + item.id} />
    }

    render() {
        const { news } = this.state;
        const { list } = news?.response ?? {};
        const path = System.resolvePath('news/');
        return (
            <div className="section-content">
                <div className="news-list">
                    {list && list.map((item) => this.getNewsItem(item, path))}
                </div>
            </div>
        );
    }
}

export default withRouter(NewPage);
