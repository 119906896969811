import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { get as L } from '../models/Label';

import InputBox from '../components/InputBox';
import FormTitle from '../components/FormTitle';
import Controller from '../models/Controller';
import Form from '../components/Form';
import Button from '../components/Button';

import { validateEmptyValue, validateNumber } from '../utils/UValidate';
import { isEmpty } from '../utils/UObj';

import AppBar from '../components/AppBar';
import System from '../models/System';
import InputDropListBox from '../components/InputDropListBox';
import HarvestForm from '../components/HarvestForm';
import TextAreaBox from '../components/TextAreaBox';
import API from '../models/API';

class UserPlantPage extends Component {

    constructor(props) {
        super(props);

        let pathData = System.matchPath(this.props.location.pathname, 'user/field/:fieldId/plant/:plantId');
        this.plantId = pathData?.params?.plantId;
        if (this.plantId) {
            this.fieldId = pathData.params.fieldId;
        } else {
            pathData = System.matchPath(this.props.location.pathname, 'user/field/:fieldId/plant');
            if (!pathData || !pathData.params.fieldId) {
                this.props.history.replace(System.resolvePath('home'));
                return;
            }
        }
        this.fieldId = pathData.params.fieldId;

        const params = new URLSearchParams(this.props.location.search);
        this.fieldName = params.get('name');

        this.state = {
            openDeleteDialog: false,
            valid: {
                harvests: []
            },
            plantGroups: [],
        };

        this.controller = new Controller(this, {
            plant_group: '',
            harvests: [{
                id: 'harvest_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000),
                start_month: '',
                duration: '',
                volume: '',
                unit_id: ''
            }],
        });

        this.plantMap = {};

        if (!System.isFarmer()) {
            this.props.history.replace(System.resolvePath('home'));
            return;
        }
    };

    componentDidMount() {
        // console.log('this.plantId', this.plantId);
        if (this.plantId) {
            this.controller.loadData(API.API_USER_FARM_FIELD + '/' + this.fieldId + '/plants/' + this.plantId, 'data', null, (data) => {
                this.controller.set('plant_group', data.plant.plant_group_id);
                this.controller.append({ ...data });
                this.loadFieldPlantCategories();
                this.loadUnit();
            });
        } else {
            this.loadFieldPlantCategories();
            this.loadUnit();
        }
    }

    loadFieldPlantCategories() {
        this.controller.loadData(API.API_PLANT_CATEGORY, 'categories', { filter: 0 }, (data) => {
            if (!this.controller.get('plant_category_id')) {
                this.controller.set('plant_category_id', (data && data.length ? data[0].id : ''));
            }
            this.loadPlants();
        });
    }

    loadPlants() {
        this.controller.loadData(API.API_PLANT, 'plants', { filter: 0 }, (data) => {
            this.plantMap = {};
            const plantGroups = [];
            // console.log(data);
            let obj, group;
            for (let key in data) {
                obj = data[key];
                group = obj['plant_group'];
                if (!this.plantMap[group.id]) {
                    this.plantMap[group.id] = [];
                    plantGroups.push(group);
                }
                this.plantMap[group.id].push(data[key]);
            }
            this.setState({ plantGroups });
        });
    }

    loadUnit() {
        this.controller.loadData(API.API_UNIT + '?filter=0', 'units');
    }

    componentWillUnmount() {
        this.controller.destroy();
    }

    closeButtonClickHandler(index) {
        // console.log(index);
        const harvests = this.controller.get('harvests');
        const field = harvests[index];
        if (validateNumber(field.id)) {
            const removeFields = this.controller.get('remove_harvests') ?? [];
            removeFields.push(field.id);
            this.controller.set('remove_harvests', removeFields);
        }
        harvests.splice(index, 1);
        this.controller.set('harvests', harvests, true);
    }

    submit() {
        this.setState({ valid: {} });

        const c = this.controller;
        const params = { ...c.all() };

        const plant_id = params['plant_id'] ?? '';
        const rai = params['rai'];
        const ngan = params['ngan'];
        const square_wa = params['square_wa'];

        const valid = {
            plant_id: validateEmptyValue(plant_id),
            harvests: []
        };

        let pass1 = validateNumber(rai);
        let pass2 = validateNumber(ngan);
        let pass3 = validateNumber(square_wa);
        if (!pass1 && !pass2 && !pass3) {
            valid.area = false;
        }

        //check harvest
        const { harvests } = params;
        let harvest;
        for (let key in harvests) {
            harvest = harvests[key];
            let pass1 = validateEmptyValue(harvest.start_month);
            let pass2 = validateNumber(harvest.duration);
            let pass3 = validateNumber(harvest.volume);
            let pass4 = validateEmptyValue(harvest.unit_id);
            // console.log(pass1, pass2, pass3, pass4);
            if (!pass1 || !pass2 || !pass3 || !pass4) {
                valid.harvests[key] = false;
            }
        }

        for (let k in valid) {
            if (valid[k] === false) {
                this.setState({ valid });
                return;
            }
            if (Array.isArray(valid[k])) {
                for (let i in valid[k]) {
                    if (valid[k][i] === false) {
                        this.setState({ valid });
                        return;
                    }
                }
            }
        }

        if (isEmpty(this.plantId)) {
            this.controller.postData(API.API_USER_FARM_FIELD + '/' + this.fieldId + '/plants',
                (data) => {
                    this.props.history.goBack();
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        } else {
            this.controller.updateData(API.API_USER_FARM_FIELD + '/' + this.fieldId + '/plants/' + this.plantId,
                (data) => {
                    this.props.history.goBack();
                },
                (error) => {
                    window.openMessageModalDialog(error);
                });
        }

    }

    async delete() {
        await this.setState({ openDeleteDialog: true });
        window.openModalDialog('deleteModal', () => {
            this.setState({ openDeleteDialog: false });
        });
    }

    confirmDelete() {
        this.controller.deleteData(API.API_USER_FARM_FIELD + '/' + this.fieldId + '/plants/' + this.plantId,
            (data) => {
                window.hideModalDialog('deleteModal');
                this.setState({ openDeleteDialog: false });
                this.props.history.goBack();
            },
            (error) => {
                window.openMessageModalDialog(error);
            });
    }

    render() {
        // console.log('UserFarmPage render');
        const c = this.controller;
        const data = c.all();
        let { plant_group, harvests } = data;
        const { valid, post, update, destroy, categories, units, openDeleteDialog, plants, plantGroups } = this.state;
        // console.log(this.plantMap, plant_group);
        const loading = (post && post.loading) || (update && update.loading) || (destroy && destroy.loading);
        return (
            <div className="section-content no-nav">
                <Form
                    loading={loading}
                    className="form-register">
                    <AppBar
                        title={L('ข้อมูลพืชที่ปลูกในแปลง') + ' "' + (this.fieldName ?? '') + '"'}
                        subTitle={L('กรอกข้อมูลข้อมูลพืชที่ปลูกและการเก็บเกี่ยว')}
                        onBackPress={() => {
                            this.props.history.replace(System.resolvePath('user/field'));
                        }}
                    />
                    <FormTitle label={L('ข้อมูลพืช')} />
                    <div className="form-row form-section-register">
                        <div className="col-12 col-sm-3">
                            <InputDropListBox
                                label={L('ประเภทการเพาะปลูก')}
                                name="plant_category_id"
                                controller={c}
                                data={categories?.response ?? []} />
                        </div>
                        <div className="col-12 col-sm-3">
                            <InputDropListBox
                                label={L('กลุ่ม')}
                                data={plantGroups}
                                value={plant_group}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    c.set('plant_group', value);
                                    c.set('plant_id', null, true);
                                }} />
                        </div>
                        <div className="col-12 col-sm-6">
                            <InputDropListBox
                                label={L('ชนิด')}
                                placeholder={L('เลือกชนิดพืช')}
                                name="plant_id"
                                controller={c}
                                data={this.plantMap[plant_group]}
                                isError={valid.plant_id === false}
                                errorMessage={L('กรุณาเลือกชนิดพืช')} />
                        </div>
                    </div>
                    <FormTitle
                        label={L('ขนาดพื้นที่เพาะปลูก')}
                        isError={valid.area === false}
                        errorMessage={L('กรุณากรอกข้อมูลขนาดพื้นที่เพาะปลูกให้ถูกต้อง')} />
                    <div className="form-row form-section-register">
                        <div className="col-12 col-sm-4">
                            <InputBox
                                label={L('ไร่')}
                                placeholder={L('จำนวนไร่')}
                                name={'rai'}
                                controller={c}
                                type="number"
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <InputBox
                                label={L('งาน')}
                                placeholder={L('จำนวนงาน')}
                                name={'ngan'}
                                controller={c}
                                type="number"
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <InputBox
                                label={L('ตารางวา')}
                                placeholder={L('จำนวนตารางวา')}
                                name={'square_wa'}
                                controller={c}
                                type="number"
                            />
                        </div>
                        <div className="col-12">
                            <TextAreaBox
                                label={L('รายละเอียดเพิ่มเติม')}
                                placeholder={L('รายละเอียด')}
                                name={'description'}
                                controller={c}
                            />
                        </div>
                    </div>
                    <FormTitle label={L('ช่วงเวลาการปลูกและเก็บเกี่ยว')}
                        onButtonPress={() => {
                            //test
                            harvests.push({
                                id: 'harvest_' + parseInt(Math.random() * 1000) + '_' + parseInt(Math.random() * 1000),
                                start_month: '',
                                duration: '',
                                volume: '',
                                unit_id: ''
                            });
                            this.controller.set('harvests', harvests, true);
                        }} />
                    <div className="form-row form-section-register">
                        <div className="col-12">
                            {harvests && harvests.map((v, i) => <HarvestForm
                                key={v.id}
                                index={i}
                                data={v}
                                units={units?.response ?? []}
                                isError={valid.harvests && valid.harvests[i] === false}
                                controller={c}
                                closeButtonClickHandler={this.closeButtonClickHandler.bind(this)}
                                showCloseButton={harvests.length > 1} />)}
                        </div>
                    </div>
                    <div className="form-row mt-4">
                        {this.plantId && <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => this.delete()}>
                                {L('ลบข้อมูล')}
                            </Button>
                        </div>}
                        <div className="col">
                            <Button
                                loading={loading}
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.submit()}>
                                {L('บันทึกข้อมูล')}
                            </Button>
                        </div>
                    </div>
                </Form>

                {openDeleteDialog && <div id="deleteModal" className="modal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{L('ลบข้อมูล')}</h5>
                            </div>
                            <div className="modal-body">
                                <p>{L('คุณต้องการลบข้อมูลนี้ใช่หรือไม่')}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary mr-3"
                                    onClick={() => {
                                        window.hideModalDialog('deleteModal');
                                        this.setState({ openDeleteDialog: false });
                                    }}>{L('ยกเลิก')}</button>
                                <button
                                    type="button"
                                    className="btn btn-primary mr-3"
                                    onClick={() => {
                                        this.confirmDelete();
                                    }}>{L('ลบข้อมูล')}</button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}

export default withRouter(UserPlantPage);
