import React, { PureComponent } from 'react';

class EmptyBox extends PureComponent {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className={`box-empty ${this.props.className ?? ''}`}>
                <h3 className="mb-4">{this.props.title}</h3>
                <img className="mb-4" src={this.props.icon} alt={this.props.title} />
                <div className="box-empty-description mb-4">{this.props.description}</div>
                {this.props.buttonLabel && <button
                    type="button"
                    className="btn btn-primary mr-3"
                    onClick={this.props.onButtonClick}>{this.props.buttonLabel}</button>}
            </div>
        );
    }
}

export default EmptyBox;
