import React, { PureComponent } from 'react';
import Avatar from './Avatar';

import { getBone } from '../utils/URender';
import System from '../models/System';
import { isMobile } from 'react-device-detect';

import { get as L } from '../models/Label';

import backIcon from '../assets/images/back-icon.svg';

// const dateOption = { type: 'short', noTime: true,noDay };

class ChatHeader extends PureComponent {

    constructor(props) {
        super(props);
    };

    onClick(e) {
        // console.log('MenuItem onClick');
        if (this.props.onClick) {
            // console.log('MenuItem this.props.onClick');
            this.props.onClick(e);
        }
    }

    getDummy() {
        return <div className="chat-item menu-item d-flex align-items-center inactive">
            <Avatar />
            <div className={`flex-1 mx-3 ellipsis`}>
                <div className={`color-black-1 font-size-0 ellipsis`}>
                    {getBone(150, 18, 4)}
                </div>
                <div className="sublabel">{getBone(280, 15, 3)}</div>
            </div>
        </div>;
    }

    render() {
        const { data } = this.props;
        if (!data) {
            return this.getDummy();
        }
        const { members } = data;
        const member = members[0];
        const user = member?.user;
        if (!user) {
            return null;
        }
        const shop = user.shop;
        const name = user.first_name + ' ' + user.last_name;
        const shopName = shop ? shop.name : '';
        return <div className="chat-item menu-item d-flex align-items-center inactive">
            {isMobile && <div className="btn-icon mr-3"
                onClick={() => this.props.onBackPress()}>
                <img src={backIcon} alt={L('ย้อนกลับ')} />
            </div>}
            <Avatar
                src={System.resolveImagePath(user.profile_image, 'profile')}
                iconFill />
            {shop && <Avatar
                className="ml-3"
                src={System.resolveImagePath(shop.logo, 'shop')}
                iconFill />}
            <div className={`flex-1 mx-3 ellipsis`}>
                <div
                    className={`color-black-1 font-size-0 ellipsis`}>
                    {name}
                </div>
                {shopName
                    ? <div className="sublabel ellipsis">{shopName}</div>
                    : <div className="sublabel">&nbsp;</div>}
            </div>
        </div>;
    }
}

export default ChatHeader;