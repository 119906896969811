import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '../components/AppBar';
import { get as L } from '../models/Label';
import ChatRightSectionContent from '../components/ChatRightSectionContent';

class ChatContactPage extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className="section-content no-nav">
            <div className="flex-1">
                <div className="container">
                    <AppBar
                        title={L('รายชื่อผู้สนทนา')}
                        showLeftArrow={true}
                        onBackPress={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <ChatRightSectionContent key="c_r_s_2" visible={true} />
                </div>
            </div>
        </div>;
    }
}

export default withRouter(ChatContactPage);
