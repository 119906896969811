import React, { PureComponent } from 'react';
import { isMobile } from 'react-device-detect';
import Controller from '../models/Controller';
import Icon from '../models/Icon';
import { get as L } from '../models/Label';

class ChatInput extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.controller = new Controller(this, {
          
        });
    };

    componentWillUnmount() {
        this.controller.destroy();
    }

    sendMessage() {
        const c = this.controller;
        if (this.props.sendHandler) {
            this.props.sendHandler(c.get('message'), null);
        }
        c.set('message', '', true);
    }

    addImage(e) {
        const images = [];
        if (e.target.files && e.target.files.length > 0) {
            const files = e.target.files;
            const self = this;
            let file;
            let reader;
            const len = Math.min(10, files.length);
            for (let i = 0; i < len; i++) {
                file = files[i];
                reader = new FileReader();
                reader.onload = function (evt) {
                    images.push(evt.target.result);
                    if (images.length >= len) {
                        if (self.props.sendHandler) {
                            self.props.sendHandler(null, images);
                        }
                    }
                }
                reader.readAsDataURL(file);
            }
        }
        e.target.value = null;
    }

    takePhoto() {

    }

    render() {
        const { data } = this.props;
        const c = this.controller;
        return (<div className="input-chat">
            {/* {isMobile !== false && <div className="btn-icon mr-3"
                onClick={() => this.takePhoto()}>
                <img src={Icon.get('camera')} alt={L('เพิ่มรูปถ่าย')} />
            </div>} */}
            <label htmlFor="images">
                <div className="btn-icon mr-3">
                    <img src={Icon.get('image')} alt={L('เพิ่มรูปภาพ')} />
                </div>
                <input
                    type="file"
                    id="images"
                    accept="image/x-png,image/jpeg,image/jpg"
                    className="d-none"
                    onChange={this.addImage.bind(this)}
                    multiple
                    capture={isMobile}
                />
            </label>
            <div className="flex-1 mr-3">
                <input
                    className="form-control"
                    type="text"
                    placeholder={L('พิมพ์ข้อความ...')}
                    value={c.get('message')}
                    onChange={(e) => c.set('message', e.target.value, true)}
                    onKeyPress={event => {
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            this.sendMessage();
                        }
                    }}
                ></input>
            </div>
            <div className="btn-icon mr-3"
                onClick={() => this.sendMessage()}>
                <img src={Icon.get('send')} alt={L('ส่งข้อความ')} />
            </div>
        </div>);
    }
}

export default ChatInput;